import styled from 'styled-components';

import PropTypes from 'prop-types';
import { colors } from '../../../utils';

const Indicator = styled.div`
    font-size: 13px;
    font-weight: bold;
    text-align: center;

    color: ${props =>
        props.$good ? colors.green.medium_aquamarine : colors.red.radical_red};
`;

Indicator.propTypes = {
    $good: PropTypes.bool
};

Indicator.defaultProps = {
    $good: false
};

export default Indicator;
