import React from 'react';
import PropTypes from 'prop-types';

function ButtonOff(props) {
  return <svg width={props.size} viewBox="0 0 54 32">
        <defs>
            <circle id="path-1" cx="38.25" cy="12" r="12" />
            <filter
                x="-29.2%"
                y="-20.8%"
                width="158.3%"
                height="158.3%"
                filterUnits="objectBoundingBox"
                id="filter-2">
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    stdDeviation="2"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                />
            </filter>
        </defs>
        <g
            id="Settings,-language-&amp;-call-timer"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
                id="web-trainer-settings-01-calltimeroff"
                transform="translate(-244.000000, -205.000000)">
                <g id="Group-6" transform="translate(247.000000, 207.000000)">
                    <g id="button-off">
                        <g
                            id="Group"
                            transform="translate(25.500000, 12.000000) scale(-1, 1) translate(-25.500000, -12.000000) ">
                            <g id="Group-3">
                                <g id="button-off">
                                    <circle
                                        id="Oval-3"
                                        fill="#D3D1D3"
                                        cx="12"
                                        cy="12"
                                        r="12"
                                    />
                                    <rect
                                        id="Rectangle-16"
                                        fill="#D3D1D3"
                                        x="9.75"
                                        y="0"
                                        width="30"
                                        height="24"
                                    />
                                    <g id="Oval-3">
                                        <use
                                            fill="black"
                                            fillOpacity="1"
                                            filter="url(#filter-2)"
                                            xlinkHref="#path-1"
                                        />
                                        <use
                                            fill="#FFFFFF"
                                            fillRule="evenodd"
                                            xlinkHref="#path-1"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

ButtonOff.propTypes = {
    size: PropTypes.number
};

ButtonOff.defaultProps = {
    size: 54
};

export default ButtonOff;
