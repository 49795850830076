import EmojiNormal1 from './EmojiNormal1';
import EmojiNormal2 from './EmojiNormal2';
import EmojiNormal3 from './EmojiNormal3';
import EmojiNormal4 from './EmojiNormal4';
import EmojiNormal5 from './EmojiNormal5';
import EmojiNormal6 from './EmojiNormal6';
import EmojiNormal7 from './EmojiNormal7';
import EmojiNormal8 from './EmojiNormal8';
import EmojiNormal9 from './EmojiNormal9';
import EmojiNormal10 from './EmojiNormal10';
import EmojiNormal11 from './EmojiNormal11';

export {
    EmojiNormal1,
    EmojiNormal2,
    EmojiNormal3,
    EmojiNormal4,
    EmojiNormal5,
    EmojiNormal6,
    EmojiNormal7,
    EmojiNormal8,
    EmojiNormal9,
    EmojiNormal10,
    EmojiNormal11
};
