import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../../utils';

const TopicWrapper = styled.div`
    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    margin: 3px 6px 3px 0;
    padding: 5px 10px;
    padding-left: ${({ $isSuper }) => ($isSuper ? '0px' : '10px')};

    background-color: ${({ $detected }) =>
        $detected ? colors.green.aquamarine : colors.white.albaster};
    color: ${({ $detected }) => ($detected ? colors.white.basic : colors.black.nero)};

    border: 1px solid
        ${({ $detected }) =>
            $detected ? colors.green.aquamarine : colors.blue.link_water};
    border-radius: 5px;

    & [data-icon-star] {
        margin: 0px 5px;
        fill: ${({ $detected }) =>
            $detected ? colors.white.basic : colors.yellow.rajah};
    }

    & [data-icon-stack] {
        margin-left: 5px;

        fill: ${({ $detected }) =>
            $detected ? colors.white.basic : colors.blue.link_water};
        stroke: ${({ $detected }) =>
            $detected ? colors.green.aquamarine : colors.white.albaster};
    }
`;

TopicWrapper.propTypes = {
    $detected: PropTypes.bool,
    $isSuper: PropTypes.bool
};

TopicWrapper.defaultProps = {
    $detected: false,
    $isSuper: false
};

export default TopicWrapper;
