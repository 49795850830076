import styled from 'styled-components';
import ColumnWrapper from '../../Column';

import { colors } from '../../../../utils';

const Column = styled(ColumnWrapper)`
    justify-content: center;

    width: 170px;

    margin-left: 28px;

    & > span {
        color: ${colors.blue.royal_blue};

        font-weight: 900;
        font-size: 16px;
    }
`;

export default Column;
