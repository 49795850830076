import styled from 'styled-components';
import { colors } from '../../../../utils';

const Wrapper = styled.div`
    display: flex;

    flex-wrap: nowrap;
    flex-direction: column;

    justify-content: flex-start;
    align-content: center;
    align-items: center;

    min-height: 100vh;

    padding-top: 40px;

    background-color: ${colors.gray.wild_sand};
`;

export default Wrapper;
