import styled from 'styled-components';
import { transparentize } from 'polished';

import { colors } from '../../../../utils';

import InfoWrapper from './InfoWrapper';

const Scores = styled.div`
    display: flex;

    padding: 1px 6px;

    border-radius: 2px;
    border: solid 1px ${colors.blue.royal_blue};
    background-color: ${transparentize('0.95', colors.blue.royal_blue)};
    color: ${colors.blue.royal_blue};
    height: 22px;

    font-size: 14px;
    font-weight: 600;

    text-align: center;
    vertical-align: middle;
    cursor: default;

    &:hover {
        color: ${colors.white.basic};
        background-color: ${colors.blue.royal_blue};
        border: solid 1px ${colors.blue.royal_blue};
    }

    &:hover ${InfoWrapper} {
        opacity: 1;
        transform: translateY(0);
        z-index: 100;
        pointer-events: all;
    }
`;

export default Scores;
