import React from 'react';

function EmojiDisaster8() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="48" viewBox="0 0 23 48">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <ellipse
                cx="11"
                cy="45.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="11"
                ry="1.5"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M10.5 45C4.7 45 0 40.3 0 34.5v-24C0 4.7 4.7 0 10.5 0S21 4.7 21 10.5v24.1C21 40.3 16.3 45 10.5 45z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M10.5 0C4.7 0 0 4.7 0 10.5v2.6C0 7.3 4.7 2.6 10.5 2.6S21 7.3 21 13.1v-2.6C21 4.7 16.3 0 10.5 0z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 45C4.7 45 0 40.3 0 34.5v-24C0 4.7 4.7 0 10.5 0S21 4.7 21 10.5v24.1C21 40.3 16.3 45 10.5 45z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M10.8 41.7c-4.3 0-7.8-3.5-7.8-7.8V9.8C3 5.5 6.5 2 10.8 2s7.8 3.5 7.8 7.8v24.1c0 4.3-3.5 7.8-7.8 7.8z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M10.8 2C6.5 2 3 5.5 3 9.8v2.8c0-4.3 3.5-7.8 7.8-7.8s7.8 3.5 7.8 7.8V9.8c0-4.3-3.5-7.8-7.8-7.8z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.8 41.7c-4.3 0-7.8-3.5-7.8-7.8V9.8C3 5.5 6.5 2 10.8 2s7.8 3.5 7.8 7.8v24.1c0 4.3-3.5 7.8-7.8 7.8z"
            />
            <circle cx="10.5" cy="22.5" r="4.5" fill="#C1C4E6" fillRule="nonzero" />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M10.5 18C8 18 6 20 6 22.5c0 1.8 1.1 3.3 2.6 4.1l5-7.3c-.8-.8-1.9-1.3-3.1-1.3z"
            />
            <circle cx="10.5" cy="11.5" r="4.5" fill="#FF506E" fillRule="nonzero" />
            <path
                fill="#FF506E"
                fillRule="nonzero"
                d="M10.5 7C8 7 6 9 6 11.5c0 1.8 1.1 3.3 2.6 4.1l5-7.3c-.8-.8-1.9-1.3-3.1-1.3z"
            />
            <circle cx="10.5" cy="34.5" r="4.5" fill="#C1C4E6" fillRule="nonzero" />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M10.5 30C8 30 6 32 6 34.5c0 1.8 1.1 3.3 2.6 4.1l5-7.3c-.8-.8-1.9-1.3-3.1-1.3z"
            />
            <circle
                cx="10.5"
                cy="22.5"
                r="4.5"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="10.5"
                cy="11.5"
                r="4.5"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="10.5"
                cy="34.5"
                r="4.5"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 34c0 2.8-.8-2.5-5-2.5S6 36.8 6 34s2.2-5 5-5 5 2.2 5 5zM16 22c0 2.8-.8-2.5-5-2.5S6 24.8 6 22s2.2-5 5-5 5 2.2 5 5zM16 11c0 2.8-.8-2.5-5-2.5S6 13.8 6 11s2.2-5 5-5 5 2.2 5 5z"
            />
        </g>
    </svg>
}

export default EmojiDisaster8;
