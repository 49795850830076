const errorCodes = {
    crx: {
        DISCONNECTED: 1001,
        SEND_TOKEN_ERROR: 1002,
        UNAUTHORIZED: 1003,
        SEND_CONFIG_ERROR: 1004
    },
    settings: {
        LOCALE_ERROR: 2001,
        NICK_NAME_ERROR: 2002
    },
    background: {
        // UNABLE_TO_CONNECT_MAIN: 3001,
        // UNABLE_TO_CONNECT_AUDIO_DEVICE: 3002,
        // UNABLE_TO_CONNECT_AUDIO_SYSTEM_INFO: 3003,
        // UNABLE_TO_CONNECT_ASR_CALL_DETECTION: 3004,
        HARDWARE_ERROR: 3005,
        UNEXPECTED_DAEMON_VERSION: 3006,
        AUTH_FAILED: 3007,
        MAIN_CONNECTION_CLOSED: 3008,
        CALL_DETECTION_STOPPED: 3009,
        AUDIO_DEVICE_STOPPED: 3010,
        AUDIO_DEVICES_CONFIG_CHANGED: 3011,
        AUDIO_SYSTEM_INFO_CONNECTION_CLOSED: 3012,
        RECOGNITION_CONNECTION_ERROR: 3013,
        SLOW_NETWORK_WARN: 3015,
        EVENT_FORWARDING_CONNECTION_CLOSED: 3016,
        MAIN_CONNECTION_CLOSED_ON_START: 3017,
        ASR_VENDOR_CONFIG_ERROR: 3018,
        CALL_MODE_ERROR: 3019,
    },
    user: {
        PATCH_USER_PROFILE_FAILED: 4000
    }
};

export default errorCodes;
