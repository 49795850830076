import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withLocalization from '../../localization/withLocalization';
import { Wrapper, Title, Message, Icon, UserNameWrapper, Label } from './styled';

import NickName from './NickName';

class UserName extends Component {
    static propTypes = {
        updateUsernameRequest: PropTypes.func.isRequired,
        personalUsername: PropTypes.string.isRequired,
        pending: PropTypes.bool.isRequired,
        errorCode: PropTypes.string,
        localization: PropTypes.object.isRequired
    };

    static defaultProps = {
        errorCode: null
    };

    _renderNickName() {
        const {
            localization,
            personalUsername,
            updateUsernameRequest,
            pending,
            errorCode
        } = this.props;

        return (
            <UserNameWrapper>
                <Label>{localization.getText('settings.nickname.title')}</Label>
                <NickName
                    localization={localization}
                    value={personalUsername || ''}
                    pending={pending}
                    errorCode={errorCode}
                    onSave={updateUsernameRequest}
                />
            </UserNameWrapper>
        );
    }

    render() {
        const { localization } = this.props;

        return (
            <Wrapper>
                <Title>{localization.getText('settings.nickname.welcome')}!</Title>
                <Message>{localization.getText('settings.nickname.message')}</Message>
                <Icon />
                {this._renderNickName()}
            </Wrapper>
        );
    }
}

export default withLocalization(UserName);
