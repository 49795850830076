import styled from 'styled-components';

import { colors } from '../../../utils';

export default styled.div`
    text-align: center;

    color: ${colors.white.basic};

    font-size: 22px;
    font-weight: 500;
`;
