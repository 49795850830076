import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../utils';

const spin = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const animationRuleSpin = css`
    ${spin} 1s infinite linear
`;

const Spinner = styled.div`
    position: relative;

    width: ${props => props.$size};
    height: ${props => props.$size};

    margin: ${props => (props.$center ? '0 auto' : '0')};

    border: ${props => props.$thickness} solid ${props => props.$backgroundColor};
    border-left: ${props => props.$thickness} solid ${props => props.$runnerColor};
    border-radius: 50%;

    font-size: 10px;
    text-indent: -9999em;

    transform: translateZ(0);
    animation: ${animationRuleSpin};

    &:after {
        width: ${props => props.$size};
        height: ${props => props.$size};
        border-radius: 50%;
    }
`;

Spinner.propTypes = {
    $backgroundColor: PropTypes.string,
    $runnerColor: PropTypes.string,
    $thickness: PropTypes.string,
    $size: PropTypes.string,
    $center: PropTypes.bool
};

Spinner.defaultProps = {
    $backgroundColor: colors.blue.i2x,
    $runnerColor: colors.white.basic,
    $small: false,
    $thickness: '3px',
    $size: '20px',
    $center: false
};

export default Spinner;
