import styled from 'styled-components';

const ItemText = styled.div`
    margin-top: -2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export default ItemText;
