import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../utils';

const Button = styled.button`
    border-radius: 24px;
    border: 0;

    padding: 12px 24px;

    font-size: 13px;
    font-weight: bold;

    background-color: ${props =>
        props.$isCallStarted ? colors.red.light_carmine_pink : colors.green.aquamarine};
    color: ${colors.white.basic};

    cursor: pointer;

    outline: none;

    & > * {
        vertical-align: middle;
    }

    & > span {
        margin-left: 6px;
    }
`;

Button.propTypes = {
    $isCallStarted: PropTypes.bool.isRequired
};

export default Button;
