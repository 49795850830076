import React from 'react';
import PropTypes from 'prop-types';
import { Card, Column, Row } from './styled';
import { Icon } from '..';

function CallEndMessage(props) {
    const { localization } = props;
    const topMessage = localization.getText('trainer.call_messages.call_end.top');

    return (
        <Card className="message">
            <Column $marginRight={15}>
                <Icon name="CallEnded" />
            </Column>
            <Column>
                <Row>
                    <strong>{topMessage}</strong>
                </Row>
            </Column>
        </Card>
    );
}

CallEndMessage.propTypes = {
    localization: PropTypes.object.isRequired
};

export default CallEndMessage;
