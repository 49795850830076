import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Title, Wrapper, SubText, Picture } from './styled';
import { Column, Icon } from '..';
import { CloseButton } from '../../BattleCard/styled';

function Prompt({ prompt = {} }) {
    const [showPrompt, setShowPrompt] = useState(true);

    useEffect(() => {
        setShowPrompt(true);
    }, [prompt.title]);

    if (!prompt.title) {
        return null;
    }

    if (!showPrompt) {
        return null;
    }

    function getIcon() {
        switch (prompt.category) {
            case 'after-the-x-call':
                return 'FirstCall';
            case 'morning':
                return 'Morning';
            case 'afternoon':
                return 'Afternoon';
            default:
                return 'TenthCall';
        }
    }

    return (
        <Wrapper>
            <Picture>
                <Icon name={getIcon()} />
            </Picture>
            <Column>
                <Title>{prompt.title}</Title>
                <SubText>{prompt.subtext}</SubText>
            </Column>
            <CloseButton $top={4} onClick={() => setShowPrompt(false)}>
                <Icon name="Cross" size={11} />
            </CloseButton>
        </Wrapper>
    );
}

Prompt.propTypes = {
    prompt: PropTypes.shape({
        title: PropTypes.string,
        subtext: PropTypes.string,
        iconName: PropTypes.string
    })
};

Prompt.defaultProps = {
    prompt: {
        title: '',
        subtext: '',
        iconName: ''
    }
};

export default Prompt;
