import { types } from '../actions/snooze';

export const initialState = {
    snoozeFor: null
};

const applyCountdown = timer => timer - 1;

export default function userReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
        case types.SET_SNOOZE_TIMER_SUCCESS:
            return { ...state, snoozeFor: payload.snoozeFor };
        case types.SNOOZE_COUNTDOWN: {
            const { snoozeFor } = payload;
            return { ...state, snoozeFor: applyCountdown(snoozeFor) };
        }
        default:
            return state;
    }
}
