import styled from 'styled-components';
import Title from '../../common/Title';

import { colors } from '../../../utils';

const CardTitle = styled(Title)`
    font-size: 15px;
    font-weight: 600;
    color: ${colors.blue.madison};
`;

export default CardTitle;
