import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Wrapper as CardWrapper } from '../../common/Card/styled';

const Card = styled(CardWrapper)`
    min-height: 80px;
    margin-top: 20px;
    overflow: visible;

    ${props =>
        !props.$enabled &&
        `
        pointer-events: none;
        touch-action: none;
        user-select: none;

        opacity: 0.5;

        filter: grayscale(100%);

        cursor: not-allowed;

        & * {
            pointer-events: none;
            touch-action: none;
            user-select: none;
        }
    `};
`;

Card.propTypes = {
    $enabled: PropTypes.bool
};

Card.defaultProps = {
    $enabled: true
};

export default Card;
