import styled from 'styled-components';

const Loading = styled.div`
    text-align: center;
    margin: 20px auto;
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
    font-size: 11px;
`;

export default Loading;
