import React from 'react';
import PropTypes from 'prop-types';

function HandClap(props) {
  return <svg width={props.size} height={props.size} viewBox="0 0 94 94">
        <defs>
            <path
                id="a"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="c"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="e"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="g"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="i"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="k"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="m"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="o"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="q"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="s"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="u"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="w"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="y"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="A"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="C"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="E"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="G"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="I"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="K"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="M"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47z"
            />
            <path
                id="O"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="Q"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="S"
                d="M0 47c0 25.958 21.042 47 47 47s47-21.042 47-47S72.958 0 47 0 0 21.042 0 47"
            />
            <path
                id="U"
                d="M47 94c25.958 0 47-21.042 47-47S72.958 0 47 0 0 21.042 0 47s21.042 47 47 47z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#F0F2FF"
                d="M94 47c0 25.958-21.042 47-47 47S0 72.958 0 47 21.042 0 47 0s47 21.042 47 47"
                opacity=".427"
            />
            <path
                fill="#D6DAFF"
                d="M39.178 18.863a.708.708 0 0 1 .56.981l-2.56 6.008a.71.71 0 0 1-1.36-.216l-.559-6.422a.704.704 0 0 1 .218-.573.705.705 0 0 1 .582-.192l3.119.414z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M39.178 18.863a.708.708 0 0 1 .56.981l-2.56 6.008a.71.71 0 0 1-1.36-.216l-.559-6.422a.704.704 0 0 1 .218-.573.705.705 0 0 1 .582-.192l3.119.414z"
            />
            <path
                fill="#D6DAFF"
                d="M24.72 19.988a.708.708 0 0 0-.402 1.055l3.46 5.54a.708.708 0 0 0 1.308-.424l-.44-6.431a.711.711 0 0 0-.91-.631l-3.016.89z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M24.72 19.988a.708.708 0 0 0-.402 1.055l3.46 5.54a.708.708 0 0 0 1.308-.424l-.44-6.431a.711.711 0 0 0-.91-.631l-3.016.89z"
            />
            <path
                fill="#D6DAFF"
                d="M14.385 27.557a.708.708 0 0 0 .32 1.084l6.104 2.32a.71.71 0 0 0 .786-1.13l-4.241-4.855a.706.706 0 0 0-1.105.047l-1.864 2.534z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M14.385 27.557a.708.708 0 0 0 .32 1.084l6.104 2.32a.71.71 0 0 0 .786-1.13l-4.241-4.855a.706.706 0 0 0-1.105.047l-1.864 2.534z"
            />
            <path
                fill="#D6DAFF"
                d="M84.769 43.123a.71.71 0 0 1-.582.968l-6.492.71a.71.71 0 0 1-.477-1.293l5.326-3.63a.71.71 0 0 1 1.06.324l1.165 2.92z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M84.769 43.123a.71.71 0 0 1-.582.968l-6.492.71a.71.71 0 0 1-.477-1.293l5.326-3.63a.71.71 0 0 1 1.06.324l1.165 2.92z"
            />
            <path
                fill="#D6DAFF"
                d="M76.71 31.066a.708.708 0 0 0-1.116.167l-3.139 5.727a.708.708 0 0 0 1.01.934l5.393-3.532a.711.711 0 0 0 .106-1.102l-2.254-2.194z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M76.71 31.066a.708.708 0 0 0-1.116.167l-3.139 5.727a.708.708 0 0 0 1.01.934l5.393-3.532a.711.711 0 0 0 .106-1.102l-2.254-2.194z"
            />
            <path
                fill="#D6DAFF"
                d="M65.05 25.76a.711.711 0 0 0-.787.808l.965 6.46c.048.32.31.569.633.6a.707.707 0 0 0 .737-.468l2.157-6.074a.712.712 0 0 0-.583-.942l-3.121-.385z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M65.05 25.76a.711.711 0 0 0-.787.808l.965 6.46c.048.32.31.569.633.6a.707.707 0 0 0 .737-.468l2.157-6.074a.712.712 0 0 0-.583-.942l-3.121-.385z"
            />
            <g>
                <g>
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M52.254 44.777a2.837 2.837 0 0 0-.874 3.917c1.793 2.967 2.741 4.588 3.386 5.731a.356.356 0 0 1-.561.424l-7.56-7.648-1.004 1.005-15.047 15.047 2.509 2.506a35.158 35.158 0 0 0 12.85 8.184l1.904.692a8.605 8.605 0 0 1 3.15 2.007l1.657 1.657L64.2 66.763l-2.134-2.134c-1.835-1.834 1.517-7.029-.764-10.891l-5.13-8.085a2.838 2.838 0 0 0-3.917-.876"
                        mask="url(#b)"
                    />
                </g>
                <g>
                    <mask id="d" fill="#fff">
                        <use xlinkHref="#c" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M52.254 44.777a2.837 2.837 0 0 0-.874 3.917c1.793 2.967 2.741 4.588 3.386 5.731a.356.356 0 0 1-.561.424l-7.56-7.648-1.004 1.005-15.047 15.047 2.509 2.506a35.158 35.158 0 0 0 12.85 8.184l1.904.692a8.605 8.605 0 0 1 3.15 2.007l1.657 1.657L64.2 66.763l-2.134-2.134c-1.835-1.834 1.517-7.029-.764-10.891l-5.13-8.085a2.838 2.838 0 0 0-3.917-.876z"
                        mask="url(#d)"
                    />
                </g>
                <g>
                    <mask id="f" fill="#fff">
                        <use xlinkHref="#e" />
                    </mask>
                    <path
                        fill="#D6DAFF"
                        d="M54.557 84.43l9.01 9.01a1.417 1.417 0 0 0 2.006 0L79.115 79.9a1.418 1.418 0 0 0 0-2.007l-9.01-9.01a1.418 1.418 0 0 0-2.007 0l-13.54 13.541a1.417 1.417 0 0 0 0 2.006"
                        mask="url(#f)"
                    />
                </g>
                <g>
                    <mask id="h" fill="#fff">
                        <use xlinkHref="#g" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M54.557 84.43l9.01 9.01a1.417 1.417 0 0 0 2.006 0L79.115 79.9a1.418 1.418 0 0 0 0-2.007l-9.01-9.01a1.418 1.418 0 0 0-2.007 0l-13.54 13.541a1.417 1.417 0 0 0 0 2.006z"
                        mask="url(#h)"
                    />
                </g>
                <g>
                    <mask id="j" fill="#fff">
                        <use xlinkHref="#i" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M54.557 84.43l9.01 9.01a1.417 1.417 0 0 0 2.006 0L79.115 79.9a1.418 1.418 0 0 0 0-2.007l-9.01-9.01a1.418 1.418 0 0 0-2.007 0l-13.54 13.541a1.417 1.417 0 0 0 0 2.006z"
                        mask="url(#j)"
                    />
                </g>
                <g>
                    <mask id="l" fill="#fff">
                        <use xlinkHref="#k" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M54.557 82.424l-2.007-2.007a1.417 1.417 0 0 1 0-2.005l11.536-11.535a1.417 1.417 0 0 1 2.006 0l2.006 2.005-13.54 13.542z"
                        mask="url(#l)"
                    />
                </g>
                <g>
                    <mask id="n" fill="#fff">
                        <use xlinkHref="#m" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M54.557 82.424l-2.007-2.007a1.417 1.417 0 0 1 0-2.005l11.536-11.535a1.417 1.417 0 0 1 2.006 0l2.006 2.005-13.54 13.542z"
                        mask="url(#n)"
                    />
                </g>
                <g>
                    <mask id="p" fill="#fff">
                        <use xlinkHref="#o" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M52.254 44.777a2.837 2.837 0 0 0-.874 3.917c1.793 2.967 2.741 4.588 3.386 5.731a.356.356 0 0 1-.561.424l-7.56-7.648-1.004 1.005-15.047 15.047 2.509 2.506a35.158 35.158 0 0 0 12.85 8.184l1.904.692a8.605 8.605 0 0 1 3.15 2.007l1.657 1.657L64.2 66.763l-2.134-2.134c-1.835-1.834 1.517-7.029-.764-10.891l-5.13-8.085a2.838 2.838 0 0 0-3.917-.876z"
                        mask="url(#p)"
                    />
                </g>
                <g>
                    <mask id="r" fill="#fff">
                        <use xlinkHref="#q" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M44.638 53.22L33.104 41.685a2.837 2.837 0 0 1 4.013-4.013L48.65 49.21"
                        mask="url(#r)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M44.638 53.22L33.104 41.685a2.837 2.837 0 0 1 4.013-4.013L48.65 49.21"
                        mask="url(#r)"
                    />
                </g>
                <g>
                    <mask id="t" fill="#fff">
                        <use xlinkHref="#s" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M32.602 65.258l-9.029-9.028a2.837 2.837 0 0 1 4.013-4.013l9.027 9.029"
                        mask="url(#t)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M32.602 65.258l-9.029-9.028a2.837 2.837 0 0 1 4.013-4.013l9.027 9.029"
                        mask="url(#t)"
                    />
                </g>
                <g>
                    <mask id="v" fill="#fff">
                        <use xlinkHref="#u" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M36.613 61.246l-13.04-13.042a2.836 2.836 0 1 1 4.013-4.011l13.04 13.04"
                        mask="url(#v)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M36.613 61.246l-13.04-13.042a2.836 2.836 0 1 1 4.013-4.011l13.04 13.04"
                        mask="url(#v)"
                    />
                </g>
                <g>
                    <mask id="x" fill="#fff">
                        <use xlinkHref="#w" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M40.626 57.233L26.08 42.688a2.838 2.838 0 0 1 4.012-4.012L44.638 53.22"
                        mask="url(#x)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M40.626 57.233L26.08 42.688a2.838 2.838 0 0 1 4.012-4.012L44.638 53.22"
                        mask="url(#x)"
                    />
                </g>
                <g>
                    <mask id="z" fill="#fff">
                        <use xlinkHref="#y" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M62.362 43.537a2.836 2.836 0 0 0-.875 3.915c1.794 2.968 2.741 4.588 3.387 5.733a.355.355 0 0 1-.561.423l-7.56-7.647-1.005 1.003-15.046 15.047 2.508 2.507a35.157 35.157 0 0 0 12.85 8.184l1.904.692a8.63 8.63 0 0 1 3.15 2.007l1.657 1.656 11.536-11.535-2.134-2.133c-1.835-1.836 1.516-7.03-.764-10.893l-5.132-8.084a2.836 2.836 0 0 0-3.915-.875"
                        mask="url(#z)"
                    />
                </g>
                <g>
                    <mask id="B" fill="#fff">
                        <use xlinkHref="#A" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M62.362 43.537a2.836 2.836 0 0 0-.875 3.915c1.794 2.968 2.741 4.588 3.387 5.733a.355.355 0 0 1-.561.423l-7.56-7.647-1.005 1.003-15.046 15.047 2.508 2.507a35.157 35.157 0 0 0 12.85 8.184l1.904.692a8.63 8.63 0 0 1 3.15 2.007l1.657 1.656 11.536-11.535-2.134-2.133c-1.835-1.836 1.516-7.03-.764-10.893l-5.132-8.084a2.836 2.836 0 0 0-3.915-.875z"
                        mask="url(#B)"
                    />
                </g>
                <g>
                    <mask id="D" fill="#fff">
                        <use xlinkHref="#C" />
                    </mask>
                    <path
                        fill="#D6DAFF"
                        d="M64.664 83.189l9.011 9.01a1.42 1.42 0 0 0 2.006 0l13.542-13.541a1.42 1.42 0 0 0 0-2.007l-9.011-9.01a1.417 1.417 0 0 0-2.006 0L64.664 81.183a1.417 1.417 0 0 0 0 2.006"
                        mask="url(#D)"
                    />
                </g>
                <g>
                    <mask id="F" fill="#fff">
                        <use xlinkHref="#E" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M64.664 83.189l9.011 9.01a1.42 1.42 0 0 0 2.006 0l13.542-13.541a1.42 1.42 0 0 0 0-2.007l-9.011-9.01a1.417 1.417 0 0 0-2.006 0L64.664 81.183a1.417 1.417 0 0 0 0 2.006z"
                        mask="url(#F)"
                    />
                </g>
                <g>
                    <mask id="H" fill="#fff">
                        <use xlinkHref="#G" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M64.664 83.189l9.011 9.01a1.42 1.42 0 0 0 2.006 0l13.542-13.541a1.42 1.42 0 0 0 0-2.007l-9.011-9.01a1.417 1.417 0 0 0-2.006 0L64.664 81.183a1.417 1.417 0 0 0 0 2.006z"
                        mask="url(#H)"
                    />
                </g>
                <g>
                    <mask id="J" fill="#fff">
                        <use xlinkHref="#I" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M64.664 81.183l-2.006-2.006a1.418 1.418 0 0 1 0-2.007l11.535-11.535a1.418 1.418 0 0 1 2.007 0l2.006 2.006-13.542 13.542z"
                        mask="url(#J)"
                    />
                </g>
                <g>
                    <mask id="L" fill="#fff">
                        <use xlinkHref="#K" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M64.664 81.183l-2.006-2.006a1.418 1.418 0 0 1 0-2.007l11.535-11.535a1.418 1.418 0 0 1 2.007 0l2.006 2.006-13.542 13.542z"
                        mask="url(#L)"
                    />
                </g>
                <g>
                    <mask id="N" fill="#fff">
                        <use xlinkHref="#M" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M62.362 43.537a2.836 2.836 0 0 0-.875 3.915c1.794 2.968 2.741 4.588 3.387 5.733a.355.355 0 0 1-.561.423l-7.56-7.647-1.005 1.003-15.046 15.047 2.508 2.507a35.157 35.157 0 0 0 12.85 8.184l1.904.692a8.63 8.63 0 0 1 3.15 2.007l1.657 1.656 11.536-11.535-2.134-2.133c-1.835-1.836 1.516-7.03-.764-10.893l-5.132-8.084a2.836 2.836 0 0 0-3.915-.875z"
                        mask="url(#N)"
                    />
                </g>
                <g>
                    <mask id="P" fill="#fff">
                        <use xlinkHref="#O" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M54.747 51.98L43.21 40.444a2.836 2.836 0 0 1 0-4.012 2.835 2.835 0 0 1 4.013 0l11.536 11.535"
                        mask="url(#P)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M54.747 51.98L43.21 40.444a2.836 2.836 0 0 1 0-4.012 2.835 2.835 0 0 1 4.013 0l11.536 11.535"
                        mask="url(#P)"
                    />
                </g>
                <g>
                    <mask id="R" fill="#fff">
                        <use xlinkHref="#Q" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M42.709 64.017l-9.028-9.029a2.836 2.836 0 1 1 4.011-4.011l9.03 9.027"
                        mask="url(#R)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M42.709 64.017l-9.028-9.029a2.836 2.836 0 1 1 4.011-4.011l9.03 9.027"
                        mask="url(#R)"
                    />
                </g>
                <g>
                    <mask id="T" fill="#fff">
                        <use xlinkHref="#S" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M46.721 60.004l-13.04-13.04a2.837 2.837 0 0 1 4.011-4.013l13.042 13.04"
                        mask="url(#T)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M46.721 60.004l-13.04-13.04a2.837 2.837 0 0 1 4.011-4.013l13.042 13.04"
                        mask="url(#T)"
                    />
                </g>
                <g>
                    <mask id="V" fill="#fff">
                        <use xlinkHref="#U" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M50.734 55.991L36.188 41.447a2.837 2.837 0 0 1 4.013-4.012L54.746 51.98"
                        mask="url(#V)"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M50.734 55.991L36.188 41.447a2.837 2.837 0 0 1 4.013-4.012L54.746 51.98"
                        mask="url(#V)"
                    />
                </g>
            </g>
        </g>
    </svg>
}

HandClap.propTypes = {
    size: PropTypes.number
};

HandClap.defaultProps = {
    size: 94
};

export default HandClap;
