import React from 'react';
import PropTypes from 'prop-types';

import {colors} from '../../../utils';

function Assessment(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-assessment" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
             height={props.size} width={props.size}>
            <path fill={props.color} fillRule="evenodd"
                  d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z" />
        </svg>
    );
}


Assessment.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

Assessment.defaultProps = {
    size: 14,
    color: `${colors.white.default}`
};

export default Assessment;
