import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Button, StartIcon, EndIcon } from './styled';
import Spinner from '../../common/Spinner';
import colors from '../../../utils/colors';

function CallButton({
    isCallStarted,
    sendManualCallEvent,
    setDisabled,
    isManualCallButtonEnabled,
    localization
}) {
    const getButtonLabel = () => {
        return localization.getText(
            isCallStarted ? 'call_buttons.end' : 'call_buttons.start'
        );
    };
    const getButtonIcon = () => {
        return isCallStarted ? <EndIcon /> : <StartIcon />;
    };
    const onButtonClick = () => {
        if (!isCallStarted) {
            setDisabled();
        }
        sendManualCallEvent(!isCallStarted);
    };

    if (!isManualCallButtonEnabled) {
        return (
            <Wrapper>
                <div style={{ width: 32 }}>
                    <Spinner
                        $size="30px"
                        $runnerColor={colors.blue.palatinate_blue}
                        $backgroundColor={colors.gray.athens}
                    />
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Button $isCallStarted={isCallStarted} onClick={onButtonClick}>
                {getButtonIcon()}
                <span>{getButtonLabel()}</span>
            </Button>
        </Wrapper>
    );
}

CallButton.propTypes = {
    isCallStarted: PropTypes.bool.isRequired,
    sendManualCallEvent: PropTypes.func.isRequired,
    isManualCallButtonEnabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    localization: PropTypes.object.isRequired
};

export default CallButton;
