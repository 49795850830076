import {
    SET_CALL_RESULT_TIMER,
    SET_RESULT_POPUP_VISIBILITY,
    SET_RESULT_POPUP_VISIBILITY_ON_LOAD,
    WINDOW_ON_LOAD,
    TOGGLE_TRANSCRIPT,
    TOGGLE_TRANSCRIPT_COPY_BUTTON,
    SET_TRANSCRIPT_COPY_BUTTON_AVAILABILITY,
    COPY_TO_CLIPBOARD,
    HIDE_NEW_CALL_MESSAGE,
    RESUME_TRAINER,
    SEND_MANUAL_CALL_EVENT,
    SET_MANUAL_BUTTON_AVAILABILITY,
    SNOOZE_TRAINER,
    ADD_SNOOZE_TIMER,
    USER_RELOAD,
    SET_INFINITY_SNOOZE,
    MANUAL_OPT_IN,
    MANUAL_OPT_STOP,
    MANUAL_OPT_OUT,
    INIT,
    SAVE_CONVERSION
} from './actionTypes/trainer';
import {HIDE_CONVERSION_MODAL} from "./actionTypes/conversion";

const setResultPopupVisibility = isVisible => ({
    type: SET_RESULT_POPUP_VISIBILITY,
    payload: {
        isCallResultVisible: isVisible
    }
});

const setResultPopupVisibilityOnLoad = () => ({
    type: SET_RESULT_POPUP_VISIBILITY_ON_LOAD,
});

const setCallResultTimer = () => ({
    type: SET_CALL_RESULT_TIMER
});

const windowOnLoad = () => ({
    type: WINDOW_ON_LOAD
});

const init = () => ({ type: INIT });

const toggleTranscript = isVisible => ({
    type: TOGGLE_TRANSCRIPT,
    payload: { isVisible }
});

const onTranscriptCopyButtonClick = () => ({
    type: TOGGLE_TRANSCRIPT_COPY_BUTTON
});

const setTranscriptCopyButtonAvailability = copyEnabled => ({
    type: SET_TRANSCRIPT_COPY_BUTTON_AVAILABILITY,
    payload: { copyEnabled }
});

const copyToClipboard = payload => ({
    type: COPY_TO_CLIPBOARD,
    payload
});

const hideNewCallMessage = () => ({
    type: HIDE_NEW_CALL_MESSAGE
});

const resumeTrainer = () => ({
    type: RESUME_TRAINER
});

const onManualCallEvent = isStart => ({
    type: SEND_MANUAL_CALL_EVENT,
    payload: {
        isStart
    }
});

const changeCallButtonAvailability = isAvailable => ({
    type: SET_MANUAL_BUTTON_AVAILABILITY,
    payload: {
        isAvailable
    }
});

const snoozeTrainer = snoozeFor => ({
    type: SNOOZE_TRAINER,
    payload: { snoozeFor }
});

const addSnoozeTimer = snoozeFor => ({
    type: ADD_SNOOZE_TIMER,
    payload: { snoozeFor }
});

export const setInfinitySnooze = () => ({
    type: SET_INFINITY_SNOOZE
});

const userReload = () => ({
    type: USER_RELOAD
});

const manualOptOut = () => ({ type: MANUAL_OPT_OUT });

const manualOptIn = () => ({ type: MANUAL_OPT_IN });

const manualOptStop = () => ({ type: MANUAL_OPT_STOP });

const saveConversion = (payload) => ({ type: SAVE_CONVERSION, payload })

const closeConversionModal = (payload) => ({ type: HIDE_CONVERSION_MODAL, payload })

export {
    setResultPopupVisibility,
    setResultPopupVisibilityOnLoad,
    setCallResultTimer,
    windowOnLoad,
    toggleTranscript,
    onTranscriptCopyButtonClick,
    setTranscriptCopyButtonAvailability,
    copyToClipboard,
    hideNewCallMessage,
    resumeTrainer,
    onManualCallEvent,
    changeCallButtonAvailability,
    snoozeTrainer,
    addSnoozeTimer,
    userReload,
    manualOptIn,
    manualOptOut,
    manualOptStop,
    init,
    saveConversion,
    closeConversionModal,
};
