import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../../utils';
import { captureException } from '../../../../../tracker/raven';
import { commonLogger } from '../../../../../utils/logger';
import { Common } from '../../../../../background/errors';

const colorInterpolation = [
    {
        start: 0,
        end: 0.1,
        color: colors.red.light_carmine_pink
    },
    {
        start: 0.1,
        end: 0.2,
        color: colors.red.froly
    },
    {
        start: 0.2,
        end: 0.3,
        color: colors.red.old_rose
    },
    {
        start: 0.3,
        end: 0.4,
        color: colors.red.brandy_rose
    },
    {
        start: 0.4,
        end: 0.5,
        color: colors.red.zorba
    },
    {
        start: 0.5,
        end: 0.6,
        color: colors.green.lemon_grass
    },
    {
        start: 0.6,
        end: 0.7,
        color: colors.green.envy
    },
    {
        start: 0.7,
        end: 0.8,
        color: colors.green.padua
    },
    {
        start: 0.8,
        end: 0.9,

        color: colors.green.medium_aquamarine
    },
    {
        start: 0.9,
        end: 1,
        color: colors.green.aquamarine
    }
];

const getColor = confidence => {
    const colorItem = colorInterpolation.find(item => {
        return confidence >= item.start && confidence <= item.end;
    });

    if (!colorItem) {
        const error = new Common.TranscriptValidationError({ confidence });
        captureException(error);
        commonLogger.error(error.message, error);

        return colors.black.default;
    }

    return colorItem.color;
};

const SoundWrapper = styled.span`
    color: ${props => getColor(props.$confidence)};
`;

SoundWrapper.propTypes = {
    $confidence: PropTypes.number.isRequired
};

export default SoundWrapper;
