import styled from "styled-components";
import {colors} from "../../../../utils";

export Card from './Card';
export Wrapper from './Wrapper';
export {InputField, ClearButton, InputWrapper} from './Input';
export {InfoLabel, InfoContainer, Title, InfoValue, InfoValueNoWrap, Subtitle, Separator} from './Modal';
export SubmitButton from './SubmitButton';
export ErrorMessage from './ErrorMessage';

export const FormGroup = styled.div`
    margin-bottom: 12px;
    
    label {
        margin-bottom: 4px;
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: #354053;
    }
`

export const SelectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'grey' : 'transparent',
        backgroundColor: colors.white.desert_storm,
    }),
}

