import * as React from 'react';

function Rocket() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34}>
            <defs>
                <clipPath id="a">
                    <path d="M320 0v651H0V0h320Z" />
                </clipPath>
                <clipPath id="b">
                    <path d="M2.017.217c2.236-.545 4.635.109 4.635.109L3.544 4.143.71 5.778A.482.482 0 0 1 0 5.342C.055 3.816.327.6 2.017.217Z" />
                </clipPath>
                <clipPath id="c">
                    <path d="M2.017.217c2.236-.545 4.635.109 4.635.109L5.616 1.58c-.872-.164-2.29-.273-3.599.055C.71 1.962.273 3.979.055 5.615 0 5.505 0 5.45 0 5.342.055 3.816.327.6 2.017.217Z" />
                </clipPath>
                <clipPath id="d">
                    <path d="M5.507 0s.655 2.4.11 4.635c-.382 1.69-3.6 1.962-5.126 2.017a.482.482 0 0 1-.436-.709L1.69 3.108 5.507 0Z" />
                </clipPath>
                <clipPath id="e">
                    <path d="M5.289 0s.654 2.4.109 4.635C5.016 6.325 1.799 6.597.273 6.652c-.11 0-.164 0-.273-.055 1.636-.163 3.653-.6 3.98-1.962.327-1.309.218-2.727.055-3.599L5.289 0Z" />
                </clipPath>
                <clipPath id="f">
                    <path d="M6.488 2.089C9.596-.91 13.413.072 14.776.508c.164.054.327.163.436.272.11.11.218.273.273.437.436 1.363 1.363 5.18-1.581 8.287-3.654 3.817-10.142 6.489-10.087 6.489L0 12.176S2.672 5.742 6.488 2.089Z" />
                </clipPath>
                <clipPath id="g">
                    <path d="M6.488 2.089C9.596-.91 13.413.072 14.776.508c.327.109.6.381.709.709.109.327.272.817.381 1.417-1.472-.49-5.18-1.308-8.178 1.581-3.053 2.945-5.398 7.688-6.216 9.433L0 12.176S2.672 5.742 6.488 2.089Z" />
                </clipPath>
                <clipPath id="h">
                    <path d="M5.027.044a.361.361 0 0 1 .49.49c-.38.818-1.09 1.963-2.071 2.89A9.84 9.84 0 0 1 .229 5.55c-.11.11-.273-.054-.218-.218a9.84 9.84 0 0 1 2.126-3.217A11.62 11.62 0 0 1 5.027.044Z" />
                </clipPath>
                <clipPath id="i">
                    <path d="M2.072 0a2.072 2.072 0 1 1 0 4.144 2.072 2.072 0 0 1 0-4.144Z" />
                </clipPath>
                <clipPath id="j">
                    <path d="M.613.601C1.268-.053 2.195-.162 3.067.22L1.104 3.873c-.218-.055-.327-.164-.49-.328a2.073 2.073 0 0 1 0-2.944Z" />
                </clipPath>
                <clipPath id="k">
                    <path d="M4.259.63c.872.764.818 2.127.273 2.672-.283.282-1.697.803-2.9 1.21l-.413.137c-.267.088-.514.168-.722.234-.218.055-.545-.272-.491-.49C.388 3.139 1.206.74 1.587.358c.49-.49 1.854-.545 2.672.272Z" />
                </clipPath>
                <clipPath id="l">
                    <path d="M1.551.318c.382.327.437.872.164 1.145S.297 2.008.079 1.79C-.139 1.572.134.427.406.155c.273-.273.818-.164 1.145.163Z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="translate(-18 -103)">
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    d="M43.583 107.797v5.453M45.218 114.34v4.362M26.135 111.614v6.543M25.044 125.245v2.726M42.492 127.971v2.181"
                />
                <path
                    fill="none"
                    stroke="#D6DAFF"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    d="M22.863 115.43v6.544M45.763 124.154v3.817"
                />
                <g clipPath="url(#b)" transform="rotate(-45 162.96 29.452)">
                    <path fill="#D6DAFF" d="M0 0h6.652v5.833H0V0z" />
                </g>
                <g clipPath="url(#c)" transform="rotate(-45 162.96 29.452)">
                    <path fill="#4450E6" d="M0 0h6.652v5.615H0V0z" opacity="50%" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m32.34 124.28-.501-4.897s-2.16 1.234-3.355 3.2c-.925 1.465 1.157 3.933 2.198 5.05.27.27.694.155.81-.192l.848-3.161Z"
                />
                <g clipPath="url(#d)" transform="rotate(-45 165.877 20.302)">
                    <path fill="#D6DAFF" d="M0 0h5.833v6.652H0V0z" />
                </g>
                <g clipPath="url(#e)" transform="rotate(-45 165.795 20.104)">
                    <path fill="#4450E6" d="M0 0h5.615v6.652H0V0z" opacity="50%" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m37.622 124.241.5-4.896s2.16 1.233 3.355 3.2c.925 1.465-1.157 3.932-2.197 5.05a.482.482 0 0 1-.81-.193l-.848-3.161Z"
                />
                <g clipPath="url(#f)" transform="rotate(-45 151.425 29.217)">
                    <path fill="#ECEDFC" d="M0 0h15.974v15.993H0V0z" />
                </g>
                <g clipPath="url(#g)" transform="rotate(-45 151.452 29.283)">
                    <path fill="#FFF" d="M0 0h15.866v13.648H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M40.243 112.52c-.115-4.279-3.47-6.322-4.742-6.978a1.187 1.187 0 0 0-.501-.115c-.154 0-.347.038-.501.115-1.272.656-4.665 2.66-4.742 6.978-.116 5.282 2.544 11.721 2.544 11.721H37.7c-.039.039 2.66-6.439 2.544-11.72Z"
                />
                <g clipPath="url(#h)" transform="rotate(-45 165.273 24.576)">
                    <path fill="#D6DAFF" d="M0 0h5.561v5.586H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M35.308 120.54a.361.361 0 0 0-.694 0c-.27.81-.578 2.12-.578 3.508a9.84 9.84 0 0 0 .771 3.779c.077.154.309.154.309 0a9.84 9.84 0 0 0 .77-3.779c.04-1.35-.27-2.66-.578-3.508ZM37.916 111.723a2.999 2.999 0 1 0-5.997 0 2.999 2.999 0 0 0 5.997 0Z"
                />
                <g clipPath="url(#i)" transform="rotate(-90 73.298 40.435)">
                    <path fill="#4450E6" d="M0 0h4.144v4.144H0V0z" />
                </g>
                <g clipPath="url(#j)" transform="rotate(-45 150.84 17.142)">
                    <path fill="#737CFF" d="M0 0h3.067v3.873H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M37.007 111.66a2.072 2.072 0 1 0-4.144 0 2.072 2.072 0 0 0 4.144 0Z"
                />
                <g clipPath="url(#k)" transform="rotate(-45 175.01 27.938)">
                    <path fill="#FFC182" d="M0 0h4.93v4.89H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#FF8A14"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M37.043 131.065c0 .54-1.156 2.853-1.735 3.971-.115.193-.578.193-.694 0-.616-1.156-1.734-3.431-1.734-3.97 0-.695.925-1.697 2.081-1.697 1.157-.077 2.082.925 2.082 1.696Z"
                />
                <g clipPath="url(#l)" transform="rotate(-45 175.03 24.872)">
                    <path fill="#FF1313" d="M0 0h1.885v1.87H0V0z" />
                </g>
            </g>
        </svg>
    );
}

export default Rocket;
