import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../utils';

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const animationRuleFadeUp = css`
    ${fadeInUp} 200ms ease forwards
`;

const List = styled.nav`
    position: absolute;
    right: -50%;
    top: 115%;

    overflow: hidden;

    width: 160px;

    padding: 8px 0px;

    background-color: ${colors.white.basic};
    box-shadow: 0 2px 6px 0 ${colors.gray.mischka};

    border-radius: 2px;

    pointer-events: ${props => (props.$visible ? 'auto' : 'none')};

    opacity: 0;
    animation: ${props => (props.$visible ? animationRuleFadeUp : `none`)};

    transition: all 0.25s ease;

    & a {
        text-decoration: none;
        color: inherit;
    }

    & > :last-child {
        margin-bottom: 0;
    }
`;

List.propTypes = {
    $visible: PropTypes.bool
};

List.defaultProps = {
    $visible: false
};

export default List;
