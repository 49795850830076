import styled from 'styled-components';

import { colors } from '../../../utils';

const CardSubtitle = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: ${colors.gray.dove_gray};
    margin-bottom: 8px;

    user-select: none;
`;

export default CardSubtitle;
