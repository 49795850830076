import React from 'react';

function EmojiNormal1() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="43" viewBox="0 0 28 43">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M18.2 1.8c-1-1.1-2.7-1.1-3.9-.1-1.2 1.1-1.3 3-.2 4.1 2.9 2.9 5 6.5 6.3 10.4-1.6-1.6-3.7-2.7-6.1-3-3.5-.5-7.3.4-9.6 2.5-1.6 1.4-1.1 4.1.9 4.8 1.1.4 2.3.2 3.1-.5 1.2-1 3.3-1.2 5-.9 2.3.5 4.1 2.5 4.1 5.1 0 3.3-3 5.8-6.4 5.1-2.1-.4-4.4-2.5-4.6-5-.1-1.4-.9-2.5-2.4-2.9-1.7-.5-3.5.8-3.5 2.5 0 7.1 5.8 15.5 14.9 14.2C23.5 37 26 34.9 27.1 25.2c.7-7-2.3-16.5-8.9-23.4z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M14.4 5.2c1.1-1 2.8-.9 3.9.1 5.3 5.4 8.3 12.6 9 18.9.2-7-2.8-15.9-9.1-22.4-1-1.1-2.7-1.1-3.9-.1-1.2 1-1.3 2.8-.3 4 .1-.2.3-.4.4-.5z"
            />
            <ellipse
                cx="15"
                cy="41.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="11.5"
                ry="1.5"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M14.4 13.2c2.3.3 4.4 1.4 6 3-1.1-3.5-2.9-6.6-5.3-9.3-.9-1-2.5-1-3.4.1-.6.8-.6 2 .1 2.7 1 1.1 1.9 2.3 2.6 3.5z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M11.6 7.3c.8-.2 1.6 0 2.2.7 1.7 1.9 3.1 4 4.1 6.3.9.5 1.8 1.1 2.6 1.9-1.1-3.5-2.9-6.6-5.3-9.3-.9-1-2.5-1-3.4.1-.1.1-.2.2-.2.3z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.4 13.2c2.3.3 4.4 1.4 6 3-1.1-3.5-2.9-6.6-5.3-9.3-.9-1-2.5-1-3.4.1-.6.8-.6 2 .1 2.7 1 1.1 1.9 2.3 2.6 3.5z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M14.4 16.8c2.3.3 7.3 2.4 8.9 3.9-.6-1.7-1.6-3.3-2.9-4.5-1.6-1.6-3.7-2.7-6.1-3-3.5-.5-7.3.4-9.6 2.5-1.2 1.1-1.2 2.8-.4 3.9 2.4-2.4 6.4-3.4 10.1-2.8z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.4 16.2c2.1 2 3.4 4.9 3.4 8M18.2 1.8c-1-1.1-2.7-1.1-3.9-.1-1.2 1.1-1.3 3-.2 4.1 2.9 2.9 5 6.5 6.3 10.4-1.6-1.6-3.7-2.7-6.1-3-3.5-.5-7.3.4-9.6 2.5-1.6 1.4-1.1 4.1.9 4.8 1.1.4 2.3.2 3.1-.5 1.2-1 3.3-1.2 5-.9 2.3.5 4.1 2.5 4.1 5.1 0 3.3-3 5.8-6.4 5.1-2.1-.4-4.4-2.5-4.6-5-.1-1.4-.9-2.5-2.4-2.9-1.7-.5-3.5.8-3.5 2.5 0 7.1 5.8 15.5 14.9 14.2C23.5 37 26 34.9 27.1 25.2c.7-7-2.3-16.5-8.9-23.4z"
            />
        </g>
    </svg>
}

export default EmojiNormal1;
