import React from 'react';

function Level1v2() {
  return <svg
        width="19px"
        height="21px"
        viewBox="0 0 19 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
            <ellipse id="path-1" cx="8" cy="9.48717949" rx="8" ry="9.48717949" />
        </defs>
        <g
            id="Gamification-Fix"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
                id="Gamification-Score-Changes"
                transform="translate(-1076.000000, -265.000000)">
                <g id="Group-8-Copy" transform="translate(1033.000000, 256.000000)">
                    <g id="Group-2" transform="translate(43.000000, 10.000000)">
                        <ellipse
                            id="Oval-2"
                            stroke="#4550E6"
                            strokeWidth="0.512820513"
                            fill="#8D94F2"
                            fillRule="nonzero"
                            cx="9.5"
                            cy="9.74358974"
                            rx="8"
                            ry="9.74358974"
                        />
                        <ellipse
                            id="XMLID_12224_"
                            fill="#E3E5FC"
                            fillRule="nonzero"
                            cx="9.5"
                            cy="19.2307692"
                            rx="9.5"
                            ry="1"
                        />
                        <path
                            d="M16.956503,10.4230769 L15.306503,12.1153846 C15.156503,12.2692308 14.856503,12.2692308 14.706503,12.1153846 L12.5065031,9.85897436 C12.3565031,9.70512821 12.0565031,9.70512821 11.9065031,9.85897436 L9.70650312,12.1153846 C9.55650312,12.2692308 9.25650312,12.2692308 9.10650312,12.1153846 L6.90650316,9.85897436 C6.75650316,9.70512821 6.45650316,9.70512821 6.30650317,9.85897436 L4.1065032,12.1153846 C3.9565032,12.2692308 3.65650321,12.2692308 3.50650321,12.1153846 L1.85650323,10.4230769 C1.70650324,10.2692308 1.45650324,10.3717949 1.50650324,10.5769231 C1.85650323,16.2692308 5.25650318,19.0897436 9.40650312,19.0897436 C13.5565031,19.0897436 16.956503,16.2692308 17.306503,10.5769231 C17.306503,10.3717949 17.056503,10.2692308 16.956503,10.4230769 Z"
                            id="XMLID_12382_"
                            fill="#ECEDFC"
                            fillRule="nonzero"
                        />
                        <g id="Group" transform="translate(1.500000, 0.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <g id="Mask" fillRule="nonzero" />
                            <rect
                                id="Rectangle"
                                fill="#ECEDFC"
                                fillRule="nonzero"
                                mask="url(#mask-2)"
                                x="5"
                                y="7.94871795"
                                width="1"
                                height="1"
                            />
                            <g
                                mask="url(#mask-2)"
                                fill="#ECEDFC"
                                fillRule="nonzero"
                                id="XMLID_12382_">
                                <g transform="translate(-10.500000, -4.102564)">
                                    <path
                                        d="M29.6235727,5.24887284 L27.8489866,7.19742098 C27.6876606,7.37456172 27.3650086,7.37456172 27.2036826,7.19742098 L24.8375679,4.5993568 C24.6762419,4.42221606 24.3535899,4.42221606 24.1922639,4.5993568 L21.8261491,7.19742098 C21.6648231,7.37456172 21.3421711,7.37456172 21.1808451,7.19742098 L18.8147304,4.5993568 C18.6534044,4.42221606 18.3307524,4.42221606 18.1694264,4.5993568 L15.8033117,7.19742098 C15.6419857,7.37456172 15.3193337,7.37456172 15.1580077,7.19742098 L13.3834216,5.24887284 C13.2220956,5.0717321 12.9532189,5.18982593 13.0069943,5.42601358 C13.3834216,11.9802209 17.0401444,15.2278012 21.5034971,15.2278012 C25.9668499,15.2278012 29.6235727,11.9802209 30,5.42601358 C30,5.18982593 29.7311233,5.0717321 29.6235727,5.24887284 Z"
                                        transform="translate(21.500000, 9.847151) scale(1, -1) translate(-21.500000, -9.847151) "
                                    />
                                    <path
                                        d="M25.1235727,0.7823716 L23.3489866,2.73091974 C23.1876606,2.90806048 22.8650086,2.90806048 22.7036826,2.73091974 L20.3375679,0.132855555 C20.1762419,-0.0442851849 19.8535899,-0.0442851849 19.6922639,0.132855555 L17.3261491,2.73091974 C17.1648231,2.90806048 16.8421711,2.90806048 16.6808451,2.73091974 L14.3147304,0.132855555 C14.1534044,-0.0442851849 13.8307524,-0.0442851849 13.6694264,0.132855555 L11.3033117,2.73091974 C11.1419857,2.90806048 10.8193337,2.90806048 10.6580077,2.73091974 L8.88342162,0.7823716 C8.72209562,0.60523086 8.45321894,0.723324687 8.50699428,0.95951234 C8.88342162,7.51371971 12.5401444,10.7612999 17.0034971,10.7612999 C21.4668499,10.7612999 25.1235727,7.51371971 25.5,0.95951234 C25.5,0.723324687 25.2311233,0.60523086 25.1235727,0.7823716 Z"
                                        transform="translate(17.000000, 5.380650) scale(1, -1) translate(-17.000000, -5.380650) "
                                    />
                                    <path
                                        d="M18.3900747,5.24887284 L16.4269124,7.19742098 C16.2484431,7.37456172 15.8915046,7.37456172 15.7130353,7.19742098 L13.0954856,4.5993568 C12.9170163,4.42221606 12.5600778,4.42221606 12.3816085,4.5993568 L9.76405885,7.19742098 C9.58558955,7.37456172 9.22865097,7.37456172 9.05018168,7.19742098 L6.43263205,4.5993568 C6.25416276,4.42221606 5.89722418,4.42221606 5.71875488,4.5993568 L3.10120526,7.19742098 C2.92273597,7.37456172 2.56579738,7.37456172 2.38732809,7.19742098 L0.424165873,5.24887284 C0.245696581,5.0717321 -0.0517522402,5.18982593 0.00773752394,5.42601358 C0.424165873,11.9802209 4.46946984,15.2278012 9.40712026,15.2278012 C14.3447707,15.2278012 18.3900747,11.9802209 18.806503,5.42601358 C18.806503,5.18982593 18.5090542,5.0717321 18.3900747,5.24887284 Z"
                                        transform="translate(9.403251, 9.847151) scale(1, -1) translate(-9.403251, -9.847151) "
                                    />
                                </g>
                            </g>
                        </g>
                        <ellipse
                            id="Oval-2"
                            stroke="#4550E6"
                            strokeWidth="0.512820513"
                            fillRule="nonzero"
                            cx="9.5"
                            cy="9.74358974"
                            rx="8"
                            ry="9.74358974"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default Level1v2;
