import {all, put, take, takeLatest} from 'redux-saga/effects';
import {types as asrTypes} from '../actions/asr';
import {getAppConfig, types as configTypes} from '../actions/config';
import {allSettingReceived} from '../actions/common';
import {requestUserScores, types as userTypes} from '../actions/user';
import {types as snoozeTypes} from '../actions/snooze';

function* onGetUserProfile(action) {
    const { snoozeFor } = action.payload;

    if (snoozeFor && snoozeFor > 0) {
        yield put({
            type: snoozeTypes.SET_SNOOZE_TIMER_SUCCESS,
            payload: { snoozeFor }
        });
        return;
    }

    // Triggering getAppConfig -> Get devices list -> start mix request (listener)
    yield put(getAppConfig());
    yield put(requestUserScores());
}

function* waitGetUserProfile() {
    yield takeLatest(userTypes.GET_USER_PROFILE_SUCCESS, onGetUserProfile);
}

function* waitForSettings() {
    yield all([
        take(configTypes.GET_APP_CONFIG_SUCCESS),
        take(asrTypes.GET_USER_ASR_CONFIG_SUCCESS)
    ]);

    yield put(allSettingReceived());
}

export default [waitGetUserProfile(), waitForSettings()];
