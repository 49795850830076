import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Wrapper, Id, Timestamp } from './styled';

function CallId({ value }) {
  return <Wrapper>
        <Timestamp>{moment(value.timestamp).format('LLL')}:</Timestamp>
        <Id>{value.callId}</Id>
    </Wrapper>
}

CallId.propTypes = {
    value: PropTypes.object.isRequired
};

export default CallId;
