import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function I2XLogo(props) {
  return <svg width={props.size} viewBox="0 0 914 526">
        <g transform="translate(-41427 -40942)">
            <use
                href="#path0_fill"
                transform="translate(41427 40942)"
                fill={props.color}
            />
        </g>
        <defs>
            <path
                id="path0_fill"
                fillRule="evenodd"
                d="M 67.027 124.13C 106.634 124.13 134.053 97.7521 134.053 62.0648C 134.053 26.3775 106.634 -9.47035e-05 67.027 -9.47035e-05C 27.4204 -9.47035e-05 0 26.3775 0 62.0648C 0 97.7521 27.4204 124.13 67.027 124.13ZM 7.61667 526L 127.96 526L 127.96 169.127L 7.61667 169.127L 7.61667 526ZM 193.464 526L 514.886 526L 514.886 423.593L 440.244 423.593C 420.44 423.593 388.45 426.696 365.6 429.799C 425.01 367.735 493.56 287.05 493.56 214.124C 493.56 124.13 431.104 65.1682 341.227 65.1682C 275.724 65.1682 233.07 88.4423 187.37 138.094L 251.35 203.263C 272.677 181.54 294.003 159.817 324.47 159.817C 356.46 159.817 379.31 178.437 379.31 220.33C 379.31 282.395 298.574 356.873 193.464 456.177L 193.464 526ZM 554.494 526L 659.603 339.805L 560.586 169.127L 688.547 169.127L 714.444 218.779C 723.584 238.95 732.724 260.673 741.864 280.844L 744.91 280.844C 751.004 260.673 760.143 238.95 766.236 218.779L 782.994 169.127L 907.906 169.127L 808.89 355.321L 914 526L 786.04 526L 758.62 476.348C 747.956 456.177 738.816 434.454 728.153 414.283L 725.106 414.283C 717.49 434.454 708.35 454.625 700.733 476.348L 680.93 526L 554.494 526ZM 565.156 1.55162L 898.767 1.55162L 898.767 94.649L 565.156 94.649L 565.156 1.55162Z"
            />
        </defs>
    </svg>
}

I2XLogo.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

I2XLogo.defaultProps = {
    size: 100,
    color: colors.blue.i2x
};

export default I2XLogo;
