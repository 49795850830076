import styled from 'styled-components';

import { colors } from '../../../../utils';

const ItemSelected = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 12px;

    color: ${colors.blue.madison};

    user-select: none;
`;

export default ItemSelected;
