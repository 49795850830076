import React from 'react';

function EmojiDisaster2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <ellipse
                cx="21.5"
                cy="41.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="11.5"
                ry="1.5"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M34.781 34.769l-1.2-7.7c-.1-.3-.5-.5-.7-.3l-6.2 4.7c-.3.2-.2.6.1.7l2.1.8c-1.9 3.5-5.9 5.4-10 4.5l6.5-16.2 3.8 1.5c.4.1.8 0 .9-.4l.6-1.5c.1-.3 0-.7-.4-.9l-3.8-1.5.9-2.1c1.9.1 3.8-1 4.5-2.9 1-2.4-.3-5.1-2.8-6.1s-5.3.1-6.2 2.5c-.7 1.8-.2 3.9 1.3 5.2l-.9 2.1-3.8-1.5c-.4-.1-.8 0-.9.4l-.6 1.5c-.1.3 0 .7.4.9l3.8 1.5-6.5 16.2c-3.5-2.2-5.2-6.3-4.1-10.2l2.1.8c.3.1.7-.1.6-.5l-1.2-7.7c-.1-.3-.5-.5-.7-.3l-6.2 4.7c-.3.2-.2.6.1.7l1.8.7c-2 6.3 1.3 13.2 7.8 15.8 6.4 2.6 13.6-.1 16.5-6l1.8.7c.3.4.7.2.6-.1zm-6.7-24.7c1 .4 1.5 1.6 1.1 2.5-.4 1-1.6 1.5-2.6 1-1-.4-1.5-1.6-1.1-2.5.4-.9 1.6-1.4 2.6-1z"
            />
            <circle
                cx="38.8"
                cy="25.8"
                r="1.8"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="2.2"
                cy="7.2"
                r="2.2"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="3.8"
                cy="16.8"
                r="1.8"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="8.8"
                cy="1.8"
                r="1.8"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="41.2"
                cy="17.2"
                r="2.2"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M34.781 34.769l-1.2-7.7c-.1-.3-.5-.5-.7-.3l-6.2 4.7c-.3.2-.2.6.1.7l2.1.8c-1.9 3.5-5.9 5.4-10 4.5l6.5-16.2 3.8 1.5c.4.1.8 0 .9-.4l.6-1.5c.1-.3 0-.7-.4-.9l-3.8-1.5.9-2.1c1.9.1 3.8-1 4.5-2.9 1-2.4-.3-5.1-2.8-6.1s-5.3.1-6.2 2.5c-.7 1.8-.2 3.9 1.3 5.2l-.9 2.1-3.8-1.5c-.4-.1-.8 0-.9.4l-.6 1.5c-.1.3 0 .7.4.9l3.8 1.5-6.5 16.2c-3.5-2.2-5.2-6.3-4.1-10.2l2.1.8c.3.1.7-.1.6-.5l-1.2-7.7c-.1-.3-.5-.5-.7-.3l-6.2 4.7c-.3.2-.2.6.1.7l1.8.7c-2 6.3 1.3 13.2 7.8 15.8 6.4 2.6 13.6-.1 16.5-6l1.8.7c.3.4.7.2.6-.1zm-6.7-24.7c1 .4 1.5 1.6 1.1 2.5-.4 1-1.6 1.5-2.6 1-1-.4-1.5-1.6-1.1-2.5.4-.9 1.6-1.4 2.6-1z"
            />
        </g>
    </svg>
}

export default EmojiDisaster2;
