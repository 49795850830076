import React from 'react';

function Morning() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={49} height={38}>
            <defs>
                <clipPath id="a">
                    <path d="M1908 0v1258H0V0h1908Z" />
                </clipPath>
                <clipPath id="b">
                    <path d="M8.2 0a8.2 8.2 0 1 1 0 16.4A8.2 8.2 0 0 1 8.2 0Z" />
                </clipPath>
                <clipPath id="c">
                    <path d="M5.142.702C1.685 2.083-.303 5.896.675 9.613c1.1 4 5.2 6.5 9.3 5.4 4-1.1 6.5-5.2 5.4-9.3-1-3.8-4.7-6.2-8.5-5.6l.007-.001.245-.037c4.023-.546 7.81 1.919 8.884 5.888l.064.25c1.2 4.4-1.4 8.8-5.8 10-4.4 1.2-8.8-1.4-10-5.8-1.1-4.1 1.2-8.4 5.1-9.8l-.101.038Z" />
                </clipPath>
                <clipPath id="d">
                    <path d="M.663.063c.5-.2 1 .1 1.1.6l.5 1.8c.2.5-.1 1-.6 1.1-.5.2-1-.1-1.1-.6l-.5-1.8c-.2-.5.1-1 .6-1.1Z" />
                </clipPath>
                <clipPath id="e">
                    <path d="M.144.444c.2-.4.8-.6 1.2-.3l1.6.9c.4.2.6.8.3 1.2-.2.4-.8.6-1.2.3l-1.6-.9c-.4-.2-.6-.8-.3-1.2Z" />
                </clipPath>
                <clipPath id="f">
                    <path d="M2.463.063c.5-.2 1 .1 1.1.6.2.5-.1 1-.6 1.1l-1.8.5c-.5.2-1-.1-1.1-.6-.2-.5.1-1 .6-1.1Z" />
                </clipPath>
                <clipPath id="g">
                    <path d="M1.044.444c.2-.4.8-.6 1.2-.3.4.2.6.8.3 1.2l-.9 1.6c-.2.4-.8.6-1.2.3-.4-.2-.6-.8-.3-1.2Z" />
                </clipPath>
                <clipPath id="h">
                    <path d="M.663.063c.5-.2 1 .1 1.1.6l.5 1.8c.2.5-.1 1-.6 1.1-.5.2-1-.1-1.1-.6l-.5-1.8c-.2-.5.1-1 .6-1.1Z" />
                </clipPath>
                <clipPath id="i">
                    <path d="M.144.444c.2-.4.8-.6 1.2-.3l1.6.9c.4.2.6.8.3 1.2-.2.4-.8.6-1.2.3l-1.6-.9c-.4-.2-.6-.8-.3-1.2Z" />
                </clipPath>
                <clipPath id="j">
                    <path d="M2.463.063c.5-.2.9.1 1.1.6.2.5-.1 1-.6 1.1l-1.8.5c-.5.2-.9-.1-1.1-.6-.2-.5.1-1 .6-1.1Z" />
                </clipPath>
                <clipPath id="k">
                    <path d="M1.044.444c.2-.4.8-.6 1.2-.3.4.2.6.8.3 1.2l-.9 1.6c-.2.4-.8.6-1.2.3-.4-.2-.6-.8-.3-1.2Z" />
                </clipPath>
                <clipPath id="l">
                    <path d="M.9 0c.497 0 .9.269.9.6 0 .331-.403.6-.9.6S0 .931 0 .6C0 .269.403 0 .9 0Z" />
                </clipPath>
                <clipPath id="m">
                    <path d="M5.778.62c0 1.4-.9 2.6-2.3 3-1.4.4-2.8-.3-3.4-1.5-.2-.3 0-.8.4-.9l4.5-1.2c.4-.1.8.2.8.6Z" />
                </clipPath>
                <clipPath id="n">
                    <path d="M.9 0c.497 0 .9.269.9.6 0 .331-.403.6-.9.6S0 .931 0 .6C0 .269.403 0 .9 0Z" />
                </clipPath>
                <clipPath id="o">
                    <path d="M17.1 0c1.6 0 2.9 1 3.3 2.5.6-.6 1.5-1 2.4-1 1.9 0 3.5 1.6 3.4 3.7.5-.4 1.2-.6 1.9-.6 2 0 3.7 1.7 3.4 3.9 1.5.4 2.7 1.7 2.7 3.4s-1.3 3.1-2.9 3.4c.9 2.3-.9 4.6-3.2 4.6-.7 0-1.3-.2-1.9-.6.1 2.1-1.5 3.7-3.4 3.7-1 0-2-.5-2.6-1.2-.5 1.2-1.7 2-3.1 2-1.4 0-2.6-.8-3.1-2-.6.7-1.5 1.2-2.6 1.2-1.9 0-3.5-1.6-3.4-3.7-.5.4-1.2.6-1.9.6-2.3 0-4.1-2.3-3.2-4.6C1.3 15 0 13.6 0 11.9s1.1-3.1 2.7-3.4c-.3-2.2 1.4-3.9 3.4-3.9.7 0 1.3.2 1.9.6-.1-2.1 1.5-3.7 3.4-3.7.9 0 1.8.4 2.4 1 .4-1.4 1.7-2.5 3.3-2.5Z" />
                </clipPath>
                <clipPath id="p">
                    <path d="M33.8 0c.4.6.6 1.2.4 1.9 0 1.7-1.3 3.1-2.9 3.4.9 2.3-.9 4.6-3.2 4.6-.7 0-1.3-.2-1.9-.6.1 2.1-1.5 3.7-3.4 3.7-1 0-2-.5-2.6-1.2-.5 1.2-1.7 2-3.1 2-1.4 0-2.6-.8-3.1-2-.6.7-1.5 1.2-2.6 1.2-1.9 0-3.5-1.6-3.4-3.7-.5.4-1.2.6-1.9.6-2.3 0-4.1-2.3-3.2-4.6C1.3 5 0 3.6 0 1.9 0 1.2.2.5.6 0c.6.4 1.1 1.2 1.1 2 .1 1.9 1.7 3.5 3.7 3.5.4 0 .7 0 1-.1.5-.1 1.1.2 1.2.7.4 1.6 1.9 2.7 3.5 2.7.7 0 1.4-.2 2-.6.4-.3 1-.2 1.3.2.7.8 1.7 1.3 2.8 1.3 1.1 0 2.1-.5 2.8-1.3.3-.4.9-.5 1.3-.2.5.4 1.2.6 2 .6 1.6 0 3.1-1.1 3.5-2.7.1-.5.7-.8 1.2-.7.4.1.7.1 1 .1 2 0 3.6-1.6 3.7-3.5 0-.8.4-1.6 1.1-2Z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="translate(-1285 -815)">
                <g clipPath="url(#b)" transform="rotate(-14.756 3823.443 -4567.513)">
                    <path fill="#F8F9FF" d="M0 0h16.4v16.4H0V0z" />
                </g>
                <g clipPath="url(#c)" transform="translate(1291.404 820.987)">
                    <path fill="#D6D9FF" d="M0 0h16.368v16.506H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1301.576 836.978a8.2 8.2 0 1 0-4.177-15.859 8.2 8.2 0 0 0 4.177 15.859Z"
                />
                <g clipPath="url(#d)" transform="translate(1295.516 815.937)">
                    <path fill="#D6D9FF" d="M0 0h2.325v3.625H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1297.279 816.6a.8.8 0 0 0-1.1-.6c-.5.1-.8.6-.6 1.1l.5 1.8c.1.5.6.8 1.1.6.5-.1.8-.6.6-1.1l-.5-1.8Z"
                />
                <g clipPath="url(#e)" transform="translate(1287.735 821.856)">
                    <path fill="#D6D9FF" d="M0 0h3.389v2.689H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1289.079 822c-.4-.3-1-.1-1.2.3-.3.4-.1 1 .3 1.2l1.6.9c.4.3 1 .1 1.2-.3.3-.4.1-1-.3-1.2l-1.6-.9Z"
                />
                <g clipPath="url(#f)" transform="translate(1286.416 830.937)">
                    <path fill="#D6D9FF" d="M0 0h3.625v2.325H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1287.079 831.5c-.5.1-.8.6-.6 1.1.1.5.6.8 1.1.6l1.8-.5c.5-.1.8-.6.6-1.1a.8.8 0 0 0-1.1-.6l-1.8.5Z"
                />
                <g clipPath="url(#g)" transform="translate(1292.235 837.656)">
                    <path fill="#D6D9FF" d="M0 0h2.689v3.389H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1292.379 839.7c-.3.4-.1 1 .3 1.2.4.3 1 .1 1.2-.3l.9-1.6c.3-.4.1-1-.3-1.2-.4-.3-1-.1-1.2.3l-.9 1.6Z"
                />
                <g clipPath="url(#h)" transform="translate(1301.416 838.737)">
                    <path fill="#FFE500" d="M0 0h2.325v3.625H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#44413B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1301.979 841.7c.1.5.6.8 1.1.6.5-.1.8-.6.6-1.1l-.5-1.8a.8.8 0 0 0-1.1-.6c-.5.1-.8.6-.6 1.1l.5 1.8Z"
                />
                <g clipPath="url(#i)" transform="translate(1308.135 833.856)">
                    <path fill="#FFE500" d="M0 0h3.389v2.689H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#44413B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1310.179 836.4c.4.3 1 .1 1.2-.3.3-.4.1-1-.3-1.2l-1.6-.9c-.4-.3-1-.1-1.2.3-.3.4-.1 1 .3 1.2l1.6.9Z"
                />
                <g clipPath="url(#j)" transform="translate(1309.216 825.037)">
                    <path fill="#D6D9FF" d="M0 0h3.625v2.325H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1312.179 826.8c.5-.1.8-.6.6-1.1-.2-.5-.6-.8-1.1-.6l-1.8.5c-.5.1-.8.6-.6 1.1.2.5.6.8 1.1.6l1.8-.5Z"
                />
                <g clipPath="url(#k)" transform="translate(1304.335 817.256)">
                    <path fill="#D6D9FF" d="M0 0h2.689v3.389H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1306.879 818.6c.3-.4.1-1-.3-1.2-.4-.3-1-.1-1.2.3l-.9 1.6c-.3.4-.1 1 .3 1.2.4.3 1 .1 1.2-.3l.9-1.6Z"
                />
                <g clipPath="url(#l)" transform="rotate(-14.756 3851.562 -4580.068)">
                    <path fill="#D6D9FF" d="M0 0h1.8v1.2H0V0z" />
                </g>
                <g clipPath="url(#m)" transform="translate(1297.3 829.98)">
                    <path fill="#D6D9FF" d="M0 0h5.778v3.732H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1302.279 830c.4-.1.8.2.8.6 0 1.4-.9 2.6-2.3 3-1.4.4-2.8-.3-3.4-1.5-.2-.3 0-.8.4-.9l4.5-1.2ZM1294.079 829.4c-.1-.5.2-1 .6-1.1.5-.1 1 .2 1.1.6"
                />
                <g clipPath="url(#n)" transform="rotate(-14.756 3846.492 -4619.948)">
                    <path fill="#D6D9FF" d="M0 0h1.8v1.2H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1304.579 826.6a.8.8 0 0 0-1.1-.6c-.5.1-.8.6-.6 1.1"
                />
                <g clipPath="url(#o)" transform="translate(1299.179 828.5)">
                    <path fill="#FFF" d="M0 0h34.2v23.8H0V0z" />
                </g>
                <g clipPath="url(#p)" transform="translate(1298.979 838.5)">
                    <path fill="#D6D9FF" d="M0 0h34.278v13.8H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1333.379 840.4c0-1.7-1.2-3-2.7-3.4.3-2.2-1.4-3.9-3.4-3.9-.7 0-1.4.2-1.9.6.1-2.1-1.5-3.7-3.4-3.7-.9 0-1.8.4-2.4 1-.4-1.5-1.7-2.5-3.3-2.5-1.6 0-2.9 1.1-3.3 2.5-.6-.6-1.5-1-2.4-1-1.9 0-3.5 1.6-3.4 3.7-.6-.4-1.2-.6-1.9-.6-2 0-3.7 1.7-3.4 3.9-1.6.3-2.7 1.7-2.7 3.4s1.3 3.1 2.9 3.4c-.9 2.3.9 4.6 3.2 4.6.7 0 1.4-.2 1.9-.6-.1 2.1 1.5 3.7 3.4 3.7 1.1 0 2-.5 2.6-1.2.5 1.2 1.7 2 3.1 2 1.4 0 2.6-.8 3.1-2 .6.7 1.6 1.2 2.6 1.2 1.9 0 3.5-1.6 3.4-3.7.6.4 1.2.6 1.9.6 2.3 0 4.1-2.3 3.2-4.6 1.6-.3 2.9-1.7 2.9-3.4Z"
                />
            </g>
        </svg>
    );
}

export default Morning;
