import styled from 'styled-components';

const PassEye = styled.span`
    height: 30px;
    width: 30px;
    border: none;
    margin-left: -40px;
    margin-right: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.9;
    }
`;

export default PassEye;
