import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ClickOutside extends Component {
    static propTypes = {
        onClickOutside: PropTypes.func,
        children: PropTypes.any
    };

    static defaultProps = {
        onClickOutside: () => {},
        children: null
    };

    static WrapperNode = null;

    componentDidMount() {
        document.addEventListener('mousedown', this._eventHandler, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._eventHandler, false);
    }

    _eventHandler = event => {
        if (this.WrapperNode.contains(event.target)) return;
        this.props.onClickOutside();
    };

    render() {
        return (
            <div
                ref={ref => {
                    this.WrapperNode = ref;
                }}>
                {this.props.children}
            </div>
        );
    }
}

export default ClickOutside;
