import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Icon, MarginAuto } from '../../common';

import { loudness } from '../../../utils';

const { LOUDNESS_ICONS, getLoudnessLevel } = loudness;

class Loudness extends PureComponent {
    static propTypes = {
        value: PropTypes.number
    };

    static defaultProps = {
        value: 0
    };

    _getLevel() {
        const { value } = this.props;
        if (!value) return;
        return getLoudnessLevel(value);
    }

    render() {
        return (
            <MarginAuto>
                    <Icon name={LOUDNESS_ICONS[this._getLevel()]} />
                </MarginAuto>
        );
    }
}

export default Loudness;
