import { call, put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions/actionTypes/trainer';

export function* handleNotification() {
    yield call(delay, 5000);
    yield put({ type: HIDE_NOTIFICATION });
}

export function* notification() {
    yield takeLatest(SHOW_NOTIFICATION, handleNotification);
}

export default [notification()];
