import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Plus(props) {
  return <svg width={props.size} viewBox="0 0 9 9">
        <g fill={props.color} fillRule="evenodd">
            <rect width="1.4" height="9" x="3.8" rx=".7" />
            <rect width="1.4" height="9" x="3.8" rx=".7" transform="rotate(90 4.5 4.5)" />
        </g>
    </svg>
}

Plus.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Plus.defaultProps = {
    size: 9,
    color: colors.red.copper
};

export default Plus;
