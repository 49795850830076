import styled from 'styled-components';

import { colors } from '../../../../utils';

const Wrapper = styled.div`
    width: 300px;
    min-width: 300px;
    min-height: 105px;

    border-radius: 4px;
    padding: 16px;

    background-color: ${colors.white.basic};
    box-shadow: 0 2px 6px 0 #cdd3df;
    overflow: hidden;
`;

export default Wrapper;
