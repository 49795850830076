import store2 from 'store2';
import { types as commonTypes } from '../actions/common';
import { isReadyToReload } from '../selectors';
import { captureException, captureWarnMessage } from '../../../tracker/raven';
import { reloaderLogger } from '../../../utils/logger';

const debug = store2.namespace('debug');

function reload() {
    if (debug.get('pauseOnReload', false)) {
        // eslint-disable-next-line no-debugger
        debugger;
    }

    if (process.env.NODE_ENV === 'development' && debug.get('disableReload', false)) {
        reloaderLogger.warn('Reloading disabled. Only for dev mode');
        return;
    }
    window.location.reload(true);
}

// TODO: Refactor to saga
// eslint-disable-next-line no-unused-vars
export default waitStore => store => {
    let promise;

    function waitAllDaemonConnectionsClosedAndReload(
        reason,
        isCallStarted,
    ) {
        if (promise) {
            reloaderLogger.warn('Reload has already scheduled!');
            return;
        }

        promise = waitStore(isReadyToReload);
        promise
            .then(() => {
                const logMessage = `App will be reloaded. Reason: ${reason}`
                reloaderLogger.warn(logMessage);
                if (isCallStarted) {
                    captureException(new Error(`Application reload happens during the call. ${logMessage}`));
                }
                reload();
            })
            .catch(error => {
                captureException(error);
                reloaderLogger.error(error.message, error);
            });
    }

    return next => action => {
        const { type, payload } = action;
        const result = next(action);

        if (type === commonTypes.RELOAD) {
            if (payload.isImmediate) {
                reloaderLogger.log('RELOAD IMMEDIATE');
                const { code, reason } = payload;
                captureWarnMessage(`RELOAD IMMEDIATE. Code: ${code}. Reason: ${reason}`);
            } else {
                const { code, countdown, reason } = payload;
                reloaderLogger.log(`RELOAD after ${countdown}sec. Code: ${code}. Reason: ${reason}`);
                captureWarnMessage(`RELOAD after ${countdown}sec. Code: ${code}. Reason: ${reason}`);
            }

            waitAllDaemonConnectionsClosedAndReload(
                payload.reason,
                payload.isCallStarted,
            );
        }

        return result;
    };
};
