import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, ErrorMessage, SaveButton, ButtonWrapper, ErrorWrapper } from './styled';

class NickName extends Component {
    static propTypes = {
        pending: PropTypes.bool.isRequired,
        errorCode: PropTypes.number,
        localization: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        errorCode: null
    };

    state = {
        value: this.props.value
    };

    onChange(value) {
        this.setState({ value });
    }

    saveButtonClicked() {
        const { pending, onSave } = this.props;

        if (!pending) {
            onSave(this.state.value);
        }
    }

    captureEnter(event) {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }

    render() {
        const { pending, errorCode, localization } = this.props;

        return (
            <div>
                <Input
                    type="text"
                    value={this.state.value}
                    disabled={pending}
                    onChange={({ target }) => this.onChange(target.value)}
                    onKeyUp={this.captureEnter}
                />
                <ErrorWrapper>
                    {errorCode && (
                        <ErrorMessage>
                            {localization.getText(`settings.error.${errorCode}`)}
                        </ErrorMessage>
                    )}
                </ErrorWrapper>
                <ButtonWrapper>
                    <SaveButton onClick={() => this.saveButtonClicked()}>
                        {localization.getText('settings.nickname.button')}
                    </SaveButton>
                </ButtonWrapper>
            </div>
        );
    }
}

export default NickName;
