import styled from 'styled-components';
import { colors } from '../../../utils';

export default styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    align-content: center;

    min-height: 100vh;
    padding: 20px;

    background-color: ${colors.blue.royal_blue};
`;
