import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled';

function Card({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

Card.propTypes = {
    children: PropTypes.any
};

Card.defaultProps = {
    children: PropTypes.any
};

export default Card;
