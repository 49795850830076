import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../utils';

const Label = styled.div`
    font-size: ${props => props.$fontSize};
    font-weight: ${props => props.$fontWeight};
    color: ${props => props.$color};
    vertical-align: middle;
`;

Label.propTypes = {
    $fontSize: PropTypes.string,
    $fontWeight: PropTypes.string,
    $color: PropTypes.string
};

Label.defaultProps = {
    $fontSize: '16px',
    $fontWeight: 'bold',
    $color: colors.blue.madison
};

export default Label;
