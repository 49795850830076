import React from 'react';

function EmojiDisaster2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="45" height="47" viewBox="0 0 45 47">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)">
                <ellipse
                    cx="20.465"
                    cy="20.444"
                    fill="#F8F9FF"
                    fillRule="nonzero"
                    rx="20.465"
                    ry="20.444"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M20.465 0C9.21 0 0 9.2 0 20.444 0 31.69 9.21 40.89 20.465 40.89c11.256 0 20.465-9.2 20.465-20.445C40.93 9.2 31.72 0 20.465 0zm0 37.822c-10.335 0-18.623-8.382-18.623-18.604C1.842 8.893 10.232.613 20.465.613s18.623 8.383 18.623 18.605c.103 10.222-8.288 18.604-18.623 18.604z"
                />
                <ellipse
                    cx="20.465"
                    cy="44.467"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="16.372"
                    ry="1.533"
                />
                <ellipse
                    cx="20.465"
                    cy="20.444"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    rx="20.465"
                    ry="20.444"
                />
                <ellipse
                    cx="32.233"
                    cy="25.044"
                    fill="#C1C4E6"
                    fillRule="nonzero"
                    rx="2.558"
                    ry="1.533"
                />
                <ellipse
                    cx="7.674"
                    cy="25.044"
                    fill="#C1C4E6"
                    fillRule="nonzero"
                    rx="2.558"
                    ry="1.533"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.14 21.262c0-1.022.818-1.84 1.841-1.84 1.024 0 1.842.818 1.842 1.84M30.698 21.262c0-1.022.818-1.84 1.842-1.84 1.023 0 1.841.818 1.841 1.84"
                />
                <g stroke="#4550E6" strokeLinecap="round" strokeLinejoin="round">
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        d="M19.953 35.369c9.414 0 10.642-3.885 10.13-7.156-.306-1.942-2.557-3.169-4.706-2.453-1.535.511-3.48.818-5.424.818-1.944 0-3.888-.307-5.423-.818-2.046-.716-4.297.511-4.707 2.453-.511 3.17.717 7.156 10.13 7.156z"
                    />
                    <path d="M30.186 28.929c-1.023.511-2.558 1.022-4.707 1.431-1.535.204-3.274.409-5.526.409-2.25 0-3.99-.205-5.525-.409-2.149-.409-3.684-.92-4.707-1.431" />
                    <path d="M15.144 25.964l-.716 4.294-.614 4.19M19.953 35.369V26.68M24.763 25.964l.716 4.294.614 4.19" />
                </g>
            </g>
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M44.488 32.689c0 2.862-2.25 5.111-5.116 5.111-2.865 0-5.116-2.249-5.116-5.111 0-2.862 5.116-11.245 5.116-11.245s5.116 8.383 5.116 11.245z"
            />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                d="M39.372 35.244c-2.353 0-4.298-1.635-4.912-3.782-.102.511-.204.92-.204 1.227 0 2.862 2.251 5.111 5.116 5.111 2.865 0 5.116-2.249 5.116-5.111 0-.307-.102-.716-.204-1.227-.614 2.147-2.558 3.782-4.912 3.782z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M44.488 32.689c0 2.862-2.25 5.111-5.116 5.111-2.865 0-5.116-2.249-5.116-5.111 0-2.862 5.116-11.245 5.116-11.245s5.116 8.383 5.116 11.245z"
            />
        </g>
    </svg>
}

export default EmojiDisaster2;
