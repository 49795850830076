import { captureException } from '../../../tracker/raven';
import { commonLogger } from '../../../utils/logger';

export default () => next => action => {
    try {
        return next(action);
    } catch (error) {
        captureException(error, action);
        commonLogger.error(error.message, error);
    }
};
