import styled from 'styled-components';

import { colors } from '../../../../../utils';

const Label = styled.div`
    width: 130px;
    min-height: 45px;

    padding: 10px;

    background-color: ${colors.green.aquamarine};
    color: ${colors.white.basic};

    border-radius: 4px;

    font-size: 13px;
    font-weight: bold;
    text-align: left;
`;

export default Label;
