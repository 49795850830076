import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const Suggestion = styled.div`
    border-bottom: 1px solid ${colors.gray.athens};
    padding: 10px 10px;
    background: ${props => (props.$isPositive ? colors.white.basic : colors.red.cupid)};
    display: flex;
    justify-content: left;
    width: 100%;
    font-size: 0.9em;

    > div:first-child {
        margin-right: 8px;

        .error-icon {
            margin-top: -2px;
            margin-left: -2px;
        }
    }

    &:last-child {
        border-bottom: none;
    }

    > div.msg {
        flex-grow: 1;
    }
`;

Suggestion.propTypes = {
    $isPositive: PropTypes.bool
};

export default Suggestion;
