// @flow

// eslint-disable-next-line import/no-named-default
import { safeStringify } from '../background/utils';

export const captureException = (
    error: Object | string,
) => {
    console.error(error)

    if(window.dispatchLog) window.dispatchLog(error);
};

export const captureWarnMessage = (
    message: string = '',
    options: Object | null = null
) => {
    if (options) {
        console.warn(message, safeStringify(options));
    } else {
        console.warn(message);
    }

    if(window.dispatchLog) window.dispatchLog(message, 'warn', options);
};

export const captureInfoMessage = (
    message: string = '',
    options: Object | null = null
) => {
    if(window.dispatchLog) window.dispatchLog(message, 'info', options);
};


