// @flow

import orderBy from 'lodash/orderBy';

export const DEFAULT_WHITELIST: string = 'default-whitelist';
export const CLICKABLE_WHITELIST: string = 'clickable-whitelist';
export const DETECTED_WHITELIST: string = 'detected-whitelist';
export const DEFAULT_BLACKLIST: string = 'default-blacklist';
export const CLICKABLE_BLACKLIST: string = 'clickable-blacklist';

export const SUPER_KEYWORD_THRESHOLD: number = 3;

export const getPillType = (type: string, count: number) => {
    let pillType = '';
    if (type === 'whitelist') {
        if (!count) {
            pillType = DEFAULT_WHITELIST;
        } else {
            pillType = DETECTED_WHITELIST;
        }
    } else {
        pillType = DEFAULT_BLACKLIST;
    }
    return pillType;
};

export const getSortedDetection = (
    list: Array<Object>,
    countable: boolean = true,
    order: string = 'desc'
) => {
    if (!list) return;
    return orderBy(list, countable ? 'count' : word => word.count !== 0, [order]);
};

export const getDetectedWords = (list: Array<Object> = []): Array<Object> => {
    return list.filter((text: Object) => text.count > 0);
};

export const phraseTypes = {
    SPOTTED_PHRASES: 'SPOTTED_PHRASES',
    TOTAL_PHRASES: 'TOTAL_PHRASES'
};
