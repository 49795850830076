import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../utils';

const AddTime = styled.div`
    color: ${colors.white.basic};

    font-size: 14px;
    font-weight: 500;

    margin: 0 2px;

    cursor: pointer;

    padding: 6px 16px;

    user-select: none;

    pointer-events: ${props => (props.$isEnabled ? 'visible' : 'none')};

    &:hover {
        background-color: ${colors.blue.biscay};

        border-radius: 2px;
    }
`;

AddTime.propTypes = {
    $isEnabled: PropTypes.bool.isRequired
};

export default AddTime;
