import styled from 'styled-components';

import PropTypes from 'prop-types';
import { colors } from '../../../../../utils';

const Button = styled.div`
    border-radius: 2px;
    background-color: ${colors.gray.athens};
    border: solid 1px ${colors.gray.rock_blue};
    text-align: center;
    vertical-align: center;
    padding: 3px 11px;

    font-size: 12px;
    font-weight: 600;
    color: ${colors.blue.dark_blue};

    cursor: ${props => (props.$enabled ? 'pointer' : 'default')};
`;

Button.propTypes = {
    $enabled: PropTypes.bool.isRequired
};

export default Button;
