import styled from 'styled-components';

import { colors } from '../../../../../utils';

const List = styled.div`
    overflow: hidden;
    overflow-y: auto;

    display: flex;

    flex-wrap: wrap;

    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;

    max-width: 262px;

    margin: 0 8px;
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.white.lyly};
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
    }

    hr {
        width: 100%;
        border-top: none;
        border-bottom: 1px solid ${colors.white.lilac};
        margin: 2px 0 6px 0;
    }
`;

export default List;
