import styled from 'styled-components';
import PropTypes from 'prop-types';
import { startsWith, endsWith } from 'lodash';

import { colors } from '../../../../utils/index';
import { detectionTypes } from '../../../../utils/transcript';

const detectionTypesColors = {
    [detectionTypes.whitelistHead]: colors.green.aquamarine,
    [detectionTypes.whitelistComplement]: colors.yellow.neon_carrot,
    [detectionTypes.blacklist]: colors.red.light_carmine_pink,
    [detectionTypes.normal]: 'transparent'
};

const Detection = styled.span`
    position: relative;
    display: inline-block;
    z-index: 0;
    margin-left: ${props =>
        startsWith(props.$text, ' ') && !startsWith(props.$text, ' ') ? '4px' : 'auto'};
    margin-right: ${props => (endsWith(props.$text, ' ') ? '4px' : 'auto')};

    &::before {
        content: '';

        position: absolute;
        bottom: 0;
        left: ${props => (props.$text === ' ' ? '-2px' : '0')};

        z-index: -1;

        height: 3px;
        width: ${props => (props.$text === ' ' ? '200%' : '100%')};

        border-radius: ${props => (props.$text === ' ' ? '0px' : '10px')};

        background-color: ${props => detectionTypesColors[props.$type]};
    }
`;

Detection.propTypes = {
    $type: PropTypes.oneOf(Object.keys(detectionTypesColors)).isRequired,
    $text: PropTypes.string.isRequired
};

export default Detection;
