import styled from 'styled-components';
import { colors } from '../../../utils';

const ResultInfoWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    height: 63px;

    padding: 0 10px;

    background-color: ${colors.white.basic};
    border-radius: 2px;
    box-shadow: -2px 0px 7px 0 #cdd3df;
`;

export default ResultInfoWrapper;
