import { createError } from '../utils';

const VibeRecognizeRequestError = createError({
    name: 'VibeRecognizeRequestError',
    message: p => `Error on creating RecognizeRequest: ${p.description}`,
    extra: p => ({ debugInfo: p.payload || {} }),
    fingerprint: p => [p.description]
});

const ListenerRequestError = createError({
    name: 'ListenerRequestError',
    message: p => `Error on creating Request: ${p.description}`,
    extra: p => ({ debugInfo: p.payload || {} }),
    fingerprint: p => [p.description]
});

export const Protobuf = { VibeRecognizeRequestError, ListenerRequestError };
