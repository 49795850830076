import React from 'react';

function EmojiDisaster7() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="42" height="46" viewBox="0 0 42 46">
        <g fill="none" fillRule="evenodd" transform="translate(1)">
            <circle cx="20" cy="20.5" r="20" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                d="M20 0C9 0 0 9 0 20s9 20 20 20 20-9 20-20S31 0 20 0zm0 37C9.9 37 1.8 28.8 1.8 18.8 1.8 8.7 10 .6 20 .6s18.2 8.2 18.2 18.2S30.1 37 20 37z"
            />
            <circle
                cx="20"
                cy="20.5"
                r="20"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                d="M15 30.5c1.4-1 3.1-1.5 5-1.5 1.8 0 3.6.6 5 1.5"
            />
            <ellipse
                cx="20"
                cy="44.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="16"
                ry="1.5"
            />
            <circle
                cx="10"
                cy="20.5"
                r="1"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="30"
                cy="20.5"
                r="1"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="32"
                cy="25.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <ellipse
                cx="8"
                cy="25.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
        </g>
    </svg>
}

export default EmojiDisaster7;
