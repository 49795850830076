import {objectToSnakeCase} from "../../utils";
import {sendRequest} from "./common";
import {REQUESTS} from "../../constants";

export const types = {
    CALL_START_RECEIVED: '@bg/CALL_START_RECEIVED',
    CALL_STARTED: '@bg/CALL_STARTED',
    CALL_ENDED: '@bg/CALL_ENDED',
    CALL_HOLDED: '@bg/CALL_HOLDED',
    CALL_UNHOLDED: '@bg/CALL_UNHOLDED',
    CALL_TIMER_UPDATED: '@bg/CALL_TIMER_UPDATED',
    CALL_DETECTION_STARTED: '@bg/CALL_DETECTION_STARTED',
    CALL_END_RECEIVED: '@bg/CALL_END_RECEIVED',
    CALL_EVENT_RECEIVED: '@bg/CALL_EVENT_RECEIVED',
    CALL_NOTIFICATION: '@bg/CALL_NOTIFICATION',

    CALL_FEEDING_STARTED: '@bg/CALL_FEEDING_STARTED',

    CALL_DETECTION_STOPPED: '@bg/CALL_DETECTION_STOPPED',
    START_CALL_DETECTION: '@bg/START_CALL_DETECTION',
    STOP_CALL_DETECTION: '@bg/STOP_CALL_DETECTION',

    RESET_SESSION_DURATION: '@bg/RESET_SESSION_DURATION',

    SEND_MANUAL_CALL_EVENT: '@bg/SEND_MANUAL_CALL_EVENT',
    OPT_IN: '@bg/OPT_IN',
    OPT_OUT: '@bg/OPT_OUT',
    OPT_STOP: '@bg/OPT_STOP',
    RESET_CALL_MODE: '@bg/RESET_CALL_MODE',

    CALL_START: '@bg/CALL_START',
    CALL_RESTART: '@bg/CALL_RESTART',
    CALL_END: '@bg/CALL_END',
    CALL_HOLD: '@bg/CALL_HOLD',
    CALL_UNHOLD: '@bg/CALL_UNHOLD',
    UTC_PING: '@bg/UTC_PING',
    UTC_PING_TIMEOUT: '@bg/UTC_PING_TIMEOUT',

    CALL_SET_MODE: '@bg/CALL_SET_MODE',

    ASR_PING: '@bg/ASR_PING',
    SAVE_CONVERSION: '@bg/SAVE_CONVERSION',

    GET_NEW_SUGGESTIONS: '@bg/GET_NEW_SUGGESTIONS',
    GET_NEW_SUGGESTIONS_SUCCESS: '@bg/GET_NEW_SUGGESTIONS_SUCCESS'
};

export const resetCallMode = () => ({
    type: types.RESET_CALL_MODE,
})

export const callDetectionStarted = sessionId => ({
    type: types.CALL_DETECTION_STARTED,
    payload: {
        sessionId
    }
});

export const callDetectionStopped = code => ({
    type: types.CALL_DETECTION_STOPPED,
    payload: {
        code
    }
});

export const callNotification = eventName => ({
    type: types.CALL_NOTIFICATION,
    payload: {
        eventName
    }
});

export const callStartReceived = ({ sessionOffsetMsec, id, metaCallID }) => dispatch => {
    dispatch({
        type: types.CALL_START_RECEIVED,
        payload: { sessionStartOffsetMsec: sessionOffsetMsec, id, metaCallID }
    });
};

export const callStarted = payload => ({
    type: types.CALL_STARTED,
    payload
});

export const callHolded = () => ({
    type: types.CALL_HOLDED,
    payload: {}
});

export const callUnholded = () => ({
    type: types.CALL_UNHOLDED,
    payload: {}
});

export const callEnded = ({ sessionOffsetMsec, reason }) => ({
    type: types.CALL_ENDED,
    payload: { sessionEndOffsetMsec: sessionOffsetMsec, reason }
});

export const updateCallTimer = () => ({
    type: types.CALL_TIMER_UPDATED
});

export const changeCallMode = (mode, forgetAudioAndTranscript = null, onEvent = null) => ({
    type: types.CALL_SET_MODE,
    payload: {mode, forgetAudioAndTranscript, onEvent}
});

export const callFeedingStarted = () => ({
    type: types.CALL_FEEDING_STARTED,
});

export const startCallDetection = () => ({ type: types.START_CALL_DETECTION });

export const stopCallDetection = reason => ({
    type: types.STOP_CALL_DETECTION,
    payload: { reason }
});

export const callEndReceived = callEndEvent => ({
    type: types.CALL_END_RECEIVED,
    payload: { callEndEvent }
});

export const resetSessionDuration = () => ({
    type: types.RESET_SESSION_DURATION,
})

export const saveConversion = (
    body
) => dispatch => {
    const payload = {
        body: objectToSnakeCase(body)
    };

    dispatch(
        sendRequest(REQUESTS.SAVE_CONVERSION(payload.body.call_id), {
            payload
        })
    );
};

export const getNewSuggestions = () => dispatch => {
    const onSuccess = payload => {
        dispatch({
            type: types.GET_NEW_SUGGESTIONS_SUCCESS,
            payload: {
                ...payload,
            }
        })
    };
    dispatch(
        sendRequest(REQUESTS.GET_NEW_SUGGESTIONS, {
            onSuccess
        })
    );
};

export const utcPingTimeout = () => ({
    type: types.UTC_PING_TIMEOUT
})
