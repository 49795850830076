const loginInfoKey = '_login_info'

export const saveLoginInfo = (locationName, username, password) => localStorage.setItem(loginInfoKey, JSON.stringify({
    locationName, username, password
}))

export const loadLoginInfo = () => JSON.parse(localStorage.getItem(loginInfoKey) || '{}')
export const clearLoginInfo = () => {
    const { locationName } = loadLoginInfo()
    saveLoginInfo(locationName, '', '')
}
