import styled from 'styled-components';
import { colors } from '../../../../utils';

const Title = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: ${colors.gray.dark};
    text-transform: capitalize;
`;

export default Title;
