import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

const CopyText = styled.div`
    position: absolute;

    right: 5px;
    bottom: 5px;

    padding: 4px 6px;

    border: 1px solid
        ${props => (props.$isEnabled ? colors.blue.palatinate_blue : colors.gray.iron)};
    border-radius: 2px;

    cursor: pointer;

    pointer-events: ${props => (props.$isEnabled ? 'visible' : 'none')};

    font-size: 10px;

    color: ${props => (props.$isEnabled ? colors.gray.silver_sand : colors.gray.light)};
`;

CopyText.propTypes = {
    $isEnabled: PropTypes.bool.isRequired
};

export default CopyText;
