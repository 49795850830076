import {all, call, put, race, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {
    callEnded,
    callStarted,
    changeCallMode,
    startCallDetection,
    stopCallDetection,
    types as callTypes,
    updateCallTimer
} from '../actions/call';
import {types as commonTypes} from '../actions/common';
import {forwardEvent, types as mainTypes} from '../actions/main';
import {CallEvents, CallMode, EXPLICIT_INTEGRATION_SOURCE, MAX_CALL_DETECTION_SESSION_DURATION} from '../../constants';
import {proto} from '../../services/AsrProto';
import {
    getCallId,
    getExplicitIntegrationSource,
    getMetaCallId,
    getSessionDurationMsec,
    isCallStarted
} from '../selectors';
import {IS_LEGACY_LISTENER_MODE} from "../../../config/electron";
import {getMetaInfo} from "../../utils/general";
import {captureInfoMessage, captureWarnMessage} from "../../../tracker/raven";
import {forwardingLogger} from "../../../utils/logger";
import {types as asrTypes} from "../actions/asr";
import {types as deviceTypes} from "../actions/device";

export function* callTimer() {
    while (true) {
        const { ended } = yield race({
            ended: take(callTypes.CALL_ENDED),
            tick: call(delay, 1000)
        });

        if (ended) {
            break;
        }
        yield put(updateCallTimer());
    }
}

export function* onCallStartReceived(action) {
    const isStarted = yield select(isCallStarted);
    if (!isStarted) {
        yield put(callStarted(action.payload));
    } else {
        console.warn(
            `Call started received but call has already been started
             (multiple sequential call start events), payload ${action.payload}`
        );
    }
}

export function* callStartReceived() {
    yield all([
        take(callTypes.CALL_DETECTION_STARTED)
    ]);
    yield takeLatest(callTypes.CALL_START_RECEIVED, onCallStartReceived);
}

export function* initCallTimer() {
    yield takeLatest(callTypes.CALL_STARTED, callTimer);
}

export function* initCallDetection() {
    const list = [
        take(commonTypes.ALL_SETTINGS_RECEIVED),
    ]
    if (IS_LEGACY_LISTENER_MODE) {
        list.push(take(mainTypes.DAEMON_INITIALIZED))
        list.push(take(deviceTypes.RECORDING_INFO_RECEIVED))
    }
    yield all(list);
    yield put(startCallDetection());
}

export function* callDetectionMaxDurationTimeout() {
    yield take(callTypes.CALL_DETECTION_STARTED);
    yield call(delay, MAX_CALL_DETECTION_SESSION_DURATION);
    yield put(stopCallDetection(proto.FinalizationReason.CALL_END_TIMEOUT));
}


export function* hold() {
    while (true) {
        yield put({ type: callTypes.ASR_PING });
        const { unhold } = yield race({
            unhold: take(callTypes.CALL_UNHOLDED),
            tick: call(delay, 5000)
        });
        if (unhold) {
            return;
        }
    }
}

export function* initOnHoldPing() {
    yield takeLatest(callTypes.CALL_HOLDED, hold);
}

export function* onCallEndReceived(action) {
    const { callEndEvent } = action.payload;
    yield put(callEnded(callEndEvent));
}

export function* callEndReceived() {
    yield takeLatest(callTypes.CALL_END_RECEIVED, onCallEndReceived);
}

export function* onCallEventReceived(action) {
    const { callEvent, eventBody, callMode, forgetAudioAndTranscript } = action.payload;

    const isOnCall = yield select(isCallStarted)
    const currentMetaCallID = yield select(getMetaCallId)
    const callMeta = getMetaInfo(eventBody)
    const metaCallID = callMeta.call_id || null;
    const explicitIntegrationSource = yield select(getExplicitIntegrationSource);
    const isEventFromNewCall = isOnCall && (!currentMetaCallID || currentMetaCallID !== metaCallID)

    if (isEventFromNewCall && callEvent === CallEvents.START) {
        // we need to finish current call first
        captureWarnMessage('previous call is not ended')

        yield put(callEnded({
            reason: proto.FinalizationReason.CALL_END_NORMAL,
            sessionOffsetMsec: yield select(getSessionDurationMsec),
        }));

        // wait for new asr session
        yield all([
            take(callTypes.CALL_DETECTION_STARTED)
        ]);
    }

    forwardingLogger.log('callEvent:', callEvent);

    // And forward event to Vibe
    yield put(forwardEvent({
        jsonRequest: eventBody,
    }));

    captureInfoMessage('call state event received', {
        type: 'callEvent',
        payload: eventBody,
        callID: yield select(getCallId),
    })

    switch (callEvent) {
        case CallEvents.START:
            if(!currentMetaCallID || currentMetaCallID !== metaCallID){
                yield put({
                    type: callTypes.CALL_START,
                    payload: {
                        meta: callMeta,
                    }
                });
                yield take(asrTypes.RECOGNITION_READY)
                yield put(changeCallMode(callMode || CallMode.DEFAULT, forgetAudioAndTranscript, callTypes.CALL_START));
            }
            break;
        case CallEvents.END:
            if (callMode) {
                yield put(changeCallMode(callMode, forgetAudioAndTranscript, CallEvents.END));
            }
            yield put({type: callTypes.CALL_END});
            break;
        case CallEvents.CHANGE:
            if (callMode) {
                yield put(changeCallMode(callMode, forgetAudioAndTranscript, CallEvents.CHANGE));
            }
            break;
        case CallEvents.HOLD:
            yield put({type: callTypes.CALL_HOLD})
            break;
        case CallEvents.UNHOLD:
            yield put({type: callTypes.CALL_UNHOLD})
            break;
        case CallEvents.PING:
            yield put({type: callTypes.UTC_PING})

            if (callMode && explicitIntegrationSource === EXPLICIT_INTEGRATION_SOURCE.UCT_CALL_EVENT) {
                yield put(changeCallMode(callMode, null, CallEvents.PING));
            }
            break;
        default:
    }


}

export function* callEventReceived() {
    yield takeEvery(callTypes.CALL_EVENT_RECEIVED, onCallEventReceived);
}


export function* onCallRestartReceived(action) {
    const isStarted = yield select(isCallStarted);
    if(isStarted) {
        // we need to finish current call first
        captureWarnMessage('previous call is not ended')

        yield put(callEnded({
            reason: proto.FinalizationReason.CALL_END_NORMAL,
            sessionOffsetMsec: yield select(getSessionDurationMsec),
        }));

        // wait for new asr session
        yield all([
            take(callTypes.CALL_DETECTION_STARTED)
        ]);
    }

    yield put({
        type: callTypes.CALL_START,
        payload: {
            meta: action?.payload?.meta || {}
        }
    });

    yield take(asrTypes.RECOGNITION_READY);

    yield put(changeCallMode(CallMode.DEFAULT));
}

export function * callRestartReceived(){
    yield takeLatest(callTypes.CALL_RESTART, onCallRestartReceived);
}

export default [
    callStartReceived(),
    initCallTimer(),
    initCallDetection(),
    callDetectionMaxDurationTimeout(),
    initOnHoldPing(),
    callEndReceived(),
    callEventReceived(),
    callRestartReceived()
];
