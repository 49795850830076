/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

class AutoScroll extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        startAtBottom: PropTypes.bool
    };

    static defaultProps = {
        startAtBottom: false
    };

    componentDidMount() {
        this.node = findDOMNode(this);
        if (this.props.startAtBottom) {
            this.node.scrollTop = this.node.scrollHeight;
        }
    }

    getSnapshotBeforeUpdate() {
        const { scrollHeight, clientHeight, scrollTop } = this.node;

        return clientHeight + scrollTop >= 0.9 * scrollHeight;
    }

    componentDidUpdate(props, state, shouldScroll) {
        if (shouldScroll) {
            this.node.scrollTop = this.node.scrollHeight;
        }
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

export default AutoScroll;
