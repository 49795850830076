export const compareVersions = (installed, required) => {
    const installedSplit = installed.split('.');
    const requiredSplit = required.split('.');

    for (let i = 0; i <= 2; i++) {
        if (installedSplit[i] > requiredSplit[i]) return true;
        if (installedSplit[i] < requiredSplit[i]) return false;
    }
    return true;
};
