import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;

    justify-content: space-between;
    align-content: center;
    align-items: center;

    flex-wrap: nowrap;

    margin-right: 12px;
`;

export default Wrapper;
