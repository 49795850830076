import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Button } from './styled';

function CopyButton({ localization, onCopy, enabled }) {
    return (
        <Wrapper>
            <Button $enabled={enabled} onClick={onCopy}>
                {enabled
                    ? localization.getText('trainer.transcript.copy_button.to_copy')
                    : localization.getText('trainer.transcript.copy_button.copied')}
            </Button>
        </Wrapper>
    );
}

CopyButton.propTypes = {
    localization: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired
};

export default CopyButton;
