import Logger from './logger';

// Default style definitions
const defaultStyles = {
    message: `
        background-color: transparent;
    `,
    level: {
        [Logger.levels.DEBUG]: `
            color: darkgray;
        `,
        [Logger.levels.WARN]: `
            padding: 2px 4px;
            border-radius: 3px;
            background-color: orange;
            color: white;
        `,
        [Logger.levels.ERROR]: `
            padding: 2px 4px;
            border-radius: 3px;
            background-color: red;
            color: white;
        `
    },
    title: `
        padding: 2px 5px;
        border-radius: 3px;
        margin-left: 5px;
        margin-right: 5px;
    `
};

// Root logger
export const rootLogger = new Logger({
    title: 'Root',
    styles: {
        ...defaultStyles,
        title: `
            ${defaultStyles.title}
            background-color: DodgerBlue;
            color: white;
        `
    }
});

export const mainConnLogger = rootLogger.createLogger({
    title: 'MainConnection'
});

export const actionLogger = rootLogger.createLogger({
    title: 'Action'
});

export const cdLogger = rootLogger.createLogger({
    title: 'CallDetection',
    styles: {
        title: `
            ${defaultStyles.title}
            background-color: MediumSeaGreen;
            color: white;
        `
    }
});

export const recLogger = rootLogger.createLogger({
    title: 'Recognition'
});

export const deviceLogger = rootLogger.createLogger({
    title: 'MixedDevice'
});

export const audioSysLogger = rootLogger.createLogger({
    title: 'AudioSystemInfo'
});

export const forwardingLogger = rootLogger.createLogger({
    title: 'Forwarding'
});

export const reloaderLogger = rootLogger.createLogger({
    title: 'Reloader'
});

export const wsLogger = rootLogger.createLogger({
    title: 'WebSocket'
});

export const commonLogger = rootLogger.createLogger({
    title: 'Common'
});

export const recorderLogger = rootLogger.createLogger({
    title: 'Recorder'
});

export default Logger;
