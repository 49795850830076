import {Validator} from 'jsonschema';
import {EXPLICIT_INTEGRATION_SOURCE} from "../constants";
import { parse as parseCopsI2X, schema as copsI2xEvent } from "./integrations/copsI2xEvent"
import { parse as parseI2X, schema as i2xCallEvent } from "./integrations/i2xCallEvent"
import { parse as parseI2XManual, schema as i2xManualCallEvent } from "./integrations/i2xManualCallEvent"
import { parse as parseUCT, schema as uctCallEvent } from "./integrations/uctCallEvent"
import { parse as parseWinAudio, schema as winAudioSessionEvent } from "./integrations/winAudioSessionEvent"
import {commonLogger} from "../../utils/logger";
import {captureException} from "../../tracker/raven";

// @docs
// https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/830865450/Integration+Options+in+the+Management+Console

const SCHEMAS = {
    [EXPLICIT_INTEGRATION_SOURCE.I2X_CALL_EVENT]: {parser: parseI2X, schema: i2xCallEvent},
    [EXPLICIT_INTEGRATION_SOURCE.RECORDER_EVENT]: {parser: parseI2X, schema: i2xCallEvent},
    [EXPLICIT_INTEGRATION_SOURCE.UCT_CALL_EVENT]: {parser: parseUCT, schema: uctCallEvent},
    [EXPLICIT_INTEGRATION_SOURCE.I2X_MANUAL_CALL_EVENT]: {parser: parseI2XManual, schema: i2xManualCallEvent},
    [EXPLICIT_INTEGRATION_SOURCE.WIN_AUDIO_SESSION_EVENT]: {parser: parseWinAudio, schema: winAudioSessionEvent},
    [EXPLICIT_INTEGRATION_SOURCE.COPS_I2X_EVENT]: {parser: parseCopsI2X, schema: copsI2xEvent},
};

export function parse(explicitIntegrationSource, event, options) {
    if(!SCHEMAS[explicitIntegrationSource]){
        const err = `Unknown explicit integration source: ${explicitIntegrationSource}`;

        captureException(new Error(err));
        commonLogger.log(err);

        return [null, null, null];
    }

    const validator = new Validator();
    const validationResults = validator.validate(event, SCHEMAS[explicitIntegrationSource].schema);
    if(!validationResults.valid){
        commonLogger.warn(`Integration event invalid: ${validationResults.toString()}`)
        commonLogger.warn(JSON.stringify(event))

        return [null, null, null];
    }

    return SCHEMAS[explicitIntegrationSource].parser(event, options)
}
