import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../../utils/colors';

const Container = styled.div`
    margin-top: 15px;
    display: flex;
`;

const Word = styled.div`
    font-size: 16px;
    background-color: ${colors.blue.palatinate_blue};
    color: ${colors.white.basic};
    padding: 5px 10px;
    border-radius: 5px;
`;

function Keyword(props) {
  return <Container>
        <Word>{props.children}</Word>
    </Container>
}

Keyword.propTypes = {
    children: PropTypes.any.isRequired
};

export default Keyword;
