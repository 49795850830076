import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../..';
import { Wrapper } from './styled';

class WordLabelDelete extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired
    };

    onClick = () => {
        this.props.onClick(this.props.name);
    };

    render() {
        return (
            <Wrapper onClick={this.onClick}>
                <Icon name="Cross" />
            </Wrapper>
        );
    }
}

export default WordLabelDelete;
