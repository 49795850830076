import format from 'date-fns/format';
import { isEmpty, includes, get, identity } from 'lodash';
// import { addBreadcrumb } from '../tracker/raven';

export default class Logger {
    constructor({
        title,
        atLevel = Logger.levels.LOG,
        parent = null,
        styles,
        blackList = [],
        context
    }) {
        this._title = title;
        this._level = atLevel;
        this._parent = parent;
        const parentStyles = parent ? parent._styles : {};
        this._styles = { ...parentStyles, ...styles };
        this._children = new Map();
        this._archive = [];

        const parentContext = parent ? parent._context : {};
        this._context = { ...parentContext, ...context };
        this._blackList = ['context'].concat(blackList);
        this._assignMethods();
        this._plugins = [];
    }

    static levelNames = {
        4: 'DEBUG',
        3: 'LOG',
        2: 'WARN',
        1: 'ERROR',
        0: 'SILENT'
    };

    static levels = {
        DEBUG: 4,
        LOG: 3,
        WARN: 2,
        ERROR: 1,
        SILENT: 0
    };

    methodsMap = {
        debug: Logger.levels.DEBUG,
        log: Logger.levels.LOG,
        warn: Logger.levels.WARN,
        error: Logger.levels.ERROR
    };

    /**
     * Assigns logger methods to class instance
     */
    _assignMethods() {
        Object.keys(this.methodsMap).forEach(methodName => {
            Object.assign(this, {
                [methodName]: (...args) => {
                    const level = this.methodsMap[methodName];
                    this._print(methodName, level, ...args);
                }
            });
        });
    }

    /**
     * @param {Number} level
     * @param  {...any} message - Extra arguments
     */
    _factory(level, ...message) {
        let extra;

        if (message.length > 1) {
            const last = message[message.length - 1];

            if (typeof last === 'object' && last !== null) {
                extra = message.pop();
            }
        }

        return {
            timestamp: new Date(),
            level,
            title: this._title,
            context: this._context,
            message: message.join(' '),
            extra
        };
    }

    _formatters = {
        timestamp: value => `[${format(value, 'HH:mm:ss')}]`,
        level: value => Logger.levelNames[value]
    };

    /**
     * @param {Object} params
     * @returns {Array} - Compiled log message
     */
    _formatMessage(params) {
        const result = [];
        const styles = [];
        Object.keys(params).forEach(paramName => {
            if (includes(this._blackList, paramName)) {
                return;
            }
            const val = get(this._formatters, paramName, identity)(params[paramName]);

            if (this._styles[paramName]) {
                result.push(`%c${val}`);
                paramName === 'level'
                    ? styles.push(this._styles.level[params.level])
                    : styles.push(this._styles[paramName]);
            } else {
                result.push(`${val} `);
            }
        });

        return [result.join('').trim(), ...styles];
    }

    /**
     * @param {String} methodName - Log method
     * @param {Number} methodLevel - Level
     * @param  {*} args - Extra arguments
     */
    _print(methodName, methodLevel, ...args) {
        if (methodLevel > this.level) {
            return;
        }

        const data = this._factory(methodLevel, ...args);
        const { extra, ...messageFields } = data;
        const formattedMessage = this._formatMessage(messageFields);

        console[methodName].call(console, ...formattedMessage);
        if (extra) {
            console[methodName].call(console, extra);
        }
    }

    use(plugin) {
        this._plugins.push(plugin);
        if (plugin.factory) {
            plugin.factory(this);
        }
    }

    /**
     * Creates a new children logger
     * @param {*} params - Logger initial parameters
     * @returns {Class} - Logger
     */
    createLogger(params) {
        const logger = new Logger({ ...params, parent: this });
        this._plugins.forEach(plugin => logger.use(plugin));
        this._children.set(logger.title, logger);

        return logger;
    }

    /**
     * @returns {String} - Logger title
     */
    getTitle() {
        return this._title;
    }

    /**
     * @returns {Number} - Level
     */
    getLevel() {
        return this._level;
    }

    /**
     * Set logger's level
     * @param {Number} newLevel - Log level
     */
    setLevel(newLevel) {
        this._level = newLevel;
    }

    /**
     * Set logger's level including children loggers
     * @param {Number} newLevel - Log level
     */
    setLevelAll(newLevel) {
        this.setLevel(newLevel);

        if (!this._children.size) return;

        this._children.forEach(logger => logger.setLevelAll(newLevel));
    }

    /**
     * Put logger to silent
     */
    off() {
        this._level = Logger.levels.SILENT;
    }

    /**
     * Put logger to silent including children loggers
     */
    offAll() {
        this._level = Logger.levels.SILENT;

        if (!this._children.size) return;

        this._children.forEach(logger => logger.offAll());
    }

    /**
     * Enables highest level of logging
     */
    on() {
        this._level = Logger.levels.DEBUG;
    }

    /**
     * Enables highest level of logging including children loggers
     */
    onAll() {
        this._level = Logger.levels.LOG;

        if (!this._children.size) return;

        this._children.forEach(logger => logger.onAll());
    }

    setContext(context) {
        if (isEmpty(context)) {
            this._context = {};
        } else {
            Object.assign(this._context, context);
        }
    }

    getContext() {
        return this._context;
    }
}
