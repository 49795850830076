import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0px);
    }
    
    to {
        opacity: 0;
        transform: translateY(93px);
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(93px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`;

const animationRuleFadeIn = css`
    ${fadeIn} 300ms ease forwards
`;

const animationRuleFadeOut = css`
    ${fadeOut} 300ms ease backwards
`;

const Wrapper = styled.div`
    width: 100%;
    height: 93px;

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: ${props => (props.$visible ? 10 : -10)};

    flex-direction: column;

    display: flex;

    opacity: ${props => (props.$visible ? '1' : '0')};
    animation: ${props => (props.$visible ? animationRuleFadeIn : animationRuleFadeOut)};
`;

Wrapper.propTypes = {
    $visible: PropTypes.bool
};

Wrapper.defaultProps = {
    $visible: false
};

export default Wrapper;
