import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const COUNT_PILL_TYPE = {
    whitelist: {
        'background-color': colors.green.aquamarine
    },
    blacklist: {
        'background-color': colors.red.light_carmine_pink // background: `1px solid rgba(255, 80, 110, .5)`
    }
};

const Wrapper = styled.div`
    ${props => COUNT_PILL_TYPE[props.$type]};
    border-radius: 100px;
    padding: 3px 9px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: ${colors.white.basic};
`;

Wrapper.propTypes = {
    $type: PropTypes.string.isRequired
};

export default Wrapper;
