import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container, Text } from './styled';

class ErrorMessage extends Component {
    static propTypes = {
        errorCode: PropTypes.number,
        countdown: PropTypes.number.isRequired,
        localization: PropTypes.object.isRequired
    };

    static defaultProps = {
        errorCode: null
    };

    _getContent = () => {
        const { errorCode, localization, countdown } = this.props;
        return (
            <Fragment>
                <div>{localization.getText(`errors.${errorCode}`)}</div>
                {countdown > 0 && (
                    <div>{localization.getText('reconnect.retry', { countdown })}</div>
                )}
            </Fragment>
        );
    };

    render() {
        return (
            <Wrapper>
                <Container>
                    <Text>{this._getContent()}</Text>
                </Container>
            </Wrapper>
        );
    }
}

export default ErrorMessage;
