import styled from 'styled-components';

export const ReloadButton = styled.button`
    font-size: 14px;
    color: #4550e6;
    font-weight: 500;
    appearance: none;
    border: none;
    background: none;
    padding: 5px;
    text-transform: uppercase;
`;
