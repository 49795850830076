import React from 'react';

function Level3v2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22">
        <g fill="none" fillRule="evenodd" transform="translate(0 1)">
            <ellipse
                cx="7"
                cy="20.284"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="5.367"
                ry="1"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M11.107 18.71l-.98-.24-.514-2.672-1.353.286.513 2.673-.793.573a.546.546 0 0 0-.093.763c.186.239.466.287.7.096l.886-.62c.094-.096.234-.096.374-.049l1.073.24c.28.047.56-.096.607-.383.046-.334-.14-.62-.42-.668zM2.893 18.71l.98-.24.514-2.672 1.353.286-.513 2.673.793.573c.233.19.28.525.093.763-.186.239-.466.287-.7.096l-.886-.62c-.094-.096-.234-.096-.374-.049l-1.073.24c-.28.047-.56-.096-.607-.383-.046-.334.14-.62.42-.668z"
            />
            <path
                fill="#8D94F2"
                fillRule="nonzero"
                d="M9.38 9.498s3.313-2.005 3.967-1.67c.653.381-.28 3.913-2.334 4.772-2.053.907-1.633-3.102-1.633-3.102zM4.62 9.498S1.307 7.493.653 7.828C0 8.208.933 11.74 2.987 12.6c2.053.907 1.633-3.102 1.633-3.102z"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M9.38 10.69s3.313-2.004 3.967-1.67l.14.144c.14-.669.14-1.194-.14-1.337-.654-.382-3.967 1.67-3.967 1.67s-.093.669 0 1.385v-.191zM.653 9.02c.654-.381 3.967 1.67 3.967 1.67v.192c.047-.716 0-1.384 0-1.384S1.307 7.493.653 7.828c-.233.142-.28.667-.14 1.336l.14-.144z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M9.38 9.498s3.313-2.005 3.967-1.67c.653.381-.28 3.913-2.334 4.772-2.053.907-1.633-3.102-1.633-3.102zM4.62 9.498S1.307 7.493.653 7.828C0 8.208.933 11.74 2.987 12.6c2.053.907 1.633-3.102 1.633-3.102z"
            />
            <ellipse
                cx="7"
                cy="13.077"
                fill="#ECEDFC"
                fillRule="nonzero"
                rx="5.367"
                ry="5.25"
            />
            <path
                fill="#8D94F2"
                fillRule="nonzero"
                d="M7 11.693c1.773 0 3.313-.716 4.293-1.766-.98-1.288-2.52-2.1-4.293-2.1-1.773 0-3.313.86-4.293 2.1.98 1.05 2.52 1.766 4.293 1.766z"
            />
            <ellipse
                cx="7"
                cy="13.077"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                rx="5.367"
                ry="5.25"
            />
            <ellipse
                cx="7"
                cy="5.155"
                fill="#ECEDFC"
                fillRule="nonzero"
                rx="4.9"
                ry="5.011"
            />
            <ellipse
                cx="7"
                cy="5.155"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                rx="4.9"
                ry="5.011"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M6.44 6.205l-.933.715c-.187.144-.234.43-.094.669l.654.906a1.12 1.12 0 0 0 1.866 0l.654-.906c.14-.191.093-.525-.094-.669l-.933-.715a.837.837 0 0 0-1.12 0z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M6.44 6.205l-.933.715c-.187.144-.234.43-.094.669l.654.906a1.12 1.12 0 0 0 1.866 0l.654-.906c.14-.191.093-.525-.094-.669l-.933-.715a.837.837 0 0 0-1.12 0z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M5.367 7.302S6.58 7.78 7 7.78c.42 0 1.633-.478 1.633-.478"
            />
            <ellipse
                cx="9.52"
                cy="4.534"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                rx="1"
                ry="1"
            />
            <ellipse
                cx="4.48"
                cy="4.534"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                rx="1"
                ry="1"
            />
            <ellipse
                cx="10.313"
                cy="6.443"
                fill="#8D94F2"
                fillRule="nonzero"
                rx="1"
                ry="1"
            />
            <ellipse
                cx="3.687"
                cy="6.443"
                fill="#8D94F2"
                fillRule="nonzero"
                rx="1"
                ry="1"
            />
        </g>
    </svg>
}

export default Level3v2;
