import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import withLocalization from '../../localization/withLocalization';
import { CALL_STATE_TRANSITION_DURATION } from '../../background/constants';
import CardWrapper from '../../components/Trainer/styled/CardWrapper';
import CallRatios from '../../components/CallRatios';
import NoCallMessage from '../../components/common/NoCallMessage';
import NewCallMessage from '../../components/common/NewCallMessage';
import CallEndMessage from '../../components/common/CallEndMessage';
import CallContinuedMessage from '../../components/common/CallContinuedMessage';
import { getUserInterfaceConfig, isExplicitMode } from '../../store/selectors/trainer';
import { DISPLAY_NEW_SPEECH_RATE, isFeatureEnabled } from '../../config/features';

class CallInformation extends PureComponent {
    static propTypes = {
        speechRate: PropTypes.number,
        newSpeechRate: PropTypes.number,
        speechRatio: PropTypes.number,
        speechPace: PropTypes.number,
        loudness: PropTypes.number,
        showNewCallMessage: PropTypes.bool.isRequired,
        showCallEndMessage: PropTypes.bool.isRequired,
        localization: PropTypes.object.isRequired,
        isCallStarted: PropTypes.bool.isRequired,
        isSoundContinued: PropTypes.bool.isRequired,
        isExplicitModeOn: PropTypes.bool.isRequired,
        displayCallOptOutControls: PropTypes.bool.isRequired,
        displayNewSpeechRate: PropTypes.bool.isRequired
    };

    static defaultProps = {
        speechRate: null,
        newSpeechRate: null,
        speechRatio: null,
        speechPace: null,
        loudness: null
    };

    _renderNewCallMessage = () => {
        const {
            localization,
            showNewCallMessage,
            displayCallOptOutControls
        } = this.props;
        if (displayCallOptOutControls) return;

        return (
            <CSSTransition
                in={showNewCallMessage}
                classNames="message"
                timeout={CALL_STATE_TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit>
                {() =>
                    showNewCallMessage ? (
                        <NewCallMessage
                            displayCallOptOutControls={displayCallOptOutControls}
                            localization={localization}
                        />
                    ) : null
                }
            </CSSTransition>
        );
    };

    _renderCallEndMessage = () => {
        const { localization, showCallEndMessage, isExplicitModeOn } = this.props;
        if (!isExplicitModeOn) {
            return null;
        }

        return (
            <CSSTransition
                in={showCallEndMessage}
                classNames="message"
                timeout={CALL_STATE_TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit>
                {() =>
                    showCallEndMessage ? (
                        <CallEndMessage localization={localization} />
                    ) : null
                }
            </CSSTransition>
        );
    };

    _renderCallContinuedMessage = () => {
        const { localization, isSoundContinued } = this.props;
        return (
            <CSSTransition
                in={isSoundContinued}
                classNames="message"
                timeout={CALL_STATE_TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit>
                {() => <CallContinuedMessage localization={localization} />}
            </CSSTransition>
        );
    };

    _renderNoCallMessage = () => {
        const { isCallStarted, localization } = this.props;

        if (!isCallStarted) {
            return <NoCallMessage localization={localization} />;
        }
    };

    _renderCallRatios = () => {
        const {
            loudness,
            speechRate,
            newSpeechRate,
            speechRatio,
            localization,
            speechPace,
            showCallEndMessage,
            displayNewSpeechRate
        } = this.props;

        return (
            <CallRatios
                callEnded={showCallEndMessage}
                disabled={false}
                loudness={loudness}
                speechRate={speechRate}
                newSpeechRate={newSpeechRate}
                speechRatio={speechRatio}
                speechPace={speechPace}
                localization={localization}
                displayNewSpeechRate={displayNewSpeechRate}
            />
        );
    };

    render() {
        return (
            <CardWrapper style={{ position: 'relative', width: 300 }}>
                {this._renderNoCallMessage()}
                {this._renderNewCallMessage()}
                {this._renderCallEndMessage()}
                {this._renderCallContinuedMessage()}
                {this._renderCallRatios()}
            </CardWrapper>
        );
    }
}

const mapStateToProps = state => ({
    speechRate: state.trainer.speechRate,
    newSpeechRate: state.trainer.newSpeechRate,
    speechPace: state.trainer.speechPace,
    speechRatio: state.trainer.speechRatio,
    loudness: state.trainer.loudness,
    showNewCallMessage: state.trainer.showNewCallMessage,
    showCallEndMessage: state.trainer.showCallEndMessage,
    isSoundContinued: state.trainer.isSoundContinued,
    isCallStarted: state.trainer.isStarted,
    displayNewSpeechRate: isFeatureEnabled(
        getUserInterfaceConfig(state),
        DISPLAY_NEW_SPEECH_RATE
    ),
    displayCallOptOutControls:
        state.trainer.userInterfaceConfig.displayCallOptOutControls,
    isExplicitModeOn: isExplicitMode(state)
});

export default connect(mapStateToProps)(withLocalization(CallInformation));
