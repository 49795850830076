import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Check(props) {
  return <svg width={props.size} viewBox="0 0 17 13">
        <path
            fill={props.color}
            fillRule="evenodd"
            stroke={props.color}
            strokeWidth=".786"
            d="M6.091 12a.927.927 0 0 1-.662-.278L1.275 7.51a.962.962 0 0 1 0-1.346.928.928 0 0 1 1.325 0L6.09 9.702l8.31-8.424a.927.927 0 0 1 1.325 0 .962.962 0 0 1-.001 1.347l-8.971 9.096A.927.927 0 0 1 6.09 12"
        />
    </svg>
}

Check.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Check.defaultProps = {
    size: 17,
    color: colors.white.basic
};

export default Check;
