import React from 'react';

function Level7() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M4.596 7.442L.166 2.56c-.159-.237-.238-.473-.08-.788.158-.315.475-.472.87-.315l6.805 2.441-3.165 3.544z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M4.866 7.907L.176 2.649C.007 2.395-.077 2.14.09 1.801c.168-.34.503-.509.922-.34l7.204 2.63-3.35 3.816z"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M9.196 3.137l11.666 7.096A14.62 14.62 0 0 0 21 8.199c0-.379 0-.71-.046-1.088-.046-.757-.597-1.42-1.332-1.561-.092 0-.184-.048-.23-.048-.091-.189-.183-.378-.275-.52-.69-1.088-1.654-1.419-2.48-1.466a6.18 6.18 0 0 0-.46-.71c-.735-.898-1.607-1.135-2.434-1.135l-.138-.142C12.732.63 11.722.49 10.895.63 9.93-.032 8.507-.268 7.129.394c-2.664 1.23-1.792 5.156.413 4.967 1.24-.142 2.205-1.514 1.654-2.224z"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M10.935 1.965c.828-.14 1.839 0 2.712.882l.138.139c.781 0 1.7.232 2.436 1.114.23.232.367.51.46.696.872.047 1.791.371 2.48 1.439.139.186.23.371.276.51.552.093 1.103.28 1.563.697 0-.14 0-.279-.046-.418-.046-.835-.643-1.485-1.47-1.624h-.092a5.079 5.079 0 0 0-.276-.511c-.69-1.068-1.655-1.393-2.482-1.439-.138-.232-.276-.464-.46-.696-.735-.882-1.608-1.114-2.435-1.114L13.6 1.5C12.728.62 11.717.48 10.89.62 9.924-.031 8.5-.263 7.12.387c-1.425.65-1.838 2.088-1.562 3.202.183-.742.689-1.439 1.562-1.856 1.47-.65 2.895-.372 3.814.232z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M9.196 3.137l11.666 7.096A14.62 14.62 0 0 0 21 8.199c0-.379 0-.71-.046-1.088-.046-.757-.597-1.42-1.332-1.561-.092 0-.184-.048-.23-.048-.091-.189-.183-.378-.275-.52-.69-1.088-1.654-1.419-2.48-1.466a6.18 6.18 0 0 0-.46-.71c-.735-.898-1.607-1.135-2.434-1.135l-.138-.142C12.732.63 11.722.49 10.895.63 9.93-.032 8.507-.268 7.129.394c-2.664 1.23-1.792 5.156.413 4.967 1.24-.142 2.205-1.514 1.654-2.224zM15.065 4.186c-.383-1.034-.876-1.809-1.37-2.326"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M8.769 13.424l1.241-1.384 1.104-.139a15.473 15.473 0 0 0 2.299 5.86c.598.923 1.885 1.107 2.759.461C18.7 16.331 20.54 13.47 21 10.148l-6.208-4.845a12.211 12.211 0 0 0-2.069-1.338c-.965-.507-2.023-.784-3.08-.692-1.978.185-3.587 1.477-4.323 3.184L3.39 11.209c-.69 1.661.552 3.599 2.39 3.553 1.288-.092 2.3-.554 2.99-1.338z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M5.313 8.712A5.136 5.136 0 0 1 9.637 5.45c1.058-.094 2.117.19 3.083.71.736.378 1.426.85 2.07 1.37l5.796 4.635c.184-.614.322-1.23.414-1.844l-6.21-4.965a12.188 12.188 0 0 0-2.07-1.372c-.966-.52-2.025-.804-3.083-.71-1.978.19-3.588 1.514-4.324 3.264l-1.932 4.87c-.276.71-.23 1.42.046 2.081l1.886-4.776z"
            />
            <ellipse
                cx="12.554"
                cy="19.302"
                fill="#ECEDFC"
                fillRule="nonzero"
                rx="5.25"
                ry="1"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M17.356 5.116c-.143-.55-.286-1.015-.465-1.395M20.096 7.442a7.886 7.886 0 0 0-.466-1.86"
            />
            <ellipse
                cx="8.674"
                cy="7.442"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                rx="1"
                ry="1"
            />
            <ellipse
                cx="11.185"
                cy="8.837"
                fill="#D6DAFE"
                fillRule="nonzero"
                rx="1"
                ry="1"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M4.602 13.023s-.186-.682.279-.93M10.957 12.093a2.56 2.56 0 0 0 1.675-.965c.43-.55.645-1.24.602-1.929l-.043-.827"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M8.769 13.424l1.241-1.384 1.104-.139a15.473 15.473 0 0 0 2.299 5.86c.598.923 1.885 1.107 2.759.461C18.7 16.331 20.54 13.47 21 10.148l-6.208-4.845a12.211 12.211 0 0 0-2.069-1.338c-.965-.507-2.023-.784-3.08-.692-1.978.185-3.587 1.477-4.323 3.184L3.39 11.209c-.69 1.661.552 3.599 2.39 3.553 1.288-.092 2.3-.554 2.99-1.338z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M5.935 3.938c.243.938 1.022 1.688 2.092 1.641 1.558-.093 2.044-2.016 1.022-2.626-.924-.516-1.849.328-1.314.704"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M11.02 4.186c.538-.639.538-1.552-.09-2.145l-.492-.502c-.18-.183-.537-.183-.717-.046a.418.418 0 0 0-.134.32l.269 2.373"
            />
        </g>
    </svg>
}

export default Level7;
