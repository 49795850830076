import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Input, Text } from './styled';

function NickName(props) {
    const { localization, personalUsername, onSave } = props;

    const [newPersonalUsername, setNewPersonalUsername] = useState(personalUsername);

    return (
        <div>
            <Row>
                <Input
                    type="text"
                    value={newPersonalUsername}
                    onChange={({ target }) => setNewPersonalUsername(target.value)}
                    onBlur={() => {
                        onSave(newPersonalUsername);
                    }}
                    onKeyUp={event => {
                        if (event.key === 'Enter') {
                            event.target.blur();
                        }
                    }}
                />
            </Row>
            <div style={{ marginTop: 12 }}>
                <Text>{localization.getText('settings.nickname.note')}</Text>
            </div>
        </div>
    );
}

NickName.propTypes = {
    personalUsername: PropTypes.string.isRequired,
    localization: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired
};

export default NickName;
