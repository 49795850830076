import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Item, ItemSelected, ItemText, List, Wrapper } from './styled';

import { Icon } from '..';

import { colors } from '../../../utils';

class Dropdown extends Component {
    static propTypes = {
        selectorValues: PropTypes.array,
        selected: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {
        selectorValues: [],
        selected: '',
        onChange: () => {}
    };

    state = {
        isOpen: false
    };

    componentDidMount() {
        document.addEventListener('mousedown', this._handleClickOutsideWrapper, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._handleClickOutsideWrapper, false);
    }

    WrapperNode;

    _handleWrapperReference = node => {
        this.WrapperNode = node;
    };

    _handleClickOutsideWrapper = event => {
        if (this.WrapperNode.contains(event.target)) {
            return;
        }

        this._handleClickOutside();
    };

    _handleClickOutside = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.setState({
                isOpen: false
            });
        }
    };

    _handleToggle = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    _handleSelect = (event, selection) => {
        const { onChange } = this.props;

        event.preventDefault();

        if (selection) {
            onChange(selection);
        }
    };

    _renderSelected() {
        const { selected } = this.props;

        return (
            <ItemSelected>
                <ItemText title={selected}>{selected}</ItemText>

                <Icon name="Triangle" color={colors.blue.madison} size={8} />
            </ItemSelected>
        );
    }

    _renderList() {
        const { selectorValues, selected } = this.props;

        return selectorValues.filter(Boolean).map(element => {
            if (element.value === selected) return null;

            return (
                <Item
                    title={element.value}
                    key={element.key}
                    onClick={event => this._handleSelect(event, element.key)}>
                    <ItemText>{element.value}</ItemText>
                </Item>
            );
        });
    }

    render() {
        const { isOpen } = this.state;

        return (
            <Wrapper
                onClick={this._handleToggle}
                $open={isOpen}
                ref={node => this._handleWrapperReference(node)}>
                {this._renderSelected()}
                <List $open={isOpen}>
                    {this._renderSelected()}
                    {this._renderList()}
                </List>
            </Wrapper>
        );
    }
}

export default Dropdown;
