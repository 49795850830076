import {defaultLocales} from "./locale";

const Hypher = require('hypher');
const hyphenationEN = require('hyphenation.en-us');
const hyphenationDE = require('hyphenation.de');

const hypherEN = new Hypher(hyphenationEN);
const hypherDE = new Hypher(hyphenationDE);

export const hyphenate = {
    [defaultLocales.en]: (text) => hypherEN.hyphenateText(text),
    [defaultLocales.de]: (text) => hypherDE.hyphenateText(text),
}
