import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../utils';
import { SubmitButton } from './SubmitButton';
import { Icon } from '..';
import { CloseButton } from '../../BattleCard/styled';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    width: 300px;
    position: relative;
    border-radius: 4px;
    padding: 16px;

    background-color: ${colors.white.basic};
    box-shadow: 0px 2px 11px 0px rgb(158, 158, 158);
`;

const ModalHeader = styled.div`
    position: relative;
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 16px;
    color: rgb(34, 34, 34);
`;

export const SubmitButtonWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
`;

const HeaderText = styled.div`
    width: 90%;
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class Modal extends Component {
    static propTypes = {
        header: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
        localization: PropTypes.object.isRequired
    };

    render() {
        const {
            isOpen,
            isDisabled,
            onClose,
            children,
            header,
            onSubmit,
            localization
        } = this.props;
        if (!isOpen) return null;

        return (
            <ModalWrapper>
                <ModalContent>
                    <ModalHeader>
                        <HeaderText>{header}</HeaderText>
                        <CloseButton $top={0} onClick={onClose}>
                            <Icon name="Cross" size={24} />
                        </CloseButton>
                    </ModalHeader>
                    {children}
                    <SubmitButtonWrapper>
                        <SubmitButton onClick={() => onSubmit()} disabled={isDisabled}>
                            {localization.getText('conversion.submitButtonText')}
                        </SubmitButton>
                    </SubmitButtonWrapper>
                </ModalContent>
            </ModalWrapper>
        );
    }
}
