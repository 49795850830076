import React from 'react';

function EmojiNormal11() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="49" height="46" viewBox="0 0 49 46">
        <g fill="none" fillRule="evenodd">
            <ellipse
                cx="19.804"
                cy="19.831"
                fill="#F8F9FF"
                fillRule="nonzero"
                rx="19.294"
                ry="19.32"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M19.804.511C9.188.511.51 9.2.51 19.831s8.678 19.32 19.294 19.32c10.617 0 19.294-8.689 19.294-19.32S30.42.511 19.804.511zm0 35.676c-9.698 0-17.558-7.871-17.558-17.583 0-9.71 7.86-17.582 17.558-17.582s17.558 7.871 17.558 17.582c0 9.712-7.86 17.583-17.558 17.583z"
                opacity=".54"
            />
            <ellipse
                cx="19.804"
                cy="44.569"
                fill="#4550E6"
                fillRule="nonzero"
                opacity=".15"
                rx="15.415"
                ry="1.431"
            />
            <ellipse
                cx="19.804"
                cy="19.831"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.022"
                rx="19.294"
                ry="19.32"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeWidth="1.022"
                d="M24.602 27.498c-1.327.92-3.062 1.43-4.798 1.43-1.735 0-3.47-.51-4.798-1.43"
            />
            <ellipse
                cx="31.34"
                cy="24.636"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.45"
                ry="1.431"
            />
            <ellipse
                cx="8.269"
                cy="24.636"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.45"
                ry="1.431"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.022"
                d="M30.42 18.4l-1.939 2.351 4.39-.92"
            />
            <ellipse
                cx="8.779"
                cy="19.831"
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.022"
                rx="1"
                ry="1"
            />
            <g
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.022">
                <path d="M48.183 20.24c0 .204-.102.307-.306.409-.204.102-.306.102-.51 0l-5.002-1.942c-.205-.103-.409-.307-.409-.614 0-.306.204-.51.51-.51l5.207-.614c.204 0 .306 0 .51.204a.781.781 0 0 1 .205.511l-.205 2.556zM47.57 9.711a.798.798 0 0 0-.407-.409c-.205-.102-.409 0-.51.102L42.16 12.37c-.204.102-.306.409-.204.715.102.205.307.41.613.41l5.308-.512c.204 0 .306-.102.408-.306.103-.103.103-.307.103-.512l-.817-2.453zM41.65 3.067c-.102-.103-.306-.103-.51-.103a.798.798 0 0 0-.409.41l-1.735 5.11c-.102.205 0 .512.204.716.204.102.51.102.715-.102l3.879-3.578a.781.781 0 0 0 .204-.511c0-.205-.102-.307-.204-.409L41.65 3.067z" />
            </g>
        </g>
    </svg>
}

export default EmojiNormal11;
