import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import transparentize from 'polished/lib/color/transparentize';
import { colors } from '../../../utils';
import { phraseTypes } from '../../../utils/words';
import { Label, Wrapper, WordsCount, BonusCount, BonusLabelWrapper } from './styled';

const renderStatistic = (isBonus, bonusCount, spottedPhrasesScore, totalPhrasesScore) => {
    const spottedBlock = (
        <WordsCount $type={phraseTypes.SPOTTED_PHRASES}>{spottedPhrasesScore}</WordsCount>
    );

    if (isBonus) {
        return (
            <Fragment>
                {spottedBlock}
                <BonusCount>+{bonusCount}</BonusCount>
            </Fragment>
        );
    }
    return (
        <Fragment>
            {spottedBlock}
            <Label $fontSize="24px" $color={transparentize(0.8, colors.gray.dark)}>
                /
            </Label>
            <WordsCount $type={phraseTypes.TOTAL_PHRASES}>{totalPhrasesScore}</WordsCount>
        </Fragment>
    );
};

const renderDescription = (isBonus, isLastCallResult, localization) => {
    const type = isLastCallResult ? 'last' : 'current';

    if (!isBonus) {
        return (
            <Label>{localization.getText(`trainer.call_popup.${type}_call_score`)}</Label>
        );
    }
    return (
        <BonusLabelWrapper>
            <Label $fontSize="14px" $fontWeight="normal">
                {localization.getText(`trainer.call_popup.${type}_call`)}
            </Label>
            <Label>{localization.getText('trainer.call_popup.bonus_score')}</Label>
        </BonusLabelWrapper>
    );
};

function CallInfo(props) {
    const {
        spottedPhrasesScore,
        totalPhrasesScore,
        isLastCallResult,
        localization,
        bonusScore
    } = props;
    const isBonus = bonusScore > 0;

    return (
        <Wrapper>
            {renderDescription(isBonus, isLastCallResult, localization)}
            {renderStatistic(isBonus, bonusScore, spottedPhrasesScore, totalPhrasesScore)}
        </Wrapper>
    );
}

CallInfo.propTypes = {
    spottedPhrasesScore: PropTypes.number,
    totalPhrasesScore: PropTypes.number,
    localization: PropTypes.object.isRequired,
    isLastCallResult: PropTypes.bool.isRequired,
    bonusScore: PropTypes.number
};

CallInfo.defaultProps = {
    spottedPhrasesScore: 0,
    totalPhrasesScore: 0,
    bonusScore: 0
};

export default CallInfo;
