import styled from 'styled-components';

import { colors } from '../../../../utils';

const Item = styled.li`
    display: flex;
    align-content: center;
    align-items: center;

    padding: 12px;
    margin: 0px;

    font-weight: normal;
    color: ${colors.blue.madison};

    transition: background-color 0.88s ubic-bezier(0.3, 0, 0, 1);

    &:hover {
        background-color: ${colors.blue.link_water};
    }
`;

export default Item;
