import styled from 'styled-components';
import { colors } from '../../../../utils';

const Button = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background-color: ${colors.white.basic};
    border-radius: 2px 2px 0 0;
    box-shadow: -2px -2px 4px 0px #cdd3df;
`;

export default Button;
