import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { WordLabel } from '..';
import { Wrapper, ListPlaceholder } from './styled';
import KeyphraseStack from './KeyphraseStack';
import { words as wordsUtil } from '../../../utils';

const { getPillType, getSortedDetection } = wordsUtil;

class WordsList extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        words: PropTypes.array,
        wordsRendered: PropTypes.number,
        configLoaded: PropTypes.bool.isRequired,
        moveSaidDoSayWordsToBottom: PropTypes.bool,
        displayDoSayAlternatives: PropTypes.bool
    };

    static defaultProps = {
        words: [],
        wordsRendered: null,
        moveSaidDoSayWordsToBottom: false,
        displayDoSayAlternatives: true
    };

    _renderContent() {
        const {
            type,
            words,
            wordsRendered,
            configLoaded,
            moveSaidDoSayWordsToBottom,
            displayDoSayAlternatives
        } = this.props;

        if (!configLoaded) {
            return <ListPlaceholder />;
        }

        if (words.length === 0) {
            return null;
        }

        if (type === 'whitelist') {
            const sortedWords = moveSaidDoSayWordsToBottom
                ? getSortedDetection(words, false, 'asc')
                : words;

            return sortedWords.map(phraseGroup => {
                return (
                    <KeyphraseStack
                        displayDoSayAlternatives={displayDoSayAlternatives}
                        topic={phraseGroup.text}
                        key={phraseGroup.text}
                        alternatives={phraseGroup.alternatives}
                        complements={phraseGroup.complements}
                    />
                );
            });
        }

        // TODO: TOTAL COUNT OF DONT SAY WORDS
        // TODO: create util for this since it is reused in https://github.com/i2x-gmbh/i2x-coach/pull/419
        // eslint-disable-next-line no-unused-vars
        const totalCount = sumBy(
            words.filter(word => sumBy(word.alternatives, 'count') !== 0),
            word =>
                sumBy(
                    word.alternatives.filter(alternative => alternative.count !== 0),
                    'count'
                )
        );

        return words.map((word, key) => {
            if (wordsRendered && key >= wordsRendered) return null;
            if (!word.count) return null;

            const localCount = sumBy(word.alternatives, 'count');

            return (
                <WordLabel
                    key={word.text}
                    pillType={getPillType(type, word.count)}
                    isClickable={false}
                    count={localCount}>
                    {word.text}
                </WordLabel>
            );
        });
    }

    render() {
        return <Wrapper>{this._renderContent()}</Wrapper>;
    }
}
export const PureWordsList = WordsList;
export default WordsList;
