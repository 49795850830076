import styled from 'styled-components';
import { colors } from '../../../../utils';

const SubmitButton = styled.button.attrs({
    type: 'button'
})`
    border-radius: 24px;
    border: 0;
    padding: 12px 24px;
    font-size: 13px;
    font-weight: bold;
    background-color: ${colors.blue.i2x};
    color: ${colors.white.basic};
    cursor: pointer;
    outline: none;
    display: block;
    margin: 16px auto 0 auto;

    &:disabled {
        opacity: 0.6;
    }
`;

export default SubmitButton;
