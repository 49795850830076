import styled from 'styled-components';

const TopicIcon = styled.div`
    margin-right: 4px;
    line-height: 10px;

    &:last-child {
        margin-right: 0;
        margin-left: 4px;
    }
`;

export default TopicIcon;
