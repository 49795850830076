import ButtonOff from './ButtonOff';
import ButtonOn from './ButtonOn';
import Cross from './Cross';
import FailIceCream from './FailIceCream';
import HandClap from './HandClap';
import Check from './Check';
import I2XLogo from './I2XLogo';
import Lock from './Lock';
import LoudnessGood from './LoudnessGood';
import LoudnessQuiet from './LoudnessQuiet';
import LoudnessLoud from './LoudnessLoud';
import Menu from './Menu';
import Plus from './Plus';
import StopWatch from './StopWatch';
import Triangle from './Triangle';
import TripleThumbsUp from './TripleThumbsUp';
import Warning from './Warning';
import WinkingFace from './WinkingFace';
import PhoneInTalk from './PhoneInTalk';
import PhoneForwarded from './PhoneForwarded';
import Headset from './Headset';
import Pencil from './Pencil';
import CheckMark from './CheckMark';
import CallEnded from './CallEnded';
import MutedMic from './MutedMic';
import NoAudioDevice from './NoAudioDevice';
import Launch from './Launch';
import Team from './Team';
import NoKeywords from './NoKeywords';
import Mic from './Mic';
import TelephonyIdError from './TelephonyIdError'
import Eye from './Eye'
import EyeClosed from './EyeClosed'
import FirstCall from './FirstCall'
import Morning from './Morning'
import Afternoon from './Afternoon'
import Evening from './Evening'
import TenthCall from './TenthCall'

export {
    ButtonOff,
    ButtonOn,
    Cross,
    FailIceCream,
    HandClap,
    Check,
    I2XLogo,
    Lock,
    LoudnessGood,
    LoudnessQuiet,
    LoudnessLoud,
    Menu,
    Plus,
    StopWatch,
    Triangle,
    TripleThumbsUp,
    Warning,
    WinkingFace,
    PhoneInTalk,
    PhoneForwarded,
    Headset,
    Pencil,
    CheckMark,
    CallEnded,
    MutedMic,
    NoAudioDevice,
    Launch,
    Team,
    NoKeywords,
    Mic,
    TelephonyIdError,
    Eye,
    EyeClosed,
    FirstCall,
    Morning,
    Afternoon,
    Evening,
    TenthCall,
};
