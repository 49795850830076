import styled from 'styled-components';
import { colors } from '../../../../utils';

const Count = styled.span`
    color: ${colors.blue.madison};
    font-weight: bold;
    margin-left: 8px;
`;

export default Count;
