import { createAudioConnection } from '../../services/WsConnection';
import { proto, types } from '../../services/ListenerProto';
import { daemonInitialized, types as mainTypes } from '../actions/main';
import {
    types as deviceTypes,
    audioChunkReceived,
    deviceConnectionClosed,
    deviceConnectionOpened,
    recordingInfoReceived
} from '../actions/device';
import { reload } from '../actions/common';
import { errorCodes } from '../../../utils';
import { deviceLogger } from '../../../utils/logger';

const { AUDIO_DEVICE_STOPPED } = errorCodes.background;

export default store => {
    const { dispatch } = store;

    function createAudioDeviceConnection({ port }) {
        const ws = createAudioConnection({ host: 'localhost', port });
        ws.on('opened', () => {
            dispatch(deviceConnectionOpened(port));
        });
        ws.on('closed', ({ wasClean }) => {
            dispatch(deviceConnectionClosed({ wasClean }));
        });
        ws.on('message', data => {
            const {
                audioResponseType,
                recordingInfo,
                audioChunk
            } = proto.AudioResponse.decode(data);

            if (audioResponseType === types.RECORDING_INFO) {
                deviceLogger.log('recordingInfo:', recordingInfo);
                dispatch(recordingInfoReceived(recordingInfo));
            } else if (audioResponseType === types.AUDIO_CHUNK) {
                const {
                    audio,
                    offsetInAudioStreamMsec,
                    dataFlow, //  DataFlow.CAPTURE=1,DataFlow.RENDER=2
                } = audioChunk;

                dispatch(audioChunkReceived({
                    audio,
                    offsetInAudioStreamMsec,
                    channel: dataFlow,
                }));
            }
        });

        return ws;
    }

    // eslint-disable-next-line no-unused-vars
    let connection = null;

    return next => action => {
        const { type, payload } = action;
        const result = next(action);

        switch (type) {
            case mainTypes.START_AUDIO_DEVICE_CONNECTION: {
                connection = createAudioDeviceConnection(payload);
                break;
            }
            case deviceTypes.DEVICE_CONNECTION_CLOSED: {
                if (!payload.wasClean) {
                    dispatch(
                        reload({
                            code: AUDIO_DEVICE_STOPPED,
                            reason: 'AUDIO_DEVICE_STOPPED'
                        })
                    );
                }
                break;
            }
            case deviceTypes.DEVICE_CONNECTION_OPENED: {
                dispatch(daemonInitialized());
                break;
            }
            default:
        }

        return result;
    };
};
