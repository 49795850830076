import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Pencil(props) {
  return <svg width={props.size} viewBox="0 0 11 11">
        <g>
            <path
                fill={props.color}
                d="M.625 8.344v2.031h2.031l5.991-5.99-2.031-2.032-5.991 5.99zm9.593-5.53a.54.54 0 0 0 0-.764L8.95.782a.54.54 0 0 0-.763 0l-.992.991 2.032 2.032.99-.992z"
            />
        </g>
    </svg>
}

Pencil.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

Pencil.defaultProps = {
    size: 11,
    color: `${colors.gray.basic}`
};

export default Pencil;
