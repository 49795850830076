import React from 'react';

function EmojiPerfect3() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="43" height="57" viewBox="0 0 43 57">
        <g fill="none" fillRule="evenodd">
            <g strokeLinecap="round">
                <path
                    stroke="#4550E6"
                    d="M38.5 5.5v10M41.5 17.5v8M6.5 12.5v12M4.5 37.5v5M36.5 42.5v4"
                />
                <path stroke="#D6DAFF" d="M.5 19.5v12M42.5 35.5v7" />
            </g>
            <g transform="rotate(-45 30.789 22.067)">
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M12 22.6l5.7-7s-4.4-1.2-8.5-.2c-3.1.7-3.6 6.6-3.7 9.4 0 .7.7 1.1 1.3.8l5.2-3z"
                />
                <path
                    fill="#4550E6"
                    fillRule="nonzero"
                    d="M9.2 18c2.4-.6 5-.4 6.6-.1l1.9-2.3s-4.4-1.2-8.5-.2c-3.1.7-3.6 6.6-3.7 9.4 0 .2 0 .3.1.5.4-3 1.2-6.7 3.6-7.3z"
                    opacity=".5"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 22.6l5.7-7s-4.4-1.2-8.5-.2c-3.1.7-3.6 6.6-3.7 9.4 0 .7.7 1.1 1.3.8l5.2-3z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M18.9 29.4l7-5.7s1.2 4.4.2 8.5c-.7 3.1-6.6 3.6-9.4 3.7-.7 0-1.1-.7-.8-1.3l3-5.2z"
                />
                <path
                    fill="#4550E6"
                    fillRule="nonzero"
                    d="M23.4 32.2c.6-2.4.4-5 .1-6.6l2.3-1.9s1.2 4.4.2 8.5c-.7 3.1-6.6 3.6-9.4 3.7-.2 0-.3 0-.5-.1 3-.3 6.7-1.1 7.3-3.6z"
                    opacity=".5"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.9 29.4l7-5.7s1.2 4.4.2 8.5c-.7 3.1-6.6 3.6-9.4 3.7-.7 0-1.1-.7-.8-1.3l3-5.2z"
                />
                <path
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    d="M37.5 17.6c5.4-5.7 3.7-12.7 2.9-15.2-.1-.3-.3-.6-.5-.8-.2-.2-.5-.4-.8-.5C36.6.3 29.6-1.5 23.9 4 16.9 10.7 12 22.5 12 22.5l7 7c-.1 0 11.8-4.9 18.5-11.9z"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M26 7.9C31.5 2.6 38.3 4.1 41 5c-.2-1.1-.5-2-.7-2.6-.2-.6-.7-1.1-1.3-1.3C36.5.3 29.5-1.5 23.8 4c-7 6.7-11.9 18.5-11.9 18.5l2.7 2.7C16.1 22 20.4 13.3 26 7.9z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M37.5 17.6c5.4-5.7 3.7-12.7 2.9-15.2-.1-.3-.3-.6-.5-.8-.2-.2-.5-.4-.8-.5C36.6.3 29.6-1.5 23.9 4 16.9 10.7 12 22.5 12 22.5l7 7c-.1 0 11.8-4.9 18.5-11.9z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.7 21.6c.3-.6-.3-1.2-.9-.9-1.4.7-3.5 2-5.3 3.8-2.3 2.3-3.4 4.7-3.9 5.9-.1.3.2.6.4.4 1.2-.5 3.6-1.6 5.9-3.9 1.8-1.7 3.1-3.8 3.8-5.3z"
                />
                <circle
                    cx="31.628"
                    cy="9.658"
                    r="5.5"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="rotate(-45 31.628 9.658)"
                />
                <circle
                    cx="31.731"
                    cy="9.601"
                    r="3.8"
                    fill="#4550E6"
                    fillRule="nonzero"
                    transform="rotate(-45 31.731 9.6)"
                />
                <path
                    fill="#737DFF"
                    fillRule="nonzero"
                    d="M29.1 7c-1.5 1.5-1.5 3.9 0 5.4.3.3.5.5.9.6l3.6-6.7c-1.6-.7-3.3-.5-4.5.7z"
                />
                <circle
                    cx="31.731"
                    cy="9.601"
                    r="3.8"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="rotate(-45 31.731 9.6)"
                />
                <path
                    fill="#FFC183"
                    fillRule="nonzero"
                    stroke="#FF8A14"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.3 37.5c-.7.7-5.2 2.2-7.4 2.9-.4.1-1-.5-.9-.9.7-2.3 2.2-6.7 2.9-7.4.9-.9 3.4-1 4.9.5 1.6 1.4 1.5 3.9.5 4.9z"
                />
                <path
                    fill="#FF1414"
                    fillRule="nonzero"
                    d="M8.1 35.8c-.5.5-2.6 1-3 .6-.4-.4.1-2.5.6-3s1.5-.3 2.1.3c.7.6.8 1.6.3 2.1z"
                />
            </g>
        </g>
    </svg>
}

export default EmojiPerfect3;
