import { OPEN_BATTLE_CARD, CLOSE_BATTLE_CARD } from './actionTypes/battleCard';

export const openBattleCard = payload => ({
    type: OPEN_BATTLE_CARD,
    payload
});

export const closeBattleCard = () => ({
    type: CLOSE_BATTLE_CARD
});
