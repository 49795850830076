import { get } from 'lodash';
import { proto as AsrProto } from '../../background/services/AsrProto';

export const getTrainer = store => store.trainer;

export const getUserInterfaceConfig = store => store.trainer.userInterfaceConfig;

export const getSuggestionCallFrequency = store => store.trainer.suggestionCallFrequency;

export const isExplicitMode = store =>
    get(store, 'trainer.userInterfaceConfig.integrationMethod', 0) ===
    AsrProto.IntegrationMethod.EXPLICIT;

export const displayTelephonyId = store => get(store, 'trainer.userInterfaceConfig.displayTelephonyId', false)
export const hidePersonalUsername = store => get(store, 'trainer.userInterfaceConfig.hidePersonalUsername', false)

export const getCallEventNotification = store => get(store, 'trainer.callEventNotification', null)

export const isConversionModalOpen = store => get(store, 'trainer.conversionModalOpen', false)

