import styled from 'styled-components';
import PropTypes from 'prop-types';

import { transparentize } from 'polished';
import { colors } from '../../../utils';

const DebugInfo = styled.div`
    position: absolute;
    z-index: -1;

    bottom: 90px;
    left: 5px;

    width: 300px;
    padding: 5px 15px 30px 15px;

    background-color: ${colors.white.basic};
    border-radius: 1px;
    box-shadow: 0 2px 6px 0 ${colors.blue.link_water};
    color: ${colors.black.default};

    opacity: ${props => (props.$isOpen ? '1' : '0')};
    transform: translateY(50px);

    transition: all 0.25s ease;

    font-size: 14px;
    font-weight: normal;
    text-align: left;

    &:after {
        border-top-color: ${colors.white.basic};
    }

    &:before {
        margin-bottom: 0.5px;
        border-top-color: ${transparentize(0.6, colors.blue.link_water)};
    }
`;

DebugInfo.propTypes = {
    $isOpen: PropTypes.bool.isRequired
};

export default DebugInfo;
