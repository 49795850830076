import {types} from '../actions/auth';
import {types as commonTypes} from '../actions/common';
import {ERR_INVALID_AUTH_SCHEME} from "../../constants";
import {clearLoginInfo} from "../../../utils/login";
import {selectUserFromToken} from "../../../utils/jwt";
import {IS_LEGACY_LISTENER_MODE} from "../../../config/electron";
import {commonLogger} from "../../../utils/logger";

const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
const IS_AUTH_WITH_PASSWORD_KEY = 'isAuthWithPassword';

const loadRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)
const saveRefreshToken = (refreshToken, isAuthWithPass) => {
    if(!refreshToken){
        localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
    } else {
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken)
    }
    if(!isAuthWithPass){
        localStorage.removeItem(IS_AUTH_WITH_PASSWORD_KEY)
    } else {
        localStorage.setItem(IS_AUTH_WITH_PASSWORD_KEY, "1")
    }
}

const isAuthWithPassword = () => !IS_LEGACY_LISTENER_MODE || !!localStorage.getItem(IS_AUTH_WITH_PASSWORD_KEY)

export const initialState = {
    token: null,
    userData: {},
    refreshToken: loadRefreshToken(),
    systemInfo: null,
    authWithPassword: isAuthWithPassword(),
};

export default function authReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
        case types.SYSTEM_INFO_UPDATED:
        case types.SYSTEM_INFO_RECEIVED: {
            return {
                ...state,
                systemInfo: payload.systemInfo
            };
        }
        case types.AUTH_WITH_PASSWORD_REQUIRED: {
            return {
                ...state,
                authWithPassword: true
            };
        }
        case types.AUTH_SUCCESS: {
            const { token, refreshToken, secret, isPasswordAuth } = payload;

            try {
                saveRefreshToken(refreshToken || null, isPasswordAuth);
            } catch (e) {
                if (e.message.indexOf('exceeded the quota') > -1) {
                    commonLogger.error(e.message, e)
                    localStorage.clear()
                    saveRefreshToken(refreshToken || null, isPasswordAuth);
                    document.location.reload()
                } else {
                    throw e
                }
            }


            const userData = selectUserFromToken(token);

            return {
                ...state,
                userData,
                token,
                refreshToken,
                secret,
            };
        }
        case types.AUTH_FAIL: {
            const authWithPassword = state.authWithPassword || payload.message === ERR_INVALID_AUTH_SCHEME;
            saveRefreshToken(null, authWithPassword);

            return {
                ...state,
                token: null,
                refreshToken: null,
                authWithPassword,
            };
        }
        case types.TOKEN_REFRESH_SUCCESS: {
            const { token } = payload;
            return {
                ...state,
                token,
            };
        }
        case commonTypes.RELOAD: {
            if (payload.logout) {
                clearLoginInfo();
                saveRefreshToken(null, false);
            }

            return state;
        }
        default:
            return state;
    }
}
