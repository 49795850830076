import styled from 'styled-components';
import { colors } from '../../../utils';

const Button = styled.div`
    color: ${colors.white.basic};
    font-size: 16px;
    font-weight: 600;
    padding: 8px 8px;
    border-radius: 16px;
    background-color: ${colors.green.flashygreen};
    display: inline;
    min-width: 190px;
    width: 100%;
    margin-top: 16px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        background-color: ${colors.green.aquamarine};
    }
`;

export default Button;
