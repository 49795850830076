import EmojiDisaster1 from './EmojiDisaster1';
import EmojiDisaster2 from './EmojiDisaster2';
import EmojiDisaster3 from './EmojiDisaster3';
import EmojiDisaster4 from './EmojiDisaster4';
import EmojiDisaster5 from './EmojiDisaster5';
import EmojiDisaster7 from './EmojiDisaster7';
import EmojiDisaster8 from './EmojiDisaster8';

export {
    EmojiDisaster1,
    EmojiDisaster2,
    EmojiDisaster3,
    EmojiDisaster4,
    EmojiDisaster5,
    EmojiDisaster7,
    EmojiDisaster8
};
