import styled from 'styled-components';
import { colors } from '../../../../utils';

const Text = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 2px;
    background-color: ${colors.white.white_smoke};
    border: none;
    padding: 10px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.38);
`;

export default Text;
