import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment/moment';
import CrossIcon from '../../common/icons/CrossIcon';
import { colors } from '../../../utils';
import OptInModal from './OptInModal';
import OptOutModal from './OptOutModal';
import { IS_ELECTRON_APP, IS_UI_ONLY_MODE } from '../../../config/electron';

const OptInWrap = styled.div`
    border: 0;
    padding: 10px 10px;
    background-color: ${props => (props.$isOptIn ? colors.green.flashygreen : '#a1a1a1')};
    color: ${props => (props.$isOptIn ? colors.white.white_smoke : colors.black.nero)};
    display: flex;
    align-content: center;
    justify-content: space-between;

    border-radius: ${props => (props.$rounded ? '4px' : '0')};
    margin: ${props => (props.$rounded ? '5px auto 0 auto' : '0')};
    width: ${props => (props.$rounded ? '300px' : '100%')};
    box-shadow: ${props => (props.$rounded ? '0 2px 6px 0 #cdd3df' : 'none')};
    font-size: ${props => (props.$rounded ? '0.775em' : '0.875em')};

    > div {
        width: 300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    b {
        font-weight: 600;
    }

    button.cross {
        border: none;
        padding: 0;
        background: none;
        cursor: pointer;
        display: block;
        line-height: 1;
    }
`;

OptInWrap.propTypes = {
    $isOptIn: PropTypes.bool.isRequired,
    $rounded: PropTypes.bool.isRequired
};

const OptInButton = styled.button`
    padding: 6px 10px 5px 10px;
    border: 1px solid
        ${props => (props.$isOptIn ? colors.white.white_smoke : colors.black.nero)};
    border-radius: 20px;
    background-color: transparent;
    color: ${props => (props.$isOptIn ? colors.white.white_smoke : colors.black.nero)};
    cursor: pointer;
    font-weight: bold;

    &:hover {
        padding: 5px 9px 4px 9px;
        border-width: 2px;
    }
`;

OptInButton.propTypes = {
    $isOptIn: PropTypes.bool
};

OptInButton.defaultProps = {
    $isOptIn: false
};

function formatTime(optInOnTimestamp) {
    if (!optInOnTimestamp) {
        return null;
    }

    const diff = moment.duration(moment().diff(optInOnTimestamp));

    return `${diff.minutes()}m ${diff.seconds()}s`;
}

export function OptInControls({
    optInOn,
    manualOptIn,
    manualOptStop,
    localization,
    isCallStarted,
    manualOptOut,
    isOptOut,
    stopRecordingCustomer
}) {
    //
    // OPT IN MODAL
    //
    const [optInModal, setOptInModalOpen] = useState(false);
    const onModalOptInClose = () => {
        setOptInModalOpen(false);
    };
    const onModalOptInConfirm = () => {
        setOptInModalOpen(false);
        manualOptIn();
    };

    //
    // OPT OUT MODAL
    //

    const [optOutModal, setOptOutModalOpen] = useState(false);
    const onModalOptOutClose = () => {
        setOptOutModalOpen(false);
    };
    const onModalOptOutConfirm = (justStop = false) => {
        setOptInModalOpen(false);
        if (justStop) {
            manualOptStop();
        } else {
            manualOptOut();
        }
    };

    //
    // Timer
    //

    const [optInOnTimestamp, setOptInOnTimestamp] = useState(0);

    useEffect(() => {
        if (optInOn && isCallStarted) {
            setOptInOnTimestamp(Date.now());
        }
    }, [optInOn, isCallStarted]);

    //

    const [hideBar, setHideBar] = useState(false);

    // reset states
    useEffect(() => {
        setHideBar(false);
        setOptInOnTimestamp(0);
        setOptInModalOpen(false);
        setOptOutModalOpen(false);
    }, [isCallStarted]);

    //

    const _onOptInButtonClick = () => {
        setOptInModalOpen(true);
    };

    const _onOptOupButtonClick = () => {
        setOptOutModalOpen(true);
    };

    if (!isCallStarted || hideBar) {
        return null;
    }

    if (isOptOut) {
        // opt out
        return (
            <OptInWrap $isOptIn={false} $rounded={IS_UI_ONLY_MODE && !IS_ELECTRON_APP}>
                <div>
                    <b>{localization.getText('trainer.opt_in_out.opt_out_text')}</b>
                </div>
                <button className="cross" type="button" onClick={() => setHideBar(true)}>
                    <CrossIcon size={22} />
                </button>
            </OptInWrap>
        );
    }

    if (stopRecordingCustomer) {
        return (
            <OptInWrap $isOptIn={false} $rounded={IS_UI_ONLY_MODE && !IS_ELECTRON_APP}>
                <div>
                    <b>
                        {localization.getText('trainer.opt_in_out.record_stopped_text')}
                    </b>
                    <button
                        className="cross"
                        type="button"
                        onClick={() => setHideBar(true)}>
                        <CrossIcon size={22} />
                    </button>
                </div>
            </OptInWrap>
        );
    }

    return (
        <>
            <OptInWrap
                $isOptIn={optInOn && isCallStarted}
                $rounded={IS_UI_ONLY_MODE && !IS_ELECTRON_APP}>
                <div>
                    {optInOn && isCallStarted ? (
                        <>
                            <span>
                                {localization.getText('trainer.opt_in_out.recorded_text')}{' '}
                                ({formatTime(optInOnTimestamp)})
                            </span>
                            <OptInButton $isOptIn onClick={_onOptOupButtonClick}>
                                {localization.getText('trainer.opt_in_out.stop')}
                            </OptInButton>
                        </>
                    ) : (
                        <>
                            <b>
                                {localization.getText(
                                    'trainer.opt_in_out.not_recorded_text'
                                )}
                            </b>
                            <OptInButton onClick={_onOptInButtonClick}>
                                {localization.getText('trainer.opt_in_out.record')}
                            </OptInButton>
                        </>
                    )}
                </div>
            </OptInWrap>

            {optInModal && (
                <OptInModal
                    localization={localization}
                    onModalOptInClose={onModalOptInClose}
                    onModalOptInConfirm={onModalOptInConfirm}
                />
            )}

            {optOutModal && (
                <OptOutModal
                    localization={localization}
                    onModalOptOutClose={onModalOptOutClose}
                    onModalOptOutConfirm={onModalOptOutConfirm}
                />
            )}
        </>
    );
}

OptInControls.propTypes = {
    optInOn: PropTypes.bool.isRequired,
    isOptOut: PropTypes.bool.isRequired,
    localization: PropTypes.object.isRequired,
    manualOptIn: PropTypes.func.isRequired,
    manualOptOut: PropTypes.func.isRequired,
    manualOptStop: PropTypes.func.isRequired,
    stopRecordingCustomer: PropTypes.bool.isRequired,
    isCallStarted: PropTypes.bool.isRequired
};
