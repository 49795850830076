import { createError } from '../utils';

const SpottingConfigDuplicateError = createError({
    name: 'SpottingConfigError',
    message: 'Duplicates in ASR spotting config',
    extra: (p = {}) => ({ debugInfo: p.config || {} })
});

const SpeakerDeviceNotFoundError = createError({
    name: 'SpeakerDeviceNotFound',
    message: 'Speaker audio device not found.'
});

const MicDeviceNotFoundError = createError({
    name: 'MicDeviceNotFound',
    message: 'Mic audio device not found.'
});

const TranscriptValidationError = createError({
    name: 'TranscriptValidationError',
    message: p =>
        `Transcript validation value should be between 0 and 1. The value is ${
            p.confidence
        }`
});

export const Common = {
    SpottingConfigDuplicateError,
    TranscriptValidationError,
    SpeakerDeviceNotFoundError,
    MicDeviceNotFoundError
};
