import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Space = styled.div`
    height: ${props => props.$size}px;
`;

Space.propTypes = {
    $size: PropTypes.number.isRequired
};
