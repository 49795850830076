import React from 'react';

function StarIcon() {
  return <svg width="12" height="12" data-icon-star>
        <path
            transform="translate(-133 -106)"
            id="a"
            strokeWidth=".2"
            d="M139 115.466l-2.686 1.487a.355.355 0 0 1-.522-.367l.517-3.18-2.21-2.27a.355.355 0 0 1 .202-.598l3.036-.465 1.341-2.864a.355.355 0 0 1 .644 0l1.34 2.864 3.037.465a.355.355 0 0 1 .201.598l-2.21 2.27.518 3.18a.355.355 0 0 1-.522.367L139 115.466z"
        />
    </svg>
}

export default StarIcon;
