import styled from 'styled-components';

import { colors } from '../../../utils';

const UserInfoWrapper = styled.div`
    padding: 8px 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    color: ${colors.gray.jumbo};
    background-color: ${colors.gray.wild_sand};
    font-size: 11px;
    height: 32px;
`;

export default UserInfoWrapper;
