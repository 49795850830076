import styled from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '../../../utils';

const Notification = styled.div`
    width: 100%;
    min-width: 100%;
    border-radius: 4px;
    padding: 8px 16px;
    //box-shadow: 0 2px 6px 0 #cdd3df;
    overflow: hidden;
    background-color: ${colors.black.default};
    color: ${colors.white.basic};
    margin-top: 8px;
    border: 1px solid #cdd3df;
`;

export default Notification;

export const NotificationLight = styled.div`
    display: flex;
    position: relative;
    margin-top: 8px;
    width: 100%;
    align-content: center;
    align-items: center;
    //box-shadow: 0 2px 6px 0 #cdd3df;
    border: 1px solid #cdd3df;
    border-radius: 4px;
    padding: 12px 16px;
    text-align: center;
    border: solid 1px ${colors.blue.royal_blue};
    background-color: ${transparentize('0.95', colors.blue.royal_blue)};
    color: ${colors.blue.royal_blue};
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    cursor: default;
`;
