import React from 'react';
import PropTypes from 'prop-types';

function FailIceCream(props) {
  return <svg width={props.size} viewBox="0 0 103 78">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#F8F9FF"
                d="M98.509 46.567C98.509 20.85 77.75 0 52.145 0 26.538 0 5.78 20.85 5.78 46.567a46.54 46.54 0 0 0 4.131 19.21h84.466a46.54 46.54 0 0 0 4.13-19.21"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.36 65.776h90.41M1.145 65.776h2.898M99.088 65.776h2.898"
            />
            <path
                fill="#D6DAFF"
                d="M79.384 76.254c0 .965-9.86 1.746-22.023 1.746s-22.023-.781-22.023-1.746 9.86-1.747 22.023-1.747c12.162 0 22.023.782 22.023 1.747"
            />
            <path
                fill="#FFF"
                d="M65.253 50.28c-6.34-6.367-16.61-6.367-22.95 0-5.47 5.493-6.224 13.93-2.253 20.228-5.094.28-8.874 1.312-8.874 2.544 0 1.446 5.215 2.62 11.648 2.62h19.891a.917.917 0 0 0 .916-.92c.568-.43 1.101-.908 1.622-1.42 6.341-6.369 6.341-16.683 0-23.051"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M65.253 50.28c-6.34-6.367-16.61-6.367-22.95 0-5.47 5.493-6.224 13.93-2.253 20.228-5.094.28-8.874 1.312-8.874 2.544 0 1.446 5.215 2.62 11.648 2.62h19.891a.917.917 0 0 0 .916-.92c.568-.43 1.101-.908 1.622-1.42 6.341-6.369 6.341-16.683 0-23.051z"
            />
            <path
                fill="#D6DAFF"
                d="M69.458 65.206a1.158 1.158 0 0 0 1.956-.594l.683-3.43a1.166 1.166 0 0 0-.317-1.051l-.791-.794s1.912-9.601 16.444-24.72c.874-.91.854-2.36-.035-3.254l-3.31-3.323a2.307 2.307 0 0 0-3.242-.031c-15.026 14.617-24.2 16.921-24.2 16.921l-.79-.794a1.16 1.16 0 0 0-1.047-.319l-3.415.686c-.907.183-1.247 1.307-.593 1.965l18.657 18.738z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M68.12 57.278l-9.425-9.466M71.287 58.271l-5.004-18.283M74.599 51.141l-3.144-14.885M79.122 44.333L76.523 32.03M83.852 38.511l-2.315-10.965M57.179 44.766l18.005 5.386M63.561 41.689l15.215 3.24M70.17 37.11l12.58 2.68M76.115 32.39l11.172 2.38M69.458 65.206a1.158 1.158 0 0 0 1.956-.594l.683-3.43a1.166 1.166 0 0 0-.317-1.051l-.791-.794s1.912-9.601 16.444-24.72c.874-.91.854-2.36-.035-3.254l-3.31-3.323a2.307 2.307 0 0 0-3.242-.031c-15.026 14.617-24.2 16.921-24.2 16.921l-.79-.794a1.16 1.16 0 0 0-1.047-.319l-3.415.686c-.907.183-1.247 1.307-.593 1.965l18.657 18.738z"
            />
            <path
                fill="#FFF"
                d="M34.074 69.85c0 .966-3.503 1.747-7.824 1.747-4.322 0-7.824-.781-7.824-1.746s3.502-1.747 7.824-1.747c4.321 0 7.824.782 7.824 1.747"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M34.074 69.85c0 .966-3.503 1.747-7.824 1.747-4.322 0-7.824-.781-7.824-1.746s3.502-1.747 7.824-1.747c4.321 0 7.824.782 7.824 1.747z"
            />
        </g>
    </svg>
}

FailIceCream.propTypes = {
    size: PropTypes.number
};

FailIceCream.defaultProps = {
    size: 103
};

export default FailIceCream;
