import styled from 'styled-components';

const DurationWrapper = styled.div`
    display: flex;

    justify-content: flex-start;
    align-content: center;
    align-items: center;

    & span {
        margin: 0 4px;

        font-size: 16px;
        font-weight: normal;
    }

    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

export default DurationWrapper;
