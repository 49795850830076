import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import CrossIcon from '../../common/icons/CrossIcon';
import { SET_SALE_TRACKING_STATUS } from '../../../store/actions/actionTypes/sales';
import { Space } from '../../common/Space';
import { getSalesTrackingData } from './SalesTrackingData';
import { logSalesEvent } from '../../../background/store/actions/eventLogger';
import {
    Card,
    ClearButton,
    FormGroup,
    InfoContainer,
    InfoLabel,
    InfoValue,
    InfoValueNoWrap,
    InputField,
    InputWrapper,
    SelectStyles,
    Separator,
    SubmitButton,
    Subtitle,
    Title
} from './styled';
import { captureException } from '../../../tracker/raven';
import { colors } from '../../../utils';
import withLocalization from '../../../localization/withLocalization';
import Modal, { ModalButton } from '../../Modal';
import HandClap from '../../common/icons/HandClap';

const filterUnique = (value, index, array) => array.indexOf(value) === index;

function SalesTracking(props) {
    const { localization } = props;

    const [options, setOptions] = useState([]);
    const [campaignRef, setCampaignRef] = React.useState('');
    const [categoryRef, setCategoryRef] = React.useState('');
    const [customerRef, setCustomerRef] = React.useState('');
    const [productRef, setProductRef] = React.useState(null);

    const [modalOpen, setModalOpen] = React.useState(false);

    const isFormValid = !!productRef;

    const trackSale = () => {
        window.dispatch2background(
            logSalesEvent('sale', props.callId, {
                campaign: campaignRef || '-',
                items: [
                    {
                        category: productRef.category,
                        product: productRef.product,
                        amount: 1
                    }
                ],
                customer_ref: customerRef
            })
        );
    };

    useEffect(() => {
        getSalesTrackingData({
            locationId: props.locationId
        })
            .then(o => {
                setOptions(o);
                setCampaignRef(o[0]?.campaign || '');
            })
            .catch(captureException);
    }, [props.locationId]);

    const close = () => {
        setProductRef(null);
        setCategoryRef('');
        setCustomerRef('');
        props.setTrackStatus('');
        setModalOpen(false);
    };

    useEffect(() => {
        if (props.trackStatus !== 'success') {
            return;
        }
        const t = setTimeout(() => close(), 2000);
        return () => {
            clearTimeout(t);
        };
    }, [props.trackStatus]);

    const selectProps = {
        maxMenuHeight: 150,
        styles: SelectStyles,
        isClearable: true,
        menuPlacement: 'auto'
    };

    return (
        <Card>
            <FormGroup>
                <label htmlFor="sales_campaign">
                    {localization.getText('sales.campaign')}
                </label>
                <Select
                    value={{
                        value: campaignRef,
                        label: campaignRef
                    }}
                    inputId="sales_campaign"
                    options={options
                        .map(item => item.campaign)
                        .filter(filterUnique)
                        .map(c => {
                            return { value: c, label: c };
                        })}
                    onChange={s => {
                        const newCampaign = s?.value || '';
                        if (newCampaign !== campaignRef) {
                            setCategoryRef(null);
                            setProductRef(null);
                        }
                        setCampaignRef(newCampaign);
                    }}
                    isClearable={!!campaignRef}
                    {...selectProps}
                />
            </FormGroup>

            <FormGroup>
                <label htmlFor="sales_customer">
                    {localization.getText('sales.customer')}
                </label>
                <InputWrapper>
                    <InputField
                        id="sales_customer"
                        type="text"
                        value={customerRef}
                        maxLength={32}
                        onChange={e => setCustomerRef(e.target.value)}
                    />
                    {customerRef && (
                        <ClearButton onClick={() => setCustomerRef('')}>
                            <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false">
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                            </svg>
                        </ClearButton>
                    )}
                </InputWrapper>
            </FormGroup>

            <FormGroup>
                <label htmlFor="sales_category">
                    {localization.getText('sales.category')}
                </label>
                <Select
                    value={{
                        value: categoryRef,
                        label: categoryRef
                    }}
                    inputId="sales_category"
                    options={options
                        .filter(item => {
                            return !campaignRef || item.campaign === campaignRef;
                        })
                        .map(item => item.category)
                        .filter(filterUnique)
                        .map(c => {
                            return { value: c, label: c };
                        })}
                    onChange={s => {
                        setCategoryRef(s?.value || '');
                        if (productRef?.category !== s?.value) {
                            setProductRef(null);
                        }
                    }}
                    isClearable={!!categoryRef}
                    {...selectProps}
                />
            </FormGroup>

            <FormGroup>
                <label htmlFor="sales_product">
                    {localization.getText('sales.product')}
                </label>
                <Select
                    value={{
                        label: productRef?.product || '',
                        value: productRef
                    }}
                    inputId="sales_product"
                    options={options
                        .filter(p => {
                            return !categoryRef || p.category === categoryRef;
                        })
                        .map(p => {
                            return { value: p, label: p.product };
                        })}
                    onChange={s => {
                        setProductRef(s?.value || null);
                        setCategoryRef(s?.value?.category || '');
                        if (s?.value?.campaign) {
                            setCampaignRef(s?.value?.campaign);
                        }
                    }}
                    isClearable={!!productRef}
                    {...selectProps}
                />
            </FormGroup>

            <SubmitButton disabled={!isFormValid} onClick={() => setModalOpen(true)}>
                {localization.getText('sales.send')}
            </SubmitButton>

            {modalOpen && (
                <Modal
                    title=""
                    borderRadius="20px"
                    overlayColor="rgba(69, 80, 230, 0.75)"
                    onClose={() => setModalOpen(false)}>
                    {props.trackStatus === 'error' && (
                        <Fragment>
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <CrossIcon size={128} />
                            </div>
                            <Space $size={20} />
                            <Title>{localization.getText('sales.modal.error')}</Title>
                            <Space $size={6} />
                            <Subtitle>
                                {localization.getText('sales.modal.try_again')}
                            </Subtitle>
                            <Space $size={6} />
                            <Separator />
                            <ModalButton
                                onClick={() => {
                                    setModalOpen(false);
                                    props.setTrackStatus('');
                                }}
                                $color={colors.gray.dark}
                                $isOutline
                                $disabledColor={colors.green.magic_mint}>
                                <span>{localization.getText('sales.close')}</span>
                            </ModalButton>
                        </Fragment>
                    )}
                    {props.trackStatus === 'loading' && (
                        <div
                            style={{
                                textAlign: 'center'
                            }}>
                            <Space $size={30} />
                            {localization.getText('sales.loading')}
                            <Space $size={30} />
                        </div>
                    )}
                    {props.trackStatus === 'success' && (
                        <Fragment>
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <HandClap size={128} />
                            </div>
                            <Space $size={20} />
                            <Title>{localization.getText('sales.modal.good_work')}</Title>
                            <Space $size={6} />
                            <Subtitle>
                                {localization.getText(
                                    'sales.modal.successfully_uploaded'
                                )}
                            </Subtitle>
                            <Space $size={6} />
                            <Separator />
                            <ModalButton
                                onClick={() => close()}
                                $color={colors.gray.dark}
                                $isOutline
                                $disabledColor={colors.green.magic_mint}>
                                <span>{localization.getText('sales.close')}</span>
                            </ModalButton>
                        </Fragment>
                    )}

                    {props.trackStatus === '' && (
                        <Fragment>
                            <Title>{localization.getText('sales.modal.title')}</Title>
                            <Subtitle>
                                {localization.getText('sales.modal.desc')}
                            </Subtitle>
                            <Separator />
                            <InfoContainer $fontSize="16px">
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.customer')}
                                    </InfoLabel>
                                    <InfoValue>
                                        <b>{customerRef || '–'}</b>
                                    </InfoValue>
                                </div>
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.campaign')}
                                    </InfoLabel>
                                    <InfoValue>
                                        <b>{campaignRef}</b>
                                    </InfoValue>
                                </div>
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.category')}
                                    </InfoLabel>
                                    <InfoValue>
                                        <b>{categoryRef}</b>
                                    </InfoValue>
                                </div>
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.product')}
                                    </InfoLabel>
                                    <InfoValue>
                                        <b>{productRef?.product || ''}</b>
                                    </InfoValue>
                                </div>
                            </InfoContainer>

                            <Separator />

                            <InfoContainer $fontSize="14px">
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.agent')}
                                    </InfoLabel>
                                    <InfoValueNoWrap $nowrap>
                                        {props.userName}
                                    </InfoValueNoWrap>
                                </div>
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.call_id')}
                                    </InfoLabel>
                                    <InfoValueNoWrap $nowrap>
                                        {props.callId || '–'}
                                    </InfoValueNoWrap>
                                </div>
                                <div>
                                    <InfoLabel>
                                        {localization.getText('sales.date_time')}
                                    </InfoLabel>
                                    <InfoValue>{new Date().toLocaleString()}</InfoValue>
                                </div>
                            </InfoContainer>

                            <Space $size={6} />

                            <ModalButton
                                onClick={() => trackSale()}
                                $color={colors.green.flashygreen}
                                $disabledColor={colors.green.magic_mint}>
                                <span>{localization.getText('sales.confirm')}</span>
                            </ModalButton>

                            <Space $size={6} />

                            <ModalButton
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                $color={colors.gray.dark}
                                $isOutline
                                $disabledColor={colors.green.magic_mint}>
                                <span>{localization.getText('sales.cancel')}</span>
                            </ModalButton>
                        </Fragment>
                    )}
                </Modal>
            )}
        </Card>
    );
}

SalesTracking.propTypes = {
    localization: PropTypes.object.isRequired,
    locationId: PropTypes.number.isRequired,
    callId: PropTypes.string,
    userName: PropTypes.string,
    trackStatus: PropTypes.string,
    setTrackStatus: PropTypes.func.isRequired
};

SalesTracking.defaultProps = {
    callId: null,
    userName: '',
    trackStatus: ''
};

const mapStateToProps = state => ({
    locationId: get(state, 'user.locationId'),
    callId: get(state, 'callId'),
    userName: get(state, 'user.userName') || get(state, 'user.email'),
    trackStatus: get(state, 'sales.trackStatus')
});

const mapDispatchToProps = dispatch => {
    return {
        setTrackStatus: status =>
            dispatch({
                type: SET_SALE_TRACKING_STATUS,
                payload: {
                    status
                }
            })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalization(SalesTracking));
