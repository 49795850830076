import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, words } from '../../../../utils';

const {
    DEFAULT_WHITELIST,
    CLICKABLE_WHITELIST,
    DETECTED_WHITELIST,
    DEFAULT_BLACKLIST,
    CLICKABLE_BLACKLIST
} = words;

const PILL_TYPES = {
    [DEFAULT_WHITELIST]: {
        'background-color': colors.white.albaster,
        color: colors.black.nero,
        'border-radius': '4px',
        'border-color': colors.blue.link_water
    },
    [CLICKABLE_WHITELIST]: {
        'background-color': colors.white.albaster,
        color: colors.black.nero,
        'border-radius': '4px',
        'border-color': colors.blue.link_water
    },
    [DEFAULT_BLACKLIST]: {
        'background-color': colors.white.basic,
        color: colors.blue.madison,
        'border-radius': '4px',
        'border-color': colors.red.light_carmine_pink
    },
    [CLICKABLE_BLACKLIST]: {
        'background-color': colors.white.basic,
        color: colors.gray.dark,
        'border-radius': '4px',
        'border-color': colors.red.light_carmine_pink
    },
    [DETECTED_WHITELIST]: {
        'background-color': colors.green.aquamarine,
        color: colors.white.basic,
        'border-radius': '4px',
        'border-color': colors.green.aquamarine
    }
};

const Wrapper = styled.li`
    display: inline-block;
    position: relative;
    margin: 3px;
    border-style: solid;
    border-width: 1px;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    text-align: left;
    hyphens: auto;
    ${props => PILL_TYPES[props.$pillType]};
    ${props => (props.$clickable ? 'padding-right: 20px;' : '')};
`;

Wrapper.propTypes = {
    $pillType: PropTypes.string.isRequired,
    $clickable: PropTypes.bool
};

Wrapper.defaultProps = {
    $clickable: false
};

export default Wrapper;
