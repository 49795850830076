import React from 'react';
import PropTypes from 'prop-types';
import { Remarkable } from 'remarkable';
import Icon from '../common/Icon';
import { CloseButton, Content, Keyword, Title, Wrapper, Url } from './styled';

const Renderer = new Remarkable();

function BattleCard(props) {
    if (!props.isOpen) {
        return null;
    }

    const content = { __html: Renderer.render(props.content) };
    return (
        <Wrapper>
            <CloseButton onClick={props.closeBattleCard}>
                <Icon name="Cross" size={11} />
            </CloseButton>
            <Title>{props.localization.getText('trainer.battle_card.title')}</Title>
            <Keyword>{props.keyword}</Keyword>
            <Content dangerouslySetInnerHTML={content} />
            <Url value={props.url} />
        </Wrapper>
    );
}

BattleCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
    localization: PropTypes.object.isRequired,
    keyword: PropTypes.string.isRequired,
    url: PropTypes.string,
    closeBattleCard: PropTypes.func.isRequired
};

BattleCard.defaultProps = {
    url: ''
};

export default BattleCard;
