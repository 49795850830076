import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import I2XLogo from '../../components/common/icons/I2XLogo';
import { colors } from '../../utils';
import withLocalization from '../../localization/withLocalization';

const MODAL_WIDTH = 336;
const MODAL_HEIGHT = 720;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px;
`;

const Button = styled.button`
    border-radius: 24px;
    border: 0;

    margin-top: 20px;

    padding: 12px 24px;

    font-size: 13px;
    font-weight: bold;

    background-color: ${colors.green.aquamarine};
    color: ${colors.white.basic};

    cursor: pointer;

    outline: none;

    & > * {
        vertical-align: middle;
    }

    & > span {
        margin-left: 6px;
    }
`;

class ModalApp extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired
    };

    constructor() {
        super();

        this.modalWindow = null;
    }

    openWindow() {
        const url = document.location.href;
        this.modalWindow = window.open(
            url,
            'targetWindow',
            `toolbar=0,
                      location=0,
                      status=0,
                      menubar=0,
                      scrollbars=1,
                      resizable=0,
                      innerWidth=${MODAL_WIDTH},
                      innerHeight=${MODAL_HEIGHT}`
        );

        if (this.modalWindow) {
            this.modalWindow.addEventListener('resize', () => {
                this.modalWindow.resizeTo(MODAL_WIDTH, MODAL_HEIGHT);
            });
        }
    }

    componentDidMount() {
        // this prevents a reboot if the application starts successfully
        if (window.loadingTimeout) {
            clearTimeout(window.loadingTimeout);
        }

        // For health check
        window.coachLoaded = Date.now();
    }

    render() {
        return (
            <Wrapper>
                <I2XLogo color={colors.blue.i2x} />
                <Button type="button" onClick={() => this.openWindow()}>
                    {this.props.localization.getText('trainer.launch')}
                </Button>
            </Wrapper>
        );
    }
}

export default withLocalization(ModalApp);
