import React from 'react';
import Placeholder from '../../Placeholder';

function ListPlaceholder() {
    return (
        <React.Fragment>
            <Placeholder width="100%" />
            <Placeholder width="70%" />
        </React.Fragment>
    );
}

export default ListPlaceholder;
