import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import userReducer from './user';
import trainer from './trainer';
import battleCardReducer from './battleCard';
import transcriptReducer from './transcript';
import promptReducer from "./prompts";
import debug from './debug';
import callSuggestionsReducer from "./callSuggestions";
import sales from "./sales";

const trainerPersistConfig = {
    key: 'trainer',
    storage,
    whitelist: [
        'lastCallPhraseSpottingScore',
        'isLastCallResult',
        'isCallResultVisible',
        'systemSoundRecognition'
    ]
};

const transcriptPersistConfig = {
    key: 'transcript',
    storage,
    whitelist: ['isVisible', 'timelineTranscripts']
};

const debugPersistConfig = {
    key: '_debug',
    storage,
    whitelist: ['callIds']
};

const promptPersistConfig = {
    key: 'prompt',
    storage,
    whitelist: ['config', 'lastUpdated', 'currentPrompt', 'calls', 'languageCode']
};

const userPersistConfig = {
    key: 'user',
    storage,
    whitelist: ['agencyId', 'locationId', 'companyId', 'userId']
}

const callSuggestionsConfig = {
    key: 'suggestions',
    storage,
    whitelist: [
        'callsAfterLastRequest', 'callSuggestionsOpen', 'isCallSuggestionsNew', 'suggestionsTimestamp', 'languageCode', 'suggestions',
    ]
};

// TODO: review persisted fields
const rootReducers = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    battleCard: battleCardReducer,
    sales,
    prompt: persistReducer(promptPersistConfig, promptReducer),
    callSuggestions: persistReducer(callSuggestionsConfig, callSuggestionsReducer),
    trainer: persistReducer(trainerPersistConfig, trainer),
    transcript: persistReducer(transcriptPersistConfig, transcriptReducer),
    _debug: persistReducer(debugPersistConfig, debug)
});

export default rootReducers;
