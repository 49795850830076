import { get } from 'lodash';
import { errorCodes } from '../../../utils';
import { version, name } from '../../../../package.json';
import {REQUESTS, USER_EVENT_TYPE} from "../../constants";
import {getUserCompanyName, getUserName} from "../selectors";
import {logUserEvent} from "./eventLogger";

const {
    UNEXPECTED_DAEMON_VERSION,
    MAIN_CONNECTION_CLOSED_ON_START
} = errorCodes.background;

const countDownByError = ({ code, isImmediate }) => {
    if (isImmediate) {
        return 1;
    }

    switch (code) {
        case MAIN_CONNECTION_CLOSED_ON_START:
            return 60;
        case UNEXPECTED_DAEMON_VERSION:
            return 30;
        default:
            return 5;
    }
};

export const types = {
    NETWORK_REQUEST: '@bg/NETWORK_REQUEST',
    UNAUTHORIZED_REQUEST: '@bg/UNAUTHORIZED_REQUEST',
    UPDATE_RELOAD_COUNT_DOWN: '@bg/UPDATE_RELOAD_COUNT_DOWN',
    ALL_SETTINGS_RECEIVED: '@bg/ALL_SETTINGS_RECEIVED',
    SHOW_NOTIFICATION: '@bg/SHOW_NOTIFICATION',
    RELOAD: '@bg/RELOAD',
    CLOSE_ALL_CONNECTIONS: '@bg/CLOSE_ALL_CONNECTIONS',
    SEND_LOG: '@bg/SEND_LOG',
    SALES_TRACKING_STATUS: '@bg/SALES_TRACKING_STATUS',
    REQUEST_DEVICE_LIST: '@bg/REQUEST_DEVICE_LIST'
};

export const sendRequest = (request, { payload, onSuccess, onError, keepKeysCase = false }) => ({
    type: types.NETWORK_REQUEST,
    request,
    payload,
    onSuccess,
    onError,
    keepKeysCase
});

export const reload = ({ code, isImmediate = true, reason, logout = false } = {}) => (
    dispatch,
    getState
) => {
    console.info('!!!! RELOAD APP !!!!');

    if(reason === 'LOGOUT') {
        dispatch(logUserEvent(USER_EVENT_TYPE.LOGOUT))
    }

    const state = getState();
    if (get(state, 'common.isReloading', false)) {
        return;
    }

    const isCallStarted = get(state, 'call.isStarted', false);

    return dispatch({
        type: types.RELOAD,
        payload: {
            code,
            isImmediate,
            reason,
            countdown: countDownByError({ code, isImmediate }),
            isCallStarted,
            logout
        }
    });
};

export const updateReloadCountDown = () => ({
    type: types.UPDATE_RELOAD_COUNT_DOWN
});

export const allSettingReceived = () => ({
    type: types.ALL_SETTINGS_RECEIVED
});

export const requestDeviceList = () => ({
    type: types.REQUEST_DEVICE_LIST
});

export const showNotification = () => ({
    type: types.SHOW_NOTIFICATION
});

export const sendLog = (state, error, level = 'error', options = undefined) => {
    let transformedObject = {
        level,
        timestamp: Math.round(new Date() / 1000),
        appName: name,
        appVersion: version,
        companyName: getUserCompanyName(state),
        message: '',
        userName: getUserName(state)
    };

    if (typeof error === 'string') {
        transformedObject = {
            ...transformedObject,
            message: error
        };
    } else {
        transformedObject = {
            ...transformedObject,
            ...error.extra,
            message: error.message,
            stack: error.stack,
        };
    }

    if(options){
        transformedObject.options = options
    }

    return sendRequest(REQUESTS.SEND_LOG, {
        payload: {
            body: transformedObject
        },
        keepKeysCase: true,
    });
};

export default types;
