import styled from 'styled-components';
import PropTypes from 'prop-types';
import transparentize from 'polished/lib/color/transparentize';

import { colors } from '../../../../utils';
import { phraseTypes } from '../../../../utils/words';

const valueStyles = {
    [phraseTypes.TOTAL_PHRASES]: `
        background-color: ${transparentize(0.6, colors.white.basic)};
        border: 1px solid ${colors.gray.mischka};
        color: ${colors.gray.dark};
    `,
    [phraseTypes.SPOTTED_PHRASES]: `
        background-color: ${colors.green.flashygreen};
        border: 1px solid ${colors.green.flashygreen};
        color: ${colors.white.basic};
    `
};

const WordsCount = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    vertical-align: center;

    padding: 3px 8px;

    border-radius: 4px;

    font-weight: bold;

    ${props => valueStyles[props.$type]};
`;

WordsCount.propTypes = {
    $type: PropTypes.oneOf(Object.keys(valueStyles))
};

WordsCount.defaultProps = {
    $type: phraseTypes.SPOTTED_PHRASES
};

export default WordsCount;
