import isNumber from 'lodash/isNumber';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import InfoPopup from './InfoPopup';

import { colors } from '../../../../../utils';
import { captureException } from '../../../../../tracker/raven';
import { commonLogger } from '../../../../../utils/logger';
import { Common } from '../../../../../background/errors';

const colorsConfig = [
    {
        start: 0,
        end: 0,
        color: colors.black.default
    },
    {
        start: 0,
        end: 0.4,
        color: colors.gray.silver_chalice
    },
    {
        start: 0.4,
        end: 0.8,
        color: colors.gray.basic
    },
    {
        start: 0.8,
        end: 0.99,
        color: colors.black.nero
    },
    {
        start: 0.99,
        end: 1,
        color: colors.black.default
    }
];

function findColor(confidence) {
    const colorItem = colorsConfig.find(item => {
        return confidence >= item.start && confidence <= item.end;
    });
    if (!colorItem) {
        const error = new Common.TranscriptValidationError({ confidence });
        captureException(error);
        commonLogger.error(error.message, error);

        return colors.black.default;
    }

    return colorItem.color;
}

const Wrapper = styled.span`
    position: relative;
    color: ${props => (isNumber(props.$confidence) ? findColor(props.$confidence) : '')};

    &:hover ${InfoPopup} {
        opacity: 1;
        transform: translateY(0);
        z-index: 100;
        pointer-events: all;
    }
`;

Wrapper.propTypes = {
    $confidence: PropTypes.number
};

export default Wrapper;
