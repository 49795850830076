import React from 'react';

function EmojiBad1() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" viewBox="0 0 55 46">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <ellipse
                cx="34.3"
                cy="42.6"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="19.3"
                ry="1.6"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M39.613 18.1c0-.9-.3-1.6-.8-2.3-1.4-1.8-2-4-2-6.2V7h-2.3v2.8c0 1.5-1.2 2.8-2.8 2.8h-1.8c-1.5 0-2.8-1.2-2.8-2.8V7h-2.3v14.7c0 6.2-5 11.2-11.2 11.2h-4c-5 0-9.1 3.8-9.6 8.7-.1.6.4 1 .9 1h20.3c8.7 0 15.7-7 15.7-15.7v-4.6c0-.4.2-.7.6-.8 1.2-.6 2.1-1.8 2.1-3.4z"
            />
            <g fill="#D6DAFF" fillRule="nonzero">
                <path d="M.6 41.9c1.4-3.6 4.9-6.2 9-6.2h4c6.2 0 11.2-5 11.2-11.2V21c0 6.2-5 11.2-11.2 11.2h-4c-5 0-9.1 3.8-9.6 8.7 0 .5.2.9.6 1zM25.2 12.2c1 .3 1.8 1 2.4 1.9.5.8 1.4 1.4 2.4 1.4h1.8c1.3 0 2.3-.8 2.7-2 .1-.5.6-.8 1.1-.9.7-.1 1.3.5 1.4 1.2.2 1.8.8 3.5 1.9 5 .1.2.2.3.3.5.3-.5.4-1.1.4-1.7 0-.9-.3-1.6-.8-2.3-1.4-1.8-2-4-2-6.2V6.5h-2.3v2.8c0 1.5-1.2 2.8-2.8 2.8h-1.8c-1.5 0-2.8-1.2-2.8-2.8V6.5h-2.3v5.7h.4z" />
            </g>
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M39.613 18.1c0-.9-.3-1.6-.8-2.3-1.4-1.8-2-4-2-6.2V7h-2.3v2.8c0 1.5-1.2 2.8-2.8 2.8h-1.8c-1.5 0-2.8-1.2-2.8-2.8V7h-2.3v14.7c0 6.2-5 11.2-11.2 11.2h-4c-5 0-9.1 3.8-9.6 8.7-.1.6.4 1 .9 1h20.3c8.7 0 15.7-7 15.7-15.7v-4.6c0-.4.2-.7.6-.8 1.2-.6 2.1-1.8 2.1-3.4z"
            />
            <circle
                cx="25.8"
                cy="3.8"
                r="3.8"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="26.2" cy="4.2" r="1.2" fill="#4550E6" fillRule="nonzero" />
            <circle
                cx="35.8"
                cy="3.8"
                r="3.8"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="36.2" cy="4.2" r="1.2" fill="#4550E6" fillRule="nonzero" />
            <path
                fill="#8D94F2"
                fillRule="nonzero"
                d="M16.817 13c-7.4 0-13.4 5.7-13.8 13-.3 5.5 3.3 10.4 8.8 11.3 6.7 1.1 12.4-4 12.4-10.5h4.1c1.3 0 2.4-1.2 2.1-2.5-1.2-6.4-6.8-11.3-13.6-11.3z"
            />
            <path
                fill="#737DFF"
                fillRule="nonzero"
                d="M16.8 16.9c6.3 0 9.7 4.2 11.4 9.9h.1c1.3 0 2.4-1.2 2.1-2.5C29.2 17.9 23.6 13 16.8 13 9.2 13 3 19.2 3 26.8c0 .6.1 1.2.2 1.9.9-6.7 6.6-11.8 13.6-11.8z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.817 13c-7.4 0-13.4 5.7-13.8 13-.3 5.5 3.3 10.4 8.8 11.3 6.7 1.1 12.4-4 12.4-10.5h4.1c1.3 0 2.4-1.2 2.1-2.5-1.2-6.4-6.8-11.3-13.6-11.3z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 26.4c0-1.5 1.2-2.8 2.8-2.8 1.6 0 2.8 1.2 2.8 2.8 0 2.9-2.4 5.3-5.3 5.3S9 29.3 9 26.4c0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4"
            />
        </g>
    </svg>
}

export default EmojiBad1;
