import React from 'react';

function PhoneForwarded() {
  return <svg width={36} viewBox="0 0 36 36">
        <g>
            <path
                fill="#D6DAFF"
                stroke="#4550E6"
                d="M31.6 25.1c-2.16 0-4.32-.36-6.48-1.08-.54-.18-1.26 0-1.8.36l-3.96 3.96c-5.04-2.52-9.18-6.84-11.88-11.88l3.96-3.96c.54-.54.72-1.26.36-1.8-.54-1.98-.9-4.14-.9-6.3 0-1.08-.72-1.8-1.8-1.8H2.8C1.72 2.6 1 3.32 1 4.4 1 21.32 14.68 35 31.6 35c1.08 0 1.8-.72 1.8-1.8v-6.3c0-1.08-.72-1.8-1.8-1.8z"
            />
            <path
                fill="#4550E6"
                d="M36.294 8.706c.39-.39.386-1.026 0-1.412L29.416.416C28.634-.366 28-.108 28 .996V4.4h-6.199c-.553 0-1.001.45-1.001 1.001V10.6c0 .553.45 1.001 1.001 1.001H28v3.405c0 1.102.633 1.362 1.416.579l6.878-6.878z"
            />
        </g>
    </svg>
}

export default PhoneForwarded;
