import get from 'lodash/get';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserInfoWrapper } from '../../components/Trainer/styled';

class UserInfoFooter extends PureComponent {
    static propTypes = {
        userInfo: PropTypes.string.isRequired
    };

    render() {
        const { userInfo } = this.props;

        if (!userInfo) {
            return null;
        }

        return <UserInfoWrapper>{userInfo}</UserInfoWrapper>;
    }
}

function checkMaxNameLength(stringVal) {
    if (stringVal.length > 27) {
        return `${stringVal.substring(0, 10)}...${stringVal.substring(
            stringVal.length - 10
        )}`;
    }
    return stringVal;
}

function getUserInfo(state) {
    const username = get(state, 'user.userName') || get(state, 'user.email');
    const companyName = get(state, 'user.companyName');
    if (!username && !companyName) {
        return '';
    }
    return `${checkMaxNameLength(username)} @ ${checkMaxNameLength(companyName)}`;
}

const mapStateToProps = state => ({
    userInfo: getUserInfo(state)
});

export default connect(mapStateToProps)(UserInfoFooter);
