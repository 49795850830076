import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToggleButton = styled.div`
    display: flex;

    flex-direction: row;

    justify-content: center;
    align-content: center;
    align-items: center;

    font-size: 12px;

    user-select: none;

    & svg {
        margin-left: 5px;
        margin-top: 1px;

        transform: rotate(${props => (props.$isVisible ? '180deg' : '0')});
        transition: transform 0.2s ease;
    }
`;

ToggleButton.propTypes = {
    $isVisible: PropTypes.bool.isRequired
};

export default ToggleButton;
