import styled from 'styled-components';
import { Row as CommonRow } from '../../../common';

const Row = styled(CommonRow)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
`;

export default Row;
