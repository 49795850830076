import styled from 'styled-components';
import { colors } from '../../../utils';

export const SuggestionsButton = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;

    > .red-dot {
        position: absolute;
        top: -1px;
        right: -1px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${colors.red.light_carmine_pink};
    }
`;
