import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled';

const hidden = '<hidden>';

function Anonymization(props) {
    const { text, anonymized } = props;

    return anonymized ? <Wrapper>{hidden}</Wrapper> : text;
}

Anonymization.propTypes = {
    text: PropTypes.string.isRequired,
    anonymized: PropTypes.bool.isRequired
};

export default Anonymization;
