import {types as callTypes, types} from '../actions/call';
import {types as asrTypes} from '../actions/asr';
import {CallMode} from "../../constants";
import {getDefaultCallMode} from "../selectors";

export const initialState = {
    id: null,
    metaCallID: null,
    lastCallId: null,
    isInitialized: false,
    isStarted: false,
    isRecognitionStarted: false,
    sessionId: null,
    sessionDurationMsec: 0,
    sessionStartOffsetMsec: 0,
    sessionEndOffsetMsec: 0,
    isOnHold: false,
    optInOn: false,
    isOptOut: false,
    removeCall: false,
    callMode: CallMode.UNKNOWN,
};

export default function callReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
        case types.CALL_DETECTION_STARTED:
            return {
                ...state,
                isInitialized: true,
                sessionId: payload.sessionId
            };
        case asrTypes.SET_SESSION_DURATION: {
            const { duration } = payload;
            return {
                ...state,
                sessionDurationMsec: duration
            };
        }
        case types.CALL_STARTED:
            return {
                ...state,
                isStarted: true,
                sessionStartOffsetMsec: payload.sessionStartOffsetMsec,
                id: payload.id,
                metaCallID: payload.metaCallID,
                isOnHold: false,
                optInOn: false
            };
        case types.CALL_ENDED:
            return {
                ...state,
                isStarted: false,
                isRecognitionStarted: false,
                sessionEndOffsetMsec: payload.sessionEndOffsetMsec,
                lastCallId: state.id,
                id: null,
                metaCallID: null,
                isOnHold: false
            };
        case types.RESET_SESSION_DURATION:
            return {
                ...state,
                sessionDurationMsec: 0,
                sessionStartOffsetMsec: 0,
                sessionEndOffsetMsec: 0,
            }
        case types.CALL_HOLDED:
            return {
                ...state,
                isOnHold: true
            };
        case types.CALL_UNHOLDED:
            return {
                ...state,
                isOnHold: false
            };
        case types.OPT_IN: {
            return {
                ...state,
                optInOn: true,
                isOptOut: false,
            };
        }
        case types.OPT_STOP: {
            return {
                ...state,
                optInOn: false,
                isOptOut: false,
            };
        }
        case types.OPT_OUT: {
            return {
                ...state,
                optInOn: false,
                isOptOut: true,
            };
        }
        case callTypes.RESET_CALL_MODE: {
            return {
                ...state,
                optInOn: false,
                isOptOut: false,
                removeCall: false,
                callMode: CallMode.UNKNOWN,
            };
        }

        case types.CALL_SET_MODE: {
            if(state.callMode === CallMode.NONE){
                return state; // it is impossible to make an upgrade from this state
            }

            const mode = payload.mode === CallMode.DEFAULT
                ? getDefaultCallMode(state)
                : payload.mode;

            switch (mode) {
                case CallMode.BOTH:
                    return {
                        ...state,
                        callMode: CallMode.BOTH,
                        optInOn: true,
                        removeCall: false,
                    };
                case CallMode.AGENT:
                    return {
                        ...state,
                        callMode: CallMode.AGENT,
                        optInOn: true,
                        removeCall: false,
                    };
                case CallMode.CUSTOMER:
                    return {
                        ...state,
                        callMode: CallMode.CUSTOMER,
                        optInOn: true,
                        removeCall: false,
                    };
                case CallMode.NONE:
                    return {
                        ...state,
                        callMode: CallMode.NONE,
                        optInOn: false,
                        removeCall: true,
                    };
                default:
                    return {
                        ...state,
                        callMode: CallMode.UNKNOWN,
                    };
            }
        }
        default:
            return state;
    }
}
