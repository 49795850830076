import React from 'react';

function EmojiNormal9() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="37" viewBox="0 0 49 38">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(3.879 4)">
                <circle
                    cx="10.609"
                    cy="10.049"
                    r="8.2"
                    fill="#F8F9FF"
                    fillRule="nonzero"
                    transform="rotate(-14.756 10.609 10.049)"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M9.4 2.1c3.8-.6 7.5 1.8 8.5 5.6 1.1 4.1-1.4 8.2-5.4 9.3-4.1 1.1-8.2-1.4-9.3-5.4-1-3.8 1.1-7.7 4.7-9C4 4 1.7 8.3 2.8 12.4c1.2 4.4 5.6 7 10 5.8 4.4-1.2 7-5.6 5.8-10-1-4.2-5-6.8-9.2-6.1z"
                />
                <circle
                    cx="10.609"
                    cy="10.049"
                    r="8.2"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="rotate(-14.756 10.609 10.049)"
                />
            </g>
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.279 1.6a.8.8 0 0 0-1.1-.6c-.5.1-.8.6-.6 1.1l.5 1.8c.1.5.6.8 1.1.6.5-.1.8-.6.6-1.1l-.5-1.8zM4.079 7c-.4-.3-1-.1-1.2.3-.3.4-.1 1 .3 1.2l1.6.9c.4.3 1 .1 1.2-.3.3-.4.1-1-.3-1.2l-1.6-.9zM2.079 16.5c-.5.1-.8.6-.6 1.1.1.5.6.8 1.1.6l1.8-.5c.5-.1.8-.6.6-1.1a.8.8 0 0 0-1.1-.6l-1.8.5zM7.379 24.7c-.3.4-.1 1 .3 1.2.4.3 1 .1 1.2-.3l.9-1.6c.3-.4.1-1-.3-1.2-.4-.3-1-.1-1.2.3l-.9 1.6z"
            />
            <path
                fill="#FFE500"
                fillRule="nonzero"
                stroke="#45413C"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.979 26.7c.1.5.6.8 1.1.6.5-.1.8-.6.6-1.1l-.5-1.8a.8.8 0 0 0-1.1-.6c-.5.1-.8.6-.6 1.1l.5 1.8zM25.179 21.4c.4.3 1 .1 1.2-.3.3-.4.1-1-.3-1.2l-1.6-.9c-.4-.3-1-.1-1.2.3-.3.4-.1 1 .3 1.2l1.6.9z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M27.179 11.8c.5-.1.8-.6.6-1.1-.2-.5-.6-.8-1.1-.6l-1.8.5c-.5.1-.8.6-.6 1.1.2.5.6.8 1.1.6l1.8-.5zM21.879 3.6c.3-.4.1-1-.3-1.2-.4-.3-1-.1-1.2.3l-.9 1.6c-.3.4-.1 1 .3 1.2.4.3 1 .1 1.2-.3l.9-1.6z"
            />
            <g transform="translate(7.879 10)">
                <ellipse
                    cx="1.739"
                    cy="5.307"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1"
                    ry="1"
                    transform="rotate(-14.756 1.74 5.307)"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.4 5c.4-.1.8.2.8.6 0 1.4-.9 2.6-2.3 3-1.4.4-2.8-.3-3.4-1.5-.2-.3 0-.8.4-.9L9.4 5z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1.2 4.4c-.1-.5.2-1 .6-1.1.5-.1 1 .2 1.1.6"
                />
                <ellipse
                    cx="11.73"
                    cy="2.7"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1"
                    ry="1"
                    transform="rotate(-14.756 11.73 2.7)"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.7 1.6a.8.8 0 0 0-1.1-.6c-.5.1-.8.6-.6 1.1"
                />
            </g>
            <g>
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M48.379 25.4c0-1.7-1.2-3-2.7-3.4.3-2.2-1.4-3.9-3.4-3.9-.7 0-1.4.2-1.9.6.1-2.1-1.5-3.7-3.4-3.7-.9 0-1.8.4-2.4 1-.4-1.5-1.7-2.5-3.3-2.5-1.6 0-2.9 1.1-3.3 2.5-.6-.6-1.5-1-2.4-1-1.9 0-3.5 1.6-3.4 3.7-.6-.4-1.2-.6-1.9-.6-2 0-3.7 1.7-3.4 3.9-1.6.3-2.7 1.7-2.7 3.4s1.3 3.1 2.9 3.4c-.9 2.3.9 4.6 3.2 4.6.7 0 1.4-.2 1.9-.6-.1 2.1 1.5 3.7 3.4 3.7 1.1 0 2-.5 2.6-1.2.5 1.2 1.7 2 3.1 2 1.4 0 2.6-.8 3.1-2 .6.7 1.6 1.2 2.6 1.2 1.9 0 3.5-1.6 3.4-3.7.6.4 1.2.6 1.9.6 2.3 0 4.1-2.3 3.2-4.6 1.6-.3 2.9-1.7 2.9-3.4z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M47.779 23.5c-.7.4-1.1 1.2-1.1 2-.1 1.9-1.7 3.5-3.7 3.5-.3 0-.6 0-1-.1-.5-.1-1.1.2-1.2.7-.4 1.6-1.9 2.7-3.5 2.7-.8 0-1.5-.2-2-.6-.4-.3-1-.2-1.3.2-.7.8-1.7 1.3-2.8 1.3-1.1 0-2.1-.5-2.8-1.3-.3-.4-.9-.5-1.3-.2-.6.4-1.3.6-2 .6-1.6 0-3.1-1.1-3.5-2.7-.1-.5-.7-.8-1.2-.7-.3.1-.6.1-1 .1-2 0-3.6-1.6-3.7-3.5 0-.8-.5-1.6-1.1-2-.4.5-.6 1.2-.6 1.9 0 1.7 1.3 3.1 2.9 3.4-.9 2.3.9 4.6 3.2 4.6.7 0 1.4-.2 1.9-.6-.1 2.1 1.5 3.7 3.4 3.7 1.1 0 2-.5 2.6-1.2.5 1.2 1.7 2 3.1 2 1.4 0 2.6-.8 3.1-2 .6.7 1.6 1.2 2.6 1.2 1.9 0 3.5-1.6 3.4-3.7.6.4 1.2.6 1.9.6 2.3 0 4.1-2.3 3.2-4.6 1.6-.3 2.9-1.7 2.9-3.4.2-.7 0-1.3-.4-1.9z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M48.379 25.4c0-1.7-1.2-3-2.7-3.4.3-2.2-1.4-3.9-3.4-3.9-.7 0-1.4.2-1.9.6.1-2.1-1.5-3.7-3.4-3.7-.9 0-1.8.4-2.4 1-.4-1.5-1.7-2.5-3.3-2.5-1.6 0-2.9 1.1-3.3 2.5-.6-.6-1.5-1-2.4-1-1.9 0-3.5 1.6-3.4 3.7-.6-.4-1.2-.6-1.9-.6-2 0-3.7 1.7-3.4 3.9-1.6.3-2.7 1.7-2.7 3.4s1.3 3.1 2.9 3.4c-.9 2.3.9 4.6 3.2 4.6.7 0 1.4-.2 1.9-.6-.1 2.1 1.5 3.7 3.4 3.7 1.1 0 2-.5 2.6-1.2.5 1.2 1.7 2 3.1 2 1.4 0 2.6-.8 3.1-2 .6.7 1.6 1.2 2.6 1.2 1.9 0 3.5-1.6 3.4-3.7.6.4 1.2.6 1.9.6 2.3 0 4.1-2.3 3.2-4.6 1.6-.3 2.9-1.7 2.9-3.4z"
                />
            </g>
        </g>
    </svg>
}

export default EmojiNormal9;
