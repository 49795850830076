import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Menu(props) {
  return <svg width={props.size} viewBox="0 0 19 12">
        <g
            fill="none"
            fillRule="evenodd"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="2">
            <path d="M17.5 1h-16M17.5 6h-16M17.5 11h-16" />
        </g>
    </svg>
}

Menu.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Menu.defaultProps = {
    size: 19,
    color: colors.black.default
};

export default Menu;
