import styled from 'styled-components';

export default styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    align-content: center;

    padding: 0 12px;

    margin: 0 auto;
`;
