import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Value } from './styled';

class SpeechRate extends PureComponent {
    static propTypes = {
        value: PropTypes.number
    };

    static defaultProps = {
        value: 0
    };

    _getValue(value) {
        return Math.floor(value, 16);
    }

    render() {
        const { value } = this.props;
        return <Value>{this._getValue(value)}</Value>;
    }
}

export default SpeechRate;
