import {all, put, take} from 'redux-saga/effects';
import {requestDeviceList, types as commonTypes} from '../actions/common';
import {types} from '../actions/main';


export function* waitForSettingsAndMainConnection() {
    yield all([
        take(types.DAEMON_VERSION_RECEIVED),
        take(commonTypes.ALL_SETTINGS_RECEIVED)
    ]);

    yield put(requestDeviceList());
}

export default [waitForSettingsAndMainConnection()];
