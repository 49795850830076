import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocalizationManager } from './utils';

const withLocalization = SourceComponent => {
    function LocalizedComponent(props) {
        return (
            <SourceComponent
                {...props}
                localization={getLocalizationManager(props.locale)}
            />
        );
    }

    LocalizedComponent.propTypes = {
        locale: PropTypes.string.isRequired
    };

    const mapStateToProps = state => ({ locale: state.user.locale });
    return connect(mapStateToProps)(LocalizedComponent);
};

export default withLocalization;
