import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, ErrorMessage, ErrorTitle, Button } from './styled';
import { Icon } from '../common';
import { colors } from '../../utils';
import withLocalization from '../../localization/withLocalization';

function NoDeviceFoundBase({ localization, reload }) {
    return (
        <Wrapper>
            <Icon
                name="NoAudioDevice"
                width={150}
                height={150}
                color={colors.blue.basic}
            />
            <ErrorTitle>
                {localization.getText('trainer.noDevicesFound.title')}
            </ErrorTitle>
            <ErrorMessage>
                {localization.getText('trainer.noDevicesFound.message')}
            </ErrorMessage>
            <Button onClick={reload}>
                {localization.getText('trainer.noDevicesFound.button')}
            </Button>
        </Wrapper>
    );
}

NoDeviceFoundBase.propTypes = {
    localization: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
};

export const NoDeviceFound = withLocalization(NoDeviceFoundBase);
