import styled from 'styled-components';

export default styled.div`
    display: flex;

    align-content: center;
    align-items: center;

    height: 25px;

    font-size: 9px;
`;
