/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Team as TeamIcon } from '../common/icons';
import colors from '../../utils/colors';
import withLocalization from '../../localization/withLocalization';

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

const ReloadButton = styled.button`
    font-size: 14px;
    color: #4550e6;
    font-weight: 600;
    font-family: inherit;
    appearance: none;
    border: none;
    background: none;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
`;

const Message = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

const Container = styled.div`
    width: 100%;
    padding: 24px 16px 16px;
    background-color: ${colors.white.basic};
    overflow: hidden;
    border-top: 1px solid ${colors.gray.wild_sand};
`;

export const NoTeamCardBase = props => {
    const { localization, reload, asrConfigErrors } = props;

    if (!asrConfigErrors || !asrConfigErrors.noTeam) {
        return null;
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <TeamIcon style={{ marginRight: 10, flexShrink: 0 }} />
                    <Message>
                        {localization.getText(`trainer.asr_config_errors.no_team`)}
                    </Message>
                </Row>
                <div style={{ textAlign: 'right' }}>
                    <ReloadButton type="button" onClick={reload}>
                        {localization.getText('trainer.asr_config_error_button')}
                    </ReloadButton>
                </div>
            </Container>
        </React.Fragment>
    );
};
NoTeamCardBase.propTypes = {
    localization: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    asrConfigErrors: state.trainer.asrConfigErrors
});

export const NoTeamCard = connect(mapStateToProps)(withLocalization(NoTeamCardBase));
