import styled from 'styled-components';
import { Wrapper as CardWrapper } from '../../common/Card/styled';

const Card = styled(CardWrapper)`
    padding: 16px;
    display: flex;
    flex-direction: row;
    max-height: 105px;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

export default Card;
