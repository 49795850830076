import styled from 'styled-components';
import { colors } from '../../../utils';

export default styled.div`
    background-color: ${colors.blue.persian_blue};
    align-self: center;
    justify-self: center;
    border-radius: 20px;
    padding: 12px;
    margin: 12px 12px 20px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
