/* eslint-disable */
import {get, noop} from 'lodash';
import {proto, proto as vibeProto, spottingConfigToProto} from '../AsrProto';
import {cdLogger, recLogger} from '../../../utils/logger';
import {getAsrEndpoint} from '../../../utils/config';
import {Asr} from '../../errors';
import {captureException} from '../../../tracker/raven';
import {checkForBigerThanInt32, guid, isValidEndpoint} from "../../utils/general";
import {getEnabledASRVendor} from '../../store/selectors';
import {updateQueryStringParameter} from "../../../utils/url";
import {IS_ELECTRON_APP_WITH_RECORDING, IS_LEGACY_LISTENER_MODE, IS_UI_ONLY_MODE} from "../../../config/electron";
import EventEmitter from "eventemitter3";
import {createAsrWsConnection} from "../WsConnection";
import {CHANNEL, EXPLICIT_INTEGRATION_SOURCE, wsConnectionClosingCodes} from "../../constants";

const holdEvents = {
    CALL_HOLD: 'call_hold',
    CALL_UNHOLD: 'call_unhold'
};

const startEvent = 'CALL_START'

export default class AsrConnection extends EventEmitter {
    constructor(name) {
        super();

        this._name = name;
        this._ready = false;
        this._ws = null;
        this._sessionConfig = null;
        this._jwt = null;
        this._logger = cdLogger;
    }

    start(config) {
        if (this._started) {
            const error = new Asr.AlreadyStartedError({
                id: this._id,
                name: this._name
            });
            captureException(error);
            this._logger.error(error.message, error);
            return;
        }

        this._started = true;
        this._id = guid();
        this._logger.log(`${this._prefix()}:: start`);

        const { jwt, ...restConfig } = config;

        if (!jwt) {
            const error = new Asr.JwtRequiredError({
                id: this._id,
                name: this._name
            });
            captureException(error);
            this._logger.error(error.message, error);
            return;
        }

        const agentID = config.userData.userId;

        this._jwt = jwt;
        this._sessionConfig = this.buildSessionConfig(restConfig);
        if (!this._sessionConfig) {
            return;
        }
        const meta = {
            description: 'Vibe connection',
            name: this._name,
            id: this._id
        };
        this._ws = createAsrWsConnection({
            ...this._getEndpoint(restConfig, agentID),
            meta
        });
        this._ws.on('opened', this._baseHandleOpen);
        this._ws.on('closed', this._handleClose);
        this._ws.on('message', data => {
            const { decode, toObject } = proto.RecognizeResponse;
            const decoded = decode(data);
            const msg = toObject(decoded);
            if (msg) {
                if (msg.serviceMessage) {
                    const status =
                        vibeProto.ServiceMessageStatus[msg.serviceMessage.status];
                    this._logger.log('service message:', status, msg.serviceMessage);
                }
                this._handleMessage(msg);
            }
        });
    }

    isOpened() {
        return this._ready;
    }

    processChunk(chunk) {
        return this._send({
            streamingRequest: {
                audioChunkWithMetadata: chunk
            }
        });
    }

    sendMeta(meta = {}) {
        this._send({
            streamingRequest: {
                serviceMessage: {
                    status: proto.ServiceMessage.Status.OK,
                    serviceIsFinalized: false,
                    metainfo: [],
                    callMetainfo: meta
                }
            }
        });
    }

    sendChannelControlRequest(channel, forgetAllData = false, forgetAudioOnly = false){
        const req = {
            channelControl: {
                channel: channel === CHANNEL.MICROPHONE
                    ? proto.Channel.MICROPHONE
                    : proto.Channel.SYSTEM,
                operation: forgetAllData
                    ? proto.ChannelControl.Operation.FORGET_ALL_DATA
                    : (
                        forgetAudioOnly
                            ? proto.ChannelControl.Operation.FORGET_AUDIO
                            : proto.ChannelControl.Operation.FORGET_AUDIO_AND_TRANSCRIPT
                    ),
            }
        }
        this._send(req);
    }

    _prefix() {
        return `(${this._name}:${this._id})`;
    }

    _sendRequest(obj) {
        if (!this._ws || !this._ws.isOpen()) {
            const error = new Asr.CantSendRequestError({
                id: this._id,
                name: this._name
            });
            this._logger.error(error.message, error);
            captureException(error);
            return error;
        }
        this._ws.send(obj);
    }

    _finalize({
                  acdSessionOffsetMsec,
                  asrSessionOffsetMsec,
                  reason = proto.FinalizationReason.CALL_END_NORMAL,
              }) {
        if (!this._ws.isOpen()) {
            // Close socket if it in CONNECTING, CLOSING or CLOSED states
            // close() is idempotent
            const msg = `Vibe:${this._id}; Reason:${proto.FinalizationReason[reason]}`;
            this._ws.close(wsConnectionClosingCodes.DEFAULT, msg);
            const error = new Asr.CantFinalizeError({
                id: this._id,
                name: this._name
            });
            captureException(error);
            this._logger.error(error.message, error);
            return;
        }
        const request = {
            streamingRequest: {
                finalizationToken: {
                    acdSessionOffsetMsec,
                    asrSessionOffsetMsec,
                    reason
                }
            }
        };

        this._send(request);
        this._logger.log(`${this._prefix()}:: finalized`, request);

        this._ws.close(wsConnectionClosingCodes.DEFAULT);
    }

    _baseHandleOpen = () => {
        this._logger.log(`${this._prefix()}:: Socket open!`);
        if (!this._started) {
            this._logger.log(
                `${this._prefix()}:: Connection already closed and discarded!`
            );
            return;
        }

        const jwtConf = {
            authRequest: { mechanismName: proto.MechanismName.JWT, authData: this._jwt }
        };

        this._send(jwtConf);

        if(IS_LEGACY_LISTENER_MODE || IS_ELECTRON_APP_WITH_RECORDING){
            this._send(this._sessionConfig);
        }

        this._logger.log(`${this._prefix()}:: initial config send`, this._sessionConfig);
        this._handleOpen();
        this._ready = true;
        this.emit('started', {
            name: this._name,
            id: this._id
        });
    };

    _handleClose = event => {
        const { code, wasClean, reason } = event;
        const { _id: id, _name: name } = this;

        // TOREVIEW: @yury @evgeniy only create ASR Closed Error if wasClean === false
        if (!wasClean) {
            const error = new Asr.ClosedError({
                id,
                name,
                code,
                reason,
                wasClean
            });

            this._logger.error(error.message, error);
        }

        if (this._ws) {
            this._ws.removeAllListeners();
            this._ws = null;
        }
        this._baseReset();
        this._reset();

        this.emit('stopped', {
            closedByClient: false,
            wasClean,
            code
        });
    };

    stop({
             acdSessionOffsetMsec,
             asrSessionOffsetMsec,
             reason,
             code,
         }) {
        this._baseReset();
        this._reset();
        if (this._ws) {
            this._logger.log(`${this._prefix()}:: stop`);
            this._ws.removeAllListeners();
            // If something goes wrong while connection is closed then we get a weird offset,
            // link to the bug below.
            // https://i2x-gmbh.atlassian.net/browse/DEVELOP-3144
            const acdSessionOffsetMsecCheck = checkForBigerThanInt32(acdSessionOffsetMsec) ? 0 : acdSessionOffsetMsec;
            const asrSessionOffsetMsecCheck = checkForBigerThanInt32(asrSessionOffsetMsec) ? 0 : asrSessionOffsetMsec;
            this._finalize({
                acdSessionOffsetMsec: acdSessionOffsetMsecCheck,
                asrSessionOffsetMsec: asrSessionOffsetMsecCheck,
                reason,
            });
            this._ws = null;
            this.emit('stopped', {
                closedByClient: true,
                wasClean: true,
                code
            });
        } else {
            // TODO: in future this could be OK
            const error = new Asr.CantStopError({
                id: this._id,
                name: this._name
            });
            captureException(error);
            this._logger.error(error.message, error);
        }
    }

    _baseReset() {
        this._sessionConfig = null;
        this._jwt = null;
        this._started = false;
        this._ready = false;
    }

    _getEndpoint(config, agentID = 0) {
        let { host, protocol } = getAsrEndpoint()

        if(isValidEndpoint(config.asr.endpoint)){
            host = config.asr.endpoint
        }

        host = updateQueryStringParameter(host, 'user_id', agentID)

        return {
            protocol,
            host
        };
    }

    getId() {
        return this._id;
    }

    buildSessionConfig({
        sampleRateHertz,
        absoluteSilenceDefinition,
        saveStream,
        systemInfo,
        enableCallMetadataOnly,
        callDetection: {
            integrationMethod,
            explicitIntegrationSource,
            optInGracePeriodMsec,
            explicitPingPeriodMsec,
            appWhitelist
        } = {}
    }) {
        let encoding = (window.hasOpus ? vibeProto.AudioEncoding.OPUS : vibeProto.AudioEncoding.FLAC);
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
            encoding = window.hasOpus ? vibeProto.AudioEncoding.OPUS : vibeProto.AudioEncoding.LINEAR16;
        }
        if(IS_ELECTRON_APP_WITH_RECORDING){
            encoding = vibeProto.AudioEncoding.OPUS;
        }

        return {
            detectionConfig: {
                saveStream,
                sampleRate: sampleRateHertz,
                sessionId: this._id,
                absoluteSilenceDefinition,
                systemInfo,
                encoding,
                integrationMethod,
                explicitIntegrationSource: [EXPLICIT_INTEGRATION_SOURCE.KEYPHRASE_AUDIO_EVENT, EXPLICIT_INTEGRATION_SOURCE.RECORDER_EVENT].includes(explicitIntegrationSource) ? EXPLICIT_INTEGRATION_SOURCE.I2X_MANUAL_CALL_EVENT : explicitIntegrationSource,
                optInGracePeriodMsec,
                explicitPingPeriodMsec,
                appWhitelist,
                enableCallMetadataOnly
            }
        };
    }

    _send(obj) {
        if ('livenessCounter' in window) {
            // livenessCounter is checked from CSharp code
            // The external process checked it periodically.
            // If the counter is unchanged, then this is a sign that UI is stuck.
            // So UI would be reloaded.
            window.livenessCounter++;
        }
        this._sendRequest(obj);
    }

    sendForwardedEvent(uctRequest) {
        cdLogger.debug('forwarded event:', uctRequest);
        this._sendRequest({ streamingRequest: { uctRequest } });
    }

    buildRecognitionConfig({
        languageCode,
        lingwareTopic,
        asr,
        sampleRateHertz,
        systemInfo,
        enableAnonymization,
        enableAsrDebug,
        enableCallMetadataOnly,
        incallRecognitionSessionNumber,
        callId
    }) {
        this._callId = callId;
        let encoding = window.hasOpus ? vibeProto.AudioEncoding.OPUS : vibeProto.AudioEncoding.FLAC;
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
            encoding = window.hasOpus ? vibeProto.AudioEncoding.OPUS : vibeProto.AudioEncoding.LINEAR16;
        }
        if(IS_ELECTRON_APP_WITH_RECORDING){
            encoding = vibeProto.AudioEncoding.OPUS;
        }

        let configError;
        let vendor, vendorConfiguration;

        try {
            [vendor, vendorConfiguration] = getEnabledASRVendor(asr);
            vendorConfiguration.lingwareTopic = lingwareTopic
        } catch (err) {
            configError = err;
            recLogger.error(`Failed to resolve ASR Vendor configuration, raw ASR configuration:${JSON.stringify(asr)}`);
        }

        return {
            initialConfig: {
                configError,
                enableAnonymization,
                enableAsrDebug,
                enableCallMetadataOnly,
                encoding,
                sampleRateHertz,
                asrVendors: { [vendor]: vendorConfiguration },
                maxAlternatives: 5,
                enableInverseNormalization: true,
                enableInterimResults: true,
                enablePhraseSpottingForInterimResults: true,
                languageCode,
                sessionInfo: {
                    source: proto.Source.MULTIPLE_CHANNELS,
                    sourceDescription: this._name,
                    callId,
                    callDetectionSessionId: this.getId(),
                    incallRecognitionSessionNumber
                },
                systemInfo
            }
        };
    }

    sendRecognitionConfig(options) {
        const {
            languageCode,
            lingwareTopic,
            asr,
            sampleRateHertz,
            spottingConfig,
            systemInfo,
            enableAnonymization,
            enableAsrDebug,
            enableCallMetadataOnly,
            incallRecognitionSessionNumber,
            callId
        } = options;

        const recognitionConfig = this.buildRecognitionConfig({
            languageCode,
            lingwareTopic,
            asr,
            sampleRateHertz,
            systemInfo,
            enableAnonymization,
            incallRecognitionSessionNumber,
            enableAsrDebug,
            enableCallMetadataOnly,
            callId
        });
        recLogger.log(
            `(${this._name}:${this._id}):: recoginiton config `,
            recognitionConfig
        );
        if (recognitionConfig.initialConfig.configError) {
            this.emit("asr_vendor_config_error")
            return;
        }
        this._send(recognitionConfig);

        if (!this._ws.isOpen()) {
            const error = new Asr.CantUpdateSpottingConfigError({
                id: this._id,
                name: this._name
            });
            captureException(error);
            recLogger.error(error.message, error);
            return;
        }

        if (!spottingConfig) {
            const error = new Asr.SpottingConfigRequiredError({
                id: this._id,
                name: this._name
            });
            captureException(error);
            recLogger.error(error.message, error);
            return;
        }

        const request = { streamingRequest: { phraseSpottingConfig: spottingConfigToProto(spottingConfig) } };
        this._send(request);
        recLogger.log(`(${this._name}:${this._id}):: spotting config request`, request);
    }

    _handleVoiceMetricsMessage = (voiceMetrics = {}) => {
        const {
            speechRatio,
            loudnessLufs: loudness,
            ourSideIsSpeaking,
            channel
        } = voiceMetrics;
        this.emit('voice_metrics', {
            loudness,
            speechRatio,
            ourSideIsSpeaking,
            channel
        });
    };

    _handleSpeechPaceMessage = (speechPaceMetrics) => {
        const { pace: speechPace, wordsPerMinute } = speechPaceMetrics;
        this.emit('speech_pace', {
            speechPace,
            wordsPerMinute
        });
    }

    _handleAsrResultMessage = asrResult => {
        if (asrResult == null) {
            recLogger.log(`(${this._name}:${this._id}):: empty asr result`);
        }
        this.emit('asr_result', asrResult);
    };

    _handlePhraseSpottingResultMessage = result => {
        this.emit('phrase_spotting_result', result);
    };

    _handleMessage = message => {
        const callDetectionResult = get(message, 'resultsMessage.callDetectionResult');
        const voiceMetrics = get(message, 'resultsMessage.voiceMetrics');
        const speechPaceMetrics = get(message, 'resultsMessage.metrics.speechPaceMetrics');
        const asrResult = get(message, 'resultsMessage.asrResult');
        const phraseSpottingResult = get(message, 'resultsMessage.phraseSpottingResult');
        const serviceMessage = get(message, 'serviceMessage');

        if (callDetectionResult) {
            this.emit('call_detection_result', callDetectionResult);
        } else if (voiceMetrics) {
            this._handleVoiceMetricsMessage(voiceMetrics);
        } else if(speechPaceMetrics) {
            this._handleSpeechPaceMessage(speechPaceMetrics);
        } else if (asrResult) {
            this._handleAsrResultMessage(asrResult);
        } else if (phraseSpottingResult) {
            this._handlePhraseSpottingResultMessage(phraseSpottingResult)
        } else if (serviceMessage) {
            const status = vibeProto.ServiceMessageStatus[serviceMessage.status];

            if (holdEvents[status] != null) {
                this.emit(holdEvents[status], {});
            } else if (status === startEvent && IS_UI_ONLY_MODE) {
                this.emit('call_start', {callID: serviceMessage.callId});
            }
        }
    };

    _reset() {
        recLogger.log(
            `(${this._name}:${this._id}) (callId:${this._callId}):: call ended`
        );
        this._callId = null;
    }

    _handleOpen = noop;
}
