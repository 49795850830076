import styled from 'styled-components';

import { colors } from '../../../../utils';

const Value = styled.span`
    font-size: 32px;
    font-weight: 300;

    text-align: center;

    color: ${colors.blue.madison};

    margin: auto;
`;

export default Value;
