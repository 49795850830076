import {CallEvents} from "../../constants";

export const schema = {
    'type': 'object',
    'properties': {
        'type': {'type': 'string', 'enum': ['start', 'end', 'ping']},
        'source': {'type': 'string', 'enum': ['i2x_manual']}
    },
    "required": ['type'],
    "if": {
        "properties": { "type": { "const": "ping" } },
    },
    "else": {
        "required": ['source']
    },
}

export function parse(event) {
    let callEvent = null;
    const callMode = null;
    const forgetAudioAndTranscript = false;

    if (event.type === 'start') {
        callEvent = CallEvents.START;
    } else if (event.type === 'end') {
        callEvent = CallEvents.END;
    } else if (event.type === 'ping') {
        callEvent = CallEvents.PING;
    }

    return [callEvent, callMode, forgetAudioAndTranscript];
}
