import { createError, withPrefix } from '../utils';

const createAsrError = params =>
    createError({
        ...params,
        message: withPrefix(p => `[${p.name}][${p.id}]:`)(params.message),
        fingerprint: p => [p.name]
    });

const AlreadyStartedError = createAsrError({
    name: 'AlreadyStartedError',
    message: 'Already started'
});
const JwtRequiredError = createAsrError({
    name: 'JwtRequiredError',
    message: 'JWT token is required'
});
const CantSendRequestError = createAsrError({
    name: 'CantSendRequestError',
    message: 'Cannot send request, connection closed'
});
const CantUpdateSpottingConfigError = createAsrError({
    name: 'CantUpdateSpottingConfigError',
    message: 'Cannot update config while socket is closed'
});
const SpottingConfigRequiredError = createAsrError({
    name: 'SpottingConfigRequiredError',
    message: 'Spotting config not specified'
});
const CantFinalizeError = createAsrError({
    name: 'CantFinalizeError',
    message: `Can't finalize not opened connection`
});
const ClosedError = createAsrError({
    name: 'ClosedError',
    message: p =>
        `Closed with error code ${p.code}, reason: ${p.reason}, wasClean: ${p.wasClean}`
});
const CantStopError = createAsrError({
    name: 'CantStopError',
    message: 'Trying to stop already closed connection'
});

export const Asr = {
    AlreadyStartedError,
    JwtRequiredError,
    CantSendRequestError,
    CantUpdateSpottingConfigError,
    SpottingConfigRequiredError,
    CantFinalizeError,
    ClosedError,
    CantStopError
};