import styled from 'styled-components';
import colors from '../../../../utils/colors';

const BonusCount = styled.div`
    vertical-align: center;
    color: ${colors.green.flashygreen};
    margin-right: 8px;
    font-size: 22px;
    font-weight: bold;
`;

export default BonusCount;
