import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../../utils';
import { TopicIcon } from './index';

const Keyword = styled.span`
    flex-shrink: 0;
    display: flex;
    align-items: center;

    max-width: 100%;

    margin: 0 4px 4px 0;
    padding: 5px 10px;

    background-color: ${({ $detected }) =>
        $detected ? colors.green.aquamarine : colors.white.albaster};
    color: ${({ $detected }) => ($detected ? colors.white.basic : colors.black.nero)};
    border: 1px solid
        ${({ $detected }) =>
            $detected ? colors.green.aquamarine : colors.blue.link_water};
    border-radius: 5px;

    font-size: 13px;
    font-weight: 600;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: default;

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > ${TopicIcon} {
        color: ${({ $detected }) =>
            !$detected ? colors.yellow.golden_tanoi : colors.white.basic};
    }
`;

Keyword.propTypes = {
    $detected: PropTypes.bool
};

Keyword.defaultProps = {
    $detected: false
};

export default Keyword;
