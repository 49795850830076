import {CallEvents, CallMode} from "../../constants";

// @docs
// https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/1145536524/Explicit+Call+Detection+Integration+API#I2x-API

export const schema = {
    'type': 'object',
    'properties': {
        'version': {'type': 'string', 'enum': ['1.0']},
    },
    'oneOf': [{
        'not': {
            'required': ['version']
        },
        'then': {
            'properties': {
                'event_type': {'type': 'string', 'enum': ['start', 'end', 'hold', 'unhold', 'change', 'ping']},
                'source': {'type': 'string'},
                'call_id': {'type': 'string'},
                'timestamp': {'type': 'string','format': 'date-time'},
                'allow_call_recording': {'type': 'boolean'}
            },
            'required': ['event_type', 'source', 'timestamp', 'call_id']
        }
    },
        {
            'if': {
                'properties': {'version': {'const': ['1.0']}}
            },
            'then': {
                'properties': {
                    'event_type': {'type': 'string', 'enum': ['start', 'end', 'hold', 'unhold', 'change', 'ping']},
                    'source': {'type': 'string'},
                    'call_id': {'type': 'string'},
                    'timestamp': {'type': 'string','format': 'date-time'},
                    'allow_call_recording': {'type': 'boolean'}
                },
                'required': ['event_type', 'source', 'timestamp', 'call_id', 'version']
            }
        }]
}

export function parse(event) {
    let callEvent = null;
    let callMode = null;
    const forgetAudioAndTranscript = false;

    if (event.allow_call_recording === true) {
        callMode = CallMode.BOTH;
    } else if(event.allow_call_recording === false){
        callMode = CallMode.NONE;
    }

    if (event.event_type === 'start') {
        callEvent = CallEvents.START;
    } else if (event.event_type === 'end') {
        callEvent = CallEvents.END;
    } else if (event.event_type === 'ping') {
        callEvent = CallEvents.PING;
    } else if (event.event_type === 'change') {
        callEvent = CallEvents.CHANGE;
    } else if (event.event_type === 'hold') {
        callEvent = CallEvents.HOLD;
    } else if (event.event_type === 'unhold') {
        callEvent = CallEvents.UNHOLD;
    }

    return [callEvent, callMode, forgetAudioAndTranscript];
}
