import React from 'react';

function EmojiNormal6() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="42" height="46" viewBox="0 0 42 46">
        <g fill="none" fillRule="evenodd" transform="translate(1)">
            <circle cx="20" cy="20.5" r="20" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                d="M20 .5c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm0 37c-10.1 0-18.2-8.2-18.2-18.2C1.8 9.2 10 1.1 20 1.1s18.2 8.2 18.2 18.2S30.1 37.5 20 37.5z"
            />
            <ellipse
                cx="20"
                cy="44.5"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="16"
                ry="1.5"
            />
            <circle
                cx="20"
                cy="20.5"
                r="20"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="32"
                cy="25.5"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <ellipse
                cx="8"
                cy="25.5"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <circle
                cx="9.5"
                cy="19.5"
                r="1"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="30.5"
                cy="19.5"
                r="1"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".7">
                <path
                    fill="#C1C4E6"
                    d="M19.5 35.636c3.818 0 7.023-2.909 7.5-6.69 0-.219-.068-.437-.205-.582-.136-.146-.409-.219-.613-.219a40.782 40.782 0 0 1-13.296 0 .531.531 0 0 0-.613.219c-.137.145-.273.363-.205.581.41 3.782 3.614 6.691 7.432 6.691z"
                />
                <path
                    fill="#8D94F2"
                    d="M19.5 32c-2.182 0-4.16.51-5.523 1.236a7.589 7.589 0 0 0 5.523 2.4c2.182 0 4.16-.945 5.523-2.4C23.659 32.51 21.682 32 19.5 32z"
                />
            </g>
        </g>
    </svg>
}

export default EmojiNormal6;
