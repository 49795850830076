import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UrlContent = styled.a`
    margin-top: 10px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
`;

function Url({ value }) {
  return <UrlContent rel="noopener noreferrer" href={value}>
        {value}
    </UrlContent>
}

Url.propTypes = {
    value: PropTypes.string.isRequired
};

export default Url;
