import * as React from 'react';

function Evening() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={39}>
            <defs>
                <clipPath id="a">
                    <path d="M1908 0v1258H0V0h1908Z" />
                </clipPath>
                <clipPath id="b">
                    <path d="M14.186 0C21.63.024 27.6.687 27.6 1.5c0 .828-6.178 1.5-13.8 1.5C6.178 3 0 2.328 0 1.5S6.178 0 13.8 0Z" />
                </clipPath>
                <clipPath id="c">
                    <path d="M23.4 0c2.1 0 4 1.5 4.5 3.5.8-.9 2-1.4 3.3-1.4 2.6 0 4.9 2.1 4.7 5 .7-.5 1.6-.8 2.6-.8 2.7 0 5 2.3 4.6 5.3 2.1.5 3.7 2.3 3.7 4.6s-1.7 4.3-3.6 4.6c1.2 3.1-1.2 6.3-4.4 6.3-.9 0-1.8-.3-2.6-.8.1 2.9-2.1 5-4.7 5-1.5 0-2.7-.7-3.6-1.7-.8 1.6-2.4 2.7-4.3 2.7s-3.6-1.1-4.3-2.7c-.9 1-2.2 1.7-3.6 1.7-2.6 0-4.9-2.1-4.7-5-.7.5-1.6.8-2.6.8-3.2 0-5.6-3.1-4.4-6.3-2.3-.3-4-2.3-4-4.6s1.6-4.1 3.7-4.6c-.4-3 1.9-5.3 4.6-5.3.9 0 1.8.3 2.6.8-.1-2.9 2.1-5 4.7-5 1.3 0 2.4.6 3.3 1.4.5-2 2.4-3.5 4.5-3.5Z" />
                </clipPath>
                <clipPath id="d">
                    <path d="M46.2 0c.5.7.8 1.6 1 2.5 0 2.3-1.8 4.2-4 4.6 1.2 3.1-1.2 6.3-4.4 6.3-.9 0-1.8-.3-2.6-.8.1 2.9-2.1 5-4.7 5-1.5 0-2.7-.7-3.6-1.7-.8 1.6-2.4 2.7-4.3 2.7s-3.6-1.1-4.3-2.7c-.9 1-2.2 1.7-3.6 1.7-2.6 0-4.9-2.1-4.7-5-.7.5-1.6.8-2.6.8-3.2 0-5.6-3.1-4.4-6.3-2.3-.3-4-2.3-4-4.6C0 1.6.3.7.8 0c1 .6 1.6 1.6 1.6 2.7.1 2.6 2.3 4.8 5 4.8.3 0 .6 0 .9-.1.9-.2 1.9.4 2.2 1.3.7 2 2.6 3.3 4.7 3.3.8 0 1.7-.3 2.4-.7.8-.5 1.8-.3 2.4.3.9.8 2.1 1.4 3.5 1.4s2.6-.5 3.5-1.4c.6-.6 1.6-.7 2.4-.3.7.5 1.5.7 2.4.7 2.1 0 4-1.4 4.7-3.3.3-.9 1.2-1.5 2.2-1.3.3.1.6.1.9.1 2.7 0 4.9-2.2 5-4.8.1-1.1.7-2.1 1.6-2.7Z" />
                </clipPath>
                <clipPath id="e">
                    <path d="M1.2 0c.663 0 1.2.358 1.2.8 0 .442-.537.8-1.2.8C.537 1.6 0 1.242 0 .8 0 .358.537 0 1.2 0Z" />
                </clipPath>
                <clipPath id="f">
                    <path d="M7.215 0c.5 0 .9.5.8 1-.5 1.8-2.1 3.1-4 3.1s-3.6-1.3-4-3.1c-.1-.5.3-1 .8-1h6.4Z" />
                </clipPath>
                <clipPath id="g">
                    <path d="M3.3 0c1.3 0 2.5.4 3.3.9-.8 1-2 1.6-3.3 1.6C2 2.5.8 1.9 0 .9.9.3 2 0 3.3 0Z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="translate(-1286 -748)">
                <g clipPath="url(#b)" transform="translate(1296.2 784)">
                    <path fill="#D6D9FF" d="M0 0h27.6v3H0V0z" />
                </g>
                <g clipPath="url(#c)" transform="translate(1286.7 748.8)">
                    <path fill="#FFF" d="M0 0h46.8v32.3H0V0z" />
                </g>
                <g clipPath="url(#d)" transform="translate(1286.5 762.5)">
                    <path fill="#F8F9FF" d="M0 0h47.2v18.6H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1333.5 765c0-2.3-1.6-4.1-3.7-4.6.4-3-1.9-5.3-4.6-5.3-1 0-1.9.3-2.6.8.2-2.9-2.1-5-4.7-5-1.3 0-2.5.5-3.3 1.4-.5-2-2.4-3.5-4.5-3.5s-4 1.5-4.5 3.5c-.9-.8-2-1.4-3.3-1.4-2.6 0-4.8 2.1-4.7 5-.8-.5-1.7-.8-2.6-.8-2.7 0-5 2.3-4.6 5.3-2.1.5-3.7 2.3-3.7 4.6s1.7 4.3 4 4.6c-1.2 3.2 1.2 6.3 4.4 6.3 1 0 1.9-.3 2.6-.8-.2 2.9 2.1 5 4.7 5 1.4 0 2.7-.7 3.6-1.7.7 1.6 2.4 2.7 4.3 2.7s3.5-1.1 4.3-2.7c.9 1 2.1 1.7 3.6 1.7 2.6 0 4.8-2.1 4.7-5 .8.5 1.7.8 2.6.8 3.2 0 5.6-3.2 4.4-6.3 1.9-.3 3.6-2.3 3.6-4.6Z"
                />
                <g clipPath="url(#e)" transform="translate(1301.7 766.3)">
                    <path fill="#C1C4E6" d="M0 0h2.4v1.6H0V0z" />
                </g>
                <g clipPath="url(#f)" transform="translate(1305.985 769.2)">
                    <path fill="#D6D9FF" d="M0 0h8.03v4.1H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1313.2 769.2c.5 0 .9.5.8 1-.5 1.8-2.1 3.1-4 3.1s-3.6-1.3-4-3.1c-.1-.5.3-1 .8-1h6.4Z"
                />
                <g clipPath="url(#g)" transform="translate(1306.7 770.8)">
                    <path fill="#C1C4E6" d="M0 0h6.6v2.5H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1310 773.3c1.3 0 2.5-.6 3.3-1.6-.8-.5-2-.9-3.3-.9-1.3 0-2.4.3-3.3.9.8 1 2 1.6 3.3 1.6Z"
                />
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1302.5 765.4c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2"
                />
                <g clipPath="url(#e)" transform="translate(1315.9 766.3)">
                    <path fill="#C1C4E6" d="M0 0h2.4v1.6H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1317.5 765.4c0-.7-.6-1.2-1.2-1.2s-1.2.6-1.2 1.2"
                />
            </g>
        </svg>
    );
}
export default Evening;
