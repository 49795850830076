// @flow

import moment from 'moment';

/**
 * Get the ISO formated date
 * @param {Date} [date] defaults to Today's date
 * @returns {String} ISO date
 */
export const getISODate = (date: Date = new Date()) => {
    return moment(date).format('YYYY-MM-DD');
};

export const getRFC3339Date = (date: Date) => {

    return moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
}

const formatClockTime = time => (time < 10 ? `0${time}` : `${time}`);

/**
 * @param {time} is in seconds
 * @param {minutesOnly} bool for format returned
 * @returns {String} formatted time in hours minutes seconds
 */
export const formatTime = (time: number, minutesOnly: boolean = false) => {
    if (time <= 0) return null;

    const totalMins = Math.floor(time / 60);
    const seconds = time % 60;
    let formattedMins = totalMins ? `${formatClockTime(totalMins)}:` : '00:';
    const formattedSeconds = seconds ? formatClockTime(seconds) : '00';

    if (minutesOnly) return `${formattedMins}${formattedSeconds}`;

    const mins = totalMins % 60;
    formattedMins = mins ? `${formatClockTime(mins)}:` : '00:';
    const totalHours = Math.floor(totalMins / 60);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;

    const formattedDays = days ? `${days}:` : '';
    const formattedHours = hours ? `${hours}:` : '';

    return `${formattedDays}${formattedHours}${formattedMins}${formattedSeconds}`;
};
