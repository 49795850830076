import React from 'react';
import PropTypes from 'prop-types';

function MutedMic(props) {
  return <svg width={props.size} viewBox="0 0 96 96">
        <g fill="none" fillRule="evenodd">
            <circle cx="48" cy="48" r="48" fill="#FF506E" />
            <g fillRule="nonzero">
                <path
                    fill="#FFF"
                    stroke="#FFF"
                    strokeWidth=".5"
                    d="M39.75 37.077c0-4.6 4.152-8.327 9.25-8.327s9.25 3.726 9.25 8.327v11.846c0 4.6-4.152 8.327-9.25 8.327s-9.25-3.726-9.25-8.327V37.077z"
                />
                <path
                    fill="#FF506E"
                    d="M49 31c3.9 0 7 2.657 7 6v12c0 3.343-3.1 6-7 6s-7-2.657-7-6V37c0-3.343 3.1-6 7-6z"
                />
                <path
                    fill="#FFF"
                    d="M35.227 41c-.645-.01-1.237.574-1.227 1.21v5.958c0 7.501 5.842 13.64 13.292 14.242v4.207h-4.834a1.2 1.2 0 0 0-1.208 1.192A1.2 1.2 0 0 0 42.458 69h12.084a1.2 1.2 0 0 0 1.208-1.191 1.2 1.2 0 0 0-1.208-1.192h-4.834V62.41C57.158 61.808 63 55.67 63 48.168V42.21c.009-.63-.57-1.208-1.208-1.208-.639 0-1.218.579-1.209 1.208v5.958c0 6.621-5.368 11.914-12.083 11.914S36.417 54.79 36.417 48.168V42.21c.01-.624-.557-1.2-1.19-1.21z"
                />
            </g>
            <rect
                width="4"
                height="51"
                x="45.738"
                y="23.531"
                fill="#FFF"
                stroke="#FF506E"
                rx="1.5"
                transform="rotate(-45 47.738 49.031)"
            />
        </g>
    </svg>
}

MutedMic.propTypes = {
    size: PropTypes.number
};

MutedMic.defaultProps = {
    size: 96
};

export default MutedMic;
