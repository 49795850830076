import { REQUESTS } from '../../constants';
import { objectToCamelCase } from '../../utils';
import { sendRequest } from './common';

export const types = {
    SET_SNOOZE_TIMER_SUCCESS: '@bg/SET_SNOOZE_TIMER_SUCCESS',
    SET_SNOOZE_TIMER_FAIL: '@bg/SET_SNOOZE_TIMER_FAIL',

    SNOOZE_TRAINER: '@bg/SNOOZE_TRAINER',
    SNOOZE_TRAINER_FAIL: '@bg/SNOOZE_TRAINER_FAIL',

    SNOOZE_COUNTDOWN: '@bg/SNOOZE_COUNTDOWN'
};

export const setSnoozeTimer = snoozeTimer => dispatch => {
    const payload = {
        body: objectToCamelCase({ snoozeFor: String(snoozeTimer) })
    };

    const onSuccess = ({ snoozeFor }) => {
        dispatch({
            type: types.SET_SNOOZE_TIMER_SUCCESS,
            payload: { snoozeFor }
        })
    };

    const onError = error => {
        dispatch({ type: types.SET_SNOOZE_TIMER_FAIL, error })
    };

    dispatch(sendRequest(REQUESTS.PATCH_USER_PROFILE, { payload, onSuccess, onError }));
};
