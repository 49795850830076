import React from 'react';
import PropTypes from 'prop-types';

import { Row, Text } from './styled';

function TelephonyID(props) {
    const { value } = props;

    return (
        <div>
            <Row>
                <Text disabled>{value}</Text>
            </Row>
        </div>
    );
}

TelephonyID.propTypes = {
    value: PropTypes.string.isRequired
};

export default TelephonyID;
