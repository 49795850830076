import styled from 'styled-components';
import { colors } from '../../../../utils';

const Input = styled.input`
    width: 100%;
    height: 40px;
    border-radius: 2px;
    background-color: ${colors.white.white_smoke};
    border: none;
    padding: 10px;
    font-size: 15px;
    color: ${colors.blue.madison};
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
`;

export default Input;
