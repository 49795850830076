import styled from 'styled-components';

export default styled.div`
    position: absolute;
    z-index: 20;
    top: 52px;
    right: 0;
    left: 0;

    width: 306px;

    margin: 0 auto;
`;
