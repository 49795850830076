// @flow
import {RECEIVE_CRX_CALL_STARTED} from "../actions/actionTypes/CRX";
import {GET_USER_PROFILE_SUCCESS} from "../actions/actionTypes/user";
import {
    GET_NEW_SUGGESTIONS,
    HIDE_SUGGESTIONS,
    SHOW_SUGGESTIONS,
    TOGGLE_SUGGESTIONS,
    NEW_SUGGESTIONS, SAVE_COUNTER_SUGGESTIONS
} from "../actions/actionTypes/suggestions";
import {types} from "../../background/store/actions/call";
import {captureException} from "../../tracker/raven";

import callSuggestionsMessagesDE from "./config/callSuggestions-de.json"
import callSuggestionsMessagesEN from "./config/callSuggestions-en.json"

const callSuggestionsMessages = {
    "de-DE": callSuggestionsMessagesDE,
    "en-EN": callSuggestionsMessagesEN,
}

export const TYPE_SUPER_WORD = "SuperWord";
export const TYPE_SPEECH_RATE_KEY = "SpeechRate";
export const TYPE_DO_SAY_RATE = "DoSayRate";
export const TYPE_DO_NOT_SAY_RATE = "DoNotSayRate";

const NEGATIVE_KEY = 'negative'
const POSITIVE_KEY = 'positive'

function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

type State = {
    callsAfterLastRequest: number,
    callSuggestionsOpen: boolean,
    isCallSuggestionsNew: boolean,
    suggestionsTimestamp: string,
    languageCode: string,
    suggestions: {
        type: string,
        keyword: string,
        message: string,
        positive: boolean,
        templateIndex: number,
    }[]
}

export const initialState: State = {
    callsAfterLastRequest: 0,
    callSuggestionsOpen: false,
    isCallSuggestionsNew: false,
    suggestionsTimestamp: '',
    languageCode: "de-DE",
    suggestions: [],
}

type Action = {
    type: string,
    payload: Object
};

// eslint-disable-next-line default-param-last
const callSuggestions = (state: State = initialState, action: Action): State => {
    const { type } = action;
    switch (type) {
        case GET_NEW_SUGGESTIONS: {
            const { payload } = action;
            window.dispatch2background({ type: types.GET_NEW_SUGGESTIONS, payload });

            return state;
        }

        case SHOW_SUGGESTIONS: {
            const { suggestions, timestamp } = action.payload;

            return {
                ...state,
                callSuggestionsOpen: true,
                suggestionsTimestamp: timestamp || state.suggestionsTimestamp,
                suggestions: suggestions || state.suggestions,
            };
        }

        case HIDE_SUGGESTIONS: {
            return { ...state, callSuggestionsOpen: false, isCallSuggestionsNew: false };
        }

        case TOGGLE_SUGGESTIONS: {
            return {
                ...state,
                callSuggestionsOpen: !state.callSuggestionsOpen,
                isCallSuggestionsNew : !state.callSuggestionsOpen ? state.isCallSuggestionsNew : false,
            };
        }

        case NEW_SUGGESTIONS: {
            const { payload } = action;
            if(payload.timestamp !== state.suggestionsTimestamp){
                return {
                    ...state,
                    callSuggestionsOpen: !!payload.timestamp,
                    isCallSuggestionsNew: !!payload.timestamp,
                    suggestionsTimestamp: payload.timestamp,
                    suggestions: payload.suggestions.reduce(
                        (accumulator, currentValue) => {
                            try {
                                const push = (messageKeyPath, keyword = '') => {
                                    const messages = callSuggestionsMessages[state.languageCode][messageKeyPath];
                                    const templateIndex = randomInteger(1, messages.length - 1);

                                    accumulator.push({
                                        type: currentValue.type,
                                        keyword,
                                        positive: currentValue.positive,
                                        message: messages[templateIndex],
                                        templateIndex,
                                    })
                                }


                                const keywords = (currentValue.keywords || []).filter(k => !!k)
                                const messageKey = `${currentValue.type}_${currentValue.positive ? POSITIVE_KEY : NEGATIVE_KEY}`

                                if(
                                    (currentValue.type === TYPE_SUPER_WORD && keywords.length === 0)
                                    ||
                                    (currentValue.type === TYPE_DO_SAY_RATE && keywords.length === 0 && !currentValue.positive)
                                    ||
                                    (currentValue.type === TYPE_DO_NOT_SAY_RATE && keywords.length === 0 && !currentValue.positive)
                                    ||
                                    (currentValue.type === TYPE_SPEECH_RATE_KEY && keywords.length === 0 && !currentValue.positive)
                                ){
                                    return accumulator;
                                }

                                if(currentValue.type === TYPE_DO_SAY_RATE && keywords.length === 0 && !currentValue.positive){
                                    return accumulator;
                                }

                                if(currentValue.type === TYPE_SPEECH_RATE_KEY) {
                                    if (!currentValue.positive) {
                                        // in this case keyword will be slow/fast/good
                                        push(`${messageKey}_${keywords[0]}`)
                                    } else {
                                        push(messageKey)
                                    }
                                } else if(keywords.length > 0) {
                                    keywords.forEach(keyword => push(messageKey, keyword))
                                } else {
                                    push(messageKey)
                                }
                            } catch (e) {
                                captureException(e)
                            }

                            return accumulator
                        }, [],
                    ),
                };
            }
            return state;
        }

        case SAVE_COUNTER_SUGGESTIONS: {
            return { ...state, callsAfterLastRequest: action.payload.callsAfterLastRequest };
        }

        case GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                languageCode: action.payload.languageCode
            };
        }

        case RECEIVE_CRX_CALL_STARTED: {
            return {
                ...state,
                callSuggestionsOpen: false
            };
        }

        default: {
            return state;
        }
    }
}

export default callSuggestions
