import styled from 'styled-components';
import { colors } from '../../../../utils';

const SaveButton = styled.button.attrs({
    type: 'button'
})`
    border: none;
    border-radius: 2px;
    background-color: ${colors.blue.madison};
    margin-left: 5px;
    padding: 7px 22px;
    color: ${colors.white.basic};
    cursor: pointer;
`;

export default SaveButton;
