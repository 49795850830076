import styled from 'styled-components';
import { colors } from '../../../utils';

export const Wrapper = styled.div`
    display: flex;

    width: 100vw;
    min-height: 100vh;

    flex-wrap: wrap;
    flex-direction: column;

    justify-content: center;
    padding-bottom: 70px;
    align-content: center;
    align-items: center;

    background-color: ${colors.white.basic};
`;
