import styled from 'styled-components';
import { colors } from '../../../../utils';

export const InputWrapper = styled.div`
    position: relative;
`;

export const InputField = styled.input`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    padding: 8px;
    background: ${colors.white.desert_storm};
    font-size: 15px;
    color: ${colors.blue.madison};
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};

    &:focus {
        border-color: #5d5ff9;
    }
`;

export const ClearButton = styled.button`
    position: absolute;
    right: 10px;
    top: 50%;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
    fill: #cccccc;
    margin-top: -10px;

    &:hover {
        fill: #333333;
    }
`;
