import styled from 'styled-components';

const TranscriptItemWrapper = styled.div`
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export default TranscriptItemWrapper;
