// @flow

import {
    OPEN_BATTLE_CARD,
    CLOSE_BATTLE_CARD,
} from '../actions/actionTypes/battleCard';

type State = {
    isOpen: boolean,
    keyword: string,
    content: string,
    url: string
};

type Action = {
    type: string,
    payload: Object
};

export const initialState: State = {
    isOpen: false,
    keyword: '',
    content: '',
    url: ''
};

const shouldOpenBattleCard = (state, { keyword, content }) => {
    if (state.keyword !== keyword || state.content !== content) {
        return true;
    }

    return state.isOpen;
};

// eslint-disable-next-line default-param-last
const battleCardReducer = (state: State = initialState, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case OPEN_BATTLE_CARD: {
            return {
                ...state,
                isOpen: shouldOpenBattleCard(state, payload),
                keyword: payload.keyword || '',
                content: payload.content || '',
                url: payload.url || ''
            };
        }

        case CLOSE_BATTLE_CARD: {
            return {
                ...state,
                isOpen: false,
            };
        }

        default:
            return state;
    }
};

export default battleCardReducer;
