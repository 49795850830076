import {sendRequest, types as commonTypes } from "./common";
import {REQUESTS} from "../../constants";


export const logUserEvent = (eventType, callId = null, meta = null) => dispatch => {
    if(!eventType){
        return
    }

    const body = {
        eventType,
        timestamp: (new Date()).toISOString()
    }

    if(callId){
        body.callId = callId;
    }

    if(meta){
        body.meta = meta;
    }

    dispatch(
        sendRequest(REQUESTS.LOG_USER_EVENT, {
            payload: {
                body
            },
        })
    );
};

export const logSalesEvent = (eventType, callId = null, meta = null) => dispatch => {
    if(!eventType){
        return
    }

    const body = {
        eventType,
        timestamp: (new Date()).toISOString(),
        ...meta,
    }

    if(callId){
        body.callId = callId;
    }

    const updateStatus = (status) => {
        dispatch({
            type: commonTypes.SALES_TRACKING_STATUS,
            payload: {
                status
            }
        })
    }

    dispatch({
        type: commonTypes.SALES_TRACKING_STATUS,
        payload: {
            status: 'loading'
        }
    })

    dispatch(
        sendRequest(REQUESTS.LOG_SALES_EVENT, {
            payload: {
                body
            },
            onSuccess: () => updateStatus('success'),
            onError: () => updateStatus('error'),
        })
    );
};
