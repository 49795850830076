import {
    HIDE_SAVED,
    SET_LOCALE_SUCCESS,
    UPDATE_NICK_NAME_SUCCESS
} from "../actions/actionTypes/user";

const userMiddleware = store => next => action => {
    const { type } = action;

    switch (type) {
        case SET_LOCALE_SUCCESS:
        case UPDATE_NICK_NAME_SUCCESS:
            if(!store.getState().user.saved){
                setTimeout(() => {
                    store.dispatch({
                        type: HIDE_SAVED
                    })
                }, 2500)
            }
            break;
        default:
    }

    return next(action);
}

export default userMiddleware