import {GET_NEW_SUGGESTIONS, SAVE_COUNTER_SUGGESTIONS} from "../actions/actionTypes/suggestions";
import {getSuggestionCallFrequency} from "../selectors/trainer";
import {RECEIVE_CRX_CALL_ENDED} from "../actions/actionTypes/CRX";

const suggestionCallMiddleware = store => next => action => {
    const { type } = action;
    const state = store.getState();

    switch (type) {
        case RECEIVE_CRX_CALL_ENDED: {
            const suggestionCallFrequency = getSuggestionCallFrequency(state)
            if(suggestionCallFrequency > 0) {
                const { callsAfterLastRequest } = state.callSuggestions
                if(callsAfterLastRequest >= (suggestionCallFrequency - 1)) {
                    store.dispatch({
                        type: SAVE_COUNTER_SUGGESTIONS,
                        payload: {
                            callsAfterLastRequest: 0,
                        }
                    })

                    setTimeout(() => store.dispatch({
                        type: GET_NEW_SUGGESTIONS,
                    }), 1000)
                } else {
                    store.dispatch({
                        type: SAVE_COUNTER_SUGGESTIONS,
                        payload: {
                            callsAfterLastRequest: callsAfterLastRequest + 1,
                        }
                    })
                }
            }
            break;
        }
        default:
    }

    return next(action);
}

export default suggestionCallMiddleware
