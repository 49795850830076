// @flow

export const defaultLocales: Object = {
    en: 'en-US',
    de: 'de-DE'
};

export const languagesHelper: Array<string> = Object.keys(defaultLocales);

export const getShortLanguageCode = (lang: string) =>
    lang && typeof lang === 'string' && lang.split('-', 1)[0];

export const getValidLocale = (code: string) => {
    // Check if string matches the minimum requirements for support
    if (!code || typeof code !== 'string' || code.length < 2) {
        return defaultLocales.de;
    }

    // Check if string matches 'xx' code (i.e 'en' or 'de' from navigator language)
    if (languagesHelper.includes(code) && code.length === 2) {
        return defaultLocales[code];
    }

    // Check if the code short version is listed
    if (defaultLocales[getShortLanguageCode(code)] === undefined) {
        return defaultLocales.de;
    }

    return defaultLocales[getShortLanguageCode(code)];
};
