import styled from 'styled-components';

import { colors } from '../../../utils';

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.blue.madison};
    margin-bottom: 8px;
`;

export default Title;
