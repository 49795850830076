import styled from 'styled-components';
import { colors } from '../../../../../utils';

const ListWrapper = styled.div`
    position: fixed;
    z-index: -1;
    margin-top: 35px;
    left: calc(50% - 140px);
    width: 280px;

    padding: 5px 0 0 0;

    background-color: ${colors.white.basic};

    border: 1px solid ${colors.blue.link_water};
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 ${colors.blue.link_water};

    opacity: 0;

    transition: all 0.25s ease;
`;

export default ListWrapper;
