import styled from 'styled-components';

import { colors } from '../../../utils';

export const ErrorTitle = styled.div`
    padding: 8px 12px;

    max-width: 300px;

    width: 300px;

    color: ${colors.blue.i2x};

    margin-top: 30px;

    font-size: 22px;

    font-weight: 'bold';

    text-align: center;
`;
