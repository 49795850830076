import React from 'react';
import PropTypes from 'prop-types';

import { MarginAuto } from '../../common';
import RatioModelisation from './RatioModelisation';

function SpeechRatio({ value }) {
  return <MarginAuto>
        <RatioModelisation value={value} />
    </MarginAuto>
}

SpeechRatio.propTypes = {
    value: PropTypes.number
};

SpeechRatio.defaultProps = {
    value: 0
};

export default SpeechRatio;
