import { connect } from 'react-redux';
import withLocalization from '../../localization/withLocalization';
import {
    copyToClipboard,
    onTranscriptCopyButtonClick,
    toggleTranscript
} from '../../store/actions/trainer';

import Transcript from '../../components/Trainer/Transcript';

const mapStateToProps = ({ transcript, trainer }) => {
    const {
        displayTranscript = false,
        displayTranscriptConfidence = false,
        displayTranscriptPronunciation = false
    } = trainer.userInterfaceConfig;

    return {
        transcript: transcript.timelineTranscripts,
        isVisible: transcript.isVisible,
        copyButtonEnabled: transcript.copyEnabled,
        whitelist: trainer.whitelist,
        blacklist: trainer.blacklist,
        showMicrophoneTranscriptOnly: !trainer.systemSoundRecognition,
        displayTranscript,
        showConfidence: displayTranscriptConfidence,
        showPronunciation: displayTranscriptPronunciation,
        isCallStarted: trainer.isStarted
    };
};

const mapDispatchToProps = {
    onToggle: toggleTranscript,
    onCopyButtonClick: onTranscriptCopyButtonClick,
    copyToClipboard
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalization(Transcript));
