import styled from 'styled-components';

import { colors } from '../../../../utils';

const ExpandWrapper = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: ${colors.gray.dark};

    margin-top: 12px;

    display: flex;
    flex-direction: row;

    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;

export default ExpandWrapper;
