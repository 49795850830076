import { takeEvery, put, select } from 'redux-saga/effects';

import { types as userTypes } from '../actions/user';
import { types as callTypes } from '../actions/call';
import { getUserASRConfig, updateUserASRConfig } from '../actions/asr';

import { getIsTrainerActive } from '../selectors';

export function* doGetAsrConfig() {
    const isTrainerActive = yield select(getIsTrainerActive);
    if (!isTrainerActive) return;
    yield put(getUserASRConfig());
}

export function* getAsrConfig() {
    yield takeEvery([userTypes.GET_USER_PROFILE_SUCCESS], doGetAsrConfig);
}

export function* doUpdateAsrConfig() {
    yield put(updateUserASRConfig());
}

export function* updateAsrConfig() {
    yield takeEvery([callTypes.CALL_ENDED], doUpdateAsrConfig);
}

export default [getAsrConfig(), updateAsrConfig()];
