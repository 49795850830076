import styled from 'styled-components';
import ColumnWrapper from '../../Column';

import { colors } from '../../../../utils';

const Column = styled(ColumnWrapper)`
    justify-content: center;
    margin-right: ${({ $marginRight }) => $marginRight || 0}px;

    color: ${colors.blue.royal_blue};
    font-weight: 600;
`;

export default Column;
