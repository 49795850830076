import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    position: relative;
    margin-top: 8px;
    width: 300px;
    align-content: center;
    align-items: center;
    box-shadow: 0 2px 6px 0 #cdd3df;
    border-radius: 4px;
    background: white;
    padding: 10px 10px;
`;

export default Wrapper;
