import flattenDeep from 'lodash/flattenDeep';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    TranscriptVirtualScrollWrapper,
    Header,
    ToggleButton,
    Wrapper,
    TranscriptItem,
    TranscriptWrapper,
    TranscriptItemWrapper
} from './styled';

import WordInformation from './WordInformation';

import CopyButton from './CopyButton';
import Icon from '../../common/Icon';
import AutoScroll from '../../common/AutoScroll';
import {
    combineTimelineTranscript,
    getTranscriptString
} from '../../../utils/transcript';
import { IS_ELECTRON_APP, IS_UI_ONLY_MODE } from '../../../config/electron';

const VIRTUAL_SCROLLING_THRESHOLD = 200;

class Transcript extends Component {
    static propTypes = {
        transcript: PropTypes.array,
        onToggle: PropTypes.func.isRequired,
        isVisible: PropTypes.bool,
        localization: PropTypes.object.isRequired,
        whitelist: PropTypes.array.isRequired,
        blacklist: PropTypes.array.isRequired,
        copyButtonEnabled: PropTypes.bool.isRequired,
        onCopyButtonClick: PropTypes.func.isRequired,
        showMicrophoneTranscriptOnly: PropTypes.bool.isRequired,
        copyToClipboard: PropTypes.func.isRequired,
        showConfidence: PropTypes.bool,
        showPronunciation: PropTypes.bool,
        displayTranscript: PropTypes.bool,
        isCallStarted: PropTypes.bool.isRequired
    };

    static defaultProps = {
        transcript: [],
        showConfidence: false,
        showPronunciation: false,
        displayTranscript: false,
        isVisible: false
    };

    virtualScrollSizesCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 50
    });

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.isCallStarted !== this.props.isCallStarted ||
            nextProps.isVisible !== this.props.isVisible ||
            nextProps.transcript.length !== this.props.transcript.length ||
            nextProps.copyButtonEnabled !== this.props.copyButtonEnabled ||
            nextProps.displayTranscript !== this.props.displayTranscript
        );
    }

    _copyText = timeTranscript => {
        const {
            copyToClipboard,
            copyButtonEnabled,
            onCopyButtonClick,
            localization
        } = this.props;

        if (!copyButtonEnabled) return;

        onCopyButtonClick();
        copyToClipboard(getTranscriptString(timeTranscript, localization));
    };

    _toggleOpen = () => {
        const { isVisible, onToggle } = this.props;

        onToggle(!isVisible);
    };

    _renderToggleButton = () => {
        const { isVisible } = this.props;

        return (
            <ToggleButton $isVisible={isVisible}>
                <Icon name="Triangle" size={10} />
            </ToggleButton>
        );
    };

    _renderHeader = () => {
        const { localization, isVisible } = this.props;

        return (
            <Header
                onClick={this._toggleOpen}
                $rounded={IS_UI_ONLY_MODE && !IS_ELECTRON_APP}>
                {isVisible
                    ? localization.getText('trainer.transcript.expand_button.hide')
                    : localization.getText('trainer.transcript.expand_button.show')}
                {this._renderToggleButton()}
            </Header>
        );
    };

    _renderWord = (word, key) => {
        const { showConfidence, showPronunciation, localization } = this.props;

        return (
            <WordInformation
                key={key}
                pronunciation={word.pronunciation}
                confidence={word.confidence}
                showConfidence={showConfidence}
                showPronunciation={showPronunciation}
                localization={localization}
                word={word}
            />
        );
    };

    _renderDetailedTranscript = parsedTranscript => {
        return flattenDeep(
            parsedTranscript.map((list, idx) => {
                return list.render.map((word, i) => {
                    return this._renderWord(word, `${list.text}_${idx}_${i}`);
                });
            })
        );
    };

    _renderTranscriptItems = timeTranscript => {
        return timeTranscript.map(item => {
            const detectedTranscript = this._renderDetailedTranscript(item.transcript);
            return (
                <TranscriptItem
                    className={item.type}
                    key={`${item.type}_${item.startOffset}`}>
                    {detectedTranscript}
                </TranscriptItem>
            );
        });
    };

    _renderCompleteTranscript = timeTranscript => {
        const { isVisible, showMicrophoneTranscriptOnly, isCallStarted } = this.props;

        if (!isVisible) return null;

        return (
            <AutoScroll startAtBottom={isCallStarted}>
                <TranscriptWrapper
                    $showMicrophoneTranscriptOnly={showMicrophoneTranscriptOnly}>
                    {this._renderTranscriptItems(timeTranscript)}
                </TranscriptWrapper>
            </AutoScroll>
        );
    };

    _clearLastTranscriptSizesCache(transcriptLength, rowCount) {
        for (let i = 1; i <= rowCount; i++) {
            this.virtualScrollSizesCache.clear(transcriptLength - i, 0);
        }
    }

    _renderRow(timeTranscript, { index, key, parent, style }) {
        const item = timeTranscript[index];

        const detectedTranscript = this._renderDetailedTranscript(item.transcript);
        return (
            <CellMeasurer
                key={key}
                cache={this.virtualScrollSizesCache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}>
                <TranscriptItemWrapper style={{ ...style }}>
                    <TranscriptItem
                        className={item.type}
                        key={`${item.type}_${item.startOffset}`}>
                        {detectedTranscript}
                    </TranscriptItem>
                </TranscriptItemWrapper>
            </CellMeasurer>
        );
    }

    _renderVirtualScrollTranscript = timeTranscript => {
        const { isVisible, showMicrophoneTranscriptOnly, isCallStarted } = this.props;

        if (!isVisible) return null;

        this._clearLastTranscriptSizesCache(timeTranscript.length, 3);

        return (
            <TranscriptVirtualScrollWrapper
                $showMicrophoneTranscriptOnly={showMicrophoneTranscriptOnly}>
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <List
                                width={width}
                                height={height}
                                deferredMeasurementCache={this.virtualScrollSizesCache}
                                rowHeight={this.virtualScrollSizesCache.rowHeight}
                                rowRenderer={data => {
                                    return this._renderRow(timeTranscript, data);
                                }}
                                rowCount={timeTranscript.length}
                                scrollToIndex={
                                    isCallStarted ? timeTranscript.length - 1 : 0
                                }
                                scrollToAlignment="end"
                            />
                        );
                    }}
                </AutoSizer>
            </TranscriptVirtualScrollWrapper>
        );
    };

    _renderTranscriptPopup = () => {
        const {
            isVisible,
            localization,
            copyButtonEnabled,
            transcript,
            whitelist,
            blacklist
        } = this.props;

        const timeTranscript = combineTimelineTranscript(
            transcript,
            whitelist,
            blacklist
        );

        return (
            <Wrapper
                $isVisible={isVisible}
                $rounded={IS_UI_ONLY_MODE && !IS_ELECTRON_APP}>
                {isVisible && (
                    <CopyButton
                        onCopy={() => this._copyText(timeTranscript)}
                        enabled={copyButtonEnabled}
                        localization={localization}
                    />
                )}
                {transcript.length < VIRTUAL_SCROLLING_THRESHOLD
                    ? this._renderCompleteTranscript(timeTranscript)
                    : this._renderVirtualScrollTranscript(timeTranscript)}
            </Wrapper>
        );
    };

    render() {
        const { displayTranscript } = this.props;

        if (!displayTranscript) return null;

        return (
            <Fragment>
                {this._renderHeader()}
                {this._renderTranscriptPopup()}
            </Fragment>
        );
    }
}

export default Transcript;
