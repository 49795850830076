export const types = {
    DEVICE_CONNECTION_OPENED: '@bg/DEVICE_CONNECTION_OPENED',
    DEVICE_CONNECTION_CLOSED: '@bg/DEVICE_CONNECTION_CLOSED',
    RECORDING_INFO_RECEIVED: '@bg/RECORDING_INFO_RECEIVED',
    AUDIO_CHUNK_RECEIVED: '@bg/AUDIO_CHUNK_RECEIVED'
};

export const audioChunkReceived = audioChunk => ({
    type: types.AUDIO_CHUNK_RECEIVED,
    payload: { audioChunk }
});

export const recordingInfoReceived = recordingInfo => ({
    type: types.RECORDING_INFO_RECEIVED,
    payload: { recordingInfo }
});

export const deviceConnectionOpened = port => ({
    type: types.DEVICE_CONNECTION_OPENED,
    payload: { port }
});

export const deviceConnectionClosed = ({ wasClean }) => ({
    type: types.DEVICE_CONNECTION_CLOSED,
    payload: { wasClean }
});
