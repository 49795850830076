import * as React from 'react';

function Afternoon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={46} height={47}>
            <defs>
                <clipPath id="a">
                    <path d="M1908 0v1258H0V0h1908Z" />
                </clipPath>
                <clipPath id="b">
                    <path d="M11.852 0C18.04.026 23 .688 23 1.5 23 2.328 17.851 3 11.5 3S0 2.328 0 1.5 5.149 0 11.5 0Z" />
                </clipPath>
                <clipPath id="c">
                    <path d="M13.5 0C20.956 0 27 6.044 27 13.5S20.956 27 13.5 27 0 20.956 0 13.5 6.044 0 13.5 0Z" />
                </clipPath>
                <clipPath id="d">
                    <path d="M14.8 0C21.6.6 27 6.4 27 13.4c0 7.5-6 13.5-13.5 13.5S0 20.9 0 13.4C0 6.4 5.3.6 12.2 0 5.9.6 1 5.9 1 12.4c0 6.9 5.6 12.5 12.5 12.5S26 19.3 26 12.4C26 6 21.1.6 14.8 0Z" />
                </clipPath>
                <clipPath id="e">
                    <path d="M1.5 0C2.3 0 3 .7 3 1.5v3C3 5.3 2.3 6 1.5 6S0 5.3 0 4.5v-3C0 .7.7 0 1.5 0Z" />
                </clipPath>
                <clipPath id="f">
                    <path d="M.45.45c.6-.6 1.5-.6 2.1 0l2.1 2.1c.6.6.6 1.5 0 2.1-.6.6-1.5.6-2.1 0l-2.1-2.1c-.6-.6-.6-1.5 0-2.1Z" />
                </clipPath>
                <clipPath id="g">
                    <path d="M4.5 0C5.3 0 6 .7 6 1.5S5.3 3 4.5 3h-3C.7 3 0 2.3 0 1.5S.7 0 1.5 0Z" />
                </clipPath>
                <clipPath id="h">
                    <path d="M2.55.45c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-2.1 2.1c-.6.6-1.5.6-2.1 0-.6-.6-.6-1.5 0-2.1Z" />
                </clipPath>
                <clipPath id="i">
                    <path d="M1.5 0C2.328 0 3 .448 3 1s-.672 1-1.5 1S0 1.552 0 1s.672-1 1.5-1Z" />
                </clipPath>
                <clipPath id="j">
                    <path d="M8.644 0c.7 0 1.1.6 1 1.3-.6 2.2-2.5 3.7-4.8 3.7s-4.3-1.6-4.8-3.7c-.2-.6.3-1.3 1-1.3h7.6Z" />
                </clipPath>
                <clipPath id="k">
                    <path d="M3.9 0c1.5 0 2.9.4 3.9 1.1C6.9 2.2 5.5 3 3.9 3S.9 2.2 0 1.1C1 .4 2.4 0 3.9 0Z" />
                </clipPath>
                <clipPath id="l">
                    <path d="M4 0c2.21 0 4 .448 4 1S6.21 2 4 2s-4-.448-4-1 1.79-1 4-1Z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="translate(-1715 -455)">
                <g clipPath="url(#b)" transform="translate(1726.5 499)">
                    <path fill="#D6D9FF" d="M0 0h23v3H0V0z" />
                </g>
                <g clipPath="url(#c)" transform="translate(1724.5 464.5)">
                    <path fill="#F8F9FF" d="M0 0h27v27H0V0z" />
                </g>
                <g clipPath="url(#d)" transform="translate(1724.5 464.6)">
                    <path fill="#FFF" d="M0 0h27v26.9H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1738 491.5c7.456 0 13.5-6.044 13.5-13.5s-6.044-13.5-13.5-13.5-13.5 6.044-13.5 13.5 6.044 13.5 13.5 13.5Z"
                />
                <g clipPath="url(#e)" transform="translate(1736.5 455.5)">
                    <path fill="#D6D9FF" d="M0 0h3v6H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1739.5 457c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3Z"
                />
                <g clipPath="url(#f)" transform="translate(1721.65 461.65)">
                    <path fill="#D6D9FF" d="M0 0h5.1v5.1H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1724.2 462.1c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l2.1 2.1c.6.6 1.5.6 2.1 0 .6-.6.6-1.5 0-2.1l-2.1-2.1Z"
                />
                <g clipPath="url(#g)" transform="translate(1715.5 476.5)">
                    <path fill="#D6D9FF" d="M0 0h6v3H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1717 476.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h3c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-3Z"
                />
                <g clipPath="url(#h)" transform="translate(1721.65 489.25)">
                    <path fill="#D6D9FF" d="M0 0h5.1v5.1H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1722.1 491.8c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2.1 0l2.1-2.1c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0l-2.1 2.1Z"
                />
                <g clipPath="url(#e)" transform="translate(1736.5 494.5)">
                    <path fill="#D6D9FF" d="M0 0h3v6H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1736.5 499c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3Z"
                />
                <g clipPath="url(#f)" transform="translate(1749.25 489.25)">
                    <path fill="#D6D9FF" d="M0 0h5.1v5.1H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1751.8 493.9c.6.6 1.5.6 2.1 0 .6-.6.6-1.5 0-2.1l-2.1-2.1c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l2.1 2.1Z"
                />
                <g clipPath="url(#g)" transform="translate(1754.5 476.5)">
                    <path fill="#D6D9FF" d="M0 0h6v3H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1759 479.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-3c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h3Z"
                />
                <g clipPath="url(#h)" transform="translate(1749.25 461.65)">
                    <path fill="#D6D9FF" d="M0 0h5.1v5.1H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1753.9 464.2c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0l-2.1 2.1c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2.1 0l2.1-2.1Z"
                />
                <g clipPath="url(#i)" transform="translate(1728 480)">
                    <path fill="#D9DCFF" d="M0 0h3v2H0V0z" />
                </g>
                <g clipPath="url(#j)" transform="translate(1733.256 483.5)">
                    <path fill="#F8F9FF" d="M0 0h9.66v5H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1741.9 483.5c.7 0 1.1.6 1 1.3-.6 2.2-2.5 3.7-4.8 3.7s-4.3-1.6-4.8-3.7c-.2-.6.3-1.3 1-1.3h7.6Z"
                />
                <g clipPath="url(#k)" transform="translate(1734.1 485.5)">
                    <path fill="#D9DCFF" d="M0 0h7.8v3H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1738 488.5c1.6 0 3-.8 3.9-1.9-1-.7-2.4-1.1-3.9-1.1s-2.9.4-3.9 1.1c.9 1.1 2.3 1.9 3.9 1.9ZM1729 479c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5"
                />
                <g clipPath="url(#i)" transform="translate(1745 480)">
                    <path fill="#D9DCFF" d="M0 0h3v2H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1747 479c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5"
                />
                <g clipPath="url(#l)" transform="translate(1734 466.5)">
                    <path fill="#D6D9FF" d="M0 0h8v2H0V0z" />
                </g>
            </g>
        </svg>
    );
}
export default Afternoon;
