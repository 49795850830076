/* eslint-disable react/no-unused-prop-types */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';

import { Wrapper, InfoPopup, SoundWrapper, Label } from './styled';
import Detection from '../styled/Detection';
import Anonymization from '../Anonymization';

const SILENCE = 'SIL';
const MIC_RESULTS = 'micResults';
const POPUP_THRESHOLD_LEFT = 10;
const POPUP_THRESHOLD_TOP = 20;

class WordInformation extends Component {
    static propTypes = {
        confidence: PropTypes.number,
        pronunciation: PropTypes.array,
        showConfidence: PropTypes.bool.isRequired,
        showPronunciation: PropTypes.bool.isRequired,
        localization: PropTypes.object.isRequired,
        word: PropTypes.object.isRequired
    };

    static defaultProps = {
        confidence: null,
        pronunciation: null
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (
            (!this.state.popupRendered && nextState.popupRendered) ||
            (!this.state.popup && nextState.popup) ||
            (this.state.leftAlignment && !nextState.leftAlignment) ||
            (this.state.topAlignment && !nextState.topAlignment)
        );
    }

    state = {
        popupRendered: false,
        leftAlignment: true,
        topAlignment: true,
        popup: null
    };

    componentDidUpdate() {
        this._setPopupPosition();
    }

    _setPopupPosition() {
        const { popup } = this.state;
        if (!popup || !this.state.leftAlignment) return;

        const popupOffsetLeft = popup.offsetParent.offsetLeft - popup.offsetLeft;
        const availableSpaceLeft = window.innerWidth - popupOffsetLeft;

        const popupOffsetTop = popup.offsetParent.offsetTop + popup.offsetTop;
        const availableSpaceTop =
            popup.parentElement.parentElement.parentElement.scrollHeight - popupOffsetTop;

        this.setState({
            leftAlignment: availableSpaceLeft > popup.clientWidth + POPUP_THRESHOLD_LEFT,
            topAlignment: availableSpaceTop > popup.clientHeight + POPUP_THRESHOLD_TOP
        });
    }

    _setRenderPopup = () => {
        this.setState({ popupRendered: true });
    };

    _shouldRenderConfidence() {
        const { showConfidence, confidence } = this.props;
        return showConfidence && isNumber(confidence);
    }

    _shouldRenderPronunciation() {
        const { showPronunciation, pronunciation, word } = this.props;
        return !word.anonymized && showPronunciation && pronunciation;
    }

    _renderConfidence() {
        const { confidence, localization } = this.props;

        return (
            <div>
                <Label>{localization.getText('trainer.transcript.confidence')}:</Label>
                {` ${Math.round(confidence * 100)}%`}
            </div>
        );
    }

    _renderPronunciation() {
        const { pronunciation, localization } = this.props;

        if (!pronunciation.length) return null;

        const sounds = pronunciation
            .filter(item => item.sound !== SILENCE)
            .map((item, i) => {
                const key = `${item.confidence}_${i}`;
                return (
                    <SoundWrapper key={key} $confidence={item.confidence}>
                        {item.sound}
                    </SoundWrapper>
                );
            });

        return (
            <Fragment>
                <Label>{localization.getText('trainer.transcript.pronunciation')}</Label>
                <Label>: /</Label>
                {sounds}
                <Label>/</Label>
            </Fragment>
        );
    }

    _renderDetection() {
        const { word } = this.props;

        return word.source === MIC_RESULTS ? (
            <Detection $text={word.text} $type={word.spotting}>
                <Anonymization text={word.text} anonymized={word.anonymized} />
            </Detection>
        ) : (
            <Anonymization text={word.text} anonymized={word.anonymized} />
        );
    }

    _renderPopup = (isConfidenceShown, isPronunciationShown) => {
        const { confidence } = this.props;
        if (!confidence) return null;

        return this.state.popupRendered ? (
            <InfoPopup
                ref={ref => {
                    this.setState({ popup: ref });
                }}
                $left={this.state.leftAlignment}
                $top={this.state.topAlignment}>
                {isConfidenceShown ? this._renderConfidence() : null}
                {isPronunciationShown ? this._renderPronunciation() : null}
            </InfoPopup>
        ) : null;
    };

    render() {
        const { confidence } = this.props;

        const isConfidenceShown = this._shouldRenderConfidence();
        const isPronunciationShown = this._shouldRenderPronunciation();

        if (!isConfidenceShown && !isPronunciationShown) return this._renderDetection();

        return (
            <Wrapper
                $confidence={isConfidenceShown ? confidence : null}
                onMouseEnter={this._setRenderPopup}>
                {this._renderDetection()}
                {this._renderPopup(isConfidenceShown, isPronunciationShown)}
            </Wrapper>
        );
    }
}

export default WordInformation;
