import styled from 'styled-components';

import { colors } from '../../../utils';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    justify-content: flex-start;
    align-content: center;
    align-items: center;

    position: relative;
    background-color: ${colors.gray.wild_sand};
`;

export default Wrapper;
