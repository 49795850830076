import Polyglot from 'node-polyglot';

class LocalizationManager extends Polyglot {
    getText(key, options = false) {
        if (options) {
            return this.t(key, options);
        }
        return this.t(key, { _: '%{key}', key });
    }

    getItems(key, options = false) {
        if (options) {
            return this.t(key, options);
        }
        return this.phrases[key];
    }
}

export default LocalizationManager;
