import { createError } from '../utils';

const AlreadyStartedError = createError({
    name: 'AlreadyStartedError',
    message: 'Audio system info connection already started'
});

export const SysInfoConn = {
    AlreadyStartedError
};
