import { types } from '../actions/user';

export const initialState = {
    isScoreLeader: false,
    userScore: 0,
    userScoreError: null,
    profile: null,
};

function setScores(payload) {
    const { score, maxScore, minScore, rank } = payload;
    const scoresInterval = maxScore - minScore
    const userScoreOffset = score - minScore;

    return {
        rank,
        score,
        percentage: (scoresInterval ? userScoreOffset / scoresInterval : 1) * 100,
    };
}


export default function userReducer(state = initialState, { type, payload = {}, error } = {}) {
    switch (type) {
        case types.SET_USER_SCORES_SUCCESS:
            return { ...state, userScore: setScores(payload), userScoreError: null };
        case types.SET_USER_SCORES_FAIL:
            return { ...state, userScoreError: error };
        case types.GET_USER_PROFILE_SUCCESS:
            return { ...state, profile: { ...payload } };
        default:
            return state;
    }
}
