import styled from 'styled-components';

export const ErrorMessage = styled.div`
    border-radius: 2px;

    padding: 8px 12px;

    max-width: 300px;

    width: 250px;

    font-size: 14px;

    text-align: center;
`;
