import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as IconList from './icons';

class Icon extends Component {
    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        name: '',
        className: ''
    };

    _getIcon() {
        const IconComponent = IconList[this.props.name];
        return <IconComponent {...this.props} />;
    }

    render() {
        return this._getIcon();
    }
}

export default Icon;
