import {clearHistory, types as asrTypes} from '../actions/asr';
import {
    callNotification,
    changeCallMode,
    getNewSuggestions,
    resetCallMode,
    saveConversion,
    stopCallDetection,
    types as callTypes,
} from '../actions/call';
import {
    buildRecognitionConfig,
    getAppConfig,
    getAsrConfig,
    getCallDurationMsec, getCallId,
    getCallMetadataOnlyFlag,
    getCallTranscriptOnlyFlag,
    getDefaultCallMode,
    getEnableRecorderFlag,
    getExplicitIntegrationSource,
    getIsTrainerEnabled,
    getLastCallId,
    getRealtimeOnlyCallsPercent,
    getRecognitionForgetStreamFlag,
    getRemoveCall,
    getSafeSystemInfo,
    getSessionDurationMsec,
    getToken, getUnfinishedCallDurationMsec,
    getUserData,
    isCallDetectorInitialized,
    isCallHolded,
    isCallStarted,
    isRealTimeCall,
} from '../selectors';
import {
    CALL_NOTIFICATION_DURATION, CALL_NOTIFICATION_TIMOUT,
    CallMode, EXPLICIT_INTEGRATION_SOURCE,
    EXPLICIT_MODE_RELOAD_TIMEOUT,
    MAX_CALL_DETECTION_SESSION_DURATION_FOR_KEYPHRASE_DETECTION,
    SAMPLE_RATE_HERTZ, USER_EVENT_TYPE,
    wsConnectionClosingCodes
} from '../../constants';
import {proto} from '../../services/AsrProto';
import {reload, types as commonTypes} from '../actions/common';
import {errorCodes} from '../../../utils';
import {checkForBigerThanInt32} from '../../utils/general';
import {types as mainTypes} from '../actions/main';
import AsrConnectionManager from '../../services/AsrConnectionManager';
import {types as configTypes, updateRecordCall} from "../actions/config";
import {
    disableRecording,
    enableRecording, IS_ELECTRON_APP,
    IS_ELECTRON_APP_WITH_RECORDING,
    IS_LEGACY_LISTENER_MODE,
    IS_UI_ONLY_MODE
} from "../../../config/electron";
import {captureWarnMessage} from "../../../tracker/raven";
import {isKeyphraseIntegrationMode} from "../../utils/asr";
import {proto as listenerProto} from "../../services/ListenerProto";
import {types as deviceTypes} from "../actions/device";
import {logUserEvent} from "../actions/eventLogger";

const { CALL_DETECTION_STOPPED, RECOGNITION_CONNECTION_ERROR } = errorCodes.background;

// TODO: Hack to keep UI from reloading at snooze state
// see also src/background/services/asr/AsrConnection.js

// -------------------------
// This whole hack needs to be deleted, need to rethink how we keep
// buddy alive, the livenessCounter is not a ood approach.

let _intervalHack;
let _intervalPing;

const imAlive = {
    start: () => {
        _intervalHack = setInterval(() => {
            if ('livenessCounter' in window) {
                window.livenessCounter++;
            }
        }, 3000);
    },
    end: () => {
        clearInterval(_intervalHack);
    }
};

const ping = {
    start: cm => {
        _intervalPing = setInterval(() => {
            cm.sendPing();
        }, 3000);
    },
    stop: () => {
        clearInterval(_intervalPing);
    }
};

// ----------------------

export default store => {
    const { dispatch, getState } = store;
    const callManager = new AsrConnectionManager({ dispatch });

    return next => action => {
        const result = next(action);
        const { type, payload } = action;
        const state = getState();

        function startCallDetection() {
            if(!getIsTrainerEnabled(state)) { // if sales only mode
                console.info('TRAINER DISABLED')
                return
            }

            callManager.start({
                // sample rate is guarantied by listener
                sampleRateHertz: SAMPLE_RATE_HERTZ,
                systemInfo: getSafeSystemInfo(state),
                userData: getUserData(state),
                jwt: getToken(state),
                ...getAsrConfig(state),
                ...getAppConfig(state)
            }, buildRecognitionConfig(state));
        }

        function endCall({ reason, code = null }) {
            const sessionDurationMsec = getSessionDurationMsec(state);
            const callDurationMsec = getCallDurationMsec(state);

            const realTimeCall = isRealTimeCall(state);
            const isMetadataOnlyCall = getCallMetadataOnlyFlag(state);
            const isTranscriptOnlyCall = getCallTranscriptOnlyFlag(state);
            const isRecognitionForgetStream = getRecognitionForgetStreamFlag(state)

            if(realTimeCall){
                callManager.setCallMode(CallMode.META);
            } else if (isRecognitionForgetStream || getRemoveCall(state) || isMetadataOnlyCall) {
                callManager.setCallMode(CallMode.NONE);
            } else if (isTranscriptOnlyCall) {
                callManager.sendForgetAudio();
            }

            callManager.stop({
                acdSessionOffsetMsec: checkForBigerThanInt32(sessionDurationMsec) ? 0 : sessionDurationMsec,
                asrSessionOffsetMsec: checkForBigerThanInt32(callDurationMsec) ? 0 : callDurationMsec,
                reason,
                code,
            });
        }

        function scheduleReloadingOnIdle() {
            setTimeout(() => {
                if (isCallStarted(getState())) {
                    return;
                }
                dispatch(reload({ reason: 'CALL_ENDED', isImmediate: true }));
            }, EXPLICIT_MODE_RELOAD_TIMEOUT);
        }

        switch (type) {
            case callTypes.START_CALL_DETECTION: {
                // Logic flow:
                // getUserProfile - set isTrainerActive -> getAppConfig -> startCallDetection
                if (!state.main.isTrainerActive) {
                    return;
                }

                startCallDetection();
                break;
            }
            case callTypes.CALL_STARTED: {
                const realtimeOnlyCallsPercent = getRealtimeOnlyCallsPercent(state);
                dispatch(updateRecordCall(realtimeOnlyCallsPercent))

                callManager.startCall(buildRecognitionConfig(state));

                callManager.markAllUnSend();
                callManager.processChunk();

                imAlive.start();
                ping.start(callManager);

                dispatch(logUserEvent(USER_EVENT_TYPE.CALL_START, getCallId(state)))

                break;
            }

            case callTypes.CALL_HOLDED: {
                callManager.resetBuffer(false);
                break;
            }

            case callTypes.ASR_PING: {
                if (isCallHolded(state)) {
                    callManager.sendPing();
                }
                break;
            }

            case deviceTypes.AUDIO_CHUNK_RECEIVED: {
                const {
                    audio,
                    offsetInAudioStreamMsec,
                    channel, //  DataFlow.CAPTURE=1,DataFlow.RENDER=2
                } = payload.audioChunk;

                if(![
                    listenerProto.DataFlow.CAPTURE,
                    listenerProto.DataFlow.RENDER,
                ].includes(channel)){
                    break;
                }

                if (isCallDetectorInitialized(state) && !isCallHolded(state)) {
                    callManager.enqueueChunks({
                        audioChunk: {
                            audioChunk: audio,
                            offsetInAudioStreamMsec,
                            channel
                        }
                    });
                    callManager.processChunk();
                }

                const callLenMscec = getUnfinishedCallDurationMsec(state)
                if (isKeyphraseIntegrationMode(getAppConfig(state)) && callLenMscec && callManager.isCallStarted()) {
                    if (callLenMscec > MAX_CALL_DETECTION_SESSION_DURATION_FOR_KEYPHRASE_DETECTION) {
                        dispatch(stopCallDetection(proto.FinalizationReason.CALL_END_TIMEOUT))
                    }

                    const timeOutNotificationMsec = MAX_CALL_DETECTION_SESSION_DURATION_FOR_KEYPHRASE_DETECTION - CALL_NOTIFICATION_DURATION;
                    if (callLenMscec > timeOutNotificationMsec) {
                        dispatch(callNotification(CALL_NOTIFICATION_TIMOUT))
                    }
                }
                break;
            }

            case callTypes.CALL_DETECTION_STOPPED: {
                const { code = null } = payload;

                // TODO: add tracking
                if (code === wsConnectionClosingCodes.NO_RELOAD) return;

                if(isCallStarted(state) && IS_UI_ONLY_MODE){
                    dispatch({type: callTypes.CALL_END});
                    break;
                }

                dispatch(
                    reload({
                        code: CALL_DETECTION_STOPPED,
                        reason: 'CALL_DETECTION_STOPPED'
                    })
                );

                break;
            }

            case callTypes.CALL_ENDED: {
                dispatch(clearHistory())

                imAlive.end();
                ping.stop();

                endCall({
                    reason: proto.FinalizationReason.CALL_END_NORMAL,
                    code: wsConnectionClosingCodes.NO_RELOAD,
                });

                dispatch(resetCallMode());
                startCallDetection();
                scheduleReloadingOnIdle();

                dispatch(logUserEvent(USER_EVENT_TYPE.CALL_END, getLastCallId(state)))

                break;
            }

            case asrTypes.ASR_VENDOR_CONFIG_ERROR: {
                dispatch(
                    reload({
                        isImmediate: true,
                        code: errorCodes.background.ASR_VENDOR_CONFIG_ERROR,
                        reason: 'ASR_VENDOR_CONFIG_ERROR'
                    })
                )
                break;
            }

            case commonTypes.RELOAD: {
                dispatch(stopCallDetection());
                break;
            }
            case commonTypes.CLOSE_ALL_CONNECTIONS:
            case callTypes.STOP_CALL_DETECTION: {
                const {
                    reason = proto.FinalizationReason.CLIENT_FORCE_RELOAD,
                    code = null
                } = payload;
                endCall({ reason, code });
                break;
            }
            case asrTypes.ASR_CONNECTION_CLOSED: {
                if ((IS_LEGACY_LISTENER_MODE || IS_ELECTRON_APP_WITH_RECORDING) && !payload.closedByClient) {
                    dispatch(
                        reload({
                            code: RECOGNITION_CONNECTION_ERROR,
                            reason: 'RECOGNITION_CONNECTION_ERROR'
                        })
                    );
                }
                break;
            }
            case mainTypes.EVENT_FORWARDED: {
                const { jsonRequest } = payload;
                callManager.forwardEvent({
                    jsonRequest: JSON.stringify(jsonRequest),
                    offsetMsec: getSessionDurationMsec(state)
                });
                break;
            }
            case callTypes.CALL_START: {
                if(callManager.isCallStarted()){
                    captureWarnMessage('previous call is not ended')

                    dispatch({
                        type: callTypes.CALL_RESTART,
                        payload
                    })

                    break;
                }

                dispatch(resetCallMode());

                callManager.emitCallStartEvent({
                    sessionOffsetMsec: getSessionDurationMsec(state),
                    meta: payload.meta,
                })
                break;
            }
            case callTypes.CALL_END: {
                callManager.emitCallEndEvent({
                    reason: proto.FinalizationReason.CALL_END_NORMAL,
                    sessionOffsetMsec: getSessionDurationMsec(state),
                })
                break;
            }
            case callTypes.CALL_HOLD: {
                callManager.emitCallHeld();
                dispatch(logUserEvent(USER_EVENT_TYPE.CALL_HOLD));
                break;
            }
            case callTypes.CALL_UNHOLD: {
                callManager.emitCallUnheld();
                dispatch(logUserEvent(USER_EVENT_TYPE.CALL_UNHOLD));
                break;
            }

            // manual opt-in/out events
            case callTypes.OPT_IN: {
                callManager.setCallMode(CallMode.BOTH);
                break;
            }
            case callTypes.OPT_STOP: {
                callManager.setCallMode(CallMode.AGENT, true, false);
                break;
            }
            case callTypes.OPT_OUT: {
                callManager.setCallMode(CallMode.AGENT, true, true);
                break;
            }

            case callTypes.CALL_SET_MODE: {
                const mode = payload.mode === CallMode.DEFAULT
                    ? getDefaultCallMode(state)
                    : payload.mode;

                callManager.setCallMode(mode, true, !!payload.forgetAudioAndTranscript);

                if(mode === CallMode.NONE){
                    callManager.emitCallEndEvent({
                        reason: proto.FinalizationReason.CALL_END_NORMAL,
                        sessionOffsetMsec: getSessionDurationMsec(state),
                    })
                }
                break;
            }

            case callTypes.RESET_CALL_MODE: {
                callManager.resetCallMode();
                break;
            }

            case callTypes.UTC_PING: {
                callManager.utcPing()
                break;
            }

            case callTypes.UTC_PING_TIMEOUT: {
                callManager.emitCallEndEvent({
                    reason: proto.FinalizationReason.SESSION_RELOAD_ON_LOST,
                    sessionOffsetMsec: getSessionDurationMsec(state),
                })
                break;
            }

            case callTypes.SEND_MANUAL_CALL_EVENT: {
                const defaultCallMode = getDefaultCallMode(state)
                if([CallMode.UNKNOWN, CallMode.NONE].indexOf(defaultCallMode) > -1){
                    dispatch(reload({
                        code: errorCodes.background.CALL_MODE_ERROR,
                        reason: 'CALL_MODE_ERROR'
                    }));
                    break;
                }

                callManager.forwardEvent({
                    jsonRequest: JSON.stringify({
                        type: payload.isStart ? 'start' : 'end',
                        source: 'i2x_manual'
                    }),
                    offsetMsec: getSessionDurationMsec(state)
                });

                setTimeout(() => { // hack for redux
                    if (payload.isStart) {
                        dispatch(resetCallMode());
                        callManager.emitCallStartEvent({
                            sessionOffsetMsec: getSessionDurationMsec(state),
                        })
                        dispatch(changeCallMode(CallMode.DEFAULT));
                    } else {
                        callManager.emitCallEndEvent({
                            reason: proto.FinalizationReason.CALL_END_NORMAL,
                            sessionOffsetMsec: getSessionDurationMsec(state),
                        })
                    }
                }, 1);
                break;
            }


            // On every call started we need to recalculate if the call is going to be
            // recorded or not based on the configuration we got.
            case configTypes.UPDATE_APP_CONFIG_SUCCESS:
            case configTypes.GET_APP_CONFIG_SUCCESS: {
                const realtimeOnlyCallsPercent = getRealtimeOnlyCallsPercent(state);
                dispatch(updateRecordCall(realtimeOnlyCallsPercent))

                // enable/disable electron recording & reload if local storage flag changed
                const enableRecorder = getEnableRecorderFlag(state)
                if(enableRecorder ? enableRecording() : disableRecording()){
                    dispatch(reload({
                        reason: 'SETTINGS_UPDATED',
                        isImmediate: true,
                    }))
                }

                if(IS_ELECTRON_APP){
                    const explicitIntegrationSource = getExplicitIntegrationSource(state)
                    if([
                        EXPLICIT_INTEGRATION_SOURCE.I2X_CALL_EVENT,
                        EXPLICIT_INTEGRATION_SOURCE.UCT_CALL_EVENT,
                        EXPLICIT_INTEGRATION_SOURCE.COPS_I2X_EVENT,
                    ].includes(explicitIntegrationSource) && window.electronAPI){
                        window.electronAPI.startCallEventsServer();
                    }
                }

                break;
            }

            case callTypes.SAVE_CONVERSION: {
                dispatch(saveConversion({
                    ...payload,
                }))

                break;
            }

            case callTypes.GET_NEW_SUGGESTIONS: {
                dispatch(getNewSuggestions({
                    ...payload,
                }))

                break;
            }

            default:
        }

        return result;
    };
};
