import styled from 'styled-components';

import { colors } from '../../../utils';

export default styled.div`
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: ${colors.white.basic};
    margin-bottom: 20px;
    margin-top: 30px;
`;
