import styled from 'styled-components';

const Wrapper = styled.span`
    position: relative;
    z-index: 0;
    opacity: 0.5;
    filter: blur(2.5px);
`;

export default Wrapper;
