import styled from 'styled-components';

import { colors } from '../../../utils';

const Title = styled.div`
    font-size: 13px;
    font-weight: normal;

    overflow: hidden;

    text-overflow: ellipsis;
    text-align: center;

    color: ${colors.blue.madison};
`;

export default Title;
