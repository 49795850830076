import styled from 'styled-components';
import Row from '../../Row';

const CardRow = styled(Row)`
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export default CardRow;
