import React from 'react';
import PropTypes from 'prop-types';

function CheckMark(props) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 18 14"
            style={props.style}>
            <path
                fill="#062761"
                stroke="#062761"
                strokeWidth=".967"
                d="M6.43 13a.978.978 0 0 1-.706-.304l-4.43-4.594a1.067 1.067 0 0 1-.002-1.468.974.974 0 0 1 1.415-.001l3.724 3.861 8.862-9.19a.974.974 0 0 1 1.415 0c.39.407.39 1.064-.001 1.47l-9.57 9.922a.978.978 0 0 1-.706.304"
            />
        </svg>
    );
}
CheckMark.propTypes = {
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number
};

CheckMark.defaultProps = {
    style: {},
    width: 18,
    height: 14
};

export default CheckMark;
