import { connect } from 'react-redux';
import CallSuggestions from '../../components/common/CallSuggestions';
import { closeCallSuggestions } from '../../store/actions/callSuggestions';
import withLocalization from '../../localization/withLocalization';
import { getSuggestionCallFrequency } from '../../store/selectors/trainer';

const mapStateToProps = state => ({
    suggestionCallFrequency: getSuggestionCallFrequency(state),
    callSuggestionsOpen: state.callSuggestions.callSuggestionsOpen,
    suggestions: state.callSuggestions.suggestions,
    suggestionsTimestamp: state.callSuggestions.suggestionsTimestamp
});

const mapDispatchToProps = dispatch => {
    return {
        closeCallSuggestions: () => dispatch(closeCallSuggestions())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalization(CallSuggestions));
