import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    position: relative;
    z-index: 20;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    cursor: ${({ $open, $disabled }) => {
        if ($disabled) {
            return 'default';
        }
        return $open ? 'default' : 'pointer';
    }};
    margin-left: 10px;
`;

Wrapper.propTypes = {
    $open: PropTypes.bool,
    $disabled: PropTypes.bool
};

Wrapper.defaultProps = {
    $open: false,
    $disabled: false
};

export default Wrapper;
