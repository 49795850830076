import styled from 'styled-components';

import { colors } from '../../../utils';

const Title = styled.div`
    margin-top: 16px;
    margin-bottom: 50px;
    font-size: 19px;
    font-weight: 600;
    color: ${colors.blue.madison};
`;

export default Title;
