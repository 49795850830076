import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Column } from './styled';
import { Icon } from '..';

// eslint-disable-next-line react/prefer-stateless-function
export default class NoCallMessage extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired
    };

    render() {
        const { localization } = this.props;

        return (
            <Card className="message">
                <Icon name="Headset" />
                <Column>
                    <span>
                        {localization.getText('trainer.call_messages.not_started')}
                    </span>
                </Column>
            </Card>
        );
    }
}
