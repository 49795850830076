import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardRow, ExpandIcon, ExpandWrapper, Title } from './styled';

class CardExpandable extends Component {
    static propTypes = {
        contentExpanded: PropTypes.any.isRequired,
        contentNotExpanded: PropTypes.any,
        buttonShowState: PropTypes.any.isRequired,
        buttonHideState: PropTypes.any.isRequired,
        icon: PropTypes.element.isRequired,
        children: PropTypes.node
    };

    static defaultProps = {
        contentNotExpanded: null,
        children: null
    };

    static Row = CardRow;

    static Title = Title;

    state = {
        isExpanded: false
    };

    _toggleExpand() {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded
        }));
    }

    _renderContent() {
        const { contentExpanded, contentNotExpanded } = this.props;
        const { isExpanded } = this.state;

        if (!isExpanded && !contentNotExpanded) return null;

        if (!isExpanded) return <CardRow>{contentNotExpanded}</CardRow>;

        return <CardRow>{contentExpanded}</CardRow>;
    }

    _getButtonContent() {
        const { buttonShowState, buttonHideState } = this.props;
        const { isExpanded } = this.state;

        return isExpanded ? buttonHideState : buttonShowState;
    }

    render() {
        const { children, icon } = this.props;
        const { isExpanded } = this.state;
        return (
            <Card>
                {children}
                {this._renderContent()}
                <CardRow>
                    <ExpandWrapper onClick={event => this._toggleExpand(event)}>
                        {this._getButtonContent()}
                        <ExpandIcon $clicked={isExpanded}>{icon}</ExpandIcon>
                    </ExpandWrapper>
                </CardRow>
            </Card>
        );
    }
}

export default CardExpandable;
