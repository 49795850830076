import {get, isEmpty} from "lodash";
import {objectToCamelCase} from '../background/utils';
import {JwtTokenErrors} from "../background/errors";
import {captureException, captureWarnMessage} from "../tracker/raven";

export const decodeJWT = jwt => {
    if (!jwt) {
        return {};
    }

    try {
        if (jwt.split(' ').length > 1) {
            return {};
        }
        const base64 = jwt
            .split('.')[1]
            .replace('-', '+')
            .replace('_', '/');
        return objectToCamelCase(JSON.parse(window.atob(base64)));
    } catch (_) {
        return {};
    }
};

const decodeToken = (token) => {
    const decoded = decodeJWT(token);

    if (isEmpty(decoded)) {
        const error = new JwtTokenErrors.JwtTokenDecodeError();
        captureException(error);
        return {};
    }
    return decoded;
};

// sometimes we get users without roles because of setup mistake.
const decodeRole = (decoded) => {
    const role = get(decoded, 'resourceAccess.web.roles[0]', '');
    if (!role) {
        const error = new JwtTokenErrors.JwtTokenEmptyRoleError({
            userId: decoded.userId
        });
        captureWarnMessage(error);
    }
    return role;
};

export const selectUserFromToken = (token) => {
    const decoded = decodeToken(token);

    const orgs = get(decoded, 'orgs', {});
    return {
        email: decoded.email,
        firstName: decoded.givenName,
        lastName: decoded.familyName,
        agencyId: orgs.agencyId,
        locationId: orgs.locationId,
        companyId: orgs.companyId,
        role: decodeRole(decoded),
        userId: decoded.userId,
    };
};
