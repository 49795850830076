import styled from 'styled-components';

const Wrapper = styled.span`
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: 20px;
    height: 100%;

    cursor: pointer;
`;

export default Wrapper;
