import styled, { keyframes } from 'styled-components';

import { colors } from '../../../../utils';

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export default styled.div`
    background-color: ${colors.black.default};
    color: ${colors.white.basic};

    display: flex;

    align-items: center;

    min-height: 60px;

    width: 306px;

    padding: 6px 10px;

    border-radius: 2px;

    box-shadow: 0 2px 4px 0 rgba(6, 39, 97, 0.31);

    cursor: pointer;

    transition: all 0.5s cubic-bezier(0.3, 0, 0, 1);
    animation-timing-function: cubic-bezier(0.3, 0, 0, 1);
    animation-name: ${fadeIn};
    animation-duration: 0.88s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;
