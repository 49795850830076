import {CallEvents} from "../../constants";

// @docs
// https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/719126547/Windows+Events
// https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/1222934529/Windows+Audio+Events+-+Technical+Description

export const schema = {
    'type': 'object',
    'properties': {
        'type': {'type': 'string', 'enum': ['start', 'end', 'ping', 'hold', 'unhold']},
        'meta_info': {
            'type': 'object',
            'properties': {
                'device': {
                    'type': 'object',
                    'properties': {
                        'id': {'type': 'string'},
                        'dataFlow': {'type': 'string'},
                        'isDefaultConsole': {'type': 'string'},
                        'isDefaultCommunications': {'type': 'string'},
                        'isDefaultMultimedia': {'type': 'string'},
                        'description': {'type': 'string'}
                    }
                },
                'displayName': {'type': 'string'},
                'sessionIdentifier': {'type': 'string'},
                'sessionInstanceIdentifier': {'type': 'string'},
                'processId': {'type': 'string'},
                'state': {'type': 'string'},
                'isSystemSound': {'type': 'string'},
            },
            'required': ['sessionIdentifier']
        }
    },
    'required': ['type'],
    "if": {
        "properties": { "event_type": { "const": "ping" } },
    },
    "else": {
        'required': ['meta_info']
    },
}

export function parse(event, {appWhitelist}) {
    let callEvent = null;
    const callMode = null;
    const forgetAudioAndTranscript = false;

    if (event.type === 'start') {
        for (const app of appWhitelist) {
            if (event.meta_info.sessionIdentifier && event.meta_info.sessionIdentifier.toLowerCase().indexOf(app.toLowerCase()) > -1) {
                callEvent = CallEvents.START;
            }
        }
    } else if (event.type === 'end') {
        for (const app of appWhitelist) {
            if (event.meta_info.sessionIdentifier && event.meta_info.sessionIdentifier.toLowerCase().indexOf(app.toLowerCase()) > -1) {
                callEvent = CallEvents.END;
            }
        }
    } else if (event.type === 'hold') {
        callEvent = CallEvents.HOLD;
    } else if (event.type === 'unhold') {
        callEvent = CallEvents.UNHOLD;
    }

    return [callEvent, callMode, forgetAudioAndTranscript];
}
