import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Eye, EyeClosed } from '../common/icons';

import {
    Card,
    CardTitle,
    Wrapper,
    ErrorMessage,
    Input,
    Row,
    SubmitButton,
    Text,
    Hr,
    PassEye
} from './styled';

import withLocalization from '../../localization/withLocalization';
import { loadLoginInfo } from '../../utils/login';
import { colors } from '../../utils';

const preSavedLoginInfo = loadLoginInfo();

class Login extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired,
        doLogin: PropTypes.func.isRequired,
        pending: PropTypes.bool.isRequired,
        error: PropTypes.string.isRequired
    };

    state = {
        locationName: (preSavedLoginInfo && preSavedLoginInfo.locationName) || '',
        username: (preSavedLoginInfo && preSavedLoginInfo.username) || '',
        password: (preSavedLoginInfo && preSavedLoginInfo.password) || '',
        showPassword: false
    };

    isValid() {
        const { locationName, password, username } = this.state;
        return locationName !== '' && password !== '' && username !== '';
    }

    componentDidMount() {
        if (this.isValid()) {
            const { locationName, password, username } = this.state;
            this.props.doLogin(locationName, username, password);
        }
    }

    render() {
        const { pending, doLogin, localization, error } = this.props;
        const { locationName, password, username, showPassword } = this.state;

        const isValid = this.isValid();

        const login = () => {
            if (isValid) {
                doLogin(locationName, username, password);
            }
        };

        const onChange = values => {
            this.setState({
                ...values
            });
        };

        return (
            <Wrapper>
                <Card $enabled={!pending}>
                    <CardTitle>{localization.getText('login.title')}</CardTitle>
                    <div>
                        {!!error && ( // from DO_LOGIN_FAILED action
                            <div style={{ marginTop: 12 }}>
                                <ErrorMessage>{localization.getText(error)}</ErrorMessage>
                            </div>
                        )}
                        <Row>
                            <Input
                                autoFocus={!this.state.locationName}
                                type="text"
                                value={this.state.locationName}
                                disabled={pending}
                                placeholder={localization.getText('login.locationName')}
                                onChange={({ target }) =>
                                    onChange({ locationName: target.value })
                                }
                                onKeyDown={e => {
                                    if (e && e.keyCode === 13) {
                                        login();
                                    }
                                }}
                            />
                        </Row>
                        <Row>
                            <Input
                                autoFocus={!!this.state.locationName}
                                type="text"
                                value={this.state.username}
                                disabled={pending}
                                placeholder={localization.getText('login.username')}
                                onChange={({ target }) =>
                                    onChange({ username: target.value })
                                }
                                onKeyDown={e => {
                                    if (e && e.keyCode === 13) {
                                        login();
                                    }
                                }}
                            />
                        </Row>
                        <Row>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                value={this.state.password}
                                disabled={pending}
                                placeholder={localization.getText('login.password')}
                                onChange={({ target }) =>
                                    onChange({ password: target.value })
                                }
                                onKeyDown={e => {
                                    if (e && e.keyCode === 13) {
                                        login();
                                    }
                                }}
                            />
                            <PassEye
                                onClick={() =>
                                    this.setState(prevState => ({
                                        showPassword: !prevState.showPassword
                                    }))
                                }>
                                {showPassword ? (
                                    <Eye size={30} color={colors.gray.silver_sand} />
                                ) : (
                                    <EyeClosed
                                        size={30}
                                        color={colors.gray.silver_sand}
                                    />
                                )}
                            </PassEye>
                        </Row>
                        <SubmitButton
                            onClick={() => {
                                login();
                            }}
                            disabled={!isValid}>
                            {localization.getText('login.doLogin')}
                        </SubmitButton>
                        <Hr />
                        <Text>
                            {localization.getText('login.troubleSigningIn')}
                            <br />
                            {localization.getText('login.contactManager')}
                        </Text>
                    </div>
                </Card>
            </Wrapper>
        );
    }
}

export default withLocalization(Login);
