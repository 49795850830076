import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function EyeClosed(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.size}
            viewBox="0 -960 960 960"
            width={props.size}>
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M816-64 648-229q-35 14-79 21.5t-89 7.5q-146 0-265-81.5T40-500q20-52 55.5-101.5T182-696L56-822l42-43 757 757-39 44ZM480-330q14 0 30-2.5t27-7.5L320-557q-5 12-7.5 27t-2.5 30q0 72 50 121t120 49Zm278 40L629-419q10-16 15.5-37.5T650-500q0-71-49.5-120.5T480-670q-22 0-43 5t-38 16L289-760q35-16 89.5-28T485-800q143 0 261.5 81.5T920-500q-26 64-67 117t-95 93ZM585-463 443-605q29-11 60-4.5t54 28.5q23 23 32 51.5t-4 66.5Z"
            />
        </svg>
    );
}

EyeClosed.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

EyeClosed.defaultProps = {
    size: 14,
    color: `${colors.black.default}`
};

export default EyeClosed;
