import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function LoudnessGood(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size} viewBox="0 0 36 29">
            <g
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path
                    stroke={colors.blue.madison}
                    strokeWidth="1.5"
                    d="M6.6 9.438H1v10.124h5.6L15.4 28H19a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-3.6L6.6 9.438z"
                />
                <path
                    stroke={colors.green.aquamarine}
                    strokeWidth="1.89"
                    d="M29.614 23.218l2.227-2.442a9.315 9.315 0 0 0 0-12.553L29.614 5.78"
                />
                <path
                    stroke={colors.green.aquamarine}
                    strokeWidth="1.89"
                    d="M27.75 19.343l1.452-1.51a4.81 4.81 0 0 0 0-6.668l-1.452-1.51"
                />
            </g>
        </svg>
    );
}

LoudnessGood.propTypes = {
    size: PropTypes.number
};

LoudnessGood.defaultProps = {
    size: 36
};

export default LoudnessGood;
