import { createAudioInfoConnection } from '../../services/WsConnection';
import {
    audioSystemInfoConnectionClosed,
    audioSystemInfoConnectionOpened,
    stopAudioSystemInfoConnection,
    types as mainTypes
} from '../actions/main';
import { types as commonTypes, reload } from '../actions/common';
import { captureException } from '../../../tracker/raven';
import { isAudioSystemInfoConnectionOpen } from '../selectors';
import { errorCodes } from '../../../utils';
import { SysInfoConn } from '../../errors';
import { audioSysLogger } from '../../../utils/logger';

const {
    AUDIO_DEVICES_CONFIG_CHANGED,
    AUDIO_SYSTEM_INFO_CONNECTION_CLOSED
} = errorCodes.background;

export default store => {
    const { dispatch, getState } = store;

    function createAudioSystemInfoConnection(port) {
        const ws = createAudioInfoConnection({ host: 'localhost', port });

        ws.on('open', () => dispatch(audioSystemInfoConnectionOpened()));
        ws.on('close', error =>
            dispatch(audioSystemInfoConnectionClosed(error.wasClean))
        );
        ws.on('message', () => {
            dispatch(
                reload({
                    code: AUDIO_DEVICES_CONFIG_CHANGED,
                    reason: 'AUDIO_DEVICES_CONFIG_CHANGED'
                })
            );
        });

        return ws;
    }
    let connection = null;

    return next => action => {
        const { type, payload } = action;
        const result = next(action);
        const state = getState();

        switch (type) {
            case commonTypes.RELOAD: {
                if (isAudioSystemInfoConnectionOpen(state)) {
                    dispatch(stopAudioSystemInfoConnection());
                }
                break;
            }
            case mainTypes.AUDIO_SYSTEM_INFO_CONNECTION_CLOSED: {
                dispatch(
                    reload({
                        code: AUDIO_SYSTEM_INFO_CONNECTION_CLOSED,
                        reason: 'AUDIO_SYSTEM_INFO_CONNECTION_CLOSED'
                    })
                );
                break;
            }
            case mainTypes.STOP_AUDIO_SYSTEM_INFO_CONNECTION: {
                if (connection && isAudioSystemInfoConnectionOpen(state)) {
                    connection.close();
                    connection = null;
                }
                break;
            }
            case mainTypes.START_AUDIO_SYSTEM_INFO_CONNECTION: {
                if (!connection) {
                    connection = createAudioSystemInfoConnection(payload.port);
                } else {
                    const error = new SysInfoConn.AlreadyStartedError();
                    captureException(error);
                    audioSysLogger.error(error.message, error);
                }
                break;
            }
            default:
        }

        return result;
    };
};
