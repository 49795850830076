import { sample, template } from "lodash";
import ordinal from "ordinal";

import {RECEIVE_CRX_CALL_ENDED, RECEIVE_CRX_CALL_STARTED} from "../actions/actionTypes/CRX";
import {INIT} from "../actions/actionTypes/trainer";
import promptsEn from "./config/prompts-en.json"
import promptsDe from "./config/prompts-de.json"
import {GET_USER_PROFILE_SUCCESS} from "../actions/actionTypes/user";

const now = new Date();
const currentDay = now.getDate();

export const initialState = {
    currentPrompt: {},
    config: {
        'morning': { expirationDate: null },
        'afternoon': { expirationDate: null }
    },
    calls: {
        expirationDate: null,
        count: 0
    },
    initialized: false,
    languageCode: 'de-DE'
};

const getPromptCategory = ({state, isCallEnded}) => {
    if(isCallEnded) {
        return 'after-the-x-call';
    }
    if(new Date(now.getFullYear(), now.getMonth(), currentDay, 12, 0, 0) > new Date()) {
        if (new Date(state.config?.morning?.expirationDate) < new Date() || !state.config?.morning?.expirationDate) {
            return 'morning';
        }
    }

    if(new Date(now.getFullYear(), now.getMonth(), currentDay, 12, 0, 0) < new Date()) {
        if (new Date(state.config?.afternoon?.expirationDate) < new Date() || !state.config?.afternoon?.expirationDate) {
            return 'afternoon'
        }
    }

    return 'generic';
}

const getExpirationTime = (category) => {
    switch (category) {
        case 'morning':
            return new Date(now.getFullYear(), now.getMonth(), currentDay + 1, 8, 0, 0);
        case 'afternoon':
            return new Date(now.getFullYear(), now.getMonth(), currentDay + 1, 12, 0, 0);
        default:
            return null;
    }

}

const getOrdinal = (number, languageCode) => {
    if (languageCode === 'de-DE') {
        if(number === 1) {
            return 'einen';
        }
        return number;
    }

    return ordinal(number || 0);
}

const getRandomPrompt = (category, completedCalls, languageCode) => {
    let prompts;

    if(languageCode === 'de-DE') {
        prompts = promptsDe
    } else {
        prompts = promptsEn;
    }

    const categoryPrompts = prompts.filter(item => item.category === category);
    const random = sample(categoryPrompts);

    function getText(word) {
        const calls = () => {
            if(languageCode === 'de-DE') {
                return completedCalls === 1 ? 'Anruf' : 'Anrufe';
            }
            return completedCalls === 1 ? 'call' : 'calls';
        }

        const sentence = template(word)({
            ordinal: getOrdinal((completedCalls), languageCode),
            numberOfCalls: completedCalls,
            calls: calls()
        });

        // uppercase first letter
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }

    if(category === 'after-the-x-call' || category === 'morning' || category === 'afternoon') {
        const random2 = sample(categoryPrompts.filter(item => item.group === random.group));

        return {
            ...random,
            title: getText(random.title),
            subtext: getText(random2.subtext)
        };
    }

    random.title = getText(random.title);
    random.subtext = getText(random.subtext);

    return random;
}

const producePrompt = (state, completedCalls, isCallEnded) => {
    const category = getPromptCategory({state, completedCalls, isCallEnded});
    const expirationDate = getExpirationTime(category);

    if(new Date(state.config?.[category]?.expirationDate) > new Date()) {
        const prompt = getRandomPrompt(category, completedCalls, state.languageCode);
        return {
            ...state,
            currentPrompt: prompt,
            ...state.config,
            [category]: {
                expirationDate
            }
        };
    }

    const prompt = getRandomPrompt(category, completedCalls, state.languageCode);

    return {
        currentPrompt: prompt,
        config: {
            ...state.config,
            [category]: {
                expirationDate
            }
        }
    };
}

// eslint-disable-next-line default-param-last
const promptReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case RECEIVE_CRX_CALL_STARTED:
            return {
                ...state,
                currentPrompt: {}
            };
        case RECEIVE_CRX_CALL_ENDED: {
            let completedCalls = state.calls.count + 1;

            if(state.calls.expirationDate !== new Date().getDate()) {
                completedCalls = 1;
            }

            return {
                ...state,
                ...producePrompt(state, completedCalls, true),
                 calls: {
                     expirationDate: new Date().getDate(),
                     count: completedCalls
                 }
            };
        }

        case INIT: {
            if (!state.initialized) {
                return {
                    ...state,
                    ...producePrompt(state)
                };
            }
            return state;
        }

        case GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                languageCode: action.payload.languageCode
            };
        }

        default:
            return state;
    }
};

export default promptReducer;
