import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../utils';

export const ModalButton = styled.button`
    border-radius: 18px;
    border: 1px solid ${props => props.$color};
    display: block;
    width: 100%;

    text-transform: uppercase;
    padding: 8px 16px;
    letter-spacing: 1px;
    font-weight: normal;

    background-color: ${props => (props.$isOutline ? colors.white.basic : props.$color)};
    color: ${props => (props.$isOutline ? props.$color : colors.white.basic)};

    cursor: pointer;

    outline: none;

    & > * {
        vertical-align: middle;
    }

    & > span {
        margin-left: 6px;
    }

    &:hover {
        box-shadow: 0 2px 4px 0 ${colors.gray.mischka};
    }

    &:disabled {
        border: 1px solid ${props => props.$disabledColor};
        background-color: ${props => props.$disabledColor};
    }
`;

ModalButton.propTypes = {
    $color: PropTypes.string.isRequired,
    $disabledColor: PropTypes.string,
    $isOutline: PropTypes.bool
};

ModalButton.defaultProps = {
    $disabledColor: colors.gray.lilac,
    $isOutline: false
};

export default ModalButton;
