import styled, { css, keyframes } from 'styled-components';

const placeholderShimmer = keyframes`
      0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`;

const animationRule = css`
    ${placeholderShimmer} 1s infinite linear
`;

const Placeholder = styled('div')`
    height: 22px;
    width: ${p => `${p.width}`};
    border-radius: 8px;
    margin-bottom: 10px;
    margin-left: ${p => p.marginLeft || '0px'};
    background: #f6f7f8
        linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)
        no-repeat;
    background-size: 800px 104px;

    animation: ${animationRule};
`;

export default Placeholder;
