import styled from 'styled-components';

import { colors } from '../../../utils';

const EmptyValue = styled.div`
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: ${colors.blue.madison};
    opacity: 0.6;
    margin: auto;
`;

export default EmptyValue;
