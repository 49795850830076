import styled from 'styled-components';

import { colors } from '../../../utils';

export default styled.div`
    color: ${colors.white.basic};

    font-weight: 500;
    font-size: 18px;

    margin: auto 0 auto 20px;
`;
