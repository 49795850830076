import styled from 'styled-components';

import { colors } from '../../../../../utils';

const ListLabel = styled.div`
    margin: 5px 8px;

    color: ${colors.black.nero};
    opacity: 0.5;

    font-size: 13px;
    font-weight: 600;
`;

export default ListLabel;
