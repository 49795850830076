import React from 'react';

function Level5v2() {
  return <svg
        width="17px"
        height="22px"
        viewBox="0 0 17 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g
            id="Gamification-Fix"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
                id="Gamification-Score-Changes"
                transform="translate(-1086.000000, -833.000000)">
                <g id="Group-7-Copy" transform="translate(1087.000000, 834.000000)">
                    <path
                        d="M9,1.96376898 C9,1.96376898 13.2,-0.955743214 14.8,0.324744591 C16.35,1.65645191 14.2,7.0857202 14.2,7.0857202 L9,1.96376898 Z"
                        id="XMLID_10855_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M10,2.55325131 C10,2.55325131 13,0.299592772 14.05,1.22154399 C15.1,2.14349521 13.5,6.03617814 13.5,6.03617814 L10,2.55325131 Z"
                        id="XMLID_10854_"
                        fill="#4550E6"
                        fillRule="nonzero"
                    />
                    <path
                        d="M6.36161882,1.96376898 C6.36161882,1.96376898 2.16161882,-0.955743214 0.561618818,0.324744591 C-0.988381182,1.65645191 1.16161882,7.0857202 1.16161882,7.0857202 L6.36161882,1.96376898 Z"
                        id="XMLID_10853_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M14.8,1.47025706 C15.1,1.72635462 15.3,2.18733023 15.35,2.69952536 C15.45,1.67513511 15.35,0.804403404 14.8,0.343427795 C13.25,-0.988279523 9,1.98245218 9,1.98245218 L9.7,2.64830584 C10.95,1.88001316 13.6,0.497086331 14.8,1.47025706 Z"
                        id="XMLID_10852_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M0.579244485,1.45157386 C1.77924449,0.478403128 4.42924449,1.86132996 5.67924449,2.62962264 L6.37924449,1.96376898 C6.37924449,1.96376898 2.17924449,-0.955743214 0.579244485,0.324744591 C0.0792444852,0.785720201 -0.0707555148,1.65645191 0.0292444852,2.68084215 C0.0792444852,2.16864703 0.279244485,1.70767142 0.579244485,1.45157386 Z"
                        id="XMLID_10851_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M9,1.96376898 C9,1.96376898 13.2,-0.955743214 14.8,0.324744591 C16.35,1.65645191 14.2,7.0857202 14.2,7.0857202 L9,1.96376898 Z"
                        id="XMLID_10850_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6.36161882,1.96376898 C6.36161882,1.96376898 2.16161882,-0.955743214 0.561618818,0.324744591 C-0.988381182,1.65645191 1.16161882,7.0857202 1.16161882,7.0857202 L6.36161882,1.96376898 Z"
                        id="XMLID_10849_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.40707266,2.55325131 C5.40707266,2.55325131 2.40707266,0.299592772 1.35707266,1.22154399 C0.307072664,2.14349521 1.90707266,6.03617814 1.90707266,6.03617814 L5.40707266,2.55325131 Z"
                        id="XMLID_10848_"
                        fill="#4550E6"
                        fillRule="nonzero"
                    />
                    <path
                        d="M14.05,2.34837326 C14.2,2.5020318 14.3,2.70690985 14.35,2.96300741 C14.45,2.19471472 14.4,1.52886107 14.05,1.22154399 C13,0.299592772 10,2.55325131 10,2.55325131 L10.7,3.21910497 C11.65,2.60447082 13.3,1.73373911 14.05,2.34837326 Z"
                        id="XMLID_10847_"
                        fill="#8D94F2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M1.29549722,2.34837326 C2.04549722,1.73373911 3.74549722,2.60447082 4.69549722,3.21910497 L5.39549722,2.55325131 C5.39549722,2.55325131 2.39549722,0.299592772 1.34549722,1.22154399 C0.995497224,1.52886107 0.945497224,2.19471472 1.04549722,2.96300741 C0.995497224,2.70690985 1.09549722,2.5020318 1.29549722,2.34837326 Z"
                        id="XMLID_10846_"
                        fill="#8D94F2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M10,2.55325131 C10,2.55325131 13,0.299592772 14.05,1.22154399 C15.1,2.14349521 13.5,6.03617814 13.5,6.03617814 L10,2.55325131 Z"
                        id="XMLID_10845_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.40707266,2.55325131 C5.40707266,2.55325131 2.40707266,0.299592772 1.35707266,1.22154399 C0.307072664,2.14349521 1.90707266,6.03617814 1.90707266,6.03617814 L5.40707266,2.55325131 Z"
                        id="XMLID_10844_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_10843_"
                        fill="#E3E5FC"
                        fillRule="nonzero"
                        cx="7.5"
                        cy="19.7682927"
                        rx="5.5"
                        ry="1"
                    />
                    <path
                        d="M15.3221274,11.8073171 L14.8721274,7.35121951 C14.4721274,3.71463415 11.3721274,1 7.67212743,1 C3.97212743,1 0.872127432,3.76585366 0.472127432,7.35121951 L0.0221274319,11.8073171 C-0.227872568,14.2146341 1.67212743,16.3146341 4.12212743,16.3146341 L4.37212743,16.3146341 C4.97212743,17.1341463 6.22212743,17.6463415 7.67212743,17.6463415 C9.12212743,17.6463415 10.3721274,17.0829268 10.9721274,16.3146341 L11.2221274,16.3146341 C13.6721274,16.3146341 15.5721274,14.2146341 15.3221274,11.8073171 Z"
                        id="XMLID_10842_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M0.470710678,9.24634146 C0.870710678,5.6097561 3.97071068,2.89512195 7.67071068,2.89512195 C11.3707107,2.89512195 14.4707107,5.66097561 14.8707107,9.24634146 L15.2707107,13.0878049 C15.3707107,12.6780488 15.3707107,12.2682927 15.3207107,11.8073171 L14.8707107,7.35121951 C14.4707107,3.71463415 11.3707107,1 7.67071068,1 C3.97071068,1 0.870710678,3.76585366 0.470710678,7.35121951 L0.0207106781,11.8073171 C-0.0292893219,12.2170732 0.0207106781,12.6780488 0.0707106781,13.0878049 L0.470710678,9.24634146 Z"
                        id="XMLID_10841_"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                    />
                    <path
                        d="M15.3221274,11.8073171 L14.8721274,7.35121951 C14.4721274,3.71463415 11.3721274,1 7.67212743,1 C3.97212743,1 0.872127432,3.76585366 0.472127432,7.35121951 L0.0221274319,11.8073171 C-0.227872568,14.2146341 1.67212743,16.3146341 4.12212743,16.3146341 L4.37212743,16.3146341 C4.97212743,17.1341463 6.22212743,17.6463415 7.67212743,17.6463415 C9.12212743,17.6463415 10.3721274,17.0829268 10.9721274,16.3146341 L11.2221274,16.3146341 C13.6721274,16.3146341 15.5721274,14.2146341 15.3221274,11.8073171 Z"
                        id="XMLID_10840_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.7,13.6146341 C8.7,13.9731707 8.1,14.7414634 7.35,14.7414634 C6.6,14.7414634 6,13.9731707 6,13.6146341 C6,13.2560976 6.6,13 7.35,13 C8.1,13 8.7,13.2560976 8.7,13.6146341 Z"
                        id="XMLID_10839_"
                        fill="#4550E6"
                        fillRule="nonzero"
                    />
                    <path
                        d="M7.35,13.7682927 C7.85,13.7682927 8.3,13.8707317 8.5,14.0756098 C8.6,13.9219512 8.7,13.7170732 8.7,13.6146341 C8.7,13.2560976 8.1,13 7.35,13 C6.6,13 6,13.3073171 6,13.6146341 C6,13.7170732 6.05,13.8707317 6.2,14.0756098 C6.4,13.8707317 6.85,13.7682927 7.35,13.7682927 Z"
                        id="XMLID_10838_"
                        fill="#8D94F2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M8.7,13.6146341 C8.7,13.9731707 8.1,14.7414634 7.35,14.7414634 C6.6,14.7414634 6,13.9731707 6,13.6146341 C6,13.2560976 6.6,13 7.35,13 C8.1,13 8.7,13.2560976 8.7,13.6146341 Z"
                        id="XMLID_10837_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9,15.9731707 C8.51162791,16.3317073 7.84883721,16.1268293 7.5,15 C7.15116279,16.1268293 6.52325581,16.2804878 6,15.9731707"
                        id="XMLID_10836_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <line
                        x1="11"
                        y1="12.4097561"
                        x2="13.7"
                        y2="12"
                        id="XMLID_10835_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <line
                        x1="11"
                        y1="14"
                        x2="13.7"
                        y2="14.3585366"
                        id="XMLID_10834_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <line
                        x1="4.7"
                        y1="12.4097561"
                        x2="2"
                        y2="12"
                        id="XMLID_10833_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <line
                        x1="4.7"
                        y1="14"
                        x2="2"
                        y2="14.3585366"
                        id="XMLID_10832_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_10831_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        fill="#4550E6"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="4.55"
                        cy="10.5634146"
                        rx="1"
                        ry="1"
                    />
                    <ellipse
                        id="XMLID_10830_"
                        stroke="#4550E6"
                        strokeWidth="0.512195122"
                        fill="#4550E6"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="11.55"
                        cy="10.5634146"
                        rx="1"
                        ry="1"
                    />
                </g>
            </g>
        </g>
    </svg>
}

export default Level5v2;
