import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { Wrapper, Scores, Icon, InfoWrapper, InfoHeader } from './styled';
import * as Icons from './Icons';

const MINIMUM_REQUIRED_POINTS = 10;
const TOP_RANK_POSITION = 3;

const config = [
    { start: 0, end: 16, icon: Icons.Level1 },
    { start: 16, end: 32, icon: Icons.Level2 },
    { start: 32, end: 48, icon: Icons.Level3 },
    { start: 48, end: 64, icon: Icons.Level4 },
    { start: 64, end: 80, icon: Icons.Level5 },
    { start: 80, end: 101, icon: Icons.Level6 }
];

function getItem(percentage, rank, scores) {
    if (scores < MINIMUM_REQUIRED_POINTS) {
        return <Icons.Level1 />;
    }

    if (rank <= TOP_RANK_POSITION) {
        return <Icons.Top />;
    }
    return find(config, item => item.start <= percentage && item.end > percentage).icon();
}

function Rank({
    score,
    rank,
    percentage,
    whitelistPhrasesSpottingScore,
    localization
}) {
    let { displayGamificationWeekly } = window;
    if (typeof displayGamificationWeekly === 'undefined') {
        displayGamificationWeekly = true;
    }
    if (!displayGamificationWeekly) return null;

    const { spottedPhrasesScore, bonusScore } = whitelistPhrasesSpottingScore;
    return (
        <Wrapper>
            <Scores>
                {score + bonusScore + spottedPhrasesScore}
                <InfoWrapper>
                    <InfoHeader>{localization.getText('header.rank.title')}</InfoHeader>
                    <div>{localization.getText('header.rank.message')}</div>
                </InfoWrapper>
            </Scores>
            <Icon>{getItem(percentage, rank, score)}</Icon>
        </Wrapper>
    );
}

Rank.propTypes = {
    score: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    whitelistPhrasesSpottingScore: PropTypes.object,
    localization: PropTypes.object.isRequired
};

Rank.defaultProps = {
    whitelistPhrasesSpottingScore: {
        bonusScore: 0,
        spottedPhrasesScore: 0,
        percentage: 0
    }
};

export default Rank;
