import styled from 'styled-components';
import { Wrapper as CardWrapper } from '../../Card/styled';

const Card = styled(CardWrapper)`
    z-index: 8;
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px;
    padding-left: 32px;
    display: flex;
    flex-direction: row;
    min-height: 0;
    max-height: 105px;

    opacity: 1;
    height: 105px;

    &.${({ className }) => className}-enter {
        height: 0;
        opacity: 0;
    }

    &.${({ className }) => className}-enter-active {
        opacity: 1;
        height: 105px;
        transition: all 300ms ease-out;
    }

    &.${({ className }) => className}-enter-done {
        opacity: 0;
        height: 0;
        transition: all 500ms ease-out;
    }
`;

export default Card;
