import styled from 'styled-components';

const Topic = styled.div`
    font-size: 13px;
    font-weight: 600;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 234px;
`;

export default Topic;
