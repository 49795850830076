import styled from 'styled-components';
import { colors } from '../../../utils';
import { Wrapper } from '../../common/Card/styled';

const ErrorMessage = styled(Wrapper)`
    margin-top: 20px;
    overflow: visible;
    background-color: ${colors.red.sunglo};
    color: white;
    font-weight: bold;
    min-height: auto;
    font-size: 1.1em;
`;

export default ErrorMessage;
