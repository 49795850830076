import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../../utils';

import ListWrapper from './ListWrapper';
import TopicWrapper from './TopicWrapper';
import TopicIcon from './TopicIcon';

const Wrapper = styled.div`
    position: relative;

    display: flex;

    width: auto;

    & ${ListWrapper} {
        margin-top: ${props => `${35 - props.$scrollTop}px`};
        transform: translateY(-5px);
        transition-delay: 300ms;
    }

    &:hover ${ListWrapper} {
        opacity: 1;
        transform: translateY(0);
        z-index: 100;
    }

    > ${TopicWrapper} > ${TopicIcon} {
        color: ${props =>
            !props.$detected ? colors.yellow.golden_tanoi : colors.white.basic};
    }

    &:hover > ${TopicIcon} {
        color: ${colors.blue.saphire};
    }

    &:hover ${TopicWrapper} {
        ${({ $stack, $detected }) =>
            $stack &&
            `
            border-color: ${colors.blue.saphire};
            color: ${colors.blue.saphire};
    
            & > svg {
                fill: ${colors.blue.saphire};
                stroke: ${$detected ? colors.green.aquamarine : colors.white.albaster};
            }
        `};
    }
`;

Wrapper.propTypes = {
    $stack: PropTypes.bool,
    $detected: PropTypes.bool,
    $scrollTop: PropTypes.number
};

Wrapper.defaultProps = {
    $stack: false,
    $detected: false,
    $scrollTop: 0
};

export default Wrapper;
