import styled from 'styled-components';
import colors from '../../../utils/colors';

const Content = styled.div`
    margin-top: 15px;
    max-height: 425px;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;
    font-size: 14px;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.gray.iron};
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
    }
`;

export default Content;
