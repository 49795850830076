import React from 'react';

function EmojiDisaster4() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="26" viewBox="0 0 73 37">
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.05"
                d="M4.768 28.38h63.555M1.102 28.38h2.037M69.953 28.38h2.037"
            />
            <path
                fill="#D6DAFF"
                d="M56.101 35.769c0 .68-6.931 1.231-15.481 1.231s-15.481-.55-15.481-1.231c0-.68 6.931-1.232 15.481-1.232s15.481.551 15.481 1.232"
            />
            <path
                fill="#FFF"
                d="M46.168 17.454a11.345 11.345 0 0 0-16.134 0c-3.845 3.873-4.374 9.822-1.583 14.263-3.58.197-6.238.926-6.238 1.794 0 1.02 3.666 1.847 8.188 1.847h13.983c.355 0 .644-.29.644-.648.399-.304.774-.64 1.14-1.002 4.457-4.49 4.457-11.763 0-16.254"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.05"
                d="M46.168 17.454a11.345 11.345 0 0 0-16.134 0c-3.845 3.873-4.374 9.822-1.583 14.263-3.58.197-6.238.926-6.238 1.794 0 1.02 3.666 1.847 8.188 1.847h13.983c.355 0 .644-.29.644-.648.399-.304.774-.64 1.14-1.002 4.457-4.49 4.457-11.763 0-16.254z"
            />
            <path
                fill="#D6DAFF"
                d="M49.124 27.979a.813.813 0 0 0 1.375-.42l.48-2.418a.824.824 0 0 0-.222-.741l-.557-.56s1.344-6.77 11.56-17.43c.614-.642.6-1.665-.025-2.295L59.41 1.772a1.618 1.618 0 0 0-2.28-.022C46.567 12.057 40.118 13.68 40.118 13.68l-.556-.56a.814.814 0 0 0-.736-.224l-2.4.483a.823.823 0 0 0-.417 1.386l13.115 13.213z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.05"
                d="M48.184 22.388l-6.626-6.674M50.41 23.089l-3.518-12.892M52.738 18.061l-2.21-10.496M55.917 13.26l-1.826-8.675M59.243 9.155l-1.628-7.732M40.492 13.566l12.657 3.797M44.979 11.396l10.695 2.285M49.624 8.167l8.843 1.89M53.803 4.839l7.854 1.678M49.124 27.979a.813.813 0 0 0 1.375-.42l.48-2.418a.824.824 0 0 0-.222-.741l-.557-.56s1.344-6.77 11.56-17.43c.614-.642.6-1.665-.025-2.295L59.41 1.772a1.618 1.618 0 0 0-2.28-.022C46.567 12.057 40.118 13.68 40.118 13.68l-.556-.56a.814.814 0 0 0-.736-.224l-2.4.483a.823.823 0 0 0-.417 1.386l13.115 13.213z"
            />
            <path
                fill="#FFF"
                d="M24.25 31.254c0 .68-2.462 1.231-5.5 1.231-3.039 0-5.5-.55-5.5-1.231 0-.68 2.461-1.232 5.5-1.232 3.038 0 5.5.551 5.5 1.232"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.05"
                d="M24.25 31.254c0 .68-2.462 1.231-5.5 1.231-3.039 0-5.5-.55-5.5-1.231 0-.68 2.461-1.232 5.5-1.232 3.038 0 5.5.551 5.5 1.232z"
            />
        </g>
    </svg>
}

export default EmojiDisaster4;
