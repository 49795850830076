import LocalizationManager from './LocalizationManager';
import { languages } from '.';
import { locale as localeUtils } from '../utils';

const { getShortLanguageCode } = localeUtils;

const getLocalizationManager = locale => {
    const shortLocale = getShortLanguageCode(locale);
    const localization = new LocalizationManager({
        locale,
        phrases: languages[shortLocale]
    });

    return localization;
};

export { getLocalizationManager };
