import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Error({ color, size }) {
  return (
    <svg width={size} height={size} className="error-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path
            fill={color}
            fillRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
  )
}

Error.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

Error.defaultProps = {
    color: colors.yellow.makara,
    size: 16
};

export default Error;
