import styled from 'styled-components';

const UserNameWrapper = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    padding: 0 24px;
`;

export default UserNameWrapper;
