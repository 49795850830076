import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import { captureWarnMessage } from '../tracker/raven';
import { safeStringify } from '../background/utils';

export const detectionTypes = {
    whitelistHead: 'whitelist_head',
    whitelistComplement: 'whitelist_complement',
    blacklist: 'blacklist',
    normal: 'normal',
};

const getSpottingIndicesForWhitelist = (transcript, list, currentList = []) => {
    const result = [];
    const lowerTranscript = transcript.toLowerCase();
    list.forEach(item => {
        let headSpot;
        item.alternatives.find(({text}) => {
            const headIndex = lowerTranscript.indexOf(text.toLowerCase());
            const headEndIndex = headIndex + text.length - 1

            const interception = find(currentList, spot => {
                return (
                    spot.endIndex >= headIndex && spot.startIndex <= headEndIndex
                );
            });

            if (headIndex !== -1 && !interception) {
                headSpot = {
                    startIndex: headIndex,
                    endIndex: headEndIndex,
                    type: detectionTypes.whitelistHead
                };
                return true;
            }

            return false;
        })

        if(headSpot){
            if(item.complements && item.complements.length > 0){
                let complementSpot;
                item.complements.find(({text}) => {
                    const complementIndex = lowerTranscript.indexOf(text.toLowerCase(), headSpot.endIndex);
                    const complementEndIndex = complementIndex + text.length - 1;

                    const interception = find(currentList, spot => {
                        return (
                            spot.endIndex >= complementIndex && spot.startIndex <= complementEndIndex
                        );
                    });

                    if (complementIndex !== -1 && !interception) {
                        complementSpot = {
                            startIndex: complementIndex,
                            endIndex: complementEndIndex,
                            type: detectionTypes.whitelistComplement
                        };
                        return true;
                    }

                    return false;
                });

                if(complementSpot){ // if CKW
                    result.push(headSpot);
                    result.push(complementSpot);
                }
            } else { // if not CKW
                result.push(headSpot)
            }
        }
    });
    
    return result;
};

const getSpottingIndicesForBlacklist = (transcript, list, currentList) => {
    const result = [];
    const lowerTranscript = transcript.toLowerCase();

    list.forEach(item => {
        const index = lowerTranscript.indexOf(item.text.toLowerCase());
        const interception = find(currentList, spot => {
            return (
                spot.endIndex >= index && spot.startIndex <= index + item.text.length - 1
            );
        });
        if (index !== -1 && !interception) {
            result.push({
                startIndex: index,
                endIndex: index + item.text.length - 1,
                type: 'blacklist'
            });
        }
    });
    return result;
};

const getSpottingType = (spottingIndices, startIndex, endIndex) => {
    const spotting = find(spottingIndices, item => {
        return item.startIndex <= startIndex && item.endIndex >= endIndex;
    });

    return spotting ? spotting.type : detectionTypes.normal;
};

const combineNums = words => {
    const result = [];
    let previousWord;
    words.forEach(word => {
        if (
            previousWord &&
            previousWord.normalizedWord === '<NUM>' &&
            word.normalizedWord !== previousWord.normalizedWord
        ) {
            result.push(previousWord);
        }
        previousWord = word;
        if (word.normalizedWord === '<NUM>') return;

        result.push(word);
    });

    if (previousWord && previousWord.normalizedWord === '<NUM>') {
        result.push(previousWord);
    }

    return result;
};

export const parseTranscript = (
    transcript,
    whitelist,
    blacklist,
) => {
    const { normalizedTranscript, words, type: source } = transcript;
    const render = [];

    const whitelistSpotting = getSpottingIndicesForWhitelist(
        normalizedTranscript,
        whitelist,
    );
    const blacklistSpotting = getSpottingIndicesForBlacklist(
        normalizedTranscript,
        blacklist,
        whitelistSpotting
    );
    const spottingIndices = whitelistSpotting.concat(blacklistSpotting);

    let index = 0;
    combineNums(words).forEach(word => {
        render.push({
            text: word.normalizedWord,
            source,
            spotting: getSpottingType(
                spottingIndices,
                index,
                index + word.normalizedWord.length - 1
            ),
            anonymized: word.words[0].anonymized,
            confidence: word.confidence,
            pronunciation: word.pronunciation,
            wordOffsetStart: word.words[0].startTimeMsec,
            wordOffsetEnd: word.words[0].endTimeMsec
        });

        index += word.normalizedWord.length;
        const punctuationText =
            word.punctuation.name && word.punctuation.name !== ' '
                ? `${word.punctuation.name} `
                : word.punctuation.name;

        render.push({
            text: punctuationText,
            source,
            spotting: getSpottingType(
                spottingIndices,
                index,
                index + punctuationText.length
            ),
            anonymized: false
        });

        index += punctuationText.length;
    });

    return [
        {
            render,
            text: transcript.normalizedTranscript
        }
    ];
};

export const combineTimelineTranscript = (
    transcripts,
    whitelist,
    blacklist,
) => {
    if (!transcripts.length) return [];

    const NEW_PHRASE_THRESHOLD = 2000;
    const orderedTranscripts = orderBy(transcripts, 'startOffset');
    const resultTranscript = [];
    let lastTranscript = {};
    let currentBatch = {};

    for (const transcript of orderedTranscripts) {
        let parsedTranscript = null;
        try {
            parsedTranscript = {
                ...transcript,
                transcript: parseTranscript(transcript, whitelist, blacklist)
            };
        } catch (e) {
            const message =
                'Cannot parse transcript, because it has wrong format. It could happen because it was persist in local storage with one version of application and rehydrate with another.';

            console.warn(message);
            console.warn(`Transcript: ${safeStringify(orderedTranscripts)}`);
            captureWarnMessage(message);
            return [];
        }

        if (
            parsedTranscript.type !== lastTranscript.type ||
            parsedTranscript.startOffset > lastTranscript.endOffset + NEW_PHRASE_THRESHOLD
        ) {
            if (lastTranscript.type) resultTranscript.push(currentBatch);
            currentBatch = { ...parsedTranscript };
        } else {
            currentBatch = {
                ...currentBatch,
                transcript: [...currentBatch.transcript, ...parsedTranscript.transcript],
                endOffset: parsedTranscript.endOffset
            };
        }
        lastTranscript = parsedTranscript;
    }

    resultTranscript.push(currentBatch);

    return resultTranscript;
};

export const getTranscriptString = (
    timeTranscript,
    localization,
) => {
    const result = timeTranscript.map(transcriptBlock => {
        const transcript = transcriptBlock.transcript.map(item => item.text).join(' ');

        const name = localization.getText(
            `trainer.transcript.name.${transcriptBlock.type}`
        );
        return `${name}: ${transcript}`;
    });

    return result.join('\n\n');
};
