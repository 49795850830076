import styled from 'styled-components';
import { colors } from '../../../../utils';

const Title = styled.span`
    font-size: 16px;
    font-weight: normal;
    color: ${colors.black.default};
    text-transform: capitalize;
    display: flex;
`;

export default Title;
