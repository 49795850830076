import React from 'react';

function Mic() {
  return <svg width="16px" height="16px" viewBox="0 0 18 18">
        <g transform="translate(-281, -161)">
            <g transform="translate(181, 35)">
                <g transform="translate(100.000000, 126.000000)">
                    <path
                        d="M11.25,8.7 L11.25,4.5 C11.25,3.255 10.245,2.25 9,2.25 C7.845,2.25 6.9075,3.12 6.78,4.2375 L11.25,8.7 Z M13.56,9 C13.2525,9 12.9825,9.225 12.9375,9.5325 C12.9,9.7725 12.8475,10.0125 12.7725,10.23 L13.725,11.1825 C13.95,10.7325 14.115,10.245 14.1975,9.7275 C14.25,9.345 13.95,9 13.56,9 Z M2.7825,3.42 C2.49,3.7125 2.49,4.185 2.7825,4.4775 L6.75,8.4525 L6.75,8.775 C6.75,9.6675 7.2,10.515 7.9725,10.9575 C8.535,11.28 9.03,11.2875 9.4875,11.19 L10.7325,12.435 C10.2,12.6825 9.6075,12.825 9,12.825 C7.095,12.825 5.34,11.4975 5.0625,9.5325 C5.0175,9.225 4.7475,9 4.44,9 C4.05,9 3.75,9.345 3.8025,9.7275 C4.1475,11.9475 6.0225,13.7025 8.25,14.04 L8.25,15.75 C8.25,16.1625 8.5875,16.5 9,16.5 C9.4125,16.5 9.75,16.1625 9.75,15.75 L9.75,14.04 C10.4325,13.9425 11.0775,13.7025 11.6625,13.365 L14.28,15.9825 C14.5725,16.275 15.045,16.275 15.3375,15.9825 C15.63,15.69 15.63,15.2175 15.3375,14.925 L3.84,3.42 C3.5475,3.1275 3.075,3.1275 2.7825,3.42 Z"
                        fill="#1D1D1D"
                    />
                </g>
            </g>
        </g>
    </svg>
}

export default Mic;
