import { every, get, omit, isEmpty, entries } from 'lodash';
import { getDeviceSampleRate } from '../utils';
import { calculateScore } from '../../utils/points';
import {isKeyphraseIntegrationMode, pathSpottingConfigMode} from "../utils/asr";
import {KEYPHRASE_GROUP_NAME_FOR_START, KEYPHRASE_GROUP_NAME_FOR_STOP, SAMPLE_RATE_HERTZ} from "../constants";

export const getSystemInfo = state => get(state, 'auth.systemInfo') || {};
export const getUserData = state => get(state, 'auth.userData') || {};

export const getSafeSystemInfo = state => {
    return omit(getSystemInfo(state), ['companySecretToken', 'toJSON']);
};

export const getAppConfig = state => get(state, 'config.app');

export const getCallDetectionAppWhitelistConfig = state => get(getAppConfig(state), 'callDetection.appWhitelist', [])

export const getSuggestionCallFrequency = state => get(getAppConfig(state), [
    'suggestionCallFrequency',
], 0);

export const getSilencDetectionThreshold = state =>
    get(getAppConfig(state), [
        'absoluteSilenceDefinition',
        'consecutiveAbsoluteSilenceToEndVoicedChunkMsec'
    ]);

export const shouldSendSystemSoundToAsr = state =>
    get(getAppConfig(state), 'recognitionUseSystem', false);

export const enableAnonymization = state =>
    get(getAppConfig(state), 'enableAnonymization', false);

export const getIsTrainerEnabled = state =>
    get(getAppConfig(state), 'enableTrainer', true);

export const getToken = state => get(state, 'auth.token');

export const getAsrConfig = state => {
    const asrConfig = get(state, 'asr.config');

    // we need to mark groups as invisible and set theirs points to 0
    return {
        ...asrConfig,
        spottingConfig: pathSpottingConfigMode(asrConfig.spottingConfig, isKeyphraseIntegrationMode(getAppConfig(state)))
    };
}

export const isCallDetectorInitialized = state => get(state, 'call.isInitialized');

export const isCallHolded = state => get(state, 'call.isOnHold');

export const isCallStarted = state => get(state, 'call.isStarted');

export const getSessionDurationMsec = state => get(state, 'call.sessionDurationMsec');

export const getSessionStartOffsetMsec = state => get(state, 'call.sessionStartOffsetMsec');

export const getSessionEndOffsetMsec = state => get(state, 'call.sessionEndOffsetMsec');

export const callDetectionOffset = state => getSessionDurationMsec(state) - getSessionStartOffsetMsec(state);

const getResultScore = (state, whitelist) => {
    const score = calculateScore(whitelist);
    if (isEmpty(whitelist)) {
        return score;
    }

    const bonusScore = every(whitelist, 'count') ? state.config.bonusScore : 0;
    return score + bonusScore;
};

export function getLatestAsrResults(state) {
    const { micVoiceMetrics, micResults, timelineTranscripts, config, battleCard } = get(
        state,
        'asr'
    );

    const keyPhraseIntegrationWords = {
        start: [],
        stop: [],
    };

    // we need to filter out start/stop phrases
    if(isKeyphraseIntegrationMode(getAppConfig(state))){
        const {whitelist} = getAsrConfig(state).spottingConfig;
        whitelist.forEach(item => {
            if([KEYPHRASE_GROUP_NAME_FOR_START].includes(item.groupName)){
                keyPhraseIntegrationWords.start.push(...item.head);
                return false;
            }
            if([KEYPHRASE_GROUP_NAME_FOR_STOP].includes(item.groupName)){
                keyPhraseIntegrationWords.stop.push(...item.head);
                return false;
            }
        })
    }

    micResults.whitelist = micResults.whitelist.filter(item => {
        return ![KEYPHRASE_GROUP_NAME_FOR_START, KEYPHRASE_GROUP_NAME_FOR_STOP].includes(item.text);
    });

    const {
        id,
        lastCallId,
        isStarted,
        sessionDurationMsec: duration,
    } = get(state, 'call');

    return {
        ...micResults,
        ...micVoiceMetrics,
        language: config.languageCode,
        callDuration: Math.floor(duration / 1000),
        isStarted,
        timelineTranscripts,
        resultScore: getResultScore(state, micResults.whitelist),
        daemonVersion: get(state, 'main.version'),
        battleCard,
        callId: isStarted ? id : lastCallId,
        keyPhraseIntegrationWords,
    };
}

// getEnabledVendorConfiguration returns an array with vendor name and vendor configuration
export function getEnabledASRVendor({ vendors = {} }) {
    const asrEntries = entries(vendors);
    const enabledEntries = asrEntries.filter(([, config]) => !!config.enabled);

    if (enabledEntries.length !== 1) {
        throw new Error("ASR Vendor Configuration Error - one ASR Vendor must be enabled")
    }

    return enabledEntries[0]
}

export function getAsrErrorState(state) {
    return { asrConfigErrors: get(state, 'asr.asrConfigErrors', {}) };
}

export function isMainConnectionOpen(state) {
    return get(state, 'main.mainConnectionOpened', false);
}

export function isAudioSystemInfoConnectionOpen(state) {
    return get(state, 'main.systemInfoConnectionOpened', false);
}

export function getCallId(state) {
    return get(state, 'call.id');
}

export function getLastCallId(state) {
    return get(state, 'call.lastCallId');
}

export function getMetaCallId(state) {
    return get(state, 'call.metaCallID');
}

export function getCallDetectionSessionId(state) {
    return get(state, 'call.sessionId');
}

export function isReloading(state) {
    return get(state, 'common.isReloading');
}

export function isImmediate(state) {
    return get(state, 'common.isImmediate');
}

export function shouldNotifyUiAboutReload(state) {
    return !isImmediate(state) && isReloading(state);
}

export function isCountDownOver(state) {
    return get(state, 'common.countdown', 0) === 0;
}

export function getCountDown(state) {
    return get(state, 'common.countdown', 0);
}

export function isReadyToReload(state) {
    return isReloading(state) && isCountDownOver(state);
}

export function getUserScores(state) {
    return get(state, 'user.userScore') || {};
}

export function getUserScoresError(state) {
    return get(state, 'user.userScoreError');
}

export function getUnfinishedCallDurationMsec(state) {
    return getSessionDurationMsec(state) - getSessionStartOffsetMsec(state);
}

export function getPrefixOffset(state) {
    return get(getAppConfig(state), 'callDetection.prefixOffset', 0);
}

export function getCallDurationMsec(state) {
    return (
        getSessionEndOffsetMsec(state) -
        getSessionStartOffsetMsec(state) +
        getPrefixOffset(state)
    );
}

export function getExplicitIntegrationSource(state) {
    return get(getAppConfig(state), 'callDetection.explicitIntegrationSource');
}

export function isRealTimeCall(state) {
    return  get(getAppConfig(state), 'recordCall') !== true
}

export function getCallMetadataOnlyFlag(state) {
    return get(getAppConfig(state), 'enableCallMetadataOnly') === true;
}

export function getCallTranscriptOnlyFlag(state) {
    return get(getAppConfig(state), 'enableCallTranscriptOnly') === true;
}

export function getEnableRecorderFlag(state) {
    return get(getAppConfig(state), 'enableRecorder') === true;
}

export function getEnableAsrDebugFlag(state) {
    return get(getAppConfig(state), 'enableAsrDebug');
}

export function getDefaultCallMode(state) {
    return get(getAppConfig(state), 'defaultCallMode', 0);
}

export function geCallMode(state) {
    return get(state, 'call.callMode', null);
}


export function buildRecognitionConfig(state) {
    return {
        shouldSendSystemSound: shouldSendSystemSoundToAsr(state),
        enableAnonymization: enableAnonymization(state),
        sessionId: getCallDetectionSessionId(state),
        sampleRateHertz: getDeviceSampleRate(state) || SAMPLE_RATE_HERTZ,
        systemInfo: getSafeSystemInfo(state),
        jwt: getToken(state),
        callId: getCallId(state),
        ...getAsrConfig(state),
        enableCallMetadataOnly: getCallMetadataOnlyFlag(state),
        enableAsrDebug: getEnableAsrDebugFlag(state)
    };
}

export function getRecognitionForgetStreamFlag(state) {
    return get(getAppConfig(state), 'recognition.system.forgetStream');
}

export function getRealtimeOnlyCallsPercent(state) {
    return get(getAppConfig(state), 'realtimeOnlyCallsPercent');
}


export function getDisplayGamificationWeekly(state) {
    return get(getAppConfig(state), 'displayGamificationWeekly');
}

export function getDisplayGamificationPerCall(state) {
    return get(getAppConfig(state), 'displayGamificationPerCall');
}

export function getIsTrainerActive(state) {
    return get(state, 'main.isTrainerActive', true);
}

export function isProfileLoaded(state) {
    return !!get(state, 'user.profile');
}

export function getUserCompanyId(state) {
    return get(state, 'user.profile.company.id');
}

export function getUserCompanyName(state) {
    return get(state, 'user.profile.company.name');
}

export function getUserName(state) {
    return get(state, 'user.profile.companyUsername');
}

export function getUserEmail(state) {
    return get(state, 'user.profile.email');
}

export function getRemoveCall(state) {
    return get(state, 'call.removeCall', false);
}

