import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Lock(props) {
  return <svg width={props.size} viewBox="0 0 14 18">
        <path
            fill={props.color}
            fillRule="evenodd"
            d="M12.25 6h-.875V4.286C11.375 1.886 9.45 0 7 0S2.625 1.886 2.625 4.286V6H1.75C.787 6 0 6.771 0 7.714v8.572C0 17.229.787 18 1.75 18h10.5c.963 0 1.75-.771 1.75-1.714V7.714C14 6.771 13.213 6 12.25 6zM7 13.714c-.963 0-1.75-.771-1.75-1.714s.787-1.714 1.75-1.714 1.75.771 1.75 1.714-.787 1.714-1.75 1.714zM9.713 6H4.288V4.286c0-1.457 1.224-2.657 2.712-2.657 1.488 0 2.713 1.2 2.713 2.657V6z"
        />
    </svg>
}

Lock.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

Lock.defaultProps = {
    size: 14,
    color: `${colors.black.default}`
};

export default Lock;
