import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Eye(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.size}
            viewBox="0 -960 960 960"
            width={props.size}>
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-146 0-264-83T40-500q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Z"
            />
        </svg>
    );
}

Eye.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

Eye.defaultProps = {
    size: 14,
    color: `${colors.black.default}`
};

export default Eye;
