import React from 'react';

function EmojiNormal10() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="42" height="46" viewBox="0 0 42 46">
        <g fill="none" fillRule="evenodd" transform="translate(1)">
            <circle cx="20" cy="20.5" r="20" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                d="M20 .5c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm0 37c-10.1 0-18.2-8.2-18.2-18.2C1.8 9.2 10 1.1 20 1.1s18.2 8.2 18.2 18.2S30.1 37.5 20 37.5z"
            />
            <ellipse
                cx="20"
                cy="44.5"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="16"
                ry="1.5"
            />
            <circle
                cx="20"
                cy="20.5"
                r="20"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="32"
                cy="25.5"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <ellipse
                cx="8"
                cy="25.5"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M34 19.5c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8M9.5 19.5c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8"
            />
            <g
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path
                    fill="#C1C4E6"
                    d="M13.9 28.5c-.3 0-.5.1-.7.3-.2.2-.3.5-.2.8.5 3.4 3.5 5.9 7 5.9s6.5-2.5 7-5.9c0-.3 0-.5-.2-.8-.2-.2-.4-.3-.7-.3H13.9z"
                />
                <path
                    fill="#8D94F2"
                    d="M25.6 32.8c-1.4-.8-3.4-1.3-5.6-1.3-2.2 0-4.2.5-5.6 1.3 1.3 1.7 3.3 2.7 5.6 2.7s4.3-1 5.6-2.7z"
                />
            </g>
        </g>
    </svg>
}

export default EmojiNormal10;
