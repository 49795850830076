import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const List = styled.ul`
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: ${props => (props.$open ? 'auto' : '0px')};

    list-style: none;
    padding: 0;
    margin: 0;

    background-color: ${colors.white.basic};

    border-radius: 2px;
    box-shadow: ${props =>
        props.$open ? `0 2px 4px 0 ${colors.blue.hawkes_blue}` : 'none'};

    transition: height 0.88s cubic-bezier(0.3, 0, 0, 1);

    & > * {
        display: ${props => (props.$open ? 'flex' : 'none')};
    }
`;

List.propTypes = {
    $open: PropTypes.bool
};

List.defaultProps = {
    $open: false
};

export default List;
