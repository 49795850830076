import styled from 'styled-components';

import { colors } from '../../../../utils';

const Title = styled.div`
    width: 223px;

    color: ${colors.blue.madison};

    font-size: 16px;
    font-weight: 600;
`;

export default Title;
