import React from 'react';

function EmojiDisaster3() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="38" height="47" viewBox="0 0 38 47">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(0 3)">
                <ellipse
                    cx="19"
                    cy="42.4"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="12.1"
                    ry="1.6"
                />
                <path
                    fill="#F8F9FF"
                    fillRule="nonzero"
                    d="M19 .5c-.7 0-1.3.6-1.3 1.3v36c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-1.1c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v1.1c0 2.2 1.8 4 4 4s4-1.8 4-4v-36c.2-.7-.4-1.3-1.1-1.3z"
                />
                <path fill="#D6DAFF" fillRule="nonzero" d="M17.7 16.6h2.5v9.5h-2.5z" />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.7 1.8v36c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-1.1c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v1.1c0 2.2 1.8 4 4 4s4-1.8 4-4v-36c0-.7-.6-1.3-1.3-1.3-.5 0-1.1.6-1.1 1.3z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M4 19.7c1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9 1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9 1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9 1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9.6 0 1.1.1 1.7.2.7.2 1.4-.5 1.3-1.2-1.7-8.4-9.1-14.8-18-14.8S2.7 10.2 1 18.7c-.2.8.6 1.4 1.3 1.2.5-.2 1.1-.2 1.7-.2z"
                />
                <path
                    fill="#F8F9FF"
                    fillRule="nonzero"
                    d="M19 8c8.1 0 15 4.6 18 11.2.1-.2.1-.3 0-.5-1.7-8.4-9.1-14.8-18-14.8S2.7 10.2 1 18.7v.5C4 12.6 10.9 8 19 8z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 19.7c1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9 1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9 1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9 1.2 0 2.3.3 3.2.9.3.2.7.2 1.1 0 .9-.6 2-.9 3.2-.9.6 0 1.1.1 1.7.2.7.2 1.4-.5 1.3-1.2-1.7-8.4-9.1-14.8-18-14.8S2.7 10.2 1 18.7c-.2.8.6 1.4 1.3 1.2.5-.2 1.1-.2 1.7-.2z"
                />
            </g>
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M7.4 9.6L5.2 4.4c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3L2 9.6c-.8 1.9.6 4 2.7 4 2.1.1 3.5-2 2.7-4z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M4.7 11.5c-1.3 0-2.3-.8-2.7-1.8-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4c-.4 1-1.4 1.8-2.7 1.8z"
            />
            <path
                stroke="#4550E6"
                d="M7.4 9.6L5.2 4.4c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3L2 9.6c-.8 1.9.6 4 2.7 4 2.1.1 3.5-2 2.7-4z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M16.1 15.2L13.9 10c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-2.2 5.2c-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M13.4 17.1c-1.3 0-2.3-.8-2.7-1.8-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4c-.4 1-1.4 1.8-2.7 1.8z"
            />
            <path
                stroke="#4550E6"
                d="M16.1 15.2L13.9 10c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-2.2 5.2c-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4z"
            />
            <g>
                <path
                    fill="#F8F9FF"
                    fillRule="nonzero"
                    d="M27.5 6.8l-2.2-5.2c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-2.2 5.2c-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M24.8 8.6c-1.3 0-2.3-.8-2.7-1.8-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4c-.4 1.1-1.4 1.8-2.7 1.8z"
                />
                <path
                    stroke="#4550E6"
                    d="M27.5 6.8l-2.2-5.2c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-2.2 5.2c-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4z"
                />
            </g>
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M35.9 13.6l-2.2-5.2c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-2.2 5.2c-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M33.2 15.4c-1.3 0-2.3-.8-2.7-1.8-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4c-.4 1.1-1.4 1.8-2.7 1.8z"
            />
            <path
                stroke="#4550E6"
                d="M35.9 13.6l-2.2-5.2c-.1-.2-.3-.3-.5-.3s-.4.1-.5.3l-2.2 5.2c-.8 1.9.6 4 2.7 4s3.5-2.1 2.7-4z"
            />
        </g>
    </svg>
}

export default EmojiDisaster3;
