import styled from 'styled-components';
import { transparentize } from 'polished';

import { colors } from '../../../../utils';

const InfoWrapper = styled.div`
    position: absolute;
    z-index: -1;
    top: 100%;

    pointer-events: none;

    width: 210px;
    padding: 15px;
    margin-left: -25px;

    background-color: ${colors.white.basic};
    border-radius: 1px;
    box-shadow: 0 2px 6px 0 ${colors.blue.link_water};
    color: ${colors.black.default};

    opacity: 0;
    transform: translateY(-5px);

    transition: all 0.25s ease;

    font-size: 14px;
    font-weight: normal;
    text-align: left;

    &:after,
    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 20%;
        height: 0;
        width: 0;
        margin-left: -6px;
        border: 7px solid transparent;

        transform: rotate(0.5turn);
    }

    &:after {
        border-top-color: ${colors.white.basic};
    }

    &:before {
        margin-bottom: 0.5px;
        border-top-color: ${transparentize(0.6, colors.blue.link_water)};
    }
`;

export default InfoWrapper;
