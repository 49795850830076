import React from 'react';

function Team(props) {
  return <svg width="50" height="50" viewBox="0 0 50 50" {...props}>
        <g transform="translate(5 5)">
            <circle cx="20" cy="20" r="20" fill="#4550E6" />
            <g transform="translate(8 7)">
                <path
                    fill="#FFF"
                    d="M12 5c1.933 0 3.5 1.567 3.5 3.5S13.933 12 12 12s-3.5-1.567-3.5-3.5S10.067 5 12 5M5 7.5c.56 0 1.08.15 1.53.42-.15 1.43.27 2.85 1.13 3.96-.5.96-1.5 1.62-2.66 1.62-1.657 0-3-1.343-3-3s1.343-3 3-3m14 0c1.657 0 3 1.343 3 3s-1.343 3-3 3c-1.16 0-2.16-.66-2.66-1.62.86-1.11 1.28-2.53 1.13-3.96.45-.27.97-.42 1.53-.42M5.5 17.75C5.5 15.68 8.41 14 12 14s6.5 1.68 6.5 3.75v1.75h-13v-1.75M0 19.5V18c0-1.39 1.89-2.56 4.45-2.9-.59.68-.95 1.62-.95 2.65v1.75H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65 2.56.34 4.45 1.51 4.45 2.9v1.5z"
                />
            </g>
        </g>
    </svg>
}

export default Team;
