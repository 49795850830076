import {CallEvents, CallMode} from "../../constants";

// @docs
// https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/1334181913/Vodafone+COPS+For+now+COPS+Cable+Care#API-Events-Description

export const schema = {
    'type': 'object',
    'properties': {
        'version': {'type': 'string', 'enum': ['1.0']},
        'event_type': {'type': 'string', 'enum': ['start', 'end', 'hold', 'unhold', 'change', 'ping']},
        'call_recording_mode': {'type': 'string', 'enum': ['no-action', 'only-meta', 'default', 'agent', 'both', 'customer']},
        'forget_audio_and_transcript': {'type': 'boolean'},
        'source': {'type': 'string'},
        'call_id': {'type': 'string'},
        'timestamp': {'type': 'string','format': 'date-time'},
        'agent_id': {'type': 'string'},
        'company_id': {'type': 'string'},
        'meta': {'type': 'object'}
    },
    'required': ['event_type'],
    "if": {
        "properties": { "event_type": { "const": "ping" } },
    },
    "else": {
        'required': ['agent_id', 'company_id', 'call_id', 'timestamp']
    },
}

const COPS_STRING_MODE_MAPPING = {
    'no-action': CallMode.NONE, // do not start recording
    'only-meta': CallMode.NONE, // do not start recording
    'default': CallMode.DEFAULT, // take call mode from app settings
    'agent': CallMode.AGENT, // record only agent side
    'customer': CallMode.CUSTOMER, // record only customer side
    'both': CallMode.BOTH, // record only both side
};

export function parse(event) {
    let callEvent = null;
    let callMode = null;

    if (event.call_recording_mode !== undefined) {
        callMode = COPS_STRING_MODE_MAPPING[event.call_recording_mode];
    }

    if (event.event_type === 'start') {
        callEvent = CallEvents.START;
    } else if (event.event_type === 'end') {
        callEvent = CallEvents.END;
    } else if (event.event_type === 'ping') {
        callEvent = CallEvents.PING;
    } else if (event.event_type === 'change') {
        callEvent = CallEvents.CHANGE;
    } else if (event.event_type === 'hold') {
        callEvent = CallEvents.HOLD;
    } else if (event.event_type === 'unhold') {
        callEvent = CallEvents.UNHOLD;
    }

    return [callEvent, callMode, !!event.forget_audio_and_transcript];
}
