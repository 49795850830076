import React from 'react';
import PropTypes from 'prop-types';

function CallEnded(props) {
  return <svg width="28" height="28" viewBox="0 0 17 13" style={props.style}>
        <g stroke="none" strokeWidth="1" transform="translate(0,-1)">
            <path
                d="M6.09115428,12 C5.85157179,12 5.61178097,11.9073459 5.42865661,11.7216147 L1.27512952,7.51029701 C0.908672468,7.13883459 0.908255802,6.53616008 1.27408785,6.16406304 C1.6399199,5.79217754 2.23366782,5.79175446 2.59991653,6.16321689 L6.09115428,9.70289838 L14.4000835,1.27857065 C14.7663322,0.90668515 15.3598718,0.907319767 15.7259121,1.27941681 C16.0917442,1.65151385 16.0913275,2.25418836 15.7248705,2.62565078 L6.75365196,11.7216147 C6.5705276,11.9073459 6.33073678,12 6.09115428,12"
                id="ico-checkmark-purple"
                stroke="#4550E6"
                strokeWidth="0.785714286"
                fill="#4550E6"
            />
        </g>
    </svg>
}
CallEnded.propTypes = {
    style: PropTypes.object
};

CallEnded.defaultProps = {
    style: {}
};

export default CallEnded;
