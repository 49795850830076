import styled from 'styled-components';
import { colors } from '../../../utils';

export default styled.div`
    margin-top: 12px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: ${colors.white.basic};
`;
