import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const Wrapper = styled.div`
    position: relative;

    height: 43px;

    border: solid 1px ${props => (props.$open ? 'transparent' : colors.blue.link_water)};
    border-radius: 2px;

    cursor: pointer;

    transition: background, 0.88s cubic-bezier(0.3, 0, 0, 1),
        border-color 0.33s cubic-bezier(0.3, 0, 0, 1);

    &:hover {
        background-color: ${props =>
            props.$open ? 'transparent' : colors.blue.link_water};
    }
`;

Wrapper.propTypes = {
    $open: PropTypes.bool
};

Wrapper.defaultProps = {
    $open: false
};

export default Wrapper;
