import { takeLatest, call, put, select, take } from 'redux-saga/effects';
import { delay, eventChannel } from 'redux-saga';

import { getTrainer } from '../selectors/trainer';

import {
    SET_RESULT_POPUP_VISIBILITY,
    SET_CALL_RESULT,
    SET_CALL_RESULT_TIMER,
    TOGGLE_TRANSCRIPT_COPY_BUTTON,
    COPY_TO_CLIPBOARD
} from '../actions/actionTypes/trainer';

import {
    setResultPopupVisibilityOnLoad,
    setCallResultTimer,
    windowOnLoad,
    setTranscriptCopyButtonAvailability
} from '../actions/trainer';

import { captureException } from '../../tracker/raven';

const CLOSE_POPUP_TIMEOUT = 10000;

export function* doSetTimer() {
    const trainer = yield select(getTrainer);
    if (!trainer.isCallResultVisible || !trainer.isLastCallResult) return;

    yield call(delay, CLOSE_POPUP_TIMEOUT);
    if (trainer.isCallResultVisible && trainer.isLastCallResult) {
        yield put({
            type: SET_RESULT_POPUP_VISIBILITY,
            payload: { isCallResultVisible: false }
        });
    }
}

const windowEventsChannel = () => {
    return eventChannel(emit => {
        window.addEventListener('load', () => {
            emit(windowOnLoad());
        });

        return () => {};
    });
};

export function* watchWindowEvents() {
    const channel = yield call(windowEventsChannel);
    yield take(channel);
    yield put(setCallResultTimer());
    yield put(setResultPopupVisibilityOnLoad());
}

export function* handleResultPopupTimer() {
    yield takeLatest(SET_CALL_RESULT_TIMER, doSetTimer);
    yield takeLatest(SET_CALL_RESULT, doSetTimer);
    yield takeLatest(SET_RESULT_POPUP_VISIBILITY, doSetTimer);
    yield call(watchWindowEvents);
}

export function* onTranscriptCopyButtonClick() {
    yield put(setTranscriptCopyButtonAvailability(false));
    yield call(delay, 1000);
    yield put(setTranscriptCopyButtonAvailability(true));
}

export function* handleTranscriptCopyButton() {
    yield takeLatest(TOGGLE_TRANSCRIPT_COPY_BUTTON, onTranscriptCopyButtonClick);
}

export function* onCopyToClipboard(action) {
    const textArea = document.createElement('textarea');
    textArea.value = action.payload;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        yield document.execCommand('copy');
    } catch (err) {
        const errorMessage = 'Unable to copy text';
        captureException(new Error(errorMessage));
        console.error(errorMessage, err);
    }

    document.body.removeChild(textArea);
}

export function* handleCopyToClipboard() {
    yield takeLatest(COPY_TO_CLIPBOARD, onCopyToClipboard);
}

export default [
    handleResultPopupTimer(),
    handleTranscriptCopyButton(),
    handleCopyToClipboard()
];
