import styled from 'styled-components';
import { colors } from '../../../utils';

export const StatusIndicator = styled.div`
    display: inline-flex;
    align-items: center;
    background-color: ${colors.gray.gray};
    padding: 4px 6px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
`;

export const Dot = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${colors.green.glow};
    border-radius: 50%;
    margin-left: 4px;
    fill: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;
