import React from 'react';

function Level2V2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
        <g fill="none" fillRule="evenodd" transform="translate(0 1)">
            <ellipse
                cx="9.5"
                cy="19.769"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="9.5"
                ry="1"
            />
            <g transform="translate(2)">
                <path
                    fill="#8D94F2"
                    fillRule="nonzero"
                    d="M5.11 9.136S1.78 7.227 1.124 7.546c-.656.363.281 3.727 2.344 4.545 2.062.818 1.64-2.955 1.64-2.955z"
                />
                <path
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    d="M1.36 8.682c.562-.273 3.046 1.045 3.796 1.454 0-.545-.047-1-.047-1s-3.328-1.909-3.984-1.59c-.281.136-.281.909.047 1.772 0-.318.047-.59.187-.636z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    d="M5.11 9.136S1.78 7.227 1.124 7.546c-.656.363.281 3.727 2.344 4.545 2.062.818 1.64-2.955 1.64-2.955z"
                />
                <path
                    fill="#8D94F2"
                    fillRule="nonzero"
                    d="M9.89 9.136s3.329-1.909 3.985-1.59c.656.363-.281 3.727-2.344 4.545-2.062.818-1.64-2.955-1.64-2.955z"
                />
                <path
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    d="M13.64 8.682c-.562-.273-3.046 1.045-3.796 1.454 0-.545.047-1 .047-1s3.328-1.909 3.984-1.59c.281.136.281.909-.047 1.772 0-.318-.047-.59-.187-.636z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    d="M9.89 9.136s3.329-1.909 3.985-1.59c.656.363-.281 3.727-2.344 4.545-2.062.818-1.64-2.955-1.64-2.955z"
                />
                <ellipse
                    cx="7.5"
                    cy="13.182"
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    rx="4.922"
                    ry="5.682"
                />
                <path
                    fill="#8D94F2"
                    fillRule="nonzero"
                    d="M11.344 9.682A4.993 4.993 0 0 1 7.5 11.5a4.993 4.993 0 0 1-3.844-1.818C4.546 8.364 5.953 7.5 7.5 7.5c1.547 0 2.953.864 3.844 2.182z"
                />
                <ellipse
                    cx="7.5"
                    cy="13.182"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    rx="4.922"
                    ry="5.682"
                />
                <ellipse
                    cx="7.5"
                    cy="5"
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    rx="4.922"
                    ry="4.773"
                />
                <path
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    d="M7.5 1.59c2.484 0 4.547 1.774 4.875 4.092.047-.227.047-.455.047-.682 0-2.636-2.203-4.773-4.922-4.773S2.578 2.364 2.578 5c0 .227 0 .455.047.682C2.953 3.364 5.015 1.59 7.5 1.59z"
                />
                <ellipse
                    cx="7.5"
                    cy="5"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    rx="4.922"
                    ry="4.773"
                />
                <path
                    fill="#ECEDFC"
                    fillRule="nonzero"
                    d="M14.578 12.09l-1.547 1.5a.438.438 0 0 1-.562 0l-2.063-2a.438.438 0 0 0-.562 0l-2.063 2a.438.438 0 0 1-.562 0l-2.063-2a.438.438 0 0 0-.562 0l-2.063 2a.438.438 0 0 1-.562 0l-1.547-1.5c-.14-.135-.375-.045-.328.137.328 5.046 3.515 7.546 7.406 7.546 3.89 0 7.078-2.5 7.406-7.546 0-.182-.234-.272-.328-.136z"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M.422 13.5l1.547 1.318c.14.137.422.137.562 0l2.063-1.727a.438.438 0 0 1 .562 0l2.063 1.727c.14.137.422.137.562 0l2.063-1.727a.438.438 0 0 1 .562 0l2.063 1.727c.14.137.422.137.562 0l1.547-1.318c.047-.045.14-.045.188-.045.046-.41.14-.819.14-1.228 0-.182-.234-.272-.328-.136l-1.547 1.5a.438.438 0 0 1-.562 0l-2.063-2a.438.438 0 0 0-.562 0l-2.063 2a.438.438 0 0 1-.562 0l-2.063-2a.438.438 0 0 0-.562 0l-2.063 2a.438.438 0 0 1-.562 0l-1.547-1.5c-.14-.136-.375-.046-.328.136.047.455.093.864.14 1.228.094 0 .141 0 .188.045z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    d="M14.578 12.09l-1.547 1.5a.438.438 0 0 1-.562 0l-2.063-2a.438.438 0 0 0-.562 0l-2.063 2a.438.438 0 0 1-.562 0l-2.063-2a.438.438 0 0 0-.562 0l-2.063 2a.438.438 0 0 1-.562 0l-1.547-1.5c-.14-.135-.375-.045-.328.137.328 5.046 3.515 7.546 7.406 7.546 3.89 0 7.078-2.5 7.406-7.546 0-.182-.234-.272-.328-.136z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M6.938 5.955L6 6.636c-.188.137-.234.41-.094.637l.657.863a1.16 1.16 0 0 0 1.875 0l.656-.863c.14-.182.094-.5-.094-.637l-.938-.681a1.013 1.013 0 0 0-1.124 0z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M5.953 7.364c.094-.091.89-.5.984-.546.329-.182.75-.182 1.079 0 .093.046.89.5.984.546l.047-.091c.14-.182.094-.5-.094-.637l-.937-.681a.938.938 0 0 0-1.079 0L6 6.636c-.188.137-.234.41-.094.637l.047.09z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    d="M6.938 5.955L6 6.636c-.188.137-.234.41-.094.637l.657.863a1.16 1.16 0 0 0 1.875 0l.656-.863c.14-.182.094-.5-.094-.637l-.938-.681a1.013 1.013 0 0 0-1.124 0z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    d="M5.813 7.045h.046S7.08 7.5 7.5 7.5c.422 0 1.64-.455 1.64-.455h.047"
                />
                <ellipse
                    cx="10.031"
                    cy="4.364"
                    fill="#4550E6"
                    fillRule="nonzero"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    rx="1"
                    ry="1"
                />
                <ellipse
                    cx="4.969"
                    cy="4.364"
                    fill="#4550E6"
                    fillRule="nonzero"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".5"
                    rx="1"
                    ry="1"
                />
                <ellipse
                    cx="10.828"
                    cy="6.182"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1"
                    ry="1"
                />
                <ellipse
                    cx="4.172"
                    cy="6.182"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1"
                    ry="1"
                />
            </g>
        </g>
    </svg>
}

export default Level2V2;
