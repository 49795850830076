import {call, fork, put, takeLatest} from 'redux-saga/effects';
import {isEmpty} from 'lodash';
import {delay} from 'redux-saga';
import moment from 'moment';

import {
    RECEIVE_CRX_ASR_ERROR_DATA,
    RECEIVE_CRX_CALL_DATA,
    RECEIVE_CRX_CALL_ENDED,
    RECEIVE_CRX_CALL_STARTED,
    RECEIVE_CRX_INITIAL_CALL_DATA,
} from '../actions/actionTypes/CRX';

import {
    CONFIGURATION_LOADED,
    SET_ASR_CONFIG_ERRORS,
    SET_BLACKLIST,
    SET_CALL_RESULT,
    SET_ELAPSED_SECONDS,
    SET_IS_STARTED,
    SET_LOUDNESS,
    SET_NEW_SPEECH_RATE,
    SET_PHRASE_INTEGRATION_WORDS,
    SET_SPEECH_PACE,
    SET_SPEECH_RATE,
    SET_SPEECH_RATIO,
    SET_TIMELINE_TRANSCRIPT,
    SET_WHITELIST
} from '../actions/actionTypes/trainer';
import {hideNewCallMessage} from '../actions/trainer';
import {CALL_STATE_TRANSITION_DURATION} from '../../background/constants';
import {closeBattleCard, openBattleCard} from '../actions/battleCard';
import {SET_CALL_ID} from '../actions/actionTypes/debug';

export function* doProcessASRData(action) {
    const {
        blacklist = null,
        whitelist = null,
        newSpeechRate = null,
        speechRatio = null,
        speechPace = null,
        speechRate = null,
        loudness = null,
        callDuration = null,
        timelineTranscripts = null,
        battleCard,
        keyPhraseIntegrationWords = null
    } = action.payload;

    if (blacklist) {
        yield put({ type: SET_BLACKLIST, payload: { blacklist } });
    }

    if (whitelist) {
        yield put({ type: SET_WHITELIST, payload: { whitelist } });
    }

    if (loudness && speechRatio) {
        yield put({ type: SET_LOUDNESS, payload: { loudness } });
    }

    if (timelineTranscripts) {
        yield put({ type: SET_TIMELINE_TRANSCRIPT, payload: { timelineTranscripts } });
    }

    if (battleCard) {
        yield put(openBattleCard(battleCard));
    }

    if (typeof callDuration === 'number' && callDuration >= 0) {
        yield put({ type: SET_ELAPSED_SECONDS, payload: { elapsedSeconds: callDuration } });
    }

    if (newSpeechRate) {
        yield put({ type: SET_NEW_SPEECH_RATE, payload: { newSpeechRate } });
    }

    if (speechPace) {
        yield put({ type: SET_SPEECH_PACE, payload: { speechPace } });
    }

    if(speechRate) {
        yield put({type: SET_SPEECH_RATE, payload: {speechRate}});
    }

    if (speechRatio) {
        yield put({ type: SET_SPEECH_RATIO, payload: { speechRatio } });
    }

    if(keyPhraseIntegrationWords){
        yield put({type: SET_PHRASE_INTEGRATION_WORDS, payload: {
            keyPhraseIntegrationWords
        }});
    }
}

export function* doProcessCallStart(action) {
    yield put({
        type: SET_IS_STARTED,
        payload: {
            isStarted: true,
            systemSoundRecognition: action.payload.systemSoundRecognition
        }
    });

    yield put({
        type: SET_CALL_ID,
        payload: { callId: action.payload.callId, timestamp: moment().format() }
    });

    yield call(delay, CALL_STATE_TRANSITION_DURATION);
    yield put(hideNewCallMessage());
}

export function* doProcessCallEnd(action) {
    yield put({
        type: SET_CALL_RESULT,
        payload: { isLastCallResult: true, whitelist: action.payload.whitelist }
    });
    yield put({ type: SET_IS_STARTED, payload: { isStarted: false } });
    yield call(doProcessASRData, action);
    yield put({ type: SET_ELAPSED_SECONDS, payload: { elapsedSeconds: 0 } });
    yield put(closeBattleCard());
}

export function* handleCallStart() {
    yield takeLatest(RECEIVE_CRX_CALL_STARTED, doProcessCallStart);
}

export function* handleCallData() {
    yield takeLatest(RECEIVE_CRX_CALL_DATA, doProcessASRData);
}

export function* handleInitialCallData() {
    function* handler(action) {
        yield put({ type: CONFIGURATION_LOADED });
        yield call(doProcessASRData, action);
    }
    yield takeLatest(RECEIVE_CRX_INITIAL_CALL_DATA, handler);
}

export function* handleAsrErrorData() {
    function* handler(action) {
        const { asrConfigErrors } = action.payload;
        if (!isEmpty(asrConfigErrors)) {
            yield put({ type: SET_ASR_CONFIG_ERRORS, payload: { asrConfigErrors } });
        }
    }

    yield takeLatest(RECEIVE_CRX_ASR_ERROR_DATA, handler);
}

export function* handleCallEnd() {
    yield takeLatest(RECEIVE_CRX_CALL_ENDED, doProcessCallEnd);
}

export function* processASRData() {
    yield fork(handleCallStart);
    yield fork(handleCallData);
    yield fork(handleCallEnd);
}

export default [
    handleInitialCallData(),
    processASRData(),
    handleAsrErrorData(),
];
