import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;

    z-index: 20;

    user-select: none;
`;

export default Wrapper;
