import styled from 'styled-components';
import PropTypes from 'prop-types';

const ExpandIcon = styled.div`
    transform: ${props => (props.$clicked ? 'rotate(180deg)' : 'rotate(0deg)')};
    transform-origin: center center;
    margin-left: 4px;
`;

ExpandIcon.propTypes = {
    $clicked: PropTypes.bool
};

ExpandIcon.defaultProps = {
    $clicked: false
};

export default ExpandIcon;
