import styled from 'styled-components';

import { colors } from '../../../utils';

const VersionInfo = styled.div`
    position: fixed;
    bottom: 10px;
    right: 0;

    display: flex;

    justify-content: flex-end;

    padding: 0px 5px;

    color: ${colors.gray.jumbo};
    font-size: 11px;
`;

export default VersionInfo;
