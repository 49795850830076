import styled from 'styled-components';

import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const TranscriptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 0;
    height: calc(100% - 40px);

    padding: 0 16px 16px 16px;

    & > div {
        width: ${props => (props.$showMicrophoneTranscriptOnly ? '100%' : '90%')};
        border-radius: 4px;
        padding: 12px;
        margin-top: 10px;
        box-shadow: 0 2px 6px 0 ${colors.blue.link_water};
        font-size: 14px;
        line-height: 1.7;
    }

    & > div:first-child {
        margin-top: 0px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.gray.iron};
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
    }
`;

TranscriptWrapper.propTypes = {
    $showMicrophoneTranscriptOnly: PropTypes.bool.isRequired
};

export default TranscriptWrapper;
