import styled from 'styled-components';

import { colors } from '../../../utils';

export default styled.div`
    position: relative;
    bottom: -20px;
    background-color: ${colors.red.light_carmine_pink};
    border-radius: 2px;
    padding: 8px 12px;
    max-width: 250px;
    width: 160px;
    margin: 0 auto;
    color: ${colors.white.basic};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
`;
