import { createError } from '../utils';

const ApiError = createError({
    name: 'ApiError',
    message: p => p.message,
    fingerprint: p => [`${p.method}:${p.route}`],
    extra: p => ({
        type: 'ApiError',
        debugInfo: p.body || {},
        meta: p.method,
        route: p.route,
        status: p.status,
    }),
    status: p => p.status
});

export const Network = { ApiError };
