import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../utils';

import Icon from '../../common/Icon';

const LogoWrapper = styled.div`
    cursor: ${props => (props.$enabled ? 'pointer' : 'default')};
    touch-action: ${props => (props.$enabled ? 'auto' : 'none')};
    pointer-events: ${props => (props.$enabled ? 'auto' : 'none')};

    user-select: none;
`;

LogoWrapper.propTypes = {
    $enabled: PropTypes.bool.isRequired
};

function LogoIcon(props) {
    return (
        <LogoWrapper $enabled={props.enabled}>
            <Icon name="I2XLogo" color={colors.blue.i2x} size={21} />
        </LogoWrapper>
    );
}

LogoIcon.propTypes = {
    enabled: PropTypes.bool
};

LogoIcon.defaultProps = {
    enabled: false
};

export default LogoIcon;
