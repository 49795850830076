import { connect } from 'react-redux';

import { updateUsername } from '../../store/actions/user';
import UserName from '../../components/UserName';
import withLocalization from '../../localization/withLocalization';

const mapStateToProps = state => ({
    personalUsername: state.user.personalUsername,
    pending: state.user.pending,
    errorCode: state.user.errorCode
});

export default connect(mapStateToProps, {
    updateUsernameRequest: updateUsername
})(withLocalization(UserName));
