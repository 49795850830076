import { types } from '../actions/common';

export const initialState = {
    isReloading: false,
    countdown: 0
};

export default function commonReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
        case types.RELOAD:
            return {
                ...state,
                isReloading: true,
                isImmediate: payload.isImmediate,
                countdown: payload.countdown
            };
        case types.UPDATE_RELOAD_COUNT_DOWN:
            return {
                ...state,
                countdown: Math.max(0, state.countdown - 1)
            };
        default:
            return state;
    }
}
