import styled from 'styled-components';

import { colors } from '../../../utils';

const Title = styled.div`
    margin-top: 50px;
    font-size: 30px;
    font-weight: 600;
    color: ${colors.blue.royal_blue};
`;

export default Title;
