import { createError } from '../utils/general';
import { proto } from '../services/ListenerProto';

const AlreadyStartedError = createError({
    name: 'AlreadyStartedError',
    message: 'Main connection already started'
});

// Default Internal Error
const InternalError = createError({
    name: 'InternalErrorDefault',
    message: p => `Daemon internal error — ${proto.Status[p.status]}`,
    fingerprint: p => [p.metainfo]
});

// Default Invalid Request Error
const InvalidRequestError = createError({
    name: 'InvalidRequestErrorDefault',
    message: p => `Daemon invalid request — ${proto.Status[p.status]}`,
    fingerprint: p => [p.metainfo]
});

export const ListenerRequestError = createError({
    name: 'ListenerRequestError',
    message: p =>
        `Daemon request error - Status: ${proto.Status[p.status]} - ${p.message}`,
    fingerprint: p => [p.metainfo]
});

export const EmptyDeviceList = createError({
    name: 'EmptyDeviceList',
    message: p =>
        `Daemon request error - Status: ${proto.Status[p.status]} - ${p.message}`,
    fingerprint: p => [p.metainfo]
});

export const StartMixedRecordingRequestFail = createError({
    name: 'StartMixedRecordingRequestFail',
    message: p =>
        `Daemon request error - Status: ${proto.Status[p.status]} - ${p.message}`,
    fingerprint: p => [p.metainfo]
});

export const EventBroadcasterRequestTimedOut = createError({
    name: 'EventBroadcasterRequestTimedOut',
    message: p =>
        `Daemon request error - Status: ${proto.Status[p.status]} - ${p.message}`,
    fingerprint: p => [p.metainfo]
});

export const DeviceListRequestFail = createError({
    name: 'EventBroadcasterRequestFail',
    message: p =>
        `Daemon request error - Status: ${proto.Status[p.status]} - ${p.message}`,
    fingerprint: p => [p.metainfo]
});

export const MainConn = {
    AlreadyStartedError,
    InternalError,
    InvalidRequestError
};
