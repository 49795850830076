import styled from 'styled-components';
import PropTypes from 'prop-types';

const CloseButton = styled.button.attrs(() => ({ type: 'button' }))`
    position: absolute;
    top: ${props => `${props.$top}px`};
    right: 2px;
    border-color: transparent;
    background-color: transparent;
    display: flex;
    width: 19px;
    height: 19px;
    justify-content: center;
    padding: 0;

    cursor: pointer;
`;

CloseButton.propTypes = {
    $top: PropTypes.number
};

CloseButton.defaultProps = {
    $top: 0
};

export default CloseButton;
