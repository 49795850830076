// @flow
import {
    UPDATE_NICK_NAME_REQUEST,
    DO_LOGIN_REQUEST,
    SET_LOCALE_REQUEST,
    LOGOUT,
} from './actionTypes/user';

export const updateUsername: Function = nickName => ({
    type: UPDATE_NICK_NAME_REQUEST,
    payload: { nickName }
});

export const updateLocale: Function = (locale: string) => ({
    type: SET_LOCALE_REQUEST,
    payload: {
        locale
    }
});

export const doLogin: Function = (locationName: string, username: string, password: string) => ({
    type: DO_LOGIN_REQUEST,
    payload: {
        locationName,
        username,
        password
    },
});

export const doLogout: Function = () => ({
    type: LOGOUT,
});
