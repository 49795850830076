import root from '../manifests/listener.proto';
import { decorateToObject, isToObject, traverse } from '../utils/proto';

traverse(root.listener, isToObject, decorateToObject);

export const proto = {
    // main request message
    Request: root.listener.Request,
    EventBroadcasterRequest: root.listener.EventBroadcasterRequest,
    // main response message
    Response: root.listener.Response,
    // audio response message
    AudioResponse: root.listener.AudioResponse,
    // audiosystem change response message
    AudiosystemChangeResponse: root.listener.AudiosystemChangeResponse,
    BroadcastedEvent: root.listener.BroadcastedEvent,
    // enums
    AudioEncoding: root.listener.AudioEncoding,
    DataFlow: root.listener.AudioDevice.DataFlow,
    Status: root.listener.ServiceMessage.Status
};

export const types = {
    SERVICE_MESSAGE: 'serviceMessage',
    AUTHORIZATION_PROMPT: 'authorizationPrompt',
    SERVICE_VERSION: 'serviceVersion',
    ACTIVE_AUDIO_DEVICES: 'activeAudioDevices',
    DEVICE_ENDPOINT: 'deviceEndpoint',
    EVENT_BROADCASTER_ENDPOINT: 'eventBroadcasterEndpoint',
    BROADCASTED_EVENT: 'broadcastedEvent',
    AUDIOSYSTEM_NOTIFICATOR_ENDPOINT: 'audiosystemNotificatorEndpoint',
    RECORDING_INFO: 'recordingInfo',
    AUDIO_CHUNK: 'audioChunk',
    SYSTEM_INFORMATION: 'systemInformation'
};
