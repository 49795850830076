import styled from 'styled-components';
import { colors } from '../../../../utils';

const Header = styled.div`
    z-index: 5;

    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    border-radius: ${props => (props.$rounded ? '0 0 4px 4px' : '0')};
    width: ${props => (props.$rounded ? '300px' : '100%')};

    font-size: 14px;
    font-weight: 600;
    color: ${colors.black.nero};

    height: 40px;

    padding: 0px 18px;
    margin: 1px auto 0 auto;

    background-color: ${colors.white.basic};
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);

    cursor: pointer;

    & > div {
        display: inline-block;
    }
`;

export default Header;
