import { put, call, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { types, updateReloadCountDown } from '../actions/common';

export function* doCountDown() {
    while (true) {
        yield call(delay, 1000);
        yield put(updateReloadCountDown());
    }
}

export function* initReloadCountDown() {
    yield takeLatest(types.RELOAD, doCountDown);
}

export default [initReloadCountDown()];
