import React from 'react';

function Top() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFAA50"
                fillRule="nonzero"
                d="M4.412 2.494l4.594 1.575c.394.131.788 0 1.05-.131l3.938-2.888c.787-.656 1.969 0 1.969.919v4.856c0 .394.13.787.525 1.05l3.937 2.756c.787.525.656 1.838-.262 2.1l-4.594 1.444a1.183 1.183 0 0 0-.788.787l-1.444 4.726c-.262.918-1.575 1.18-2.1.393L8.35 16.144c-.262-.263-.656-.525-1.05-.525l-4.856.131c-1.05 0-1.575-1.05-.919-1.838l2.888-3.937c.262-.262.262-.787.13-1.05L2.97 4.331c-.394-1.181.525-2.1 1.443-1.837z"
            />
            <path
                stroke="#FFAA50"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.313"
                d="M4.412 2.494l4.594 1.575c.394.131.788 0 1.05-.131l3.938-2.888c.787-.656 1.969 0 1.969.919v4.856c0 .394.13.787.525 1.05l3.937 2.756c.787.525.656 1.838-.262 2.1l-4.594 1.444a1.183 1.183 0 0 0-.788.787l-1.444 4.726c-.262.918-1.575 1.18-2.1.393L8.35 16.144c-.262-.263-.656-.525-1.05-.525l-4.856.131c-1.05 0-1.575-1.05-.919-1.838l2.888-3.937c.262-.262.262-.787.13-1.05L2.97 4.331c-.394-1.181.525-2.1 1.443-1.837z"
            />
        </g>
    </svg>
}

export default Top;
