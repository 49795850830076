import React from 'react';

function TelephonyIdError(props) {
  return <svg width="50px" height="50px" viewBox="0 0 50 50" {...props}>
        <defs>
            <path
                d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,13 C11.45,13 11,12.55 11,12 L11,8 C11,7.45 11.45,7 12,7 C12.55,7 13,7.45 13,8 L13,12 C13,12.55 12.55,13 12,13 Z M13,17 L11,17 L11,15 L13,15 L13,17 Z"
                id="path-1"
            />
            <filter
                x="-137.5%"
                y="-132.5%"
                width="375.0%"
                height="375.0%"
                filterUnits="objectBoundingBox"
                id="filter-2">
                <feMorphology
                    radius="0.5"
                    operator="erode"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                />
                <feOffset
                    dx="0"
                    dy="3"
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation="2.5"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                />
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
                <feGaussianBlur
                    stdDeviation="9"
                    in="shadowOffsetOuter2"
                    result="shadowBlurOuter2"
                />
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0"
                    type="matrix"
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                />
                <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter3" />
                <feGaussianBlur
                    stdDeviation="5"
                    in="shadowOffsetOuter3"
                    result="shadowBlurOuter3"
                />
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.14 0"
                    type="matrix"
                    in="shadowBlurOuter3"
                    result="shadowMatrixOuter3"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                    <feMergeNode in="shadowMatrixOuter3" />
                </feMerge>
            </filter>
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Mute-Icon" transform="translate(7.000000, 6.000000)">
                <g id="Group-12-Copy-2">
                    <circle id="Oval" fill="#4550E6" cx="20" cy="20" r="20" />
                    <g
                        id="-Round-/-Alert-/-error"
                        transform="translate(8.000000, 8.000000)">
                        <g>
                            <rect
                                id="Rectangle-Copy-27"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                            />
                            <g id="🔹Icon-Color">
                                <use
                                    fill="black"
                                    fillOpacity="1"
                                    filter="url(#filter-2)"
                                    xlinkHref="#path-1"
                                />
                                <use
                                    fill="#FFFFFF"
                                    fillRule="evenodd"
                                    xlinkHref="#path-1"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default TelephonyIdError;
