import React from 'react';

function Icon() {
  return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="105"
        height="121"
        viewBox="0 0 105 121">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <ellipse cx="51.5" cy="116" fill="#EBEDF4" rx="42.5" ry="4" />
            <g stroke="#4550E6">
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    strokeWidth="2"
                    d="M97.005 46.05a45.517 45.517 0 0 0-3.64-14.808 45.744 45.744 0 0 0-8.843-13.326 5.563 5.563 0 0 0-1.52-6.528 48.773 48.773 0 0 0-14.748-8.46 49.408 49.408 0 0 0-33.543.012 48.91 48.91 0 0 0-14.76 8.48 5.563 5.563 0 0 0-1.518 6.541A45.77 45.77 0 0 0 9.71 31.058a45.387 45.387 0 0 0-3.716 14.99C2.363 47.943 0 51.718 0 55.88v12.26c0 3.046 1.248 5.815 3.249 7.821 2.204 2.206 4.958 3.25 8.054 3.25.465-22.923.465-34.391 0-34.404a9.073 9.073 0 0 0-1.574.083 41.872 41.872 0 0 1 3.298-12.368 42.068 42.068 0 0 1 7.89-11.913 5.538 5.538 0 0 0 6.127-.648 37.932 37.932 0 0 1 11.412-6.565c8.273-2.996 17.774-3 26.05-.017a38.216 38.216 0 0 1 11.399 6.545 5.55 5.55 0 0 0 6.139.641 41.92 41.92 0 0 1 11.227 24.32c-.532-.063-.377-.1-1.574-.082-.798.013-.798 11.481 0 34.406l1.497-.072a22.197 22.197 0 0 1-6.777 9.09 21.842 21.842 0 0 1-13.637 4.776H65.55c-.82-3.052-3.599-5.202-6.769-5.202-3.873 0-7.008 3.142-7.008 7.017a7.01 7.01 0 0 0 7.008 7.01 7.028 7.028 0 0 0 6.77-5.194h7.23c5.988 0 11.517-2.089 15.89-5.571 4.218-3.357 7.367-8.023 8.82-13.368C100.866 75.722 103 72.12 103 68.14V55.88c0-4.171-2.363-7.934-5.995-9.83z"
                />
                <path
                    strokeLinecap="square"
                    strokeWidth="1.5"
                    d="M56.5 88.5l7 11M63.5 91.5l-6.463 10.156M53.5 91.5l5.926 9.313M60.426 89.5L54.5 98.813"
                />
                <path strokeLinecap="square" strokeWidth="2" d="M65.5 93.5v2" />
                <rect
                    width="8.2"
                    height="37"
                    x="12"
                    y="44.5"
                    fill="#FFF"
                    fillRule="nonzero"
                    strokeWidth="2"
                    rx="4"
                />
                <rect
                    width="8.2"
                    height="37"
                    x="82.5"
                    y="44.5"
                    fill="#FFF"
                    fillRule="nonzero"
                    strokeWidth="2"
                    rx="4"
                />
            </g>
        </g>
    </svg>
}

export default Icon;
