import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Warning({ color, size }) {
  return <svg width={size} viewBox="0 0 13 13">
        <path
            fill={color}
            fillRule="evenodd"
            d="M2.101 13H10.9a2 2 0 0 0 1.761-2.947L8.262 1.872a2 2 0 0 0-3.523 0l-4.4 8.18A2 2 0 0 0 2.102 13zm5.103-2.274H5.796V9.209h1.408v1.517zm0-3.033H5.796V4.661h1.408v3.032z"
        />
    </svg>
}

Warning.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
};

Warning.defaultProps = {
    color: colors.yellow.makara,
    size: 16
};

export default Warning;
