// @flow
import { SET_SALE_TRACKING_STATUS } from '../actions/actionTypes/sales';

type State = {
    trackStatus: string;
};

type Action = {
    type: string,
    payload: Object
};

export const initialState: State = {
    trackStatus: ''
};

// eslint-disable-next-line default-param-last
const sales = (state: State = initialState, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_SALE_TRACKING_STATUS: {
            const { status } = payload;
            return { trackStatus: status };
        }
        default: {
            return state;
        }
    }
};

export default sales;
