import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Card, Column, EmptyValue, Title, Indicator } from './styled';

import Loudness from './Loudness';
import SpeechRatio from './SpeechRatio';
import SpeechRate from './SpeechRate';

import { getLoudnessLevel, LOUDNESS_LEVELS } from '../../utils/loudness';

class CallRatios extends Component {
    static propTypes = {
        loudness: PropTypes.number,
        speechRatio: PropTypes.number,
        speechPace: PropTypes.number,
        speechRate: PropTypes.number,
        newSpeechRate: PropTypes.number,
        localization: PropTypes.object.isRequired,
        disabled: PropTypes.bool.isRequired,
        callEnded: PropTypes.bool.isRequired,
        displayNewSpeechRate: PropTypes.bool.isRequired
    };

    static defaultProps = {
        loudness: null,
        speechRatio: null,
        speechRate: null,
        newSpeechRate: null,
        speechPace: null
    };

    shouldComponentUpdate(nextProps) {
        return (
            this.props.loudness !== nextProps.loudness ||
            this.props.speechRatio !== nextProps.speechRatio ||
            this.props.speechRate !== nextProps.speechRate ||
            this.props.speechPace !== nextProps.speechPace ||
            this.props.disabled !== nextProps.disabled ||
            this.props.callEnded !== nextProps.callEnded
        );
    }

    _getSpeechRatio(ratio) {
        const { localization } = this.props;

        if (!ratio) {
            return (
                <EmptyValue>
                    {localization.getText('trainer.call_ratios.no_value')}
                </EmptyValue>
            );
        }

        return (
            // this is chrome bug(firefox works fine). It affects auto scroll and sound type blinking
            // TODO: this is a temp fix for chrome need to check if it will works for future versions
            <div style={{ width: 72, height: 40 }}>
                <span style={{ position: 'absolute', top: '33px', left: '20px' }}>
                    <SpeechRatio value={ratio} />
                </span>
            </div>
        );
    }

    _getSpeechRate(rate) {
        const { localization } = this.props;
        if (!rate) {
            return (
                <EmptyValue>
                    {localization.getText('trainer.call_ratios.no_value')}
                </EmptyValue>
            );
        }

        return (
            // this is chrome bug(firefox works fine). It affects auto scroll and sound type blinking
            // TODO: this is a temp fix for chrome need to check if it will works for future versions
            <div style={{ width: 72, height: 40 }}>
                <span style={{ position: 'absolute', left: '130px' }}>
                    <SpeechRate value={rate} />
                </span>
            </div>
        );
    }

    _getSpeechPace() {
        const { speechPace, localization } = this.props;

        if (!speechPace) return;

        let pace;
        switch (speechPace) {
            case 10:
                pace = localization.getText('trainer.call_ratios.speech_pace_level.slow');
                break;
            case 20:
                pace = localization.getText('trainer.call_ratios.speech_pace_level.good');
                break;
            case 30:
                pace = localization.getText('trainer.call_ratios.speech_pace_level.fast');
                break;
            default:
        }

        return <Indicator $good={speechPace === 20}>{pace}</Indicator>;
    }

    _getLoudnessLevel(loudness) {
        if (!loudness) return;
        return getLoudnessLevel(loudness);
    }

    _getLoudness(loudness, displayNewSpeechRate) {
        const { localization } = this.props;

        if (!loudness) {
            return (
                <Fragment>
                    <EmptyValue>
                        {localization.getText('trainer.call_ratios.no_value')}
                    </EmptyValue>
                    <Title>{localization.getText('trainer.call_ratios.loudness')}</Title>
                </Fragment>
            );
        }

        const level = this._getLoudnessLevel(loudness);

        let indicator;
        switch (level) {
            case LOUDNESS_LEVELS.QUIET:
                indicator = localization.getText(
                    'trainer.call_ratios.loudness_level.quiet'
                );
                break;
            case LOUDNESS_LEVELS.GOOD:
                indicator = localization.getText(
                    'trainer.call_ratios.loudness_level.good'
                );
                break;
            case LOUDNESS_LEVELS.LOUD:
                indicator = localization.getText(
                    'trainer.call_ratios.loudness_level.loud'
                );
                break;
            default:
        }

        if (!displayNewSpeechRate) {
            return (
                <Fragment>
                    <Loudness value={loudness} localization={localization} />
                    <Title>
                        {localization.getText(
                            `trainer.call_ratios.loudness_level.${level}`
                        )}
                    </Title>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <div
                    style={{
                        width: 89,
                        height: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <Loudness value={loudness} localization={localization} />
                </div>
                <Title>{localization.getText('trainer.call_ratios.loudness')}</Title>
                <Indicator $good={level === LOUDNESS_LEVELS.GOOD}>{indicator}</Indicator>
            </Fragment>
        );
    }

    render() {
        const {
            localization,
            disabled,
            loudness,
            speechRatio,
            speechRate,
            newSpeechRate,
            displayNewSpeechRate
        } = this.props;

        return (
            <Card $disabled={disabled}>
                <Column>
                    {this._getSpeechRatio(speechRatio)}
                    <Title>
                        {localization.getText('trainer.call_ratios.speech_ratio')}
                    </Title>
                </Column>
                <Column>
                    {this._getSpeechRate(
                        displayNewSpeechRate ? newSpeechRate : speechRate
                    )}
                    <Title>
                        {localization.getText(
                            'trainer.call_ratios.speech_rate.words_per_min'
                        )}
                    </Title>
                    {displayNewSpeechRate && <Title>{this._getSpeechPace()}</Title>}
                </Column>
                <Column>{this._getLoudness(loudness, displayNewSpeechRate)}</Column>
            </Card>
        );
    }
}

export default CallRatios;
