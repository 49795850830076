import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled';

function CountPill(props) {
    return <Wrapper $type={props.type}>{props.count}</Wrapper>;
}

CountPill.propTypes = {
    type: PropTypes.string,
    count: PropTypes.number
};

CountPill.defaultProps = {
    type: '',
    count: 0
};

export default CountPill;
