import React from 'react';
import PropTypes from 'prop-types';

import withLocalization from '../../localization/withLocalization';
import { Wrapper, ResultInfoWrapper } from './styled';
import CloseButton from './CloseButton';
import CallInfo from './CallInfo';
import Emoji from './Emoji';

function CallResultPopup(props) {
    const {
        spottedPhrasesScore,
        totalPhrasesScore,
        bonusScore,
        localization,
        isLastCallResult,
        isVisible,
        onClose
    } = props;

    return (
        <Wrapper $visible={isVisible}>
            <CloseButton onClose={onClose} />
            <ResultInfoWrapper>
                <CallInfo
                    spottedPhrasesScore={spottedPhrasesScore}
                    totalPhrasesScore={totalPhrasesScore}
                    isLastCallResult={isLastCallResult}
                    localization={localization}
                    bonusScore={bonusScore}
                />
                <Emoji
                    visible={isVisible}
                    spottedPhrasesScore={spottedPhrasesScore}
                    totalPhrasesScore={totalPhrasesScore}
                />
            </ResultInfoWrapper>
        </Wrapper>
    );
}

CallResultPopup.propTypes = {
    spottedPhrasesScore: PropTypes.number,
    totalPhrasesScore: PropTypes.number,
    isVisible: PropTypes.bool.isRequired,
    isLastCallResult: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    localization: PropTypes.object.isRequired,
    bonusScore: PropTypes.number
};

CallResultPopup.defaultProps = {
    spottedPhrasesScore: 0,
    totalPhrasesScore: 0,
    bonusScore: 0
};

export const PureCallResultPopup = CallResultPopup;

export default withLocalization(CallResultPopup);
