import styled from 'styled-components';
import { colors } from '../../../../utils';

const EmptyStateText = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.gray.rock_blue};
    font-size: 17px;
    font-weight: 600;
`;

export default EmptyStateText;
