import React from 'react';

function EndIcon() {
  return <svg width="26" height="10" viewBox="0 0 26 10">
        <g fill="none" fillRule="evenodd">
            <path d="M29.941 5.97l-16.97 16.971L-4 5.971 12.97-11z" />
            <path
                fill="#FFF"
                d="M17.63 3.022a15.149 15.149 0 0 0-9.32 0v3.111c0 .382-.218.729-.55.891a11.407 11.407 0 0 0-2.928 2.121 1.003 1.003 0 0 1-1.414 0L.95 6.678a1.003 1.003 0 0 1 0-1.415c6.64-6.64 17.402-6.64 24.041 0a1.003 1.003 0 0 1 0 1.415l-2.474 2.475a1.003 1.003 0 0 1-1.415 0 11.36 11.36 0 0 0-2.927-2.122 1.004 1.004 0 0 1-.545-.898V3.022z"
            />
        </g>
    </svg>
}

export default EndIcon;
