import {createEventForwardingConnection} from '../../services/WsConnection';
import {eventForwardingConnectionClosed, types as mainTypes} from '../actions/main';
import {reload, types as commonTypes} from '../actions/common';
import {errorCodes} from '../../../utils';
import {proto} from '../../services/ListenerProto';
import {forwardingLogger} from '../../../utils/logger';
import {captureException, captureWarnMessage} from "../../../tracker/raven";
import {parse} from "../../services/EventsValidator";
import {getCallDetectionAppWhitelistConfig, getCallId, getExplicitIntegrationSource} from "../selectors";
import {types as callTypes} from "../actions/call";

const { EVENT_FORWARDING_CONNECTION_CLOSED } = errorCodes.background;

export default store => {
    const { dispatch, getState } = store;

    function onCallEvent(rawStringContent) {
        try {
            // @docs
            // https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/2463891471/Telephony+Integration+Space

            const eventBody = JSON.parse(rawStringContent);
            const currentState = getState();
            const appWhitelist = getCallDetectionAppWhitelistConfig(currentState)
            const explicitIntegrationSource = getExplicitIntegrationSource(currentState)
            const callID = getCallId(currentState)

            const [callEvent, callMode, forgetAudioAndTranscript] = parse(explicitIntegrationSource, eventBody, {appWhitelist})

            if(callEvent) {
                dispatch({
                    type: callTypes.CALL_EVENT_RECEIVED,
                    payload: {
                        callEvent, eventBody, callMode, forgetAudioAndTranscript
                    }
                });
            } else {
                captureWarnMessage('call state event parse error', {
                    type: 'callEvent',
                    error: 'unhandled call event',
                    payload: rawStringContent,
                    callID
                })
            }
        } catch (e) {
            captureException(e)
        }
    }

    if(window.electronAPI?.onCallEvent){
        window.electronAPI.onCallEvent(([callEvent]) => {
            onCallEvent(JSON.stringify(callEvent))
        });
    }

    function createForwardingConnection({ port }) {
        forwardingLogger.log('createForwardingConnection on port', port);
        const ws = createEventForwardingConnection({ host: 'localhost', port });
        ws.on('opened', () => {
            forwardingLogger.log('ForwardingConnection opened on port', port);
        });
        ws.on('closed', ({ wasClean }) => {
            forwardingLogger.log('ForwardingConnection closed. WasClean:', wasClean);
            dispatch(eventForwardingConnectionClosed(wasClean));
        });
        ws.on('message', data => {
            const { rawStringContent } = proto.BroadcastedEvent.decode(data);
            if (rawStringContent) {
                onCallEvent(rawStringContent);
            }
        });

        return ws;
    }

    // eslint-disable-next-line no-unused-vars
    let connection = null;

    return next => action => {
        const { type, payload } = action;
        const result = next(action);

        switch (type) {
            case mainTypes.START_EVENT_FORWARDING_CONNECTION: {
                connection = createForwardingConnection(payload);
                break;
            }
            case commonTypes.CLOSE_ALL_CONNECTIONS: {
                const { code } = payload;
                if (connection && connection.isOpen()) {
                    connection.close(code);
                }
                break;
            }
            case mainTypes.EVENT_FORWARDING_CONNECTION_CLOSED: {
                const { wasClean } = payload;

                // TOFIX: listener ws connection returns default code
                // Need to get state because WSConnection always return code 1000
                const {
                    main: { isTrainerActive }
                } = getState();

                if (isTrainerActive && !wasClean) {
                    dispatch(
                        reload({
                            code: EVENT_FORWARDING_CONNECTION_CLOSED,
                            reason: 'EVENT_FORWARDING_CONNECTION_CLOSED'
                        })
                    );
                }
                break;
            }
            default:
        }

        return result;
    };
};
