import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Label } from './styled';

import Icon from '../../Icon';

function EmptyState(props) {
  return <Wrapper>
        <Label>
            <div>
                {props.localization.getText(
                    'trainer.blacklist.summary.empty_state.line_1'
                )}
            </div>
            <div>
                {props.localization.getText(
                    'trainer.blacklist.summary.empty_state.line_2'
                )}
            </div>
        </Label>
        <Icon name="TripleThumbsUp" size={80} />
    </Wrapper>
}

EmptyState.propTypes = {
    localization: PropTypes.object.isRequired
};

export default EmptyState;
