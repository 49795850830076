// @flow
export const GET_USER_PROFILE_SUCCESS: string = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL: string = 'GET_USER_PROFILE_FAIL';
export const HIDE_SAVED: string = 'HIDE_SAVED';

export const UPDATE_NICK_NAME_REQUEST: string = 'UPDATE_NICK_NAME_REQUEST';
export const UPDATE_NICK_NAME_SUCCESS: string = 'UPDATE_NICK_NAME_SUCCESS';
export const UPDATE_NICK_NAME_FAIL: string = 'UPDATE_NICK_NAME_FAIL';

export const SET_LOCALE_REQUEST: string = 'SET_LOCALE_REQUEST';
export const SET_LOCALE_SUCCESS: string = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_FAIL: string = 'SET_LOCALE_FAIL';

export const SET_USER_SCORES_SUCCESS: string = 'SET_USER_SCORES_SUCCESS';
export const SET_USER_SCORE_FAILED: string = 'SET_USER_SCORE_FAILED';

export const DO_LOGIN_REQUEST: string = 'DO_LOGIN_REQUEST';
export const DO_LOGIN_SUCCESS: string = 'DO_LOGIN_SUCCESS';
export const DO_LOGIN_FAILED: string = 'DO_LOGIN_FAILED';
export const TOKEN_REFRESH_SUCCESS: string = 'TOKEN_REFRESH_SUCCESS';
export const AUTH_WITH_PASSWORD_REQUIRED: string = 'AUTH_WITH_PASSWORD_REQUIRED';
export const LOGOUT = 'LOGOUT'
