export const types = {
    START_MAIN_CONNECTION: '@bg/START_MAIN_CONNECTION',
    STOP_MAIN_CONNECTION: '@bg/STOP_MAIN_CONNECTION',
    MAIN_CONNECTION_OPENED: '@bg/MAIN_CONNECTION_OPENED',
    MAIN_CONNECTION_CLOSED: '@bg/MAIN_CONNECTION_CLOSED',

    INIT_LISTENER: '@bg/INIT_LISTENER',

    START_AUDIO_SYSTEM_INFO_CONNECTION: '@bg/START_AUDIO_SYSTEM_INFO_CONNECTION',
    STOP_AUDIO_SYSTEM_INFO_CONNECTION: '@bg/STOP_AUDIO_SYSTEM_INFO_CONNECTION',
    AUDIO_SYSTEM_INFO_CONNECTION_OPENED: '@bg/AUDIO_SYSTEM_INFO_CONNECTION_OPENED',
    AUDIO_SYSTEM_INFO_CONNECTION_CLOSED: '@bg/AUDIO_SYSTEM_INFO_CONNECTION_CLOSED',

    REQUEST_DEVICE_LIST: '@bg/REQUEST_DEVICE_LIST',
    START_AUDIO_DEVICE_CONNECTION: '@bg/START_AUDIO_DEVICE_CONNECTION',

    START_EVENT_FORWARDING_CONNECTION: '@bg/START_EVENT_FORWARDING_CONNECTION',
    EVENT_FORWARDING_CONNECTION_CLOSED: '@bg/EVENT_FORWARDING_CONNECTION_CLOSED',

    EVENT_FORWARDED: '@bg/EVENT_FORWARDED',

    DEVICE_LIST_RECEIVED: '@bg/DEVICE_LIST_RECEIVED',
    DEVICE_LIST_RECEIVE_ERROR: '@bg/DEVICE_LIST_RECEIVE_ERROR',
    DAEMON_VERSION_RECEIVED: '@bg/DAEMON_VERSION_RECEIVED',

    DAEMON_INITIALIZED: '@bg/DAEMON_INITIALIZED',

    GET_SYSTEM_INFORMATION: '@bg/GET_SYSTEM_INFORMATION'
};

export const startMainConnection = () => ({
    type: types.START_MAIN_CONNECTION
});

export const stopMainConnection = () => ({
    type: types.STOP_MAIN_CONNECTION
});

export const mainConnectionOpened = () => ({
    type: types.MAIN_CONNECTION_OPENED
});

export const initListener = () => dispatch => {
    dispatch({
        type: types.INIT_LISTENER,
    })
}

export const mainConnectionClosed = (wasClean, code) => ({
    type: types.MAIN_CONNECTION_CLOSED,
    payload: { wasClean, code }
});

export const startAudioSystemInfoConnection = port => ({
    type: types.START_AUDIO_SYSTEM_INFO_CONNECTION,
    payload: { port }
});

export const stopAudioSystemInfoConnection = () => ({
    type: types.STOP_AUDIO_SYSTEM_INFO_CONNECTION
});

export const audioSystemInfoConnectionOpened = () => ({
    type: types.AUDIO_SYSTEM_INFO_CONNECTION_OPENED
});

export const audioSystemInfoConnectionClosed = wasClean => ({
    type: types.AUDIO_SYSTEM_INFO_CONNECTION_CLOSED,
    payload: { wasClean }
});

export const startAudioDeviceConnection = port => ({
    type: types.START_AUDIO_DEVICE_CONNECTION,
    payload: { port }
});

export const startEventForwardingConnection = port => ({
    type: types.START_EVENT_FORWARDING_CONNECTION,
    payload: { port }
});

export const eventForwardingConnectionClosed = wasClean => ({
    type: types.EVENT_FORWARDING_CONNECTION_CLOSED,
    payload: { wasClean }
});

export const forwardEvent = ({ jsonRequest }) => ({
    type: types.EVENT_FORWARDED,
    payload: { jsonRequest }
});

export const deviceListReceived = (mics, speakers) => ({
    type: types.DEVICE_LIST_RECEIVED,
    payload: { mics, speakers }
});

export const deviceListReceiveError = () => ({
    type: types.DEVICE_LIST_RECEIVE_ERROR
});

export const daemonVersionReceived = version => ({
    type: types.DAEMON_VERSION_RECEIVED,
    payload: { version }
});

export const daemonInitialized = () => ({
    type: types.DAEMON_INITIALIZED
});

export const getSystemInformation = () => ({ type: types.GET_SYSTEM_INFORMATION });
