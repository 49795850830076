import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../utils';

const Wrapper = styled.div`
    position: absolute;
    z-index: 15;
    top: 38px;
    overflow: hidden;
    width: ${props => (props.$rounded ? '300px' : '100%')};
    height: ${props => (props.$isVisible ? 'calc(100% - 40px)' : '0px')};

    background-color: ${colors.white.basic};

    transition: height 0.25s ease;
    display: flex;
    flex-direction: column;
`;

Wrapper.propTypes = {
    $isVisible: PropTypes.bool
};

Wrapper.defaultProps = {
    $isVisible: false
};

export default Wrapper;
