import styled from 'styled-components';

export default styled.div`
    display: flex;

    flex-direction: row;

    justify-content: center;
    align-items: center;
    align-content: center;

    margin-top: 16px;
`;
