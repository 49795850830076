import React from 'react';

function EmojiPerfect2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47" viewBox="0 0 46 47">
        <g fill="none" fillRule="evenodd">
            <ellipse
                cx="23"
                cy="45.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="11.5"
                ry="1.5"
            />
            <circle cx="23" cy="23" r="13.5" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M24.3 9.6c6.3.6 11.2 6 11.2 12.4 0 6.9-5.6 12.5-12.5 12.5S10.5 28.9 10.5 22c0-6.5 4.9-11.8 11.2-12.4C14.8 10.2 9.5 16 9.5 23c0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-7-5.4-12.8-12.2-13.4z"
            />
            <circle
                cx="23"
                cy="23"
                r="13.5"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M24.5 2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5V2zM9.2 7.1c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l2.1 2.1c.6.6 1.5.6 2.1 0 .6-.6.6-1.5 0-2.1L9.2 7.1zM2 21.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h3c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H2zM7.1 36.8c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2.1 0l2.1-2.1c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0l-2.1 2.1zM21.5 44c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-3c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3zM36.8 38.9c.6.6 1.5.6 2.1 0 .6-.6.6-1.5 0-2.1l-2.1-2.1c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l2.1 2.1zM44 24.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-3c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h3zM38.9 9.2c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0l-2.1 2.1c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2.1 0l2.1-2.1z"
            />
            <ellipse
                cx="14.5"
                cy="26"
                fill="#D9DCFF"
                fillRule="nonzero"
                rx="1.5"
                ry="1"
            />
            <g
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path
                    fill="#F8F9FF"
                    d="M26.9 28.5c.7 0 1.1.6 1 1.3-.6 2.2-2.5 3.7-4.8 3.7s-4.3-1.6-4.8-3.7c-.2-.6.3-1.3 1-1.3h7.6z"
                />
                <path
                    fill="#D9DCFF"
                    d="M23 33.5c1.6 0 3-.8 3.9-1.9-1-.7-2.4-1.1-3.9-1.1s-2.9.4-3.9 1.1c.9 1.1 2.3 1.9 3.9 1.9z"
                />
            </g>
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 24c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5"
            />
            <ellipse
                cx="31.5"
                cy="26"
                fill="#D9DCFF"
                fillRule="nonzero"
                rx="1.5"
                ry="1"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M32 24c0-.8-.7-1.5-1.5-1.5S29 23.2 29 24"
            />
            <ellipse cx="23" cy="12.5" fill="#D6DAFF" fillRule="nonzero" rx="4" ry="1" />
        </g>
    </svg>
}

export default EmojiPerfect2;
