import styled from 'styled-components';
import { Column as CommonColumn } from '../../common';

const Column = styled(CommonColumn)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export default Column;
