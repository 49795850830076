import {CALL_NOTIFICATION} from "../actions/actionTypes/trainer";
import {CALL_NOTIFICATION_DURATION} from "../../background/constants";

let callNotificationTimeout;

const trainerMiddleware = store => next => action => {
    const {type, payload} = action;

    switch (type) {
        case CALL_NOTIFICATION: {
            if(payload.eventName && !callNotificationTimeout){
                callNotificationTimeout = setTimeout(() => {
                    store.dispatch({
                        type: CALL_NOTIFICATION,
                        payload: {
                            remove: true
                        }
                    })
                    callNotificationTimeout = undefined;
                }, CALL_NOTIFICATION_DURATION)
            }
            break;
        }
        default:
    }

    return next(action);
}

export default trainerMiddleware
