import { combineReducers } from 'redux';
import authReducer from './auth';
import callReducer from './call';
import asrReducer from './asr';
import mainReducer from './main';
import configReducer from './config';
import commonReducer from './common';
import userReducer from './user';
import snoozeReducer from './snooze';

const rootReducer = combineReducers({
    asr: asrReducer,
    auth: authReducer,
    call: callReducer,
    main: mainReducer,
    config: configReducer,
    common: commonReducer,
    user: userReducer,
    snooze: snoozeReducer
});

export default rootReducer;
