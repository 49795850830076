import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function LoudnessLoud(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size} viewBox="0 0 39 29">
            <g fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path
                    stroke={colors.blue.madison}
                    strokeWidth="1.5"
                    d="M6.6 9.438H1v10.124h5.6L15.4 28H19a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-3.6L6.6 9.438z"
                    fill="none"
                />
                <path
                    stroke={colors.red.light_carmine_pink}
                    strokeWidth="1.89"
                    fill="none"
                    d="M31.477 27.093l3.068-3.47a13.774 13.774 0 0 0 0-18.247l-3.068-3.47"
                />
                <path
                    stroke={colors.red.light_carmine_pink}
                    strokeWidth="1.89"
                    fill="none"
                    d="M29.614 23.218l2.227-2.442a9.315 9.315 0 0 0 0-12.553L29.614 5.78"
                />
                <path
                    stroke={colors.red.light_carmine_pink}
                    strokeWidth="1.89"
                    fill="none"
                    d="M27.75 19.343l1.452-1.51a4.81 4.81 0 0 0 0-6.668l-1.452-1.51"
                />
            </g>
        </svg>
    );
}

LoudnessLoud.propTypes = {
    size: PropTypes.number
};

LoudnessLoud.defaultProps = {
    size: 39
};

export default LoudnessLoud;
