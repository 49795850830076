import startOfWeek from 'date-fns/start_of_week';

import { getRFC3339Date } from '../../../utils/date';
import { REQUESTS } from '../../constants';
import { sendRequest } from './common';

export const types = {
    SET_USER_SCORES_SUCCESS: '@bg/SET_USER_SCORES',
    SET_USER_SCORES_FAIL: '@bg/SET_USER_SCORES_FAIL',

    GET_USER_PROFILE_SUCCESS: '@bg/GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAIL: '@bg/GET_USER_PROFILE_FAIL',

    SET_USER_USERNAME_SUCCESS: '@bg/SET_USER_USERNAME_SUCCESS',
    SET_USER_USERNAME_FAIL: '@bg/SET_USER_USERNAME_FAIL',

    SET_USER_LOCALE_SUCCESS: '@bg/SET_USER_LOCALE_SUCCESS',
    SET_USER_LOCALE_FAIL: '@bg/SET_USER_LOCALE_FAIL',
};

export const requestUserScores = () => dispatch => {
    const onSuccess = payload => {
        dispatch({
            type: types.SET_USER_SCORES_SUCCESS,
            payload
        })
    };

    const onError = error => {
        dispatch({
            type: types.SET_USER_SCORES_FAIL,
            error
        })
    };

    dispatch(
        sendRequest(REQUESTS.GET_USER_SCORES, {
            payload: {
                queryParams: {
                    from: getRFC3339Date(startOfWeek(new Date(), { weekStartsOn: 1 })),
                    to: getRFC3339Date(new Date())
                }
            },
            onSuccess,
            onError
        })
    );
};

export const updateUserLocale = locale => dispatch => {
    const payload = {
        body: { language_code: String(locale) }
    };

    const onSuccess = (data) => {
        dispatch({
            type: types.SET_USER_LOCALE_SUCCESS,
            payload: {
                locale: data.languageCode
            }
        })
    };

    const onError = error => {
        dispatch({ type: types.SET_USER_LOCALE_FAIL, error });
    };

    dispatch(sendRequest(REQUESTS.PATCH_USER_PROFILE, { payload, onSuccess, onError }));
};

export const updateUserUsername = personalUsername => dispatch => {
    const payload = {
        body: { personal_username: String(personalUsername) }
    };

    const onSuccess = (data) => {
        return {
            type: types.SET_USER_USERNAME_SUCCESS,
            payload: {
                personalUsername: data.personalUsername
            }
        }
    };

    const onError = error => {
        return { type: types.SET_USER_USERNAME_FAIL, error };
    };

    dispatch(sendRequest(REQUESTS.PATCH_USER_PROFILE, { payload, onSuccess, onError }));
};

export const getUserProfile = () => dispatch => {
    const onSuccess = payload => {
        dispatch({
            type: types.GET_USER_PROFILE_SUCCESS,
            payload: {
                ...payload,
            }
        })
    };

    const onError = error => {
        dispatch({
            type: types.GET_USER_PROFILE_FAIL,
            error
        })
    };

    dispatch(
        sendRequest(REQUESTS.GET_USER_PROFILE, {
            onSuccess,
            onError
        })
    );
};
