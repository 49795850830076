import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
        margin-left: 5px;
        vertical-align: middle;
    }

    > div:first-child {
        margin-left: 0;
        vertical-align: middle;
    }
`;

export default Wrapper;
