import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../../utils';

const TranscriptVirtualScrollWrapper = styled.div`
    & div.ReactVirtualized__List {
        outline-style: none;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${colors.gray.iron};
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 4px;
        }
    }

    height: calc(100% - 40px);

    & div.speakerResults,
    & div.micResults {
        width: ${props => (props.$showMicrophoneTranscriptOnly ? '100%' : '90%')};
        border-radius: 4px;
        padding: 12px;
        margin-top: 10px;
        box-shadow: 0 2px 6px 0 ${colors.blue.link_water};
        font-size: 14px;
        line-height: 1.7;
    }

    & div.speakerResults:first-child,
    & div.micResults:first-child {
        margin-top: 0px;
    }
`;

TranscriptVirtualScrollWrapper.propTypes = {
    $showMicrophoneTranscriptOnly: PropTypes.bool.isRequired
};

export default TranscriptVirtualScrollWrapper;
