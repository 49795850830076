import React from 'react';

function NoKeywords(props) {
  return <svg width="170" height="130" viewBox="0 0 170 130" {...props}>
        <g fill="none">
            <path
                fill="#FFF"
                d="M78.432 69.955L21.58 70c-1.971 0-3.571-1.658-3.573-3.708L18 57.757c-.001-2.049 1.596-3.71 3.568-3.712L78.421 54c1.972-.001 3.571 1.659 3.573 3.707L82 66.242c.002 2.05-1.596 3.712-3.568 3.713"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M78.432 69.955L21.58 70c-1.971 0-3.571-1.658-3.573-3.708L18 57.757c-.001-2.049 1.596-3.71 3.568-3.712L78.421 54c1.972-.001 3.571 1.659 3.573 3.707L82 66.242c.002 2.05-1.596 3.712-3.568 3.713z"
                transform="translate(0 1)"
            />
            <path
                fill="#FFF"
                d="M151.432 79.955L94.58 80c-1.972.001-3.572-1.658-3.573-3.707L91 67.757c-.002-2.049 1.596-3.711 3.568-3.713L151.42 64c1.972-.002 3.571 1.658 3.573 3.707l.006 8.536c.002 2.048-1.596 3.71-3.568 3.712"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M151.432 79.955L94.58 80c-1.972.001-3.572-1.658-3.573-3.707L91 67.757c-.002-2.049 1.596-3.711 3.568-3.713L151.42 64c1.972-.002 3.571 1.658 3.573 3.707l.006 8.536c.002 2.048-1.596 3.71-3.568 3.712z"
                transform="translate(0 1)"
            />
            <g transform="translate(0 1) translate(0 120)">
                <path
                    fill="#EBEDF4"
                    d="M168 4.5C168 6.985 130.392 9 84 9S0 6.985 0 4.5 37.608 0 84 0s84 2.015 84 4.5"
                />
            </g>
            <path
                fill="#D6DAFF"
                d="M34 89h-4v34.67c0 .735.538 1.33 1.2 1.33h1.6c.663 0 1.2-.595 1.2-1.33V89z"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M34 89h-4v34.67c0 .735.538 1.33 1.2 1.33h1.6c.663 0 1.2-.595 1.2-1.33V89z"
                transform="translate(0 1)"
            />
            <path
                fill="#D6DAFF"
                d="M77 88h-4v34.69c0 .724.538 1.31 1.2 1.31h1.6c.663 0 1.2-.586 1.2-1.31V88z"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M77 88h-4v34.69c0 .724.538 1.31 1.2 1.31h1.6c.663 0 1.2-.586 1.2-1.31V88z"
                transform="translate(0 1)"
            />
            <path
                fill="#D6DAFF"
                d="M36 88c0-2.21-1.791-4-4-4-2.21 0-4 1.79-4 4s1.79 4 4 4c2.209 0 4-1.79 4-4"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M36 88c0-2.21-1.791-4-4-4-2.21 0-4 1.79-4 4s1.79 4 4 4c2.209 0 4-1.79 4-4z"
                transform="translate(0 1)"
            />
            <path
                fill="#FFF"
                d="M34 88c0-1.105-.895-2-2-2s-2 .895-2 2 .895 2 2 2 2-.895 2-2"
                transform="translate(0 1)"
            />
            <path
                fill="#D6DAFF"
                d="M79 88c0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4 4-1.79 4-4"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M79 88c0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4 4-1.79 4-4z"
                transform="translate(0 1)"
            />
            <path
                fill="#FFF"
                d="M76 88c0-1.105-.895-2-2-2s-2 .895-2 2 .895 2 2 2 2-.895 2-2"
                transform="translate(0 1)"
            />
            <path
                fill="#D6DAFF"
                d="M84 96.249v9.502c0 .69-.587 1.249-1.312 1.249H23.312c-.725 0-1.312-.56-1.312-1.249V96.25c0-.69.587-1.249 1.312-1.249h59.376c.725 0 1.312.559 1.312 1.249"
                transform="translate(0 1)"
            />
            <path
                fill="#FFF"
                d="M40 95l-10.186 12h-5.677c-1.18 0-2.137-.559-2.137-1.249v-.406L30.781 95H40zM51.499 95L41 107 50.501 107 61 95zM80 95L70.026 107 61 107 70.974 95z"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M84 96.249v9.502c0 .69-.587 1.249-1.312 1.249H23.312c-.725 0-1.312-.56-1.312-1.249V96.25c0-.69.587-1.249 1.312-1.249h59.376c.725 0 1.312.559 1.312 1.249z"
                transform="translate(0 1)"
            />
            <path
                fill="#D6DAFF"
                d="M108 123H88l3.06-7.288v-.004l1.23-2.941.339-.806.552-1.312.113-.267 1.23-2.941.226-.535 2.18-5.203c.394-.937 1.746-.937 2.14 0l2.18 5.203.226.535 1.23 2.94.113.268.553 1.312.339.806 1.23 2.94v.005L108 123z"
                transform="translate(0 1)"
            />
            <path
                fill="#FFF"
                d="M105 116L90 116 94.026 108 101.155 108z"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M108 123H88l3.06-7.288v-.004l1.23-2.941.339-.806.552-1.312.113-.267 1.23-2.941.226-.535 2.18-5.203c.394-.937 1.746-.937 2.14 0l2.18 5.203.226.535 1.23 2.94.113.268.553 1.312.339.806 1.23 2.94v.005L108 123z"
                transform="translate(0 1)"
            />
            <path
                fill="#D6DAFF"
                d="M108.666 121H88.334c-.737 0-1.334.735-1.334 1.642v.717c0 .906.597 1.641 1.334 1.641h20.332c.737 0 1.334-.735 1.334-1.641v-.717c0-.907-.597-1.642-1.334-1.642"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M108.666 121H88.334c-.737 0-1.334.735-1.334 1.642v.717c0 .906.597 1.641 1.334 1.641h20.332c.737 0 1.334-.735 1.334-1.641v-.717c0-.907-.597-1.642-1.334-1.642z"
                transform="translate(0 1)"
            />
            <path
                fill="#F0F2FF"
                d="M24.996 41c1.472 0 2.75-1.076 2.95-2.543.035-.263.054-.531.054-.804 0-3.267-2.632-5.916-5.878-5.916-.134 0-.265.006-.397.015-.268-3.221-2.95-5.752-6.22-5.752-3.449 0-6.245 2.813-6.245 6.284 0 .153.008.306.018.457-.978-.55-2.119-.844-3.332-.788-3.137.144-5.714 2.669-5.936 5.82l-.005.075C-.095 39.563 1.281 41 2.988 41h22.008zM166.326 48c4.72 0 4.981-6.72.267-6.936-.129-.006-.258-.009-.389-.009-.841 0-1.651.126-2.412.357-.63-3.636-3.967-6.412-7.99-6.412-4.112 0-7.506 2.9-8.028 6.654-.976-.442-2.069-.69-3.223-.69-.645 0-1.27.077-1.867.222-4.127 1.006-3.26 6.814 1 6.814h22.642zM49.326 15c4.72 0 4.981-6.72.267-6.936-.129-.006-.258-.01-.389-.01-.841 0-1.651.127-2.412.358C46.162 4.775 42.825 2 38.802 2c-4.112 0-7.506 2.899-8.028 6.654-.976-.442-2.069-.69-3.223-.69-.645 0-1.27.077-1.867.222-4.127 1.005-3.26 6.814 1 6.814h22.642z"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M116 44L115.5.44M59.5 38.557L59.5.443"
                transform="translate(0 1)"
            />
            <path
                fill="#FFF"
                d="M111.281 55.43l-54.963-15.4c-1.906-.535-3.02-2.5-2.486-4.392l2.221-7.877c.534-1.89 2.512-2.99 4.418-2.457l54.964 15.401c1.906.535 3.02 2.5 2.486 4.392l-2.222 7.877c-.533 1.89-2.511 2.99-4.418 2.456"
                transform="translate(0 1)"
            />
            <path
                stroke="#4550E6"
                strokeWidth="2"
                d="M111.281 55.43l-54.963-15.4c-1.906-.535-3.02-2.5-2.486-4.392l2.221-7.877c.534-1.89 2.512-2.99 4.418-2.457l54.964 15.401c1.906.535 3.02 2.5 2.486 4.392l-2.222 7.877c-.533 1.89-2.511 2.99-4.418 2.456z"
                transform="translate(0 1)"
            />
        </g>
    </svg>
}

export default NoKeywords;
