import React from 'react';

function EmojiNormal2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="45" viewBox="0 0 49 46">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <circle cx="26.5" cy="19.7" r="18.7" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M26.5 1C16.2 1 7.8 9.4 7.8 19.7c0 10.3 8.4 18.7 18.7 18.7 10.3 0 18.7-8.4 18.7-18.7C45.2 9.4 36.8 1 26.5 1zm0 34.5c-9.4 0-17-7.6-17-17s7.6-17 17-17 17 7.6 17 17-7.6 17-17 17z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.9 38.4c.2-.8.8-1.5 1.7-1.7l3.4-.8c.1 0 .2-.1.2-.3 0-.2-.1-.2-.2-.3l-3.4-.8c-.8-.2-1.5-.8-1.7-1.7l-.8-3.4c0-.1-.1-.2-.3-.2-.1 0-.2.1-.3.2l-.8 3.4c-.2.8-.8 1.5-1.7 1.7l-3.4.8c-.1 0-.2.1-.2.3 0 .1.1.2.2.3l3.4.8c.8.2 1.5.8 1.7 1.7l.8 3.4c0 .1.1.2.3.2.1 0 .2-.1.3-.2l.8-3.4zM44.7 5l2.6-.9c.1 0 .2-.1.2-.2s-.1-.2-.2-.2L45.1 3c-.3-.1-.5-.3-.6-.6L43.8.2c0-.1-.1-.2-.2-.2s-.2.1-.2.2l-.7 2.2c-.1.3-.3.5-.6.6l-2.2.7c-.1 0-.2.1-.2.2s.1.2.2.2l2.2.7c.3.1.5.3.6.6l.7 2.2c0 .1.1.2.2.2s.2-.1.2-.2l.9-2.6z"
            />
            <ellipse
                cx="26.1"
                cy="43.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="14.9"
                ry="1.5"
            />
            <circle
                cx="26.5"
                cy="19.7"
                r="18.7"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                d="M31.2 27.1c-1.3.9-2.9 1.4-4.7 1.4-1.7 0-3.3-.5-4.7-1.4"
            />
            <path
                fill="#4550E6"
                fillRule="nonzero"
                d="M39.9 22.9h-5.4c-2.6 0-4.9-1.9-5.3-4.5l-.7-4.5c-.3-1.6 1-3.1 2.7-3.1h12c1.6 0 2.9 1.4 2.7 3l-.5 4.3c-.5 2.8-2.8 4.8-5.5 4.8z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M29.1 18.4c.3 1.5 1.1 2.8 2.3 3.6l11.2-11.2H31c-1.7 0-2.9 1.5-2.7 3.1l.8 4.5z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M39.9 22.9h-5.4c-2.6 0-4.9-1.9-5.3-4.5l-.7-4.5c-.3-1.6 1-3.1 2.7-3.1h12c1.6 0 2.9 1.4 2.7 3l-.5 4.3c-.5 2.8-2.8 4.8-5.5 4.8z"
            />
            <path
                fill="#4550E6"
                fillRule="nonzero"
                d="M13.1 22.9h5.4c2.6 0 4.9-1.9 5.3-4.5l.7-4.5c.3-1.6-1-3.1-2.7-3.1h-12c-1.6 0-2.9 1.4-2.7 3l.5 4.3c.5 2.8 2.8 4.8 5.5 4.8z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M7.8 18.1c.2 1.8 1.3 3.3 2.9 4.2l11.5-11.5H10.1c-1.6 0-2.9 1.4-2.7 3l.4 4.3z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.1 22.9h5.4c2.6 0 4.9-1.9 5.3-4.5l.7-4.5c.3-1.6-1-3.1-2.7-3.1h-12c-1.6 0-2.9 1.4-2.7 3l.5 4.3c.5 2.8 2.8 4.8 5.5 4.8z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M29.3 11.5c-.8-.4-1.7-.7-2.8-.7-1 0-2 .2-2.8.7"
            />
        </g>
    </svg>
}

export default EmojiNormal2;
