import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Column } from './styled/index';
import { Icon, Row } from '..';

// eslint-disable-next-line react/prefer-stateless-function
export default class CallContinuedMessage extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired
    };

    render() {
        const { localization } = this.props;
        return (
            <Card className="message">
                <Column $marginRight={30}>
                    <Icon name="PhoneForwarded" />
                </Column>
                <Column>
                    <Row>
                        {localization.getText('trainer.call_messages.sound_continued')}
                    </Row>
                </Column>
            </Card>
        );
    }
}
