import styled from 'styled-components';
import {colors} from "../../../../utils";

const Header = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-content: center;
    align-items: center;
    background: ${colors.blue.biscay};
    color: white;
    padding: 15px;
    
    svg.icon-assessment {
        margin-left: -4px;
        margin-right: 8px;
    }
    
    > div {
        flex-grow: 1;
        
        h6 {
            font-size: 1em; 
            margin-bottom: 4px;
            margin-top: 0;
            font-weight: 500;
        }
        
        p {
            margin: 0;
            font-size: 0.7em;
        }
    }
    
    button {
        position: absolute;
        top: 8px;
        right: 8px;
        
        svg {
            fill: white;
        }
    }
`;

export default Header;
