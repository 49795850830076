import React from 'react';

function EmojiNormal8() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="39" viewBox="0 0 48 39">
        <g fill="none" fillRule="evenodd">
            <ellipse
                cx="24"
                cy="37.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="13.8"
                ry="1.5"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M47.5 17c0-2.3-1.6-4.1-3.7-4.6.4-3-1.9-5.3-4.6-5.3-1 0-1.9.3-2.6.8.2-2.9-2.1-5-4.7-5-1.3 0-2.5.5-3.3 1.4-.5-2-2.4-3.5-4.5-3.5s-4 1.5-4.5 3.5c-.9-.8-2-1.4-3.3-1.4-2.6 0-4.8 2.1-4.7 5-.8-.5-1.7-.8-2.6-.8-2.7 0-5 2.3-4.6 5.3C2.3 12.9.7 14.7.7 17s1.7 4.3 4 4.6c-1.2 3.2 1.2 6.3 4.4 6.3 1 0 1.9-.3 2.6-.8-.2 2.9 2.1 5 4.7 5 1.4 0 2.7-.7 3.6-1.7.7 1.6 2.4 2.7 4.3 2.7s3.5-1.1 4.3-2.7c.9 1 2.1 1.7 3.6 1.7 2.6 0 4.8-2.1 4.7-5 .8.5 1.7.8 2.6.8 3.2 0 5.6-3.2 4.4-6.3 1.9-.3 3.6-2.3 3.6-4.6z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M46.7 14.5c-.9.6-1.5 1.6-1.6 2.7-.1 2.6-2.3 4.8-5 4.8-.3 0-.6 0-.9-.1-1-.2-1.9.4-2.2 1.3-.7 1.9-2.6 3.3-4.7 3.3-.9 0-1.7-.2-2.4-.7-.8-.4-1.8-.3-2.4.3-.9.9-2.1 1.4-3.5 1.4s-2.6-.6-3.5-1.4c-.6-.6-1.6-.8-2.4-.3-.7.4-1.6.7-2.4.7-2.1 0-4-1.3-4.7-3.3-.3-.9-1.3-1.5-2.2-1.3-.3.1-.6.1-.9.1-2.7 0-4.9-2.2-5-4.8 0-1.1-.6-2.1-1.6-2.7-.5.7-.8 1.6-.8 2.5 0 2.3 1.7 4.3 4 4.6-1.2 3.2 1.2 6.3 4.4 6.3 1 0 1.9-.3 2.6-.8-.2 2.9 2.1 5 4.7 5 1.4 0 2.7-.7 3.6-1.7.7 1.6 2.4 2.7 4.3 2.7s3.5-1.1 4.3-2.7c.9 1 2.1 1.7 3.6 1.7 2.6 0 4.8-2.1 4.7-5 .8.5 1.7.8 2.6.8 3.2 0 5.6-3.2 4.4-6.3 2.2-.4 4-2.3 4-4.6-.2-.9-.5-1.8-1-2.5z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M47.5 17c0-2.3-1.6-4.1-3.7-4.6.4-3-1.9-5.3-4.6-5.3-1 0-1.9.3-2.6.8.2-2.9-2.1-5-4.7-5-1.3 0-2.5.5-3.3 1.4-.5-2-2.4-3.5-4.5-3.5s-4 1.5-4.5 3.5c-.9-.8-2-1.4-3.3-1.4-2.6 0-4.8 2.1-4.7 5-.8-.5-1.7-.8-2.6-.8-2.7 0-5 2.3-4.6 5.3C2.3 12.9.7 14.7.7 17s1.7 4.3 4 4.6c-1.2 3.2 1.2 6.3 4.4 6.3 1 0 1.9-.3 2.6-.8-.2 2.9 2.1 5 4.7 5 1.4 0 2.7-.7 3.6-1.7.7 1.6 2.4 2.7 4.3 2.7s3.5-1.1 4.3-2.7c.9 1 2.1 1.7 3.6 1.7 2.6 0 4.8-2.1 4.7-5 .8.5 1.7.8 2.6.8 3.2 0 5.6-3.2 4.4-6.3 1.9-.3 3.6-2.3 3.6-4.6z"
            />
            <ellipse
                cx="16.9"
                cy="19.1"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="1.2"
                ry="1"
            />
            <g
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path
                    fill="#D6DAFF"
                    d="M27.2 21.2c.5 0 .9.5.8 1-.5 1.8-2.1 3.1-4 3.1s-3.6-1.3-4-3.1c-.1-.5.3-1 .8-1h6.4z"
                />
                <path
                    fill="#C1C4E6"
                    d="M24 25.3c1.3 0 2.5-.6 3.3-1.6-.8-.5-2-.9-3.3-.9-1.3 0-2.4.3-3.3.9.8 1 2 1.6 3.3 1.6z"
                />
            </g>
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 17.4c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2"
            />
            <ellipse
                cx="31.1"
                cy="19.1"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="1.2"
                ry="1"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M31.5 17.4c0-.7-.6-1.2-1.2-1.2s-1.2.6-1.2 1.2"
            />
        </g>
    </svg>
}

export default EmojiNormal8;
