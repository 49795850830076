import styled from 'styled-components';

import { colors } from '../../../utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    overflow: hidden;

    width: 100%;
    height: 100vh;

    background-color: ${colors.white.basic};
`;

export default Wrapper;
