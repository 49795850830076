import styled from 'styled-components';
import { colors } from '../../../utils';

export const SubmitButton = styled.button.attrs({
    type: 'button'
})`
    background-color: ${colors.green.shamrock};
    border-radius: 18px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    border: 0;
    padding: 10px 16px;
    letter-spacing: 1.3px;
    text-transform: uppercase;

    &:disabled {
        opacity: 0.7;
    }
`;
