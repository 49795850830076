import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../utils';
import withLocalization from '../../../localization/withLocalization';

const SetInfinitySnoozeBase = styled.div`
    color: ${colors.white.basic};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 20px auto 0;
    cursor: pointer;
    padding: 6px 16px;
    user-select: none;
    pointer-events: ${props => (props.$isEnabled ? 'visible' : 'none')};
    &:hover {
        background-color: ${colors.blue.biscay};
    }
`;

SetInfinitySnoozeBase.propTypes = {
    $isEnabled: PropTypes.bool.isRequired
};

export default withLocalization(props => {
    return (
        <SetInfinitySnoozeBase onClick={props.onClick} $isEnabled={props.isEnabled}>
            {props.localization.getText('trainer.inactive.deactivate')}
        </SetInfinitySnoozeBase>
    );
});
