import {SETTINGS_PAGE} from '../../utils/routes';
import {messageTypes} from '../../utils/callHeader';
import {errorCodes} from '../../utils';

const {
    background: {
        AUDIO_DEVICE_STOPPED,
        UNEXPECTED_DAEMON_VERSION,
        AUTH_FAILED,
        MAIN_CONNECTION_CLOSED,
        CALL_DETECTION_STOPPED,
        AUDIO_DEVICES_CONFIG_CHANGED,
        AUDIO_SYSTEM_INFO_CONNECTION_CLOSED,
        RECOGNITION_CONNECTION_ERROR,
        HARDWARE_ERROR,
        SLOW_NETWORK,
        SLOW_NETWORK_WARN,
        EVENT_FORWARDING_CONNECTION_CLOSED,
        MAIN_CONNECTION_CLOSED_ON_START
    },
    settings: {NICK_NAME_ERROR},
    user: {PATCH_USER_PROFILE_FAILED}
} = errorCodes;

const de = {
    header: {
        titles: {
            call_summary: 'Zusammenfassung',
            calling: 'Dein aktueller Anruf',
            [SETTINGS_PAGE]: 'Einstellungen'
        },
        menu: {
            home: 'Startseite',
            settings: 'Einstellungen',
            dashboard: 'Dashboard',
            sound_test: 'Sound test',
            snooze: 'Schlummern',
            enable: 'Enable',
            logout: 'Ausloggen',
        },
        rank: {
            title: 'Wöchentlicher Punktestand!',
            message:
                'Sammle Punkte durch die Verwendung von Wörtern aus der „Sagen“-Liste.'
        }
    },
    reconnect: {
        title: 'Wiederverbinden...',
        subtitle: 'Verbindung unterbrochen. Keine Panik',
        retry: 'Aktualisierung startet in %{countdown} Sekunden.'
    },
    trainer: {
        launch: 'Starten',
        empty_state: {
            title: 'Bereit für den nächsten Anruf?'
        },
        noDevicesFound: {
            message:
                'Es wurde kein aktives Audiogerät gefunden. Bitte überprüfe die Lautsprecher- und Mikrofoneinstellungen.',
            title: 'Kein aktives Audiogerät',
            button: 'Erneut versuchen'
        },
        receiving_sound_state: 'Aufnahme läuft...',
        whitelist: {
            title: 'Sagen',
            summary: {
                result: {
                    said: 'Gesagt',
                    unsaid: 'Nicht gesagt'
                },
                details: {
                    text: 'Die Wörter oder Phrasen, die Du öfter verwenden möchtest.'
                }
            },
            stack: {
                list_label: 'Alternativen'
            }
        },
        blacklist: {
            title: 'Nicht sagen',
            empty_state: 'Gut gemacht, das kann so bleiben!',
            summary: {
                empty_state: {
                    line_1: 'Sauber!',
                    line_2: 'Mach weiter so!'
                }
            }
        },
        expand_button: {
            more: 'Mehr',
            hide: 'Verkleinern'
        },
        call_ratios: {
            loudness: 'Lautstärke',
            loudness_level: {
                quiet: 'Leise',
                good: 'Gut',
                loud: 'Laut'
            },
            speech_ratio: 'Sprechanteil',
            speech_rate: {
                title: 'Sprechgeschwindigkeit',
                words_per_min: 'Wörter/Min',
                average: '(Ø %{average})'
            },
            speech_pace_level: {
                slow: 'Langsam',
                good: 'Gut',
                fast: 'Schnell'
            },
            no_value: 'Keine Werte'
        },
        call_header: {
            summary: {
                title: {
                    default: 'Anruf beendet'
                }
            },
            realtime: {
                overtime: 'Überzeit'
            },
            status: {
                [messageTypes.HIGH]: [
                    'Läuft bei dir. Weiter so!',
                    'Super gemacht!',
                    'Gute Arbeit!',
                    'Yeah, bleib dran!',
                    'Du machst das wunderbar!',
                    'Du bist genial!',
                    'Läuft bei dir!',
                    'Du bist der Hammer!'
                ],
                [messageTypes.REGULAR]: [
                    'Super Einsatz!',
                    'Bleib dran!',
                    'Joa, ganz gut!',
                    'Du machst das!'
                ],
                [messageTypes.LOW]: [
                    'Attacke - der nächste sitzt!',
                    'Oh oh...',
                    'Übung macht den Meister!'
                ]
            }
        },
        call_messages: {
            new_call: {
                top: 'Neues Telefonat erkannt!',
                bottom: `Los geht's!`,
                bottomOptin: `Warten auf Opt-in Bestätigung...`
            },
            call_end: {
                top: 'Anruf erfolgreich durchgeführt!'
            },
            silence: 'Telefonat pausiert oder beendet',
            sound_continued: 'Ton erkannt',
            not_started: 'Bereit für den nächsten Anruf?',
            key_phrase_integration_start_words: 'Anruf Start Schlüsselwort',
            key_phrase_integration_stop_words: 'Anruf Stop Schlüsselwort',
        },
        transcript: {
            copy_button: {
                to_copy: 'Text kopieren',
                copied: 'Kopiert in die Zwischenablage'
            },
            expand_button: {
                show: 'Transkript anzeigen',
                hide: 'Transkript verbergen'
            },
            name: {
                micResults: 'Du',
                speakerResults: 'Kunde'
            },
            confidence: 'Konfidenz',
            pronunciation: 'Aussprache'
        },
        call_popup: {
            last_call_score: 'Punkte letztes Telefonat!',
            current_call_score: 'Aktuelle Punkte!',
            last_call: 'Letztes Telefonat',
            current_call: 'Aktuelles Telefonat',
            bonus_score: 'Bonuspunkte!'
        },
        battle_card: {
            title: 'Keyword detected'
        },
        inactive: {
            temporary: {
                title: 'Schlummern...',
                subtext: 'Der Assistent ist paused.',
                limit:
                    'Limit erreicht! Schalte mich wieder ein, damit ich dir helfen kann'
            },
            permanent: {
                title: 'Dein Assistent ist paused:',
                subtext: 'Deine Anrufe werden nicht aufgezeichnet',
                placeholderTitle: 'Wenn dein Assistent paused ist:',
                placeholderBody: `
                    <div>- kannst du deine Performance <b>nicht</b> im Dashboard sehen</div>
                    <div>- kannst du <b>keine</b> Punkte sammeln</div>
                    <div>- kannst du <b>kein</b> Feedback von deinem Manager erhalten</div>
                `
            },
            button: 'Online gehen',
            resuming: 'Fortsetzen...',
            deactivate: 'Pause'
        },
        asr_config_errors: {
            no_keywords: `Sagen und Nicht Sagen Keywords sind nicht festgelegt.`,
            no_team: `Hoppla, es scheint du bist noch nicht Teil eines Teams. Bitte kontaktiere deinen Teamleiter.`
        },
        asr_config_error_button: 'Neu laden',
        opt_in: {
            button: 'Opt-in'
        },
        opt_out: {
            button: 'Opt-out'
        },
        opt_in_out: {
            stop: 'Beenden',
            record: 'Aufnehmen',
            not_recorded_text: 'Kunde wird nicht aufgenommen.',
            recorded_text: 'Kunde wird aufgenommen...',
            opt_out_text: 'Der Kunde wird während dieses Anrufes nicht aufgenommen.',
            record_stopped_text: 'Die Aufnahme wurde gestoppt.',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            opt_in_modal: {
                title: 'Haben Sie das Einverständnis des Kunden zur Audioaufnahme des Anrufs eingeholt?',
                option1: 'Ja, ich habe das Einverständnis des Kunden erhalten, diesen Anruf aufzuzeichnen.',
                option2: 'Mir ist bewusst, dass eine Aufzeichnung des Kunden ohne die Einholung des Einverständnisses ggf. gegen geltende Datenschutzrichtlinien verstößt.',
            },
            opt_out_modal: {
                title: 'Bitte geben Sie einen Grund für die Beendigung der Aufzeichnung an.',
                option1: 'Der Kunde möchte sein Einverständnis widerrufen.',
                option1_note: 'Aufnahme und Transkript des Kunden werden gelöscht.',
                option2: 'Sonstige Gründe',
                option2_note: 'Aufnahme und Transkript des Kunden werden nicht gelöscht.',
            }
        },
        callEventNotification: {
            call_start_phrase: 'Die Gesprächsaufzeichnung wird gestartet.',
            call_restart_phrase: 'Eine neue Gesprächsaufzeichnung wird gestartet.',
            call_end_phrase: 'Die Gesprächsaufzeichnung wird beendet.',
            call_timeout: 'Der Anruf wird bald automatisch beendet.',
        }
    },
    settings: {
        title: 'Wähle eine Sprache:',
        languages: {
            en: 'Englisch',
            de: 'Deutsch'
        },
        nickname: {
            title: 'Anzeigename:',
            note: 'Wählen einen Namen, mit dem dein Teamleiter dich identifizieren kann',
            success_msg: 'Dein Profil wurde erfolgreich aktualisiert!',
            welcome: 'Willkommen',
            message: 'Bereit für den nächsten Anruf?',
            button: "Los geht's!"
        },
        inputDevice: {
            title: 'Mikrofon',
        },
        telephonyId: 'Telefonie-Login-ID',
        error: {
            [NICK_NAME_ERROR]: `Dein Benutzername konnte nicht geändert werden. Bitte versuche es später noch einmal.`
        },
        timer: {
            title: 'Anruf Stoppuhr',
            subtitle: {
                enable:
                    'Diese Einstellung hilft dir dabei deine Anrufzeit im Blick zu behalten und warnt dich, wenn du über die Zeit hinaus redest. Finde deine optimale Anruflänge über die Zeit heraus und konfiguriere sie hier. Max. 60 Min.',
                disable: 'Es wurde keine Anrufzeit als Richtwert festgelegt.'
            },
            minutes: 'Minuten',
            seconds: 'Sekunden',
            slider: {
                minutes: 'Minuten',
                seconds: 'Sekunden'
            }
        },
        debug_info: {
            copy_button: 'Kopieren',
            empty: 'No information available.'
        }
    },
    sound_test: {
        start: {
            title: 'Sound Check',
            line_1:
                'Please say the words that appear on the next screen into your microphone.',
            buttons: {
                start: 'Start',
                exit: 'Cancel'
            }
        },
        ongoing: {
            time_remaining: 'Seconds remaining',
            buttons: {
                exit: 'Cancel'
            }
        },
        success: {
            title: 'YOU SOUND GREAT!',
            line: 'Everything works as normal.',
            buttons: {
                finish: 'FINISH'
            }
        },
        fail: {
            title: 'Ooops...',
            line_1: 'Something went wrong.',
            line_2: 'Please try again.',
            buttons: {
                finish: 'Cancel',
                retry: 'TRY AGAIN'
            }
        }
    },
    receiving_sound: {
        title: 'i2x denkt nach…',
        subtitle: 'Dies wird ein paar Sekunden dauern'
    },
    call_buttons: {
        start: 'Start',
        end: 'Stop'
    },
    errors: {
        [AUDIO_DEVICE_STOPPED]: `Wir können Sie nicht hören. Error: ${AUDIO_DEVICE_STOPPED}`,
        [UNEXPECTED_DAEMON_VERSION]: `Damit Sie die neueste Version von i2x benutzen können, bitten wir Sie einen Neustart durchzuführen. Error: ${UNEXPECTED_DAEMON_VERSION}`,
        [MAIN_CONNECTION_CLOSED]: `Wir können Sie nicht hören. Error: ${MAIN_CONNECTION_CLOSED}`,
        [MAIN_CONNECTION_CLOSED_ON_START]: `Wir können Sie nicht hören. Error: ${MAIN_CONNECTION_CLOSED_ON_START}`,
        [EVENT_FORWARDING_CONNECTION_CLOSED]: `Wir können Sie nicht hören. Error: ${EVENT_FORWARDING_CONNECTION_CLOSED}`,
        [AUDIO_SYSTEM_INFO_CONNECTION_CLOSED]: `Wir können Sie nicht hören. Error: ${AUDIO_SYSTEM_INFO_CONNECTION_CLOSED}`,
        [CALL_DETECTION_STOPPED]: `Wir haben Probleme beim Aufbau einer Verbindung. Unser Team kümmert sich darum. Error: ${CALL_DETECTION_STOPPED}`,
        [RECOGNITION_CONNECTION_ERROR]: `Wir haben Probleme beim Aufbau einer Verbindung. Unser Team kümmert sich darum. Error: ${RECOGNITION_CONNECTION_ERROR}`,
        [AUDIO_DEVICES_CONFIG_CHANGED]: `Ihr Audio Set-up hat sich geändert. Error: ${AUDIO_DEVICES_CONFIG_CHANGED}`,
        [AUTH_FAILED]: `Es scheint ein Problem bei der Autentifizierung aufgetretten zu sein. Bitte kontaktieren Sie Ihren Systemadministrator, wenn das Problem weiterhin auftritt. Error: ${AUTH_FAILED}`,
        [HARDWARE_ERROR]: `Etwas scheint mit den Audio-Hardware Ihres Computers nicht zu stimmen. Bitte kontaktieren Sie Ihren Systemadministrator, wenn das Problem weiterhin auftritt. Error: ${HARDWARE_ERROR}`,
        [SLOW_NETWORK]: `Die Netzwerkverbidnung ist zu langsam. Wir müssen die Applikation aktualisieren. Bitte kontaktieren Sie ihren Systemadministrator, wenn das Problem weiterhin auftritt. Error: ${SLOW_NETWORK}`,
        [PATCH_USER_PROFILE_FAILED]:
            'It seems that we have some trouble with your request...'
    },
    warnings: {
        [SLOW_NETWORK_WARN]: `Die Internetverbindung scheint zu langsam zu sein. Die Echtzeit-Analyse könnte eingeschränkt sein.`
    },
    no_telephony_id: 'Deinem Konto ist keine Telefonie-Login-ID zugewiesen, wende dich bitte an deinen Teamleiter.',
    login: {
        title: 'Anmeldung',
        UnknownError: 'Unbekannter Fehler.',
        NoNetwork: 'Keine Netzwerkverbindung.',
        InvalidCredentials: 'Fehler. Überprüfe deine Eingaben oder versuche es später nochmals.',
        username: 'Name / E-Mail',
        password: 'Passwort',
        locationName: 'Standort',
        doLogin: 'Anmelden',
        troubleSigningIn: 'Schwierigkeiten bei der Anmeldung? Passwort vergessen?',
        contactManager: 'Bitte Vorgesetzten kontaktieren.',
    },
    conversion: {
        title: 'Hat eine der folgenden Aktionen stattgefunden: Vertragsverlängerung, Upsell, Verkauf oder Cross-Sell?',
        result: {
            yesOne: "Ja, nur eine",
            yesMultiple: 'Ja, mehr als eine',
            no: 'Nein'
        },
        submitButtonText: "ABSENDEN"
    },
    callSuggestions: {
        title: 'Performance Feedback',
        numberOfCalls: 'Basierend auf den letzten %{calls} Anrufen',
        lastUpdate: 'Letztes Update',
    },
    tabs: {
        assistance: 'Assistent',
        sales: 'Sales',
    },
    sales: {
        agent: 'Agent',
        loading: 'LADEN...',
        campaign: 'Projekt',
        customer: 'Kunden / Vertrags-ID (optional)',
        category: 'Kategorie',
        product: 'Produkt',
        call_id: 'Anruf-ID',
        date_time: 'Datum und Uhrzeit',
        send: 'ABSENDEN',
        close: 'SCHLIESSEN',
        cancel: 'ABBRECHEN und BEARBEITEN',
        confirm: 'Bestätigen',
        modal: {
            try_again: 'Bitte versuchen Sie es erneut,',
            error: 'Ups, ein Fehler ist aufgetreten',
            good_work: 'Gute Arbeit!',
            successfully_uploaded: 'Die Sales-Informationen wurden erfolgreich hochgeladen.',
            title: 'Sales-Übersicht',
            desc: 'Sie können Ihre Sales-Daten unten sehen.',
        },
    }
};

export default de;
