import React from 'react';

function EmojiPerfect1() {
  return <svg
        width="46px"
        height="47px"
        viewBox="0 0 46 47"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g
            id="Gamification-Fix"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
                id="Last-Call-Feedback-Changes"
                transform="translate(-1715.000000, -455.000000)">
                <g id="Group-21" transform="translate(1715.000000, 455.000000)">
                    <ellipse
                        id="XMLID_15881_"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        cx="23"
                        cy="45.5"
                        rx="11.5"
                        ry="1.5"
                    />
                    <circle
                        id="XMLID_15880_"
                        fill="#F8F9FF"
                        fillRule="nonzero"
                        cx="23"
                        cy="23"
                        r="13.5"
                    />
                    <path
                        d="M24.3,9.6 C30.6,10.2 35.5,15.6 35.5,22 C35.5,28.9 29.9,34.5 23,34.5 C16.1,34.5 10.5,28.9 10.5,22 C10.5,15.5 15.4,10.2 21.7,9.6 C14.8,10.2 9.5,16 9.5,23 C9.5,30.5 15.5,36.5 23,36.5 C30.5,36.5 36.5,30.5 36.5,23 C36.5,16 31.1,10.2 24.3,9.6 Z"
                        id="XMLID_15879_"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                    <circle
                        id="XMLID_15878_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="23"
                        cy="23"
                        r="13.5"
                    />
                    <path
                        d="M24.5,2 C24.5,1.2 23.8,0.5 23,0.5 C22.2,0.5 21.5,1.2 21.5,2 L21.5,5 C21.5,5.8 22.2,6.5 23,6.5 C23.8,6.5 24.5,5.8 24.5,5 L24.5,2 Z"
                        id="XMLID_15877_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.2,7.1 C8.6,6.5 7.7,6.5 7.1,7.1 C6.5,7.7 6.5,8.6 7.1,9.2 L9.2,11.3 C9.8,11.9 10.7,11.9 11.3,11.3 C11.9,10.7 11.9,9.8 11.3,9.2 L9.2,7.1 Z"
                        id="XMLID_15876_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M2,21.5 C1.2,21.5 0.5,22.2 0.5,23 C0.5,23.8 1.2,24.5 2,24.5 L5,24.5 C5.8,24.5 6.5,23.8 6.5,23 C6.5,22.2 5.8,21.5 5,21.5 L2,21.5 Z"
                        id="XMLID_15875_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.1,36.8 C6.5,37.4 6.5,38.3 7.1,38.9 C7.7,39.5 8.6,39.5 9.2,38.9 L11.3,36.8 C11.9,36.2 11.9,35.3 11.3,34.7 C10.7,34.1 9.8,34.1 9.2,34.7 L7.1,36.8 Z"
                        id="XMLID_15874_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M21.5,44 C21.5,44.8 22.2,45.5 23,45.5 C23.8,45.5 24.5,44.8 24.5,44 L24.5,41 C24.5,40.2 23.8,39.5 23,39.5 C22.2,39.5 21.5,40.2 21.5,41 L21.5,44 Z"
                        id="XMLID_15873_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M36.8,38.9 C37.4,39.5 38.3,39.5 38.9,38.9 C39.5,38.3 39.5,37.4 38.9,36.8 L36.8,34.7 C36.2,34.1 35.3,34.1 34.7,34.7 C34.1,35.3 34.1,36.2 34.7,36.8 L36.8,38.9 Z"
                        id="XMLID_15872_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M44,24.5 C44.8,24.5 45.5,23.8 45.5,23 C45.5,22.2 44.8,21.5 44,21.5 L41,21.5 C40.2,21.5 39.5,22.2 39.5,23 C39.5,23.8 40.2,24.5 41,24.5 L44,24.5 Z"
                        id="XMLID_15871_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M38.9,9.2 C39.5,8.6 39.5,7.7 38.9,7.1 C38.3,6.5 37.4,6.5 36.8,7.1 L34.7,9.2 C34.1,9.8 34.1,10.7 34.7,11.3 C35.3,11.9 36.2,11.9 36.8,11.3 L38.9,9.2 Z"
                        id="XMLID_15870_"
                        stroke="#4550E6"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_15869_"
                        fill="#D9DCFF"
                        fillRule="nonzero"
                        cx="14.5"
                        cy="26"
                        rx="1.5"
                        ry="1"
                    />
                    <g
                        id="XMLID_15866_"
                        transform="translate(18.000000, 28.000000)"
                        fillRule="nonzero"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path
                            d="M8.9,0.5 C9.6,0.5 10,1.1 9.9,1.8 C9.3,4 7.4,5.5 5.1,5.5 C2.8,5.5 0.8,3.9 0.3,1.8 C0.1,1.2 0.6,0.5 1.3,0.5 L8.9,0.5 Z"
                            id="XMLID_15868_"
                            fill="#F8F9FF"
                        />
                        <path
                            d="M5,5.5 C6.6,5.5 8,4.7 8.9,3.6 C7.9,2.9 6.5,2.5 5,2.5 C3.5,2.5 2.1,2.9 1.1,3.6 C2,4.7 3.4,5.5 5,5.5 Z"
                            id="XMLID_15867_"
                            fill="#D9DCFF"
                        />
                    </g>
                    <path
                        d="M14,24 C14,23.2 14.7,22.5 15.5,22.5 C16.3,22.5 17,23.2 17,24"
                        id="XMLID_15865_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_15864_"
                        fill="#D9DCFF"
                        fillRule="nonzero"
                        cx="31.5"
                        cy="26"
                        rx="1.5"
                        ry="1"
                    />
                    <path
                        d="M32,24 C32,23.2 31.3,22.5 30.5,22.5 C29.7,22.5 29,23.2 29,24"
                        id="XMLID_15863_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_15862_"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        cx="23"
                        cy="12.5"
                        rx="4"
                        ry="1"
                    />
                </g>
            </g>
        </g>
    </svg>
}

export default EmojiPerfect1;
