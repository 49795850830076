import React from 'react';
import styled from 'styled-components';
import Icon from '../../../common/Icon';
import { colors } from '../../../../utils';

const IconWrapper = styled.div`
    position: absolute;
    border: none;
    background-color: ${colors.white.white_smoke};
    top: 12px;
    right: 12px;
`;

function CheckMarkIcon() {
  return <IconWrapper>
        <Icon name="CheckMark" />
    </IconWrapper>
}

export default CheckMarkIcon;
