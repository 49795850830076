import get from 'lodash/get';

import { types } from '../actions/main';
import { types as deviceTypes } from '../actions/device';
import { types as snoozeTypes } from '../actions/snooze';
import { types as userTypes } from '../actions/user';

// TODO: Hack to keep UI from reloading at snooze state
// see also src/background/services/asr/AsrConnection.js
const imAlive = () => {
    setInterval(() => {
        if ('livenessCounter' in window) {
            window.livenessCounter++;
        }
    }, 1000);
};

const setIsTrainerActive = payload => {
    return Boolean(get(payload, 'snoozeFor') <= 0);
};

export const initialState = {
    systemInfoConnectionOpened: false,
    mainConnectionOpened: false,
    recordingInfo: null,
    isTrainerActive: false,
    deviceListError: false
};

export default function mainReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
        case types.MAIN_CONNECTION_OPENED:
            return {
                ...state,
                mainConnectionOpened: true
                // devices: {}
            };
        case snoozeTypes.SET_SNOOZE_TIMER_SUCCESS: {
            imAlive();
            return {
                ...state,
                isTrainerActive: false
            };
        }
        case types.MAIN_CONNECTION_CLOSED:
            return { ...state, mainConnectionOpened: false };
        case types.AUDIO_SYSTEM_INFO_CONNECTION_OPENED:
            return { ...state, systemInfoConnectionOpened: true };
        case types.AUDIO_SYSTEM_INFO_CONNECTION_CLOSED:
            return { ...state, systemInfoConnectionOpened: false };

        case types.DAEMON_VERSION_RECEIVED:
            return {
                ...state,
                version: payload.version
            };
        case types.DEVICE_LIST_RECEIVE_ERROR:
            imAlive();
            return {
                ...state,
                deviceListError: true
            };
        case types.DEVICE_LIST_RECEIVED: {
            return {
                ...state,
                deviceList: {
                    mics: payload.mics,
                    speakers: payload.speakers
                }
            }
        }
        // case deviceTypes.DEVICE_CONNECTION_OPENED:
        // case deviceTypes.DEVICE_CONNECTION_CLOSED:
        case deviceTypes.RECORDING_INFO_RECEIVED:
            return {
                ...state,
                recordingInfo: payload.recordingInfo
            };
        case userTypes.GET_USER_PROFILE_SUCCESS: {
            return { ...state, isTrainerActive: setIsTrainerActive(payload) };
        }
        default:
            return state;
    }
}
