import React from 'react';
import PropTypes from 'prop-types';

function NoAudioDevice(props) {
  return <svg width={props.width} height={props.height} viewBox="0 0 44 46">
        <g id="Current-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-563.000000, -729.000000)">
                <g id="XMLID_2692_" transform="translate(564.000000, 729.000000)">
                    <circle
                        id="XMLID_2710_"
                        fill="#F8F9FF"
                        fillRule="nonzero"
                        cx="20"
                        cy="20.5"
                        r="20"
                    />
                    <path
                        d="M20,0.5 C9,0.5 0,9.5 0,20.5 C0,31.5 9,40.5 20,40.5 C31,40.5 40,31.5 40,20.5 C40,9.5 31,0.5 20,0.5 Z M20,37.5 C9.9,37.5 1.8,29.3 1.8,19.3 C1.8,9.2 10,1.1 20,1.1 C30,1.1 38.2,9.3 38.2,19.3 C38.2,29.3 30.1,37.5 20,37.5 Z"
                        id="XMLID_2707_"
                        fill="#E5E8FF"
                        fillRule="nonzero"
                    />
                    <circle
                        id="XMLID_2705_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="20"
                        cy="20.5"
                        r="20"
                    />
                    <path
                        d="M15,30.5 C16.4,29.5 18.2,30 20,30 C21.8,30 23.6,29.6 25,30.5"
                        id="XMLID_2704_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                    />
                    <ellipse
                        id="XMLID_7866_"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        cx="20"
                        cy="44.5"
                        rx="16"
                        ry="1.5"
                    />
                    <circle
                        id="XMLID_2702_"
                        stroke="#4550E6"
                        fill="#4550E6"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="10"
                        cy="20.5"
                        r="1"
                    />
                    <path
                        d="M9.3,14 C10.347619,13.9375 11.3952381,13.84375 12.5380952,13.65625 C13.5857143,13.46875 14.5380952,13.25 15.3,13"
                        id="XMLID_2701_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle
                        id="XMLID_2700_"
                        stroke="#4550E6"
                        fill="#4550E6"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="30"
                        cy="20.5"
                        r="1"
                    />
                    <path
                        d="M30.7,14 C29.652381,13.9375 28.6047619,13.84375 27.4619048,13.65625 C26.4142857,13.46875 25.4619048,13.25 24.7,13"
                        id="XMLID_2699_"
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_2698_"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        cx="32"
                        cy="25.5"
                        rx="2.5"
                        ry="1.5"
                    />
                    <ellipse
                        id="XMLID_2697_"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        cx="8"
                        cy="25.5"
                        rx="2.5"
                        ry="1.5"
                    />
                    <g id="XMLID_2693_" transform="translate(32.000000, 17.000000)">
                        <path
                            d="M10,11 C10,13.8 7.8,16 5,16 C2.2,16 0,13.8 0,11 C0,8.2 5,0 5,0 C5,0 10,8.2 10,11 Z"
                            id="XMLID_2696_"
                            fill="#F8F9FF"
                            fillRule="nonzero"
                        />
                        <path
                            d="M5,13.5806452 C2.7,13.5806452 0.8,12.0322581 0.2,10 C0.1,10.483871 0,10.8709677 0,11.1612903 C0,13.8709677 2.2,16 5,16 C7.8,16 10,13.8709677 10,11.1612903 C10,10.8709677 9.9,10.483871 9.8,10 C9.2,12.0322581 7.3,13.5806452 5,13.5806452 Z"
                            id="XMLID_2695_"
                            fill="#E5E8FF"
                            fillRule="nonzero"
                        />
                        <path
                            d="M10,11 C10,13.8 7.8,16 5,16 C2.2,16 0,13.8 0,11 C0,8.2 5,0 5,0 C5,0 10,8.2 10,11 Z"
                            id="XMLID_2694_"
                            stroke="#4550E6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

NoAudioDevice.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number
};

NoAudioDevice.defaultProps = {
    height: 108,
    width: 98
};

export default NoAudioDevice;
