import styled from 'styled-components';

import { colors } from '../../../../utils';

const TranscriptItem = styled.div`
    &.speakerResults {
        align-self: flex-start;
        background-color: ${colors.white.basic};
    }

    &.micResults {
        align-self: flex-end;
        background-color: ${colors.gray.broken_white};
    }
`;

export default TranscriptItem;
