/* eslint-disable no-unreachable */
import { REQUESTS } from '../../constants';
import { sendRequest } from './common';
import {validateSpottingConfig} from "../../utils";
import {renameKeys} from "../../utils/general";

export const types = {
    GET_USER_ASR_CONFIG_SUCCESS: '@bg/GET_USER_ASR_CONFIG_SUCCESS',
    GET_USER_ASR_CONFIG_FAIL: '@bg/GET_USER_ASR_CONFIG_FAIL',

    UPDATE_USER_ASR_CONFIG_SUCCESS: '@bg/UPDATE_USER_ASR_CONFIG_SUCCESS',
    UPDATE_USER_ASR_CONFIG_FAIL: '@bg/UPDATE_USER_ASR_CONFIG_FAIL',

    MIC_UPDATE_PHRASE_SPOTTING_RESULTS: '@bg/MIC_UPDATE_PHRASE_SPOTTING_RESULTS',
    MIC_UPDATE_ASR_RESULTS: '@bg/MIC_UPDATE_ASR_RESULTS',
    MIC_UPDATE_ASR_VOICE_METRICS: '@bg/MIC_UPDATE_ASR_VOICE_METRICS',

    MIC_UPDATE_ASR_SPEECH_PACE: '@bg/MIC_UPDATE_ASR_SPEECH_PACE',

    SPEAKER_UPDATE_ASR_RESULTS: '@bg/SPEAKER_UPDATE_ASR_RESULTS',
    SPEAKER_UPDATE_ASR_VOICE_METRICS: '@bg/SPEAKER_UPDATE_ASR_VOICE_METRICS',
    CLEAR_HISTORY: '@bg/CLEAR_HISTORY',

    ASR_CONNECTION_CLOSED: '@bg/ASR_CONNECTION_CLOSED',

    RECOGNITION_READY: '@bg/RECOGNITION_READY',
    SOUND_CHUNK: '@bg/SOUND_CHUNK',

    SET_SESSION_DURATION: '@bg/SET_SESSION_DURATION',

    ASR_VENDOR_CONFIG_ERROR: '@bg/ASR_VENDOR_CONFIG_ERROR',
};

const userASRConfigActionFactory = ({ successType, failType }) => () => dispatch => {
    const onSuccess = data => {
        const validatedSpottingConfig = validateSpottingConfig(data.spottingConfig);

        dispatch({
            type: successType,
            payload: {
                ...data,
                asr: data.asr ? {
                    ...data.asr,
                    vendors: data.asr.vendors
                        ? renameKeys(data.asr.vendors, { i2X: 'i2x' }) :
                        null,
                } : null,
                spottingConfig: validatedSpottingConfig
            }
        })
    };

    const onError = error => {
        dispatch({ type: failType, error })
    };

    dispatch(sendRequest(REQUESTS.GET_USER_ASR_CONFIG, { onSuccess, onError }));
};
export const getUserASRConfig = userASRConfigActionFactory({
    successType: types.GET_USER_ASR_CONFIG_SUCCESS,
    failType: types.GET_USER_ASR_CONFIG_FAIL
});

export const updateUserASRConfig = userASRConfigActionFactory({
    successType: types.UPDATE_USER_ASR_CONFIG_SUCCESS,
    failType: types.UPDATE_USER_ASR_CONFIG_FAIL
});

export const updateMicAsrResults = results => ({
    type: types.MIC_UPDATE_ASR_RESULTS,
    payload: results
});
export const updateMicPhraseSpottingResults = results => ({
    type: types.MIC_UPDATE_PHRASE_SPOTTING_RESULTS,
    payload: results
});

export const updateMicAsrVoiceMetrics = voiceMetrics => ({
    type: types.MIC_UPDATE_ASR_VOICE_METRICS,
    payload: { ...voiceMetrics }
});

export const updateMicAsrSpeechPace = speechPace => ({
    type: types.MIC_UPDATE_ASR_SPEECH_PACE,
    payload: { ...speechPace }
});

export const updateSpeakerAsrResults = results => ({
    type: types.SPEAKER_UPDATE_ASR_RESULTS,
    payload: results
});

export const updateSpeakerAsrVoiceMetrics = voiceMetrics => ({
    type: types.SPEAKER_UPDATE_ASR_VOICE_METRICS,
    payload: { ...voiceMetrics }
});

export const soundChunk = (micChunk, speakerChunk) => ({
    type: types.SOUND_CHUNK,
    payload: {
        micChunk,
        speakerChunk
    }
});

export const updateSessionDurationMsec = (duration) => ({
    type: types.SET_SESSION_DURATION,
    payload: {duration}
})

export const asrConnectionClosed = ({ wasClean, closedByClient }) => ({
    type: types.ASR_CONNECTION_CLOSED,
    payload: { wasClean, closedByClient }
});

export const clearHistory = () => ({
    type: types.CLEAR_HISTORY
});

export const recognitionReady = () => ({
    type: types.RECOGNITION_READY
});

export const asrVendorConfigError = () => ({
    type: types.ASR_VENDOR_CONFIG_ERROR
})