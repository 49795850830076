import { reload } from '../actions/common';
import { types as snoozeTypes } from '../actions/snooze';
import {INFINITY_SNOOZE_THRESHOLD, USER_EVENT_TYPE} from '../../constants';
import {logUserEvent} from "../actions/eventLogger";
import {captureInfoMessage} from "../../../tracker/raven";

export default store => {
    const { dispatch, getState } = store;
    let countdown = null;
    let reloadTimer = null;

    return next => action => {
        const { type, payload } = action;
        const result = next(action);

        switch (type) {
            case snoozeTypes.SET_SNOOZE_TIMER_SUCCESS: {
                const { snoozeFor } = payload;

                // Clear all timers
                if (countdown) {
                    clearInterval(countdown);
                }
                if (reloadTimer) {
                    clearTimeout(reloadTimer);
                }

                // Note: snoozeTimer is in seconds
                const timer = snoozeFor * 1000;

                captureInfoMessage(`App deactivation for ${snoozeFor} seconds`)

                const onTimeout = () => {
                    dispatch({ type: '@bg/RESUME_TRAINER_ACTIVE' });
                    dispatch(reload({ isImmediate: true, reason: 'RESUME_TRAINER' }));
                    dispatch(logUserEvent(USER_EVENT_TYPE.APP_ACTIVATE));
                };

                reloadTimer = setTimeout(() => {
                    onTimeout();
                }, timer);

                if(snoozeFor > 0){
                    dispatch(logUserEvent(USER_EVENT_TYPE.APP_DEACTIVATE))
                }

                if (snoozeFor > INFINITY_SNOOZE_THRESHOLD) {
                    break;
                }

                const onInterval = () => {
                    const stateSnoozeFor = getState().snooze.snoozeFor;
                    if (stateSnoozeFor <= 0) return;
                    dispatch({
                        type: snoozeTypes.SNOOZE_COUNTDOWN,
                        payload: { snoozeFor: stateSnoozeFor }
                    });
                };

                countdown = setInterval(() => {
                    onInterval();
                }, 1000);

                break;
            }
            default:
        }

        return result;
    };
};
