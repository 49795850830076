import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Card,
    CardSubtitle,
    VersionInfo,
    Wrapper,
    DebugInfo,
    DebugInfoCard,
    DebugText,
    CopyText,
    Loading,
    ErrorMessage,
    SavedMessage
} from './styled';

import { Dropdown } from '../common';

import withLocalization from '../../localization/withLocalization';
import { locale as localeUtils } from '../../utils';
import packageJSON from '../../../package.json';
import NickName from './NickName';
import TelephonyID from './TelephonyID';
import CallId from './CallId';
import { getDebugInfo } from '../../utils/debug';

const { languagesHelper, getShortLanguageCode, getValidLocale } = localeUtils;

class Settings extends Component {
    static propTypes = {
        locale: PropTypes.string.isRequired,
        setLocale: PropTypes.func.isRequired,
        updateUsernameRequest: PropTypes.func.isRequired,
        isCallStarted: PropTypes.bool.isRequired,
        daemonVersion: PropTypes.string,
        localization: PropTypes.object.isRequired,
        personalUsername: PropTypes.string,
        telephonyId: PropTypes.string.isRequired,
        displayTelephonyId: PropTypes.bool.isRequired,
        hidePersonalUsername: PropTypes.bool.isRequired,
        callIds: PropTypes.array,
        deviceList: PropTypes.object.isRequired,
        copyToClipboard: PropTypes.func.isRequired,
        pending: PropTypes.bool.isRequired,
        saved: PropTypes.bool.isRequired,
        errorCode: PropTypes.string,
        initialized: PropTypes.bool.isRequired
    };

    static defaultProps = {
        callIds: [],
        daemonVersion: '',
        errorCode: null,
        personalUsername: ''
    };

    state = {
        isDebugInfoOpen: false
    };

    _handleLanguageChange = locale => {
        const { setLocale, isCallStarted } = this.props;

        if (isCallStarted || !locale) return;

        setLocale(getValidLocale(locale));
    };

    _handleInputDeviceChange() {
        // TODO: Waiting for DEVELOP-6582
        // getSelectedDevice() returns the device id
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    _renderLanguageSelect() {
        const { locale, localization, isCallStarted } = this.props;

        const selectorValues = languagesHelper.map(lang => ({
            key: lang,
            value: localization.getText(`settings.languages.${[lang]}`)
        }));

        const selected = localization.getText(
            `settings.languages.${[getShortLanguageCode(locale)]}`
        );

        return (
            <Card $enabled={!isCallStarted}>
                <CardSubtitle>{localization.getText('settings.title')}</CardSubtitle>
                <Dropdown
                    selectorValues={selectorValues}
                    selected={selected}
                    onChange={this._handleLanguageChange}
                />
            </Card>
        );
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    _renderInputDeviceSelect() {
        const { deviceList, localization } = this.props;

        const selectorValues = deviceList.mics.map(device => ({
            key: device.id,
            value: device.description,
            isDefault: device.isDefaultConsole
        }));

        const defaultDevice = selectorValues.find(device => device.isDefault) || {};

        return (
            <Card title={defaultDevice.value}>
                <CardSubtitle>
                    {localization.getText('settings.inputDevice.title')}
                </CardSubtitle>
                <Dropdown
                    selectorValues={selectorValues.filter(
                        ({ key }) => defaultDevice.key === key
                    )}
                    selected={defaultDevice.value}
                    onChange={this._handleInputDeviceChange}
                />
            </Card>
        );
    }

    _renderNickName() {
        const {
            hidePersonalUsername,
            localization,
            personalUsername,
            pending,
            updateUsernameRequest
        } = this.props;

        return !hidePersonalUsername ? (
            <Card $enabled={!pending}>
                <CardSubtitle>
                    {localization.getText('settings.nickname.title')}
                </CardSubtitle>
                <NickName
                    localization={localization}
                    personalUsername={personalUsername || ''}
                    onSave={updateUsernameRequest}
                />
            </Card>
        ) : null;
    }

    _renderTelephonyId = () => {
        const { displayTelephonyId, localization, telephonyId: value } = this.props;

        return displayTelephonyId ? (
            <Card $enabled>
                <CardSubtitle>
                    {localization.getText('settings.telephonyId')}
                </CardSubtitle>
                <TelephonyID localization={localization} value={value} />
            </Card>
        ) : null;
    };

    _toggleDebugInfo = () => {
        this.setState(prevState => ({
            isDebugInfoOpen: !prevState.isDebugInfoOpen
        }));
    };

    _copyDebugInfo = () => {
        const { callIds, copyToClipboard } = this.props;

        copyToClipboard(getDebugInfo([...callIds]));
    };

    _isCopyButtonEnabled = () => Boolean(this.props.callIds.length > 0);

    _renderDebugInfo() {
        const { callIds, localization } = this.props;

        return (
            <DebugInfo>
                <DebugText onClick={this._toggleDebugInfo}>Debug info</DebugText>
                <DebugInfoCard $isOpen={this.state.isDebugInfoOpen}>
                    {!callIds.length
                        ? localization.getText('settings.debug_info.empty')
                        : callIds.map(element => (
                              <CallId key={element.callId} value={element} />
                          ))}

                    <CopyText
                        onClick={this._copyDebugInfo}
                        $isEnabled={this._isCopyButtonEnabled()}>
                        {localization.getText('settings.debug_info.copy_button')}
                    </CopyText>
                </DebugInfoCard>
            </DebugInfo>
        );
    }

    _renderVersion() {
        const { daemonVersion } = this.props;
        return (
            <VersionInfo>
                {daemonVersion ? `daemon: v${daemonVersion}` : null}
                <br />
                coach: v{packageJSON.version}
                {process.env.NODE_ENV === 'production' ? '' : `-${process.env.NODE_ENV}`}
            </VersionInfo>
        );
    }

    render() {
        const { initialized, errorCode, localization, saved } = this.props;

        if (!initialized) {
            return (
                <Wrapper>
                    <Loading>Loading...</Loading>
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                {errorCode && (
                    <ErrorMessage>
                        {localization.getText(`settings.error.${errorCode}`)}
                    </ErrorMessage>
                )}
                {saved && (
                    <SavedMessage>
                        {localization.getText('settings.nickname.success_msg')}
                    </SavedMessage>
                )}
                {/* {this._renderLanguageSelect()} */}
                {this._renderNickName()}
                {this._renderTelephonyId()}
                {this._renderDebugInfo()}
                {this._renderVersion()}
            </Wrapper>
        );
    }
}

export const PureSettings = Settings;
export default withLocalization(Settings);
