import EventEmitter from 'eventemitter3';
import WsConnection from './WsConnection';
import { proto } from './ListenerProto';
import { Protobuf } from '../errors';
import { captureException } from '../../tracker/raven';
import { mainConnLogger as logger } from '../../utils/logger';

const encodeRequest = payload => {
    const description = proto.Request.verify(payload);
    if (description) {
        const error = new Protobuf.ListenerRequestError({ description, payload });
        captureException(error);
        return logger.error(error.message);
    }
    const request = proto.Request.create(payload);
    return proto.Request.encode(request).finish();
};

export default class MainConnection extends EventEmitter {
    constructor(params) {
        super();
        this._ws = null;
        this._params = params;
    }

    start() {
        const meta = {
            description: 'Main listener connection'
        };
        this._ws = new WsConnection({ ...this._params, encodeRequest, meta });
        this._ws.on('opened', () => this.emit('opened'));
        this._ws.on('closed', event => this.emit('closed', event));
        this._ws.on('message', data => this.emit('message', data));
    }

    sendAuthorizationRequest(secret) {
        const request = { authorizationRequest: { payload: secret } };
        this._ws.send(request);
        logger.log('authorization request', request);
    }

    sendRefreshTokenRequest(token) {
        const request = { jwt: { token } };
        this._ws.send(request);
        logger.log('refresh token request', request);
    }

    sendDeviceListRequest() {
        const request = { deviceListRequest: {} };
        this._ws.send(request);
        logger.log('device list request', request);
    }

    sendStartMixedRecordingRequest(audioDevices, encoding = proto.AudioEncoding.FLAC) {
        // eslint-disable-next-line no-param-reassign
        if (window.hasOpus) encoding = proto.AudioEncoding.OPUS;
        const request = { startMixedRecordingRequest: { audioDevices, encoding } };
        this._ws.send(request);
        logger.log('start mixed recording request', request);
    }

    sendStopMixedRecordingRequest() {
        const request = { stopMixedRecordingRequest: {} };
        this._ws.send(request);
        logger.log('stop mixed recording request', request);
    }

    sendServiceVersionRequest() {
        const request = { serviceVersionRequest: {} };
        this._ws.send(request);
        logger.log('service version request', request);
    }

    sendEventBroadcasterRequest() {
        const request = { eventBroadcasterRequest: {} };
        this._ws.send(request);
        logger.log('event broadcaster request', request);
    }

    sendSystemInformationRequest() {
        const request = { systemInformationRequest: {} };
        this._ws.send(request);
        logger.log('system information request', request);
    }

    finishConnection(reason = null) {
        logger.log('finish main listener connection');
        this.sendStopMixedRecordingRequest();
        this._ws.close(reason);
    }
}
