import styled from 'styled-components';

export default styled.div`
    position: fixed;

    bottom: 10px;
    left: 0;

    padding: 0px 5px;
`;
