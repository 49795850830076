import React from 'react';

function PlusIcon() {
    return (
        <svg width="10" height="10" viewBox="200 200 100 100">
            <path
                fill="currentColor"
                d="M202 242h40v-40a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v40h40a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-40v40a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-40h-40a2 2 0 0 1-2-2v-12a2 2 0 0 1 2-2Z"
            />
        </svg>
    );
}

export default PlusIcon;
