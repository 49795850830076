import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';

import { Button, Wrapper } from './styled';

function CloseButton(props) {
    return (
        <Wrapper>
            <Button onClick={props.onClose}>
                <Icon name="Cross" size={11} />
            </Button>
        </Wrapper>
    );
}

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default CloseButton;
