export const calculateScore = list => {
    return list
        .filter(word => word.count !== 0)
        .map(item =>
            item.alternatives.filter(word => word.count !== 0).map(alt => alt.points)
        )
        .reduce((acc, points) => {
            return acc + Math.max(...points);
        }, 0);
};
