import styled from 'styled-components';
import { colors } from '../../../utils';

const Hr = styled.hr`
    width: 128px;
    border-top: 1px solid ${colors.gray.athens};
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin: 20px auto;
`;

export default Hr;
