import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;

    flex-direction: column;

    justify-content: flex-start;
    align-content: center;
    align-items: center;

    margin-top: 16px;

    width: 100%;
    margin-bottom: 10px;
`;

export default Wrapper;
