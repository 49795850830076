import React from 'react';

function EmojiDisaster5() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="36" height="47" viewBox="0 0 36 47">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <ellipse
                cx="17.5"
                cy="44.5"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="9.5"
                ry="1.5"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M26.4 22c0 .1.1.2.1.3 0 1.4-3.9 2.5-8.8 2.5s-8.8-1.1-8.8-2.5c0-.1 0-.2.1-.3-1.9 2.1-3 4.8-3 7.8 0 6.5 5.3 11.7 11.7 11.7 6.4 0 11.7-5.3 11.7-11.7 0-2.9-1.1-5.7-3-7.8z"
            />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                d="M26.4 22c0 .1.1.2.1.3 0 .2-.1.3-.2.5 1.3 1.8 2.1 4 2.1 6.3 0 5.9-4.8 10.7-10.7 10.7C11.8 39.8 7 35 7 29.1c0-2.4.8-4.6 2.1-6.3-.1-.2-.2-.3-.2-.5 0-.1 0-.2.1-.3-1.9 2.1-3 4.8-3 7.8 0 6.5 5.3 11.7 11.7 11.7 6.4 0 11.7-5.3 11.7-11.7 0-2.9-1.1-5.7-3-7.8z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M26.4 22c0 .1.1.2.1.3 0 1.4-3.9 2.5-8.8 2.5s-8.8-1.1-8.8-2.5c0-.1 0-.2.1-.3-1.9 2.1-3 4.8-3 7.8 0 6.5 5.3 11.7 11.7 11.7 6.4 0 11.7-5.3 11.7-11.7 0-2.9-1.1-5.7-3-7.8z"
            />
            <ellipse
                cx="24.5"
                cy="32.9"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="1.5"
                ry="1"
            />
            <ellipse
                cx="10.5"
                cy="32.9"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="1.5"
                ry="1"
            />
            <ellipse
                cx="11.9"
                cy="28.3"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                rx="1"
                ry="1.3"
            />
            <ellipse
                cx="21.9"
                cy="28.3"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                rx="1"
                ry="1.3"
            />
            <circle
                cx="17.6"
                cy="35.6"
                r="2.6"
                fill="#E5E8FF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="31.8"
                cy="18.8"
                r="1.8"
                fill="#E5E8FF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.2 17.015c-.5.1-.8.4-.9.9-.3-.2-.7-.3-1.1-.3-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2 0-.2 0-.4-.1-.6h.3c.6-.1 1-.7.9-1.3-.1-.6-.7-1-1.3-.9z"
            />
            <ellipse
                cx="16.8"
                cy="22.5"
                fill="#F8F9FF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                rx="8.8"
                ry="2.5"
            />
            <path
                fill="#C1C4E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 24.5c1.5.3 3.4.5 5.5.5s4-.2 5.5-.5c-.6-1.4-1-2.9-1-4.4 0-1.8.4-3 .9-4.1H12.3c.5 1.1.9 2.2.9 4.1-.1 1.5-.6 3-1.2 4.4z"
            />
            <path
                fill="#E5E8FF"
                fillRule="nonzero"
                d="M26.8 6.1v-.3c0-1.6-1.3-2.9-2.9-2.9-.4 0-.8.1-1.2.3C21.4 1.3 19.2 0 16.8 0c-2.4 0-4.7 1.3-5.9 3.2-.4-.2-.8-.3-1.2-.3-1.6 0-2.9 1.3-2.9 2.9v.3C4.5 7.2 3 8.7 3 10.4c0 1.6 1.3 3.1 3.5 4.2.6 2.2 2.5 3.8 4.9 3.8 1.5 0 2.9-.7 3.8-1.7.4.4 1.1.8 1.7.8.6 0 1.3-.3 1.7-.8.9 1.1 2.3 1.7 3.8 1.7 2.3 0 4.3-1.6 4.9-3.8 2.2-1.1 3.5-2.6 3.5-4.2-.2-1.7-1.6-3.2-4-4.3z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M6.8 9.5v-.3c0-1.6 1.3-2.9 2.9-2.9.4 0 .8.1 1.2.3 1.3-1.9 3.5-3.2 5.9-3.2 2.4 0 4.7 1.3 5.9 3.2.4-.2.8-.3 1.2-.3 1.6 0 2.9 1.3 2.9 2.9v.3c1.5.7 2.7 1.6 3.3 2.6.3-.5.5-1.1.5-1.7 0-1.7-1.5-3.2-3.8-4.3v-.3c0-1.6-1.3-2.9-2.9-2.9-.4 0-.8.1-1.2.3C21.4 1.3 19.2 0 16.8 0c-2.4 0-4.7 1.3-5.9 3.2-.4-.2-.8-.3-1.2-.3-1.6 0-2.9 1.3-2.9 2.9v.3C4.5 7.2 3 8.7 3 10.4c0 .6.2 1.1.5 1.7.6-.9 1.7-1.9 3.3-2.6z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M26.8 6.1v-.3c0-1.6-1.3-2.9-2.9-2.9-.4 0-.8.1-1.2.3C21.4 1.3 19.2 0 16.8 0c-2.4 0-4.7 1.3-5.9 3.2-.4-.2-.8-.3-1.2-.3-1.6 0-2.9 1.3-2.9 2.9v.3C4.5 7.2 3 8.7 3 10.4c0 1.6 1.3 3.1 3.5 4.2.6 2.2 2.5 3.8 4.9 3.8 1.5 0 2.9-.7 3.8-1.7.4.4 1.1.8 1.7.8.6 0 1.3-.3 1.7-.8.9 1.1 2.3 1.7 3.8 1.7 2.3 0 4.3-1.6 4.9-3.8 2.2-1.1 3.5-2.6 3.5-4.2-.2-1.7-1.6-3.2-4-4.3z"
            />
            <path
                fill="#F0F0F0"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 24.5c.6-1.4.8-3 1-4.5M19.4 24.9s-.4-.6-.4-2.9"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 16v2.7"
            />
        </g>
    </svg>
}

export default EmojiDisaster5;
