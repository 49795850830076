import { head, keys, values } from 'lodash';

import {
    ListenerRequestError,
    EmptyDeviceList,
    StartMixedRecordingRequestFail,
    EventBroadcasterRequestTimedOut,
    DeviceListRequestFail
} from '../errors/MainConnectionErrors';

/*
    From listener proto file:
        AuthorizationRequest authorization_request = 1;
        DeviceListRequest device_list_request = 2;
        ServiceVersionRequest service_version_request = 5;
        SystemInformationRequest system_information_request = 6;
        StartMixedRecordingRequest start_mixed_recording_request = 8;
        StopMixedRecordingRequest stop_mixed_recording_request = 9;
        EventBroadcasterRequest event_broadcaster_request = 10;
 */
export const requestTypes = {
    AUHTORIZATION_REQUEST: 'authorizationRequest',
    DEVICE_LIST_REQUEST: 'deviceListRequest',
    SERVICE_VERSION_REQUEST: 'serviceVersionRequest',
    SYSTEM_INFORMATION_REQUEST: 'systemInformationRequest',
    START_MIXED_RECORDING_REQUEST: 'startMixedRecordingRequest',
    STOP_MIXED_RECORDING_REQUEST: 'stopMixedRecordingRequest',
    EVENT_BROADCASTER_REQUEST: 'eventBroadcasterRequest'
};

/*
    Meta info messages constants from: https://github.com/i2x-gmbh/listener/blob/master/i2x_listener/listener.cpp
 */
export const metaInfoMessages = {
    [requestTypes.START_MIXED_RECORDING_REQUEST]: {
        REQUEST_NOT_SUCCESSFUL: 'StartMixedRecordingRequest was not successful.',
        EMPTY_DEVICE_LIST: 'Received empty list of devices.'
    },
    [requestTypes.EVENT_BROADCASTER_REQUEST]: {
        REQUEST_TIMED_OUT: 'Broadcaster endpoint timed out'
    },
    [requestTypes.DEVICE_LIST_REQUEST]: {
        REQUEST_NOT_SUCCESSFUL:
            'GetActiveAudioDevices call was not successful. Try to make a new connection or restart the listener service.'
    }
};

/**
 * @param status: string
 * @param requestType: string
 * @param metainfo: Array<string>
 * @param extra: Object
 */
export const getListenerRequestError = (status, requestType, metainfo, extra) => {
    try {
        let message = '';
        const types = values(requestTypes);

        if (requestType === requestTypes.START_MIXED_RECORDING_REQUEST) {
            if (metainfo.includes(metaInfoMessages[requestType].EMPTY_DEVICE_LIST)) {
                message = `${requestType} - EMPTY_DEVICE_LIST`;
                return new EmptyDeviceList({ status, message, metainfo, extra });
            }

            if (metainfo.includes(metaInfoMessages[requestType].REQUEST_NOT_SUCCESSFUL)) {
                message = `${requestType} - REQUEST_NOT_SUCCESSFUL`;
                return new StartMixedRecordingRequestFail({
                    status,
                    message,
                    metainfo,
                    extra
                });
            }
        } else if (requestType === requestTypes.EVENT_BROADCASTER_REQUEST) {
            if (metainfo.includes(metaInfoMessages[requestType].REQUEST_TIMED_OUT)) {
                message = `${requestType} - REQUEST_TIMED_OUT`;
                return new EventBroadcasterRequestTimedOut({
                    status,
                    message,
                    metainfo,
                    extra
                });
            }
        } else if (requestType === requestTypes.DEVICE_LIST_REQUEST) {
            if (metainfo.includes(metaInfoMessages[requestType].REQUEST_NOT_SUCCESSFUL)) {
                message = `${requestType} - REQUEST_NOT_SUCCESSFUL`;
                return new DeviceListRequestFail({
                    status,
                    message,
                    metainfo,
                    extra
                });
            }
        } else if (types.includes(requestType)) {
            message = `${requestType}`;
        } else {
            // Default case when the metainfo do not result in an error category
            message = `Unknown request type: ${requestType}`;
        }

        return new ListenerRequestError({ status, message, metainfo, extra });
    } catch (e) {
        const message = `Unknown request type: ${requestType}`;
        return new ListenerRequestError({ status, message, metainfo, extra });
    }
};

export const getRequestType = meta => {
    return head(keys(meta)) || null;
};
