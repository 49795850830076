import { all } from 'redux-saga/effects';

import asr from './asr';
import call from './call';
import reloader from './reloader';
import user from './user';
import snooze from './snooze';
import devices from './devices';

export default function* rootSaga() {
    yield all([...asr, ...call, ...reloader, ...user, ...snooze, ...devices]);
}
