import EventEmitter from 'eventemitter3';
import { identity } from 'lodash';
import { proto as listenerProt } from './ListenerProto';
import { proto } from './AsrProto';
import { captureException } from '../../tracker/raven';
import { Protobuf } from '../errors/ProtobufErrors';
import { wsLogger } from '../../utils/logger';

const types = {
    MAIN: 'main',
    AUDIO_INFO: 'audioInfo',
    AUDIO: 'audio',
    BROADCAST: 'broadcast',
    ASR: 'asr'
};

export default class WsConnection extends EventEmitter {
    constructor({ protocol = 'ws', host, port, type, encodeRequest = identity, meta }) {
        super();
        this._encodeRequest = encodeRequest;
        this.port = port;
        this.type = type;
        this.url = `${protocol}://${host}${port ? `:${port}` : ''}`;
        this.meta = meta || {};
        try {
            this._socket = new WebSocket(this.url);
            this._socket.binaryType = 'arraybuffer';
            this._socket.onopen = () => this.emit('opened');
            this._socket.onclose = event => {
                const { wasClean, code, reason } = event;
                // TODO: check behaviour on connection fail
                if (wasClean) {
                    wsLogger.log(
                        `Websocket ${this.url} was closed gracefully. Code: ${code};`,
                        `Reason: ${reason || '—'};`,
                        this.meta
                    );
                } else {
                    wsLogger.log(
                        `Websocket ${this.url} closed abruptly, error code:${code};`,
                        `Reason: ${reason || '—'};`,
                        this.meta
                    );
                }
                this.emit('closed', event);
            };
            this._socket.onmessage = message => {
                try {
                    this.emit('message', new Uint8Array(message.data));
                } catch (error) {
                    captureException(error);
                    wsLogger.error(error.message, error);
                    this._socket.close();
                }
            };
        } catch (error) {
            captureException(error);
            wsLogger.log(error.message, error);
        }
        this._hasListeners = false;
    }

    send(payload) {
        if (!this.isOpen()) {
            return;
        }
        const encoded = this._encodeRequest(payload);
        return this._socket.send(encoded);
    }

    close(...args) {
        if (this.isClosed() || this.isClosing()) {
            return;
        }
        this._socket.close(...args);
    }

    isOpen() {
        return this._socket.readyState === this._socket.OPEN;
    }

    isConnecting() {
        return this._socket.readyState === this._socket.CONNECTING;
    }

    isClosing() {
        return this._socket.readyState === this._socket.CLOSING;
    }

    isClosed() {
        return this._socket.readyState === this._socket.CLOSED;
    }

    on(...args) {
        this._hasListeners = true;
        super.on(...args);
    }

    removeAllListeners(...args) {
        this._hasListeners = false;
        return super.removeAllListeners(...args);
    }
}

function encodeDaemonRequest(payload) {
    const description = listenerProt.Request.verify(payload);

    if (description) {
        const error = new Protobuf.ListenerRequestError({ description, payload });
        captureException(error);
        return wsLogger.error(error.message, error);
    }
    const request = listenerProt.Request.create(payload);
    return listenerProt.Request.encode(request).finish();
}

function encodeAsrRequest(payload) {
    const description = proto.RecognizeRequest.verify(payload);

    if (description) {
        const error = new Protobuf.VibeRecognizeRequestError({ description, payload });
        captureException(error);
        return wsLogger.error(error.message, error);
    }
    const request = proto.RecognizeRequest.create(payload);
    return proto.RecognizeRequest.encode(request).finish();
}

export function createAudioInfoConnection({ host, port }) {
    return new WsConnection({
        host,
        port,
        type: types.AUDIO_INFO,
        encodeRequest: encodeDaemonRequest,
        meta: {
            description: 'Audio Info listener connection'
        }
    });
}

export function createAudioConnection({ host, port }) {
    return new WsConnection({
        host,
        port,
        type: types.AUDIO,
        encodeRequest: encodeDaemonRequest,
        meta: {
            description: 'Mixed device listener connection'
        }
    });
}

export function createAsrWsConnection({ protocol, host, port, meta }) {
    return new WsConnection({
        protocol,
        host,
        port,
        type: types.ASR,
        encodeRequest: encodeAsrRequest,
        meta
    });
}

export function createEventForwardingConnection({ host, port }) {
    return new WsConnection({
        host,
        port,
        type: types.BROADCAST,
        meta: {
            description: 'Event forwarding listener connection'
        }
    });
}
