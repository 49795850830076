import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../../utils';

const Bar = styled.div`
    width: ${props => 78 * props.$percentage}px;
    background-color: ${props => props.$color};
    height: 5px;
    border-radius: 2px;

    transition: width 350ms ease;

    &:not(:first-child) {
        margin-left: 4px;
    }
`;

Bar.propTypes = {
    $color: PropTypes.string,
    $percentage: PropTypes.number
};

Bar.defaultProps = {
    $color: colors.blue.palatinate_blue,
    $percentage: 50
};

export default Bar;
