import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Triangle(props) {
  return <svg width={props.size} height={props.size} viewBox="0 0 6 6">
        <path
            fill={props.color}
            fillRule="evenodd"
            d="M2.098 4.643L.152 1.53A1 1 0 0 1 1 0h3.892a1 1 0 0 1 .848 1.53L3.794 4.643a1 1 0 0 1-1.696 0z"
            opacity={props.opacity}
        />
    </svg>
}

Triangle.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    opacity: PropTypes.number
};

Triangle.defaultProps = {
    size: 6,
    color: colors.blue.madison,
    opacity: 0.699
};

export default Triangle;
