const LOUDNESS_LEVELS = {
    GOOD: 'good',
    LOUD: 'loud',
    QUIET: 'quiet'
};

const LOUDNESS_ICONS = {
    [LOUDNESS_LEVELS.GOOD]: 'LoudnessGood',
    [LOUDNESS_LEVELS.LOUD]: 'LoudnessLoud',
    [LOUDNESS_LEVELS.QUIET]: 'LoudnessQuiet'
};

const getLoudnessLevel = loudness => {
    if (!loudness || typeof loudness !== 'number')
        throw Error('GetLoudnessLevel: No valid value was provided');

    let level;

    if (loudness < -41) {
        level = LOUDNESS_LEVELS.QUIET;
    }
    if (loudness > -16) {
        level = LOUDNESS_LEVELS.LOUD;
    }
    if (loudness >= -41 && loudness <= -16) {
        level = LOUDNESS_LEVELS.GOOD;
    }

    return level;
};

export { LOUDNESS_LEVELS, LOUDNESS_ICONS, getLoudnessLevel };
