import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Bar, Wrapper } from './styled';

import { colors } from '../../../../utils';

class RatioModelisation extends PureComponent {
    static propTypes = {
        value: PropTypes.number
    };

    static defaultProps = {
        value: 0
    };

    _getRestPercentage() {
        const { value } = this.props;
        if (!value) return 0;
        return 1 - value;
    }

    render() {
        return (
            <Wrapper>
                <Bar
                    $color={colors.blue.palatinate_blue}
                    $percentage={this.props.value}
                />
                <Bar
                    $color={colors.gray.silver_sand}
                    $percentage={this._getRestPercentage()}
                />
            </Wrapper>
        );
    }
}

export default RatioModelisation;
