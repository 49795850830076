import React from 'react';
import PropTypes from 'prop-types';

function WinkingFace({ size }) {
  return <svg width={size} viewBox="0 0 80 75">
        <g fill="none" fillRule="evenodd">
            <circle cx="32.333" cy="32.333" r="31.5" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M32.333.833C15 .833.833 15 .833 32.333c0 17.334 14.167 31.5 31.5 31.5 17.334 0 31.5-14.166 31.5-31.5 0-17.333-14.166-31.5-31.5-31.5zm0 58.167C16.5 59 3.667 46.167 3.667 30.333 3.667 14.5 16.5 1.667 32.333 1.667 48.167 1.667 61 14.5 61 30.333 61 46.167 48.167 59 32.333 59z"
                opacity=".54"
            />
            <ellipse
                cx="32.333"
                cy="72.667"
                fill="#4550E6"
                fillRule="nonzero"
                opacity=".15"
                rx="25.167"
                ry="2.333"
            />
            <circle
                cx="32.333"
                cy="32.333"
                r="31.5"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.667"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeWidth="1.667"
                d="M40.167 44.833c-2.167 1.5-5 2.334-7.834 2.334-2.833 0-5.666-.834-7.833-2.334"
            />
            <ellipse
                cx="51.167"
                cy="40.167"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="4"
                ry="2.333"
            />
            <ellipse
                cx="13.5"
                cy="40.167"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="4"
                ry="2.333"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.667"
                d="M49.667 30L46.5 33.833l7.167-1.5"
            />
            <circle
                cx="14.333"
                cy="32.333"
                r="1.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.667"
            />
            <g
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinejoin="round"
                strokeWidth="1.667">
                <path d="M78.667 33c0 .333-.167.5-.5.667-.334.166-.5.166-.834 0L69.167 30.5c-.334-.167-.667-.5-.667-1s.333-.833.833-.833l8.5-1c.334 0 .5 0 .834.333.166.167.333.5.333.833L78.667 33zM77.667 15.833c-.167-.333-.334-.5-.667-.666-.333-.167-.667 0-.833.166l-7.334 4.834c-.333.166-.5.666-.333 1.166.167.334.5.667 1 .667l8.667-.833c.333 0 .5-.167.666-.5.167-.167.167-.5.167-.834l-1.333-4zM68 5c-.167-.167-.5-.167-.833-.167-.334.167-.5.334-.667.667l-2.833 8.333c-.167.334 0 .834.333 1.167.333.167.833.167 1.167-.167L71.5 9c.167-.167.333-.5.333-.833 0-.334-.166-.5-.333-.667L68 5z" />
            </g>
        </g>
    </svg>
}

WinkingFace.propTypes = {
    size: PropTypes.number
};

WinkingFace.defaultProps = {
    size: 80
};

export default WinkingFace;
