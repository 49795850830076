import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;

    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;

    flex-wrap: wrap;
`;

export default Wrapper;
