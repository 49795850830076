import React from 'react';

function Level6v2() {
  return <svg
        width="23px"
        height="21px"
        viewBox="0 0 23 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g
            id="Gamification-Fix"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
                id="Gamification-Score-Changes"
                transform="translate(-1087.000000, -977.000000)">
                <g id="Group-2-Copy" transform="translate(1088.000000, 978.000000)">
                    <path
                        d="M4.59607963,7.44186047 L0.16537372,2.55958363 C0.00713422326,2.32334443 -0.0719855251,2.08710523 0.0862539716,1.77211963 L0.0862539716,1.77211963 C0.244493468,1.45713403 0.560972462,1.29964123 0.956571204,1.45713403 L7.76086957,3.89827244 L4.59607963,7.44186047 Z"
                        id="XMLID_12019_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M4.86643726,7.90697674 L0.175101586,2.64914016 C0.00755388345,2.39472871 -0.0762199678,2.14031726 0.0913277347,1.801102 L0.0913277347,1.801102 C0.258875437,1.46188673 0.593970842,1.2922791 1.0128401,1.46188673 L8.2173913,4.09080503 L4.86643726,7.90697674 Z"
                        id="XMLID_12017_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.19552839,3.13738992 L20.8622046,10.2325581 C20.9540682,9.57034244 21,8.90812674 21,8.19860992 C21,7.82020094 21,7.48909309 20.9540682,7.11068412 C20.9081364,6.35386618 20.3569549,5.69165048 19.6220461,5.54974711 C19.5301825,5.54974711 19.4383189,5.50244599 19.3923871,5.50244599 C19.3005235,5.3132415 19.20866,5.12403702 19.1167964,4.98213365 C18.4278194,3.89420786 17.4632517,3.56310001 16.6364794,3.51579889 C16.498684,3.27929328 16.3608886,3.04278767 16.1771614,2.80628206 C15.4422527,1.90756076 14.5695485,1.67105515 13.7427762,1.67105515 C13.6968444,1.62375403 13.6509126,1.57645291 13.6049808,1.52915178 C12.7322767,0.630430476 11.7217772,0.488527112 10.8950049,0.630430476 C9.93043713,-0.0317852249 8.50655145,-0.268290832 7.12859757,0.393924869 C4.46455339,1.62375403 5.33725752,5.54974711 7.54198373,5.36054263 C8.78214223,5.21863926 9.74670995,3.84690674 9.19552839,3.13738992 Z"
                        id="XMLID_12016_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M10.9354993,1.96471733 C11.7627185,1.82546793 12.7737642,1.96471733 13.6469401,2.84663021 C13.6928967,2.89304668 13.7388533,2.93946314 13.78481,2.98587961 C14.5660726,2.98587961 15.4852051,3.21796195 16.2205111,4.09987482 C16.4502942,4.33195716 16.5881641,4.61045596 16.6800773,4.79612183 C17.5532532,4.8425383 18.4723857,5.16745357 19.161735,6.23503232 C19.2996049,6.42069819 19.3915181,6.60636406 19.4374748,6.74561346 C19.9889543,6.83844639 20.5404338,7.02411226 21,7.44186047 C21,7.30261106 21,7.16336166 20.9540434,7.02411226 C20.9080868,6.18861585 20.3106506,5.53878531 19.4834314,5.39953591 C19.4374748,5.39953591 19.3915181,5.39953591 19.3915181,5.39953591 C19.2996049,5.21387004 19.2076916,5.02820417 19.1157784,4.88895477 C18.426429,3.82137602 17.4613399,3.49646075 16.6341207,3.45004428 C16.4962508,3.21796195 16.3583809,2.98587961 16.1745544,2.75379728 C15.4392484,1.8718844 14.5660726,1.63980206 13.7388533,1.63980206 C13.6928967,1.59338559 13.6469401,1.54696913 13.6009835,1.50055266 C12.7278076,0.618639783 11.7167619,0.479390381 10.8895426,0.618639783 C9.92445351,-0.0311907583 8.49979816,-0.263273094 7.12109942,0.386557447 C5.69644407,1.03638799 5.28283445,2.47529847 5.55857419,3.58929369 C5.74240069,2.84663021 6.24792356,2.1503832 7.12109942,1.732635 C8.59171141,1.08280446 10.0163668,1.36130326 10.9354993,1.96471733 Z"
                        id="XMLID_12015_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M9.19552839,3.13738992 L20.8622046,10.2325581 C20.9540682,9.57034244 21,8.90812674 21,8.19860992 C21,7.82020094 21,7.48909309 20.9540682,7.11068412 C20.9081364,6.35386618 20.3569549,5.69165048 19.6220461,5.54974711 C19.5301825,5.54974711 19.4383189,5.50244599 19.3923871,5.50244599 C19.3005235,5.3132415 19.20866,5.12403702 19.1167964,4.98213365 C18.4278194,3.89420786 17.4632517,3.56310001 16.6364794,3.51579889 C16.498684,3.27929328 16.3608886,3.04278767 16.1771614,2.80628206 C15.4422527,1.90756076 14.5695485,1.67105515 13.7427762,1.67105515 C13.6968444,1.62375403 13.6509126,1.57645291 13.6049808,1.52915178 C12.7322767,0.630430476 11.7217772,0.488527112 10.8950049,0.630430476 C9.93043713,-0.0317852249 8.50655145,-0.268290832 7.12859757,0.393924869 C4.46455339,1.62375403 5.33725752,5.54974711 7.54198373,5.36054263 C8.78214223,5.21863926 9.74670995,3.84690674 9.19552839,3.13738992 Z"
                        id="XMLID_12014_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M15.0652174,4.18604651 C14.6817391,3.15245478 14.1886957,2.37726098 13.6956522,1.86046512"
                        id="XMLID_12013_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.76872206,13.4238478 L10.0102428,12.0396953 L11.1138167,11.90128 C11.4816747,14.0236472 12.2633729,16.0075991 13.4129291,17.7608589 C14.0106983,18.6836273 15.2982013,18.8681809 16.171864,18.2222431 C18.7008876,16.330568 20.5401775,13.4699862 21,10.1480202 L14.7923965,5.3034865 C14.1486451,4.79596392 13.4589113,4.33457975 12.7231954,3.96547242 C11.7575682,3.45794984 10.6999765,3.18111934 9.64238477,3.27339618 C7.66514811,3.45794984 6.05576943,4.7498255 5.32005347,6.45694691 L3.38879905,11.2092038 C2.69906534,12.8701868 3.94058603,14.8080003 5.77987595,14.7618619 C7.06737889,14.669585 8.07898834,14.2082009 8.76872206,13.4238478 Z"
                        id="XMLID_12012_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                    />
                    <path
                        d="M5.31319214,8.71213014 C6.00322768,6.91512821 7.65931296,5.63831105 9.63741483,5.44915296 C10.6954693,5.35457391 11.7535238,5.63831105 12.7195736,6.15849582 C13.4556115,6.53681202 14.145647,7.00970726 14.7896802,7.52989203 L20.5859787,12.1642654 C20.7699882,11.5495016 20.9079953,10.9347378 21,10.319974 L14.7896802,5.35457391 C14.145647,4.83438914 13.4556115,4.3614939 12.7195736,3.9831777 C11.7535238,3.46299293 10.6954693,3.17925579 9.63741483,3.27383484 C7.65931296,3.46299293 6.04923004,4.78709962 5.31319214,6.53681202 L3.38109264,11.407633 C3.10507842,12.1169759 3.15108079,12.8263188 3.42709501,13.4883721 L5.31319214,8.71213014 Z"
                        id="Shape"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                    <ellipse
                        id="XMLID_12011_"
                        fill="#ECEDFC"
                        fillRule="nonzero"
                        cx="12.5543478"
                        cy="19.3023256"
                        rx="5.25"
                        ry="1"
                    />
                    <path
                        d="M17.3564206,5.11627907 C17.2133079,4.56659619 17.0701952,4.10147992 16.8913043,3.72093023"
                        id="XMLID_12010_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M20.0955511,7.44186047 C20.0025278,6.69767442 19.8164813,6.05980066 19.6304348,5.58139535"
                        id="XMLID_12009_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        id="XMLID_12008_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        fill="#4550E6"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        cx="8.67391304"
                        cy="7.44186047"
                        rx="1"
                        ry="1"
                    />
                    <ellipse
                        id="XMLID_12007_"
                        fill="#D6DAFE"
                        fillRule="nonzero"
                        cx="11.1847826"
                        cy="8.8372093"
                        rx="1"
                        ry="1"
                    />
                    <path
                        d="M4.60196732,13.0232558 C4.60196732,13.0232558 4.41592081,12.3410853 4.88103709,12.0930233"
                        id="XMLID_12006_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.9565217,12.0930233 C11.6010159,12.0011484 12.2025438,11.6795866 12.6322066,11.1283376 L12.6322066,11.1283376 C13.0618694,10.5770887 13.2767008,9.88802756 13.2337345,9.19896641 L13.1907682,8.37209302"
                        id="XMLID_12005_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.76872206,13.4238478 L10.0102428,12.0396953 L11.1138167,11.90128 C11.4816747,14.0236472 12.2633729,16.0075991 13.4129291,17.7608589 C14.0106983,18.6836273 15.2982013,18.8681809 16.171864,18.2222431 C18.7008876,16.330568 20.5401775,13.4699862 21,10.1480202 L14.7923965,5.3034865 C14.1486451,4.79596392 13.4589113,4.33457975 12.7231954,3.96547242 C11.7575682,3.45794984 10.6999765,3.18111934 9.64238477,3.27339618 C7.66514811,3.45794984 6.05576943,4.7498255 5.32005347,6.45694691 L3.38879905,11.2092038 C2.69906534,12.8701868 3.94058603,14.8080003 5.77987595,14.7618619 C7.06737889,14.669585 8.07898834,14.2082009 8.76872206,13.4238478 Z"
                        id="XMLID_12004_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.93478261,3.93798657 C6.17810598,4.87588606 6.95674078,5.62620565 8.02736362,5.57931067 C9.58463321,5.48552073 10.07128,3.56282677 9.04932179,2.9531921 C8.12469297,2.43734738 7.20006415,3.28145692 7.73537557,3.65661672"
                        id="XMLID_12003_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.0204311,4.18604651 C11.557984,3.54700881 11.557984,2.63409781 10.9308389,2.04070565 L10.438082,1.5386046 C10.2588977,1.3560224 9.90052907,1.3560224 9.72134476,1.49295905 C9.6317526,1.58425015 9.58695652,1.67554125 9.58695652,1.8124779 L9.855733,4.18604651"
                        id="XMLID_12002_"
                        stroke="#4550E6"
                        strokeWidth="0.5"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </g>
    </svg>
}

export default Level6v2;
