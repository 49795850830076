import React from 'react';

function EmojiNormal5() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="44" height="39" viewBox="0 0 44 39">
        <g fill="none" fillRule="evenodd">
            <ellipse
                cx="22"
                cy="37.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="17"
                ry="1.5"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4551E7"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.9 19c-1.8-1.7-3.4-6.3-4.5-9.7-.2-.4-.8-.4-.9 0-1.2 3.4-2.7 7.9-4.5 9.7h9.9zM27 19c1.8-1.7 3.4-6.3 4.5-9.7.2-.4.8-.4.9 0 1.2 3.4 2.7 7.9 4.5 9.7H27z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M22.1 5.3c.2-.4.8-.4.9 0 1.3 3.4 5.4 12.8 9 12.8 3.2 0 6.3-4.5 7.9-7.1.3-.4 1-.2.9.3l-.9 14.3c0 3.6-7.8 6.5-17.5 6.5S4.9 29.2 4.9 25.6L4 11.3c0-.5.7-.7.9-.3 1.6 2.6 4.8 7.1 7.9 7.1 4 0 8-9.4 9.3-12.8z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M10.61 30.5l16.4-16.4c-1.9-3-3.6-6.8-4.3-8.8-.2-.4-.8-.4-.9 0-1.3 3.4-5.4 12.8-9 12.8-3.2 0-6.3-4.5-7.9-7.1-.3-.4-1-.2-.9.3l.9 14.3c-.2 1.9 2.1 3.7 5.7 4.9z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M22.1 5.3c.2-.4.8-.4.9 0 1.3 3.4 5.4 12.8 9 12.8 3.2 0 6.3-4.5 7.9-7.1.3-.4 1-.2.9.3l-.9 14.3c0 3.6-7.8 6.5-17.5 6.5S4.9 29.2 4.9 25.6L4 11.3c0-.5.7-.7.9-.3 1.6 2.6 4.8 7.1 7.9 7.1 4 0 8-9.4 9.3-12.8z"
            />
            <path
                fill="#C1C4E6"
                fillRule="nonzero"
                d="M25.5 22c0-1.9-1.6-3.5-3.5-3.5s-3.5 1.6-3.5 3.5v2c0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5v-2z"
            />
            <path
                fill="#C1C4E6"
                fillRule="nonzero"
                d="M22 26c-1.9 0-3.5-1.6-3.5-3.5V24c0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5v-1.5c0 1.9-1.6 3.5-3.5 3.5z"
            />
            <path
                fill="#C1C4E6"
                fillRule="nonzero"
                d="M22 18.5c-1.9 0-3.5 1.6-3.5 3.5v2.2c.1-1.8 1.6-3.2 3.5-3.2s3.3 1.4 3.5 3.2V22c0-1.9-1.6-3.5-3.5-3.5z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M25.5 22c0-1.9-1.6-3.5-3.5-3.5s-3.5 1.6-3.5 3.5v2c0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5v-2z"
            />
            <circle
                cx="34"
                cy="24"
                r="2"
                fill="#C1C4E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="11"
                cy="24"
                r="2"
                fill="#C1C4E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M4.514 31c0 3.6 8.017 6.5 17.986 6.5 9.97 0 17.986-2.9 17.986-6.5v-5c0 3.6-8.017 6.5-17.986 6.5-9.97 0-17.986-2.9-17.986-6.5v5z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M22.5 32.5c-9.97 0-17.986-2.9-17.986-6.5v2.5c0 3.6 8.017 6.5 17.986 6.5 9.97 0 17.986-2.9 17.986-6.5V26c0 3.6-8.017 6.5-17.986 6.5z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.914 31c0 3.6 7.8 6.5 17.5 6.5s17.5-2.9 17.5-6.5v-5c0 3.6-7.8 6.5-17.5 6.5s-17.5-2.9-17.5-6.5v5z"
            />
            <g transform="translate(9 4)">
                <circle cx="3" cy="2.5" r="2.5" fill="#D6DAFF" fillRule="nonzero" />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M1.2 4.3c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0L1.2 4.3z"
                />
                <circle
                    cx="3"
                    cy="2.5"
                    r="2.5"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g transform="translate(29 4)">
                <circle cx="3" cy="2.5" r="2.5" fill="#D6DAFF" fillRule="nonzero" />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M1.2 4.3c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0L1.2 4.3z"
                />
                <circle
                    cx="3"
                    cy="2.5"
                    r="2.5"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g transform="translate(0 5)">
                <circle cx="3.5" cy="3.5" r="3" fill="#D6DAFF" fillRule="nonzero" />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M1.4 5.6C.2 4.4.2 2.5 1.4 1.4 2.6.2 4.5.2 5.6 1.4L1.4 5.6z"
                />
                <circle
                    cx="3.5"
                    cy="3.5"
                    r="3"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g transform="translate(19)">
                <circle cx="3" cy="3.5" r="3" fill="#D6DAFF" fillRule="nonzero" />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M.9 5.6C-.3 4.4-.3 2.5.9 1.4 2.1.2 4 .2 5.1 1.4L.9 5.6z"
                />
                <circle
                    cx="3"
                    cy="3.5"
                    r="3"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g transform="translate(37 5)">
                <circle cx="3.5" cy="3.5" r="3" fill="#D6DAFF" fillRule="nonzero" />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M1.4 5.6C.2 4.4.2 2.5 1.4 1.4 2.6.2 4.5.2 5.6 1.4L1.4 5.6z"
                />
                <circle
                    cx="3.5"
                    cy="3.5"
                    r="3"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
}

export default EmojiNormal5;
