import React from 'react';
import PropTypes from 'prop-types';

import WordLabelDelete from './WordLabelDelete';
import { Wrapper, Count } from './styled';

function WordLabel(props) {
    return (
        <Wrapper
            $pillType={props.pillType}
            $clickable={props.onWordClick && props.isClickable}>
            {props.children}
            {props.onWordClick && props.isClickable && (
                <WordLabelDelete onClick={props.onWordClick} name={props.children} />
            )}
            {props.count && props.pillType.includes('blacklist') ? (
                <Count>{props.count}x</Count>
            ) : null}
        </Wrapper>
    );
}

WordLabel.propTypes = {
    children: PropTypes.any.isRequired,
    isClickable: PropTypes.bool,
    count: PropTypes.number,
    onWordClick: PropTypes.func,
    pillType: PropTypes.string
};

WordLabel.defaultProps = {
    count: 0,
    isClickable: true,
    pillType: '',
    onWordClick: () => {}
};

export default WordLabel;
