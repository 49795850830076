import styled from 'styled-components';
import ColumnWrapper from '../../Column';

const Column = styled(ColumnWrapper)`
    justify-content: center;
    margin-right: ${props => props.$marginRight}px;
`;

Column.defaultProps = {
    $marginRight: 0
};

export default Column;
