import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function StopWatch(props) {
  return <svg width={props.size} viewBox="0 0 15 18">
        <defs>
            <ellipse id="a" cx="6.5" cy="8.613" rx="6.5" ry="6.387" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                stroke={props.color}
                strokeWidth="1.008"
                d="M12.427 5.715a6.816 6.816 0 0 1 2.077 4.898c0 3.808-3.138 6.891-7.004 6.891-3.866 0-7.004-3.083-7.004-6.89 0-3.327 2.408-6.141 5.656-6.764V2.176a.68.68 0 0 1 .68-.68H8.17a.68.68 0 0 1 .68.68v1.675a7.032 7.032 0 0 1 2.091.76l.876-.877a.68.68 0 0 1 .963 0l.332.333a.68.68 0 0 1 0 .962l-.685.686z"
            />
            <g transform="translate(1 2)">
                <use fill="#FFF" />
                <ellipse
                    cx="6.5"
                    cy="8.613"
                    stroke={props.color}
                    strokeWidth="1.008"
                    rx="7.004"
                    ry="6.891"
                />
            </g>
            <path
                stroke={props.color}
                strokeLinecap="round"
                strokeWidth="1.324"
                d="M7.5 6.58v4.033M4.9 12.588l2.6-1.975"
            />
        </g>
    </svg>
}

StopWatch.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

StopWatch.defaultProps = {
    size: 15,
    color: colors.blue.palatinate_blue
};

export default StopWatch;
