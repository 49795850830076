import { sendRequest } from './common';
import { REQUESTS } from '../../constants';
import {saveRealtimeCallChecker} from "../../utils/asr";

export const types = {
    GET_APP_CONFIG: '@bg/GET_APP_CONFIG',
    GET_APP_CONFIG_SUCCESS: '@bg/GET_APP_CONFIG_SUCCESS',
    GET_APP_CONFIG_FAIL: '@bg/GET_APP_CONFIG_FAIL',

    UPDATE_APP_CONFIG: '@bg/UPDATE_APP_CONFIG',
    UPDATE_APP_CONFIG_SUCCESS: '@bg/UPDATE_APP_CONFIG_SUCCESS',
    UPDATE_APP_CONFIG_FAIL: '@bg/UPDATE_APP_CONFIG_FAIL',

    SET_RECORD_CALL: '@bg/SET_RECORD_CALL',
};

export const requestClientConfig = ({ pending, success, fail }) => () => dispatch => {
    const onSuccess = config => {
        dispatch({
            type: success,
            payload: { config }
        });
    };

    const onError = error => {
        dispatch({ type: fail, error });
    };
    dispatch({ type: pending });
    dispatch(sendRequest(REQUESTS.GET_APP_CONFIG, { onSuccess, onError }));
};

export const updateRecordCall = (realtimeOnlyCallsPercent) => dispatch => {
    const recordCall = saveRealtimeCallChecker(realtimeOnlyCallsPercent);

    dispatch({
        type: types.SET_RECORD_CALL,
        payload: {
            recordCall
        }
    })
}

export const getAppConfig = requestClientConfig({
    pending: types.GET_APP_CONFIG,
    success: types.GET_APP_CONFIG_SUCCESS,
    fail: types.GET_APP_CONFIG_FAIL
});
