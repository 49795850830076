import { createError } from '../utils';

const JwtTokenEmptyRoleError = createError({
    name: 'JwtTokenEmptyRoleError',
    message: p => `Got token with empty role field. UserId: ${p.userId}`
});

const JwtTokenDecodeError = createError({
    name: 'JwtTokenDecodeError',
    message: `Cound't decode the token`
});

export const JwtTokenErrors = { JwtTokenEmptyRoleError, JwtTokenDecodeError };
