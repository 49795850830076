import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../../../utils';

const InfoPopup = styled.div`
    position: absolute;
    z-index: -1;
    left: ${props => (props.$left ? '0px' : '')};
    right: ${props => (props.$left ? '' : '0px')};
    top: ${props => (props.$top ? '20px' : '')};
    bottom: ${props => (props.$top ? '' : '20px')};

    pointer-events: none;

    max-width: 250px;
    width: max-content;
    padding: 10px;

    background-color: ${colors.white.basic};
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 ${colors.blue.link_water};
    color: ${colors.black.default};
    border: solid 1px ${colors.blue.link_water};

    opacity: 0;
    transform: translateY(-5px);

    transition: all 0.25s ease;

    font-size: 12px;
    font-weight: normal;

    user-select: none;
`;

InfoPopup.propTypes = {
    $left: PropTypes.bool.isRequired,
    $top: PropTypes.bool.isRequired
};

export default InfoPopup;
