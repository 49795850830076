import React from 'react';

function EmojiNormal3() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="36" height="47" viewBox="0 0 36 47">
        <g fill="none" fillRule="evenodd" transform="translate(0 1)">
            <ellipse
                cx="18"
                cy="44.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="14.5"
                ry="1.5"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M29.9 17.7c.5-.7.7-1.6.6-2.6-.2-2.2-2.1-3.8-4.3-3.8h-1c.9-.7 1.5-1.8 1.5-3 0-2.1-1.7-3.8-3.8-3.8-2 0-8.1.7-6.9-3.4.2-.6-.3-1.1-.9-1-2.8.7-5.9 3.6-5.9 8.2 0 1.2.6 2.3 1.5 3h-1c-2.2 0-4.1 1.6-4.3 3.8-.1.9.2 1.8.6 2.6C2.9 18.6.7 21.6.8 25c.2 3.9 3.6 6.8 7.5 6.8h19.2c3.9 0 7.3-3 7.5-6.8.2-3.4-2-6.4-5.1-7.3z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M5.1 27.1c-.2-3.4 2.1-6.4 5.2-7.3-.5-.7-.7-1.6-.6-2.6.2-2.2 2.1-3.8 4.3-3.8h1c-.9-.7-1.5-1.8-1.5-3 0-3.1 1.4-5.4 3.1-6.8-.6-.5-.9-1.3-.6-2.5.2-.6-.3-1.1-.9-1-2.8.7-5.9 3.6-5.9 8.2 0 1.2.6 2.3 1.5 3h-1c-2.2 0-4.1 1.6-4.3 3.8-.1.9.2 1.8.6 2.6C2.9 18.6.7 21.6.8 25c.2 3.5 2.9 6.2 6.3 6.7-1.1-1.1-1.9-2.8-2-4.6z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M29.9 17.7c.5-.7.7-1.6.6-2.6-.2-2.2-2.1-3.8-4.3-3.8h-1c.9-.7 1.5-1.8 1.5-3 0-2.1-1.7-3.8-3.8-3.8-2 0-8.1.7-6.9-3.4.2-.6-.3-1.1-.9-1-2.8.7-5.9 3.6-5.9 8.2 0 1.2.6 2.3 1.5 3h-1c-2.2 0-4.1 1.6-4.3 3.8-.1.9.2 1.8.6 2.6C2.9 18.6.7 21.6.8 25c.2 3.9 3.6 6.8 7.5 6.8h19.2c3.9 0 7.3-3 7.5-6.8.2-3.4-2-6.4-5.1-7.3z"
            />
            <g transform="translate(10 17)">
                <ellipse
                    cx="1.5"
                    cy="3.1"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1.1"
                    ry="1"
                />
                <g
                    fillRule="nonzero"
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path
                        fill="#D6DAFF"
                        d="M10.9 5c.5 0 .9.5.7 1-.4 1.6-1.9 2.8-3.7 2.8-1.8 0-3.3-1.2-3.7-2.8-.1-.5.2-1 .7-1h6z"
                    />
                    <path
                        fill="#C1C4E6"
                        d="M8 8.8c1.2 0 2.3-.6 3-1.5-.8-.5-1.8-.8-3-.8s-2.2.3-3 .8c.7.9 1.8 1.5 3 1.5z"
                    />
                </g>
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1.2 1.6C1.2 1 1.7.5 2.3.5c.6 0 1.1.5 1.1 1.1"
                />
                <ellipse
                    cx="14.5"
                    cy="3.1"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1.1"
                    ry="1"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.8 1.6c0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1"
                />
            </g>
            <g>
                <path
                    fill="#C1C4E6"
                    fillRule="nonzero"
                    d="M33.6 33.4H2.5c-.4 0-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8h31.1c.4 0 .8.3.8.8v3c-.1.4-.4.8-.8.8z"
                />
                <path
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    d="M34.3 30.7H1.7v-1.1c0-.4.3-.8.8-.8h31.1c.4 0 .8.3.8.8v1.1h-.1z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M33.6 33.4H2.5c-.4 0-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8h31.1c.4 0 .8.3.8.8v3c-.1.4-.4.8-.8.8z"
                />
                <g>
                    <path
                        fill="#D6DAFF"
                        fillRule="nonzero"
                        d="M29.4 44H6.6c-1.5 0-2.8-1.1-3-2.6l-1.1-8h31.1l-1.1 8c-.3 1.5-1.6 2.6-3.1 2.6z"
                    />
                    <path
                        fill="#C1C4E6"
                        fillRule="nonzero"
                        d="M33.3 35.3l.3-1.9H2.4l.3 1.9z"
                    />
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M29.4 44H6.6c-1.5 0-2.8-1.1-3-2.6l-1.1-8h31.1l-1.1 8c-.3 1.5-1.6 2.6-3.1 2.6z"
                    />
                </g>
            </g>
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M32 24.3v1.1c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1v-2.7M22.2 9.1V11c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1v-.8"
            />
            <g>
                <path
                    fill="#FFFEF2"
                    fillRule="nonzero"
                    d="M7 28.1V30c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1v-1.9H7z"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 28.8v1.1c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1V28"
                />
            </g>
        </g>
    </svg>
}

export default EmojiNormal3;
