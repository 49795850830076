import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const RadioButtonContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
`;

const RadioInput = styled.input.attrs(() => ({
    type: 'radio'
}))`
    display: none;

    &:checked + span {
        background-color: white;
        border-color: #666666; // Add this line to change the border color when selected
    }

    &:checked + span:after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #666666;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const RadioLabel = styled.span`
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #666666;
    margin-right: 8px;
    transition: background-color 0.3s, border-color 0.3s; // Add border-color to the transition
`;

const LabelText = styled.span`
    user-select: none;
    font-size: 12px;
`;

function RadioGroup({ options, onChange, defaultValue }) {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    return (
        <RadioContainer>
            {options.map(option => (
                <RadioButtonContainer key={option.value}>
                    <RadioInput
                        id={option.value}
                        name="radio-group"
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={e => {
                            setSelectedValue(e.target.value);
                            onChange(e.target.value);
                        }}
                    />
                    <RadioLabel htmlFor={option.value} />
                    <LabelText>{option.label}</LabelText>
                </RadioButtonContainer>
            ))}
        </RadioContainer>
    );
}

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string
};

RadioGroup.defaultProps = {
    defaultValue: ''
};

export default RadioGroup;
