import React from 'react';

function EmojiBad4() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="41" height="46" viewBox="0 0 41 46">
        <g fill="none" fillRule="evenodd">
            <ellipse
                cx="18"
                cy="44.4"
                fill="#F8F9FF"
                fillRule="nonzero"
                rx="14.5"
                ry="1.6"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.5 10.1c.4-.7.7-1.5.7-2.4C10.2 5.1 8.1 3 5.5 3S.8 5.1.8 7.7s2.1 4.7 4.7 4.7h.4c0 1.4 1.2 2.6 2.6 2.6 1.4 0 2.6-1.2 2.6-2.6-.1-1-.7-1.9-1.6-2.3z"
            />
            <circle
                cx="16.1"
                cy="3.1"
                r="2.6"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M34.7 34.2c3.1 0 5.6-2.5 5.6-5.6 0-3.1-2.5-5.6-5.6-5.6h-2.1v3.4h2.1c1.2 0 2.1 1 2.1 2.1s-1 2.1-2.1 2.1h-3.9V34h3.9v.2z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M33.9 24.8c3.1 0 5.6 2.5 5.6 5.6 0 .6-.1 1.1-.2 1.6.7-.9 1.1-2.1 1.1-3.3 0-3.1-2.5-5.6-5.6-5.6h-2.1v1.7h1.2z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M34.7 34.2c3.1 0 5.6-2.5 5.6-5.6 0-3.1-2.5-5.6-5.6-5.6h-2.1v3.4h2.1c1.2 0 2.1 1 2.1 2.1s-1 2.1-2.1 2.1h-3.9V34h3.9v.2z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M2.5 19.5v9c0 8.8 6.9 16 15.5 16s15.5-7.2 15.5-16v-9h-31z"
            />
            <path
                fill="#F8F9FF"
                fillRule="nonzero"
                d="M18 39c-6.4 0-12.1-2.8-15.1-6.9 1.6 7.1 7.7 12.4 15.1 12.4 7.4 0 13.5-5.3 15.1-12.4-3 4.1-8.7 6.9-15.1 6.9z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.5 19.5v9c0 8.8 6.9 16 15.5 16s15.5-7.2 15.5-16v-9h-31z"
            />
            <ellipse
                cx="18"
                cy="19.5"
                fill="#F8F9FF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                rx="15.5"
                ry="2"
            />
            <g
                fill="#D6DAFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M21.3 32.9c.6 0 1 .5.8 1.1-.5 1.9-2.2 3.2-4.2 3.2s-3.7-1.4-4.2-3.2c-.1-.5.3-1.1.8-1.1h6.8z" />
                <path d="M18 37.2c1.4 0 2.6-.7 3.4-1.7-.9-.6-2.1-.9-3.4-.9-1.3 0-2.5.3-3.4.9.8 1.1 2 1.7 3.4 1.7z" />
            </g>
            <g transform="translate(9 27)">
                <ellipse
                    cx="1.7"
                    cy="3.8"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1.3"
                    ry="1"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1.3 2.1c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3"
                />
                <ellipse
                    cx="16.3"
                    cy="3.8"
                    fill="#D6DAFF"
                    fillRule="nonzero"
                    rx="1.3"
                    ry="1"
                />
                <path
                    stroke="#4550E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.7 2.1c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3"
                />
            </g>
        </g>
    </svg>
}

export default EmojiBad4;
