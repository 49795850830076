import { sample } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as emojis from './icons';

const getEmojiGroup = (spottedPhrases, totalPhrases) => {
    const percentage = (spottedPhrases / totalPhrases) * 100;

    if (percentage < 20) return null; // emojis.disaster;
    if (percentage < 40) return null; // emojis.bad;
    if (percentage < 80) return emojis.normal;
    return emojis.perfect;
};

class Emoji extends Component {
    static propTypes = {
        spottedPhrasesScore: PropTypes.number,
        totalPhrasesScore: PropTypes.number,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        spottedPhrasesScore: 0,
        totalPhrasesScore: 0
    };

    shouldComponentUpdate(nextProps) {
        return !!nextProps.visible;
    }

    render() {
        const { spottedPhrasesScore, totalPhrasesScore } = this.props;
        if (!totalPhrasesScore) return null;

        const group = getEmojiGroup(spottedPhrasesScore, totalPhrasesScore);
        if (group === null) {
            return <div />;
        }
        const randomEmojiName = sample(Object.keys(group));
        const RandomEmoji = group[randomEmojiName];

        return <RandomEmoji />;
    }
}

export default Emoji;
