import styled from 'styled-components';
import PropTypes from 'prop-types';

const MenuIcon = styled.span`
    width: 21px;
    height: 21px;

    cursor: ${props => (props.$enabled ? 'pointer' : 'default')};
    touch-action: ${props => (props.$enabled ? 'auto' : 'none')};
    pointer-events: ${props => (props.$enabled ? 'auto' : 'none')};

    user-select: none;
`;

MenuIcon.propTypes = {
    $enabled: PropTypes.bool
};

MenuIcon.defaultProps = {
    $enabled: false
};

export default MenuIcon;
