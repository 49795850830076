import {CallEvents, CallMode} from "../../constants";

// @docs
// https://i2x-gmbh.atlassian.net/wiki/spaces/TECH/pages/445382792/UCT-like+events

export const schema = {
    'type': 'object',
    'properties': {
        'source': {'type': 'string'},
        'call_id': {'type': 'string'},
        'uct_id': {'type': ['string', 'integer']},
        'event_type': {'type': 'string', 'enum': ['start', 'end', 'ping', 'hold', 'unhold', 'change']},
        'timestamp': {'type': 'string'},
        'allow_call_recording': {'type': 'boolean'},
        'CallMetaData': {
            'type': 'object',
            'properties': {
                'customer_id': {'type': 'string'},
                'resolution': {'type': 'string'},
                'resolution_reason': {'type': 'integer'},
                'bought_product': {'type': 'object'},
                'call_type': {'type': 'string'},
                'call_cluster': {'type': 'string'}
            }
        }
    },
    'required': ['event_type'],
    "if": {
        "properties": { "event_type": { "const": "ping" } },
    },
    "else": {
        'required': ['source', 'call_id', 'timestamp'],
    },
}

export function parse(event) {
    let callEvent = null;
    let callMode = null;
    const forgetAudioAndTranscript = false;

    if (event.allow_call_recording === true) {
        callMode = CallMode.BOTH;
    } else if(event.allow_call_recording === false){
        callMode = CallMode.NONE;
    }

    if (event.event_type === 'start') {
        callEvent = CallEvents.START;
    } else if (event.event_type === 'end') {
        callEvent = CallEvents.END;
    } else if (event.event_type === 'hold') {
        callEvent = CallEvents.HOLD;
    } else if (event.event_type === 'unhold') {
        callEvent = CallEvents.UNHOLD;
    } else if (event.event_type === 'ping') {
        callEvent = CallEvents.PING;
    } else if (event.event_type === 'change') {
        callEvent = CallEvents.CHANGE;
    }

    return [callEvent, callMode, forgetAudioAndTranscript];
}
