import React from 'react';

function TenthCall() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={28} height={44}>
            <defs>
                <clipPath id="a">
                    <path d="M1908 0v1258H0V0h1908Z" />
                </clipPath>
                <clipPath id="b">
                    <path d="M13.4.738c1.2-1 2.9-1 3.9.1 6.6 6.9 9.6 16.4 8.9 23.4-1.1 9.7-3.6 11.8-11.3 12.9-9.1 1.3-14.9-7.1-14.9-14.2 0-1.7 1.8-3 3.5-2.5 1.5.4 2.3 1.5 2.4 2.9.2 2.5 2.5 4.6 4.6 5 3.4.7 6.4-1.8 6.4-5.1 0-2.6-1.8-4.6-4.1-5.1-1.7-.3-3.8-.1-5 .9-.8.7-2 .9-3.1.5-2-.7-2.5-3.4-.9-4.8 2.3-2.1 6.1-3 9.6-2.5 2.4.3 4.5 1.4 6.1 3-1.3-3.9-3.4-7.5-6.3-10.4-1.1-1.1-1-3 .2-4.1Z" />
                </clipPath>
                <clipPath id="c">
                    <path d="M.982.738c1.2-1 2.9-1 3.9.1 6.3 6.5 9.3 15.4 9.1 22.4-.7-6.3-3.7-13.5-9-18.9-1.1-1-2.8-1.1-3.9-.1-.1.1-.3.3-.4.5-1-1.2-.9-3 .3-4Z" />
                </clipPath>
                <clipPath id="d">
                    <path d="M11.852 0C18.04.026 23 .688 23 1.5 23 2.328 17.851 3 11.5 3S0 2.328 0 1.5 5.149 0 11.5 0Z" />
                </clipPath>
                <clipPath id="e">
                    <path d="M.554.706a2.228 2.228 0 0 1 3.284.032c2.4 2.7 4.2 5.8 5.3 9.3-1.6-1.6-3.7-2.7-6-3-.7-1.2-1.6-2.4-2.6-3.5-.7-.7-.7-1.9-.1-2.7Z" />
                </clipPath>
                <clipPath id="f">
                    <path d="M.316.706A2.228 2.228 0 0 1 3.6.738c2.4 2.7 4.2 5.8 5.3 9.3-.8-.8-1.7-1.4-2.6-1.9-1-2.3-2.4-4.4-4.1-6.3-.6-.7-1.4-.9-2.2-.7 0-.1.1-.2.2-.3Z" />
                </clipPath>
                <clipPath id="g">
                    <path d="M.958 2.635c2.3-2.1 6.1-3 9.6-2.5 2.4.3 4.5 1.4 6.1 3 1.3 1.2 2.3 2.8 2.9 4.5-1.6-1.5-6.6-3.6-8.9-3.9-3.7-.6-7.7.4-10.1 2.8-.8-1.1-.8-2.8.4-3.9Z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="translate(-1296 -262)">
                <g clipPath="url(#b)" transform="translate(1296.9 262.962)">
                    <path fill="#D6D9FF" d="M0 0h26.3v37.272H0V0z" />
                </g>
                <g clipPath="url(#c)" transform="translate(1309.318 262.962)">
                    <path fill="#F8F9FF" d="M0 0h13.991v23.238H0V0z" />
                </g>
                <g clipPath="url(#d)" transform="translate(1299.5 302)">
                    <path fill="#D6D9FF" d="M0 0h23v3H0V0z" />
                </g>
                <g clipPath="url(#e)" transform="translate(1307.262 268.162)">
                    <path fill="#D6D9FF" d="M0 0h9.138v10.038H0V0z" />
                </g>
                <g clipPath="url(#f)" transform="translate(1307.6 268.162)">
                    <path fill="#F8F9FF" d="M0 0h8.9v10.038H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#4450E6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1310.4 275.2c2.3.3 4.4 1.4 6 3-1.1-3.5-2.9-6.6-5.3-9.3-.9-1-2.5-1-3.4.1h0c-.6.8-.6 2 .1 2.7 1 1.1 1.9 2.3 2.6 3.5Z"
                />
                <g clipPath="url(#g)" transform="translate(1299.742 275.065)">
                    <path fill="#F8F9FF" d="M0 0h19.558v7.635H0V0z" />
                </g>
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1316.4 278.2c2.1 2 3.4 4.9 3.4 8"
                />
                <path
                    fill="none"
                    stroke="#454FE6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1314.2 263.8c-1-1.1-2.7-1.1-3.9-.1-1.2 1.1-1.3 3-.2 4.1 2.9 2.9 5 6.5 6.3 10.4-1.6-1.6-3.7-2.7-6.1-3-3.5-.5-7.3.4-9.6 2.5-1.6 1.4-1.1 4.1.9 4.8 1.1.4 2.3.2 3.1-.5 1.2-1 3.3-1.2 5-.9 2.3.5 4.1 2.5 4.1 5.1 0 3.3-3 5.8-6.4 5.1-2.1-.4-4.4-2.5-4.6-5-.1-1.4-.9-2.5-2.4-2.9-1.7-.5-3.5.8-3.5 2.5 0 0 0 0 0 0 0 7.1 5.8 15.5 14.9 14.2 7.7-1.1 10.2-3.2 11.3-12.9.7-7-2.3-16.5-8.9-23.4Z"
                />
            </g>
        </svg>
    );
}

export default TenthCall;
