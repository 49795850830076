import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal';
import RadioGroup from '../../common/RadioGroup';

export const CONVERSION_RESULT = {
    NO_CONVERSION: 'no_conversion',
    SHORT_CALL: 'short_call',
    CONVERSION_ONE: 'conversion_one',
    CONVERSION_MULTIPLE: 'conversion_multiple',
    NO_ANSWER: 'no_answer',
    IGNORED: 'ignored',
    OVERWRITTEN: 'overwritten'
};

function ConversionModal(props) {
    const { userId, callId, onFinish } = props;

    const [conversionResult, setConversionResult] = useState(null);

    const onSubmitModal = () => {
        if (conversionResult)
            props.saveConversion({ conversionResult, userId: String(userId), callId });
        onFinish();
    };

    const onCloseModal = () => {
        props.saveConversion({
            conversionResult: CONVERSION_RESULT.NO_ANSWER,
            userId: String(userId),
            callId
        });
        onFinish();
    };

    return (
        <Modal
            header={props.localization.getText('conversion.title')}
            isOpen
            localization={props.localization}
            onSubmit={onSubmitModal}
            onClose={onCloseModal}
            isDisabled={!conversionResult}>
            <RadioGroup
                onChange={value => {
                    setConversionResult(value);
                }}
                options={[
                    {
                        label: props.localization.getText('conversion.result.yesOne'),
                        value: CONVERSION_RESULT.CONVERSION_ONE
                    },
                    {
                        label: props.localization.getText(
                            'conversion.result.yesMultiple'
                        ),
                        value: CONVERSION_RESULT.CONVERSION_MULTIPLE
                    },
                    {
                        label: props.localization.getText('conversion.result.no'),
                        value: CONVERSION_RESULT.NO_CONVERSION
                    }
                ]}
            />
        </Modal>
    );
}

ConversionModal.propTypes = {
    saveConversion: PropTypes.func.isRequired,
    onFinish: PropTypes.func,
    userId: PropTypes.number.isRequired,
    callId: PropTypes.string.isRequired,
    localization: PropTypes.object.isRequired
};

ConversionModal.defaultProps = {
    onFinish: () => {}
};

export default ConversionModal;
