import styled from 'styled-components';
import { colors } from '../../../../utils';

export default styled.div`
    margin-top: 4px;

    font-weight: 500;
    font-size: 12px;

    color: ${colors.gray.dark};
`;
