import { put, takeLatest, take, select } from 'redux-saga/effects';
import { types as snoozeTypes, setSnoozeTimer } from '../actions/snooze';
import { isCallStarted, getIsTrainerActive, getSessionDurationMsec } from '../selectors';
import { DEFAULT_SNOOZE_TIMER } from '../../../utils/snooze';
import { types as callTypes} from '../actions/call';
import { types as commonTypes } from '../actions/common';
import { proto } from '../../services/AsrProto';
import { wsConnectionClosingCodes } from '../../constants';

export function* onSnoozeTrainer(action) {
    try {
        const { snoozeFor = DEFAULT_SNOOZE_TIMER } = action.payload;

        const isTrainerActive = yield select(getIsTrainerActive);

        // Send snooze request
        yield put(setSnoozeTimer(snoozeFor));

        // Cover the case when trainer is already inactive
        // and user adds snooze timer again
        if (isTrainerActive) {
            const isStarted = yield select(isCallStarted);

            // Wait success request to end the call or close all connections
            yield take(snoozeTypes.SET_SNOOZE_TIMER_SUCCESS);

            if (isStarted) {
                // Trigger Post Recording
                const {sessionOffsetMsec} = yield select(getSessionDurationMsec);
                yield put({
                    type: callTypes.CALL_ENDED,
                    payload: {
                        reason: proto.FinalizationReason.CALL_END_NORMAL,
                        sessionEndOffsetMsec: sessionOffsetMsec,
                    }
                });
            }

            // Use same saga if trainer already snoozed
            // Code NO_RELOAD WS closing code
            yield put({
                type: commonTypes.CLOSE_ALL_CONNECTIONS,
                payload: {
                    reason: proto.FinalizationReason.CALL_END_NORMAL,
                    code: wsConnectionClosingCodes.NO_RELOAD
                }
            });
        }
    } catch (error) {
        yield put({ type: snoozeTypes.SNOOZE_TRAINER_FAIL });
    }
}

export function* handleSnoozeTrainer() {
    yield takeLatest(snoozeTypes.SNOOZE_TRAINER, onSnoozeTrainer);
}

export default [handleSnoozeTrainer()];
