import root from '../manifests/vibe.proto';
import rootPhraseSpotting from '../manifests/phrase_spotting.proto';
import {decorateToObject, isToObject, traverse} from '../utils/proto';

traverse(root.vibe, isToObject, decorateToObject);

const { SpotterKind } = rootPhraseSpotting.phrase_spotting
const proto = {
    MechanismName: root.vibe.AuthenticationRequest.MechanismName,
    RecognizeRequest: root.vibe.RecognizeRequest,
    RecognizeResponse: root.vibe.RecognizeResponse,
    ServiceMessage: root.vibe.ServiceMessage,
    ServiceMessageStatus: root.vibe.ServiceMessage.Status,
    AudioEncoding: root.vibe.AudioEncoding,
    OperationMode: root.vibe.RecognitionConfig.OperationMode,
    Channel: root.vibe.AudioChunkWithMetadata.Channel,
    AudioChunkWithMetadata: root.vibe.AudioChunkWithMetadata,
    ChannelControl: root.vibe.ChannelControl,
    Vendor: root.vibe.SpeechRecognitionResult.Vendor,
    Source: root.vibe.SessionInfo.Source,
    FinalizationReason: root.vibe.FinalizationToken.Reason,
    IntegrationMethod: root.vibe.CallDetectionConfig.IntegrationMethod,
    ExplicitIntegrationSource: root.vibe.CallDetectionConfig.ExplicitIntegrationSource,
};

const spottingKindToProto = (kind = '') => {
    return SpotterKind[kind] || SpotterKind.UNKNOWN
}

const spottingConfigToProto = (cfg = {}) => {
    const mapper = (acc, entry) => {
        acc[entry] = cfg[entry].map(phrase => {
            return {
                ...phrase,
                kind: spottingKindToProto(phrase.kind),
            };
        });
        return acc;
    }

    return Object.keys(cfg).reduce(mapper, {});
}

export { proto,  spottingConfigToProto };
