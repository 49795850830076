import { connect } from 'react-redux';
import BattleCard from '../../components/BattleCard';
import withLocalization from '../../localization/withLocalization';
import { closeBattleCard } from '../../store/actions/battleCard';

const mapStateToProps = state => ({
    ...state.battleCard
});
const mapDispatchToProps = {
    closeBattleCard
};

export const PureBattleCard = BattleCard;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalization(BattleCard));
