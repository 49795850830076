import React from 'react';

function Level4v2() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18" viewBox="0 0 28 18">
        <g fill="none" fillRule="evenodd" transform="translate(0 1)">
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                d="M24.957 10.321s1.765.425 2.739 0c-.244 1.458-1.705 2.186-3.044 2.125-1.339-.06.305-2.125.305-2.125z"
            />
            <ellipse
                cx="15.217"
                cy="16.089"
                fill="#E3E5FC"
                fillRule="nonzero"
                rx="11.565"
                ry="1"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M10.043 4.554C10.043 2.064 7.973 0 5.478 0 2.983 0 .304 2.671.304 5.16c0 2.49 2.679 3.947 5.174 3.947h.305a4.23 4.23 0 0 0 4.26 4.25V4.554z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M5.54 1.7c2.312 0 4.2 1.7 4.503 3.886V4.554C10.043 2.064 7.973 0 5.478 0 2.983 0 .304 2.671.304 5.16c0 .304.061.669.122.911C.913 3.825 3.287 1.7 5.54 1.7z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                d="M10.043 4.554C10.043 2.064 7.973 0 5.478 0 2.983 0 .304 2.671.304 5.16c0 2.49 2.679 3.947 5.174 3.947h.305a4.23 4.23 0 0 0 4.26 4.25V4.554z"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                d="M24.713 12.932c.183-.91.304-1.943.244-2.914-.244-4.554-3.592-8.257-7.548-8.44-4.505-.242-8.279 3.947-8.279 9.108 0 .789.061 1.578.244 2.307.06.243.243.486.487.486 8.765 2.125 14.974-.122 14.365 0 .244-.061.426-.304.487-.547z"
            />
            <path
                fill="#8D94F2"
                fillRule="nonzero"
                d="M24.957 10.99v-.972c-.244-4.554-3.592-8.257-7.548-8.44-4.505-.242-8.279 3.947-8.279 9.108v.121c1.34.729 3.896 1.7 8.157 1.7 3.774-.06 6.209-.85 7.67-1.518z"
            />
            <path
                fill="#737DFF"
                fillRule="nonzero"
                d="M9.313 10.807c.791-4.19 4.14-7.286 8.035-7.103 3.652.182 6.695 3.278 7.426 7.346.06 0 .122-.06.122-.06v-.972c-.244-4.554-3.592-8.257-7.548-8.44-4.505-.242-8.278 3.947-8.278 9.108v.121c.121-.06.182 0 .243 0z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                d="M24.957 10.99v-.972c-.244-4.554-3.592-8.257-7.548-8.44-4.505-.242-8.279 3.947-8.279 9.108v.121c1.34.729 3.896 1.7 8.157 1.7 3.774-.06 6.209-.85 7.67-1.518z"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M10.957 9.41a2.439 2.439 0 0 0-2.435 2.43l-.244 3.278c0 .364.244.668.609.668h3.652c.304 0 .609-.243.609-.547l.243-3.4a2.439 2.439 0 0 0-2.434-2.428z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M10.835 11.293c1.217 0 2.191.85 2.435 1.943l.121-1.397a2.439 2.439 0 0 0-2.434-2.428 2.439 2.439 0 0 0-2.435 2.428L8.4 13.054c.365-1.033 1.278-1.761 2.435-1.761z"
            />
            <path
                fill="#ECEDFC"
                fillRule="nonzero"
                d="M25.809 15.118l-.244-3.279a2.439 2.439 0 0 0-2.435-2.428 2.439 2.439 0 0 0-2.434 2.428l.243 3.4c0 .304.304.547.609.547H25.2c.365 0 .67-.304.609-.668z"
            />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M23.252 11.293c-1.217 0-2.191.85-2.435 1.943l-.121-1.397a2.439 2.439 0 0 1 2.434-2.428 2.439 2.439 0 0 1 2.435 2.428l.122 1.215c-.365-1.033-1.278-1.761-2.435-1.761z"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                d="M10.957 9.41a2.439 2.439 0 0 0-2.435 2.43l-.244 3.278c0 .364.244.668.609.668h3.652c.304 0 .609-.243.609-.547l.243-3.4a2.439 2.439 0 0 0-2.434-2.428zM25.809 15.118l-.244-3.279a2.439 2.439 0 0 0-2.435-2.428 2.439 2.439 0 0 0-2.434 2.428l.243 3.4c0 .304.304.547.609.547H25.2c.365 0 .67-.304.609-.668z"
            />
            <ellipse
                cx="5.235"
                cy="3.279"
                fill="#4550E6"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                rx="1"
                ry="1"
            />
            <ellipse
                cx="7.426"
                cy="5.646"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="1"
                ry="1"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".609"
                d="M1.704 7.893s2.435.364 3.166-1.518M12.113 3.46s1.4 2.308 2.191 2.369c.792.06 1.644-1.154 2.74-1.154 1.095 0 2.313 1.821 2.982 1.7.67-.121 2.678-2.064 2.678-2.064M17.043 4.675V1.518M14.304 5.829s-1.278 2.003-1.46 4.492M20.026 6.375s.852 1.518 1.157 4.068M13.209 10.868s3.348.728 7.67.121"
            />
        </g>
    </svg>
}

export default Level4v2;
