import React from 'react';

function PhoneInTalk() {
  return <svg width={38} viewBox="0 0 38 38">
        <g>
            <path
                fill="#D6DAFF"
                stroke="#4550E6"
                strokeWidth="1.5"
                d="M35 26c-2.4 0-4.8-.4-7.2-1.2-.6-.2-1.4 0-2 .4l-4.4 4.4C15.8 26.8 11.2 22 8.2 16.4l4.4-4.4c.6-.6.8-1.4.4-2-.6-2.2-1-4.6-1-7 0-1.2-.8-2-2-2H3c-1.2 0-2 .8-2 2 0 18.8 15.2 34 34 34 1.2 0 2-.8 2-2v-7c0-1.2-.8-2-2-2z"
            />
            <path fill="#4550E6" d="M37 19C37 9 29 1 19 1v4c7.8 0 14 6.2 14 14h4z" />
            <path fill="#4550E6" d="M25 19h4c0-5.6-4.4-10-10-10v4c3.4 0 6 2.6 6 6z" />
        </g>
    </svg>
}

export default PhoneInTalk;
