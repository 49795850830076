import { forEach, isFunction, isObject } from 'lodash';

export function decorate(target, method, fn) {
    const original = target[method];
    // eslint-disable-next-line
    target[method] = fn(target, original);
}

export function decorateToObject(target) {
    decorate(target, 'toObject', (ctx, wrapped) => (...args) => {
        const [obj, ...rest] = args;
        // eslint-disable-next-line guard-for-in
        for (const prop in obj) {
            const value = obj[prop];

            if (!Object.prototype.hasOwnProperty.call(obj, prop) && !isFunction(value)) {
                Object.defineProperty(obj, prop, { value });
            }
        }
        return wrapped.apply(ctx, [obj, ...rest]);
    });
}

export function traverse(obj, predicate, fn) {
    forEach(obj, ( value, key) => {
        if (predicate(obj, key)) {
            fn(obj, key)
        }
        if (isObject(value)) {
            traverse(value, predicate, fn);
        }
    });
}

export const isToObject = (obj, key) => key === 'toObject' && isFunction(obj[key]);
