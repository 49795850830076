import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Column, Row } from './styled';
import { Icon } from '..';

// eslint-disable-next-line react/prefer-stateless-function
export default class NewCallMessage extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired
    };

    render() {
        const { localization } = this.props;
        const topMessage = localization.getText('trainer.call_messages.new_call.top');
        const bottomMessage = localization.getText(
            'trainer.call_messages.new_call.bottom'
        );

        return (
            <Card className="message">
                <Column $marginRight={30}>
                    <Icon name="PhoneInTalk" />
                </Column>
                <Column>
                    <Row>
                        <strong>{topMessage}</strong>
                    </Row>
                    <Row>
                        <span>{bottomMessage}</span>
                    </Row>
                </Column>
            </Card>
        );
    }
}
