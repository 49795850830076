import React from 'react';

function EmojiNormal4() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="45" viewBox="0 0 49 46">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <circle cx="20" cy="20" r="20" fill="#F8F9FF" fillRule="nonzero" />
            <path
                fill="#D6DAFF"
                fillRule="nonzero"
                d="M20 0C9 0 0 9 0 20s9 20 20 20 20-9 20-20S31 0 20 0zm0 37C9.9 37 1.8 28.8 1.8 18.8 1.8 8.7 10 .6 20 .6s18.2 8.2 18.2 18.2C38.3 28.8 30.1 37 20 37z"
            />
            <ellipse
                cx="20"
                cy="43.5"
                fill="#D6DAFF"
                fillRule="nonzero"
                rx="16"
                ry="1.5"
            />
            <circle
                cx="20"
                cy="20"
                r="20"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="31.5"
                cy="24.5"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <ellipse
                cx="7.5"
                cy="24.5"
                fill="#C1C4E6"
                fillRule="nonzero"
                rx="2.5"
                ry="1.5"
            />
            <path
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 20.8c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8M30 20.8c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                stroke="#4550E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M42.6 28.6l4.5-1.5c.2-.1.3-.2.3-.4s-.1-.3-.3-.4l-3.7-1.2c-.5-.2-.9-.6-1.1-1.1l-1.2-3.7c-.1-.2-.2-.3-.4-.3s-.3.1-.4.3L39.1 24c-.2.5-.6.9-1.1 1.1l-3.7 1.2c-.2.1-.3.2-.3.4s.1.3.3.4l3.7 1.2c.5.2.9.6 1.1 1.1l1.2 3.7c.1.2.2.3.4.3s.3-.1.4-.3l1.5-4.5z"
            />
            <g stroke="#4550E6" strokeLinecap="round" strokeLinejoin="round">
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M19.5 34.6c9.2 0 10.4-3.8 9.9-7-.3-1.9-2.5-3.1-4.6-2.4-1.5.5-3.4.8-5.3.8s-3.8-.3-5.3-.8c-2-.7-4.2.5-4.6 2.4-.5 3.1.7 7 9.9 7z"
                />
                <path d="M29.5 28.3c-1 .5-2.5 1-4.6 1.4-1.5.2-3.2.4-5.4.4s-3.9-.2-5.4-.4c-2.1-.4-3.6-.9-4.6-1.4" />
                <path d="M14.8 25.4l-.7 4.2-.6 4.1M19.5 34.6v-8.5M24.2 25.4l.7 4.2.6 4.1" />
            </g>
        </g>
    </svg>
}

export default EmojiNormal4;
