import styled from 'styled-components';

import { colors } from '../../utils';

const Title = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: ${colors.black.default};
`;

export default Title;
