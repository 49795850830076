import styled from 'styled-components';
import RowWrapper from '../../Row';

import { colors } from '../../../../utils';

const Row = styled(RowWrapper)`
    margin-right: ${({ $marginRight }) => $marginRight || 0}px;

    & > strong {
        color: ${colors.blue.royal_blue};

        font-weight: 900;
        font-size: 16px;
    }

    & > span {
        color: ${colors.blue.royal_blue};

        font-weight: normal;
        font-size: 16px;
    }
`;

export default Row;
