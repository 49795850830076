import {types} from '../actions/asr';
import {types as callTypes} from '../actions/call';
import {
    getDefaultMicResults,
    onCallEnded,
    updateMicResults,
    updatePhraseSpottingResults,
    updateVoiceMetrics
} from '../../utils';
import {normalizeSpottingConfig, prepareBlackList, prepareWhiteList, updateTranscript,} from '../../utils/asr';

export const initialState = {
    config: {
        languageCode: null,
        lingwareTopic: null,
        spottingConfig: null,
        asr: null
    },
    micResults: {},
    micResultsRaw: null,
    micResultsHistory: [],
    micVoiceMetrics: {},
    speakerVoiceMetrics: {},
    timelineTranscripts: null,
    micSessionNumber: 0,
    speakerSessionNumber: 0,
    battleCard: null,
    asrConfigErrors: {}
};

export default function asrReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
        case types.GET_USER_ASR_CONFIG_SUCCESS: {
            const spottingConfig = normalizeSpottingConfig(payload.spottingConfig);

            return {
                ...state,
                config: {
                    languageCode: payload.languageCode,
                    lingwareTopic: payload.lingwareTopic,
                    spottingConfig,
                    asr: payload.asr
                },
                asrConfigErrors: payload.errors,
                micResults: getDefaultMicResults(spottingConfig)
            };
        }
        case types.UPDATE_USER_ASR_CONFIG_SUCCESS: {
            return {
                ...state,
                asrConfigErrors: payload.errors,
                config: {
                    languageCode: payload.languageCode,
                    lingwareTopic: payload.lingwareTopic,
                    spottingConfig: normalizeSpottingConfig(payload.spottingConfig),
                    asr: payload.asr
                }
            };
        }
        case types.GET_USER_ASR_CONFIG_FAIL:
        case types.UPDATE_USER_ASR_CONFIG_FAIL:
            return {
                ...state,
                config: {
                    languageCode: null,
                    lingwareTopic: null,
                    spottingConfig: null,
                    asr: null
                }
            };

        case types.MIC_UPDATE_ASR_RESULTS:
            return {
                ...state,
                ...updateMicResults(state, payload),
                timelineTranscripts: updateTranscript(
                    state.timelineTranscripts,
                    'micResults',
                    payload
                ),
            };
        case types.MIC_UPDATE_PHRASE_SPOTTING_RESULTS:
            return {
                ...state,
                ...updatePhraseSpottingResults(state, payload),
            };
        case types.SPEAKER_UPDATE_ASR_RESULTS:
            return {
                ...state,
                timelineTranscripts: updateTranscript(
                    state.timelineTranscripts,
                    'speakerResults',
                    payload
                ),
            };

        case types.MIC_UPDATE_ASR_VOICE_METRICS:
            return {
                ...state,
                micVoiceMetrics: updateVoiceMetrics(state.micVoiceMetrics, payload)
            };
        case types.SPEAKER_UPDATE_ASR_VOICE_METRICS:
            return {
                ...state,
                speakerVoiceMetrics: updateVoiceMetrics(
                    state.speakerVoiceMetrics,
                    payload
                )
            };

        case types.MIC_UPDATE_ASR_SPEECH_PACE:
            return {
                ...state,
                micVoiceMetrics: {
                    ...state.micVoiceMetrics,
                    speechPace: payload.speechPace,
                    newSpeechRate: payload.wordsPerMinute
                }
            };
        case types.CLEAR_HISTORY:
            return {
                ...state,
                micResultsHistory: []
            };

        case callTypes.CALL_STARTED:
            return {
                ...state,
                micResults: {
                    whitelist: prepareWhiteList(state.config.spottingConfig),
                    blacklist: prepareBlackList(state.config.spottingConfig),
                    language: state.config.languageCode
                },
                timelineTranscripts: [],
                micResultsRaw: null, // DEVELOP-2988 fix.
                micSessionNumber: state.micSessionNumber + 1,
                speakerSessionNumber: state.speakerSessionNumber + 1,
                battleCard: null
            };
        case callTypes.CALL_ENDED:
            return onCallEnded(state, payload);

        default:
            return state;
    }
}
