import React from 'react';
import PropTypes from 'prop-types';

function TripleThumbsUp(props) {
  return <svg width={props.size} viewBox="0 0 93 94">
        <defs>
            <path
                id="a"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="c"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="e"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="g"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="i"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="k"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="m"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="o"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="q"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="s"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="u"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="w"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="y"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="A"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="C"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="E"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="G"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="I"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="K"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="M"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="O"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="Q"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="S"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="U"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="W"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="Y"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375"
            />
            <path
                id="aa"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="ac"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="ae"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
            <path
                id="ag"
                d="M.043 47.375c0 25.636 20.782 46.42 46.42 46.42 25.637 0 46.42-20.784 46.42-46.42 0-25.638-20.783-46.42-46.42-46.42C20.825.955.043 21.737.043 47.375z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#F8F9FF"
                d="M92.93 47.375c0 25.637-20.783 46.42-46.42 46.42-25.636 0-46.42-20.783-46.42-46.42C.09 21.737 20.875.955 46.51.955c25.637 0 46.42 20.782 46.42 46.42"
            />
            <path
                fill="#D6DAFF"
                d="M74.119 17.452a.7.7 0 0 1 .504.995l-2.818 5.801a.7.7 0 0 1-1.33-.278l-.24-6.362a.699.699 0 0 1 .828-.715l3.056.56z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                d="M74.119 17.452a.7.7 0 0 1 .504.995l-2.818 5.801a.7.7 0 0 1-1.33-.278l-.24-6.362a.699.699 0 0 1 .828-.715l3.056.56z"
            />
            <path
                fill="#D6DAFF"
                d="M59.801 17.857a.701.701 0 0 0-.447 1.022l3.142 5.633c.157.281.484.42.794.334a.7.7 0 0 0 .518-.689l-.121-6.364a.7.7 0 0 0-.865-.667l-3.02.73z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                d="M59.801 17.857a.701.701 0 0 0-.447 1.022l3.142 5.633c.157.281.484.42.794.334a.7.7 0 0 0 .518-.689l-.121-6.364a.7.7 0 0 0-.865-.667l-3.02.73z"
            />
            <path
                fill="#D6DAFF"
                d="M47.432 45.76a.702.702 0 0 1 1.046.387l1.877 6.17a.702.702 0 0 1-1.167.7l-4.498-4.505a.697.697 0 0 1 .12-1.086l2.622-1.666z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                d="M47.432 45.76a.702.702 0 0 1 1.046.387l1.877 6.17a.702.702 0 0 1-1.167.7l-4.498-4.505a.697.697 0 0 1 .12-1.086l2.622-1.666z"
            />
            <path
                fill="#D6DAFF"
                d="M37.204 55.786a.701.701 0 0 0 .367 1.054l6.133 1.997a.699.699 0 0 0 .72-1.15l-4.414-4.588a.7.7 0 0 0-1.089.098l-1.717 2.59z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                d="M37.204 55.786a.701.701 0 0 0 .367 1.054l6.133 1.997a.699.699 0 0 0 .72-1.15l-4.414-4.588a.7.7 0 0 0-1.089.098l-1.717 2.59z"
            />
            <path
                fill="#D6DAFF"
                d="M34.562 20.766a.7.7 0 0 0-1.085.262l-2.591 5.905a.7.7 0 0 0 1.074.831l5-3.94a.703.703 0 0 0 .009-1.093l-2.407-1.965z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                d="M34.562 20.766a.7.7 0 0 0-1.085.262l-2.591 5.905a.7.7 0 0 0 1.074.831l5-3.94a.703.703 0 0 0 .009-1.093l-2.407-1.965z"
            />
            <path
                fill="#D6DAFF"
                d="M22.632 16.553a.702.702 0 0 0-.706.865l1.51 6.27a.7.7 0 0 0 1.359.012l1.596-6.163a.7.7 0 0 0-.653-.875l-3.106-.11z"
            />
            <path
                stroke="#4550E6"
                strokeLinejoin="round"
                d="M22.632 16.553a.702.702 0 0 0-.706.865l1.51 6.27a.7.7 0 0 0 1.359.012l1.596-6.163a.7.7 0 0 0-.653-.875l-3.106-.11z"
            />
            <g>
                <g>
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M28.054 37.814c-.144-3.154-.707-9.88-3.3-9.501a1.892 1.892 0 0 0-1.598 2.145l.09.625c.67 4.574-.615 9.647-4.454 10.209l1.395 9.565.491-.071c2.399-.35 3.777 1.147 8.074.518l7.278-1.064a1.681 1.681 0 1 0-.486-3.327l.83-.12a1.682 1.682 0 0 0-.486-3.327l.833-.123a1.68 1.68 0 1 0-.487-3.327l.833-.122a1.682 1.682 0 0 0-.487-3.327l-8.526 1.247z"
                        mask="url(#b)"
                    />
                </g>
                <g>
                    <mask id="d" fill="#fff">
                        <use xlinkHref="#c" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M26.39 38.057l1.665-.243"
                        mask="url(#d)"
                    />
                </g>
                <g>
                    <mask id="f" fill="#fff">
                        <use xlinkHref="#e" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M35.543 46.913l-.832.122"
                        mask="url(#f)"
                    />
                </g>
                <g>
                    <mask id="h" fill="#fff">
                        <use xlinkHref="#g" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M35.89 43.465l-.833.122"
                        mask="url(#h)"
                    />
                </g>
                <g>
                    <mask id="j" fill="#fff">
                        <use xlinkHref="#i" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M36.235 40.016l-.832.12"
                        mask="url(#j)"
                    />
                </g>
                <g>
                    <mask id="l" fill="#fff">
                        <use xlinkHref="#k" />
                    </mask>
                    <path
                        fill="#D6DAFF"
                        d="M16.79 53.053l-18.083 2.644a1.162 1.162 0 0 1-1.316-.98l-1.55-10.597a1.162 1.162 0 0 1 .981-1.317l18.371-2.684a.87.87 0 0 1 .986.734l1.591 10.884a1.161 1.161 0 0 1-.98 1.316"
                        mask="url(#l)"
                    />
                </g>
                <g>
                    <mask id="n" fill="#fff">
                        <use xlinkHref="#m" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        d="M16.79 53.053l-18.083 2.644a1.162 1.162 0 0 1-1.316-.98l-1.55-10.597a1.162 1.162 0 0 1 .981-1.317l18.371-2.684a.87.87 0 0 1 .986.734l1.591 10.884a1.161 1.161 0 0 1-.98 1.316z"
                        mask="url(#n)"
                    />
                </g>
                <g>
                    <mask id="p" fill="#fff">
                        <use xlinkHref="#o" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M17.816 52.053l1.348-.196a1.162 1.162 0 0 0 .98-1.317l-1.306-8.932a1.16 1.16 0 0 0-1.315-.98l-1.349.196 1.642 11.23z"
                        mask="url(#p)"
                    />
                </g>
                <g>
                    <mask id="r" fill="#fff">
                        <use xlinkHref="#q" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.816 52.053l1.348-.196a1.162 1.162 0 0 0 .98-1.317l-1.306-8.932a1.16 1.16 0 0 0-1.315-.98l-1.349.196 1.642 11.23z"
                        mask="url(#r)"
                    />
                </g>
                <g>
                    <mask id="t" fill="#fff">
                        <use xlinkHref="#s" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        d="M28.054 37.814c-.144-3.154-.707-9.88-3.3-9.501a1.892 1.892 0 0 0-1.598 2.145l.09.625c.67 4.574-.615 9.647-4.454 10.209l1.395 9.565.491-.071c2.399-.35 3.777 1.147 8.074.518l7.278-1.064a1.681 1.681 0 1 0-.486-3.327l.83-.12a1.682 1.682 0 0 0-.486-3.327l.833-.123a1.68 1.68 0 1 0-.487-3.327l.833-.122a1.682 1.682 0 0 0-.487-3.327l-8.526 1.247z"
                        mask="url(#t)"
                    />
                </g>
                <g>
                    <mask id="v" fill="#fff">
                        <use xlinkHref="#u" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M65.257 37.814c.144-3.154.707-9.88 3.3-9.501a1.893 1.893 0 0 1 1.599 2.145l-.092.625c-.668 4.574.616 9.647 4.455 10.209l-1.395 9.565-.49-.071c-2.4-.35-3.778 1.147-8.075.518L57.28 50.24a1.68 1.68 0 1 1 .487-3.327l-.833-.12a1.682 1.682 0 0 1 .487-3.328l-.832-.122a1.68 1.68 0 0 1 .487-3.327l-.832-.122a1.681 1.681 0 0 1 .486-3.327l8.526 1.247z"
                        mask="url(#v)"
                    />
                </g>
                <g>
                    <mask id="x" fill="#fff">
                        <use xlinkHref="#w" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M66.921 38.057l-1.664-.243"
                        mask="url(#x)"
                    />
                </g>
                <g>
                    <mask id="z" fill="#fff">
                        <use xlinkHref="#y" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M57.767 46.913l.832.122"
                        mask="url(#z)"
                    />
                </g>
                <g>
                    <mask id="B" fill="#fff">
                        <use xlinkHref="#A" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M57.422 43.465l.832.122"
                        mask="url(#B)"
                    />
                </g>
                <g>
                    <mask id="D" fill="#fff">
                        <use xlinkHref="#C" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M57.077 40.016l.832.12"
                        mask="url(#D)"
                    />
                </g>
                <g>
                    <mask id="F" fill="#fff">
                        <use xlinkHref="#E" />
                    </mask>
                    <path
                        fill="#D6DAFF"
                        d="M76.521 53.053l18.083 2.644a1.162 1.162 0 0 0 1.316-.98l1.55-10.597a1.162 1.162 0 0 0-.981-1.317l-18.37-2.684a.87.87 0 0 0-.987.734L75.54 51.737a1.161 1.161 0 0 0 .98 1.316"
                        mask="url(#F)"
                    />
                </g>
                <g>
                    <mask id="H" fill="#fff">
                        <use xlinkHref="#G" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        d="M76.521 53.053l18.083 2.644a1.162 1.162 0 0 0 1.316-.98l1.55-10.597a1.162 1.162 0 0 0-.981-1.317l-18.37-2.684a.87.87 0 0 0-.987.734L75.54 51.737a1.161 1.161 0 0 0 .98 1.316z"
                        mask="url(#H)"
                    />
                </g>
                <g>
                    <mask id="J" fill="#fff">
                        <use xlinkHref="#I" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M75.494 52.053l-1.347-.196a1.161 1.161 0 0 1-.98-1.317l1.306-8.932a1.161 1.161 0 0 1 1.316-.98l1.348.196-1.643 11.23z"
                        mask="url(#J)"
                    />
                </g>
                <g>
                    <mask id="L" fill="#fff">
                        <use xlinkHref="#K" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M75.494 52.053l-1.347-.196a1.161 1.161 0 0 1-.98-1.317l1.306-8.932a1.161 1.161 0 0 1 1.316-.98l1.348.196-1.643 11.23z"
                        mask="url(#L)"
                    />
                </g>
                <g>
                    <mask id="N" fill="#fff">
                        <use xlinkHref="#M" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        d="M65.257 37.814c.144-3.154.707-9.88 3.3-9.501a1.893 1.893 0 0 1 1.599 2.145l-.092.625c-.668 4.574.616 9.647 4.455 10.209l-1.395 9.565-.49-.071c-2.4-.35-3.778 1.147-8.075.518L57.28 50.24a1.68 1.68 0 1 1 .487-3.327l-.833-.12a1.682 1.682 0 0 1 .487-3.328l-.832-.122a1.68 1.68 0 0 1 .487-3.327l-.832-.122a1.681 1.681 0 0 1 .486-3.327l8.526 1.247z"
                        mask="url(#N)"
                    />
                </g>
                <g>
                    <mask id="P" fill="#fff">
                        <use xlinkHref="#O" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M46.973 68.652c.335-4.345 1.404-13.601 4.967-12.965a2.612 2.612 0 0 1 2.112 3.028l-.153.858c-1.123 6.283.43 13.339 5.702 14.279l-2.34 13.139-.675-.12c-3.294-.588-5.261 1.418-11.161.365l-9.998-1.784a2.32 2.32 0 0 1 .816-4.57l-1.142-.204a2.322 2.322 0 1 1 .816-4.57l-1.143-.203a2.32 2.32 0 0 1 .816-4.57l-1.142-.205a2.322 2.322 0 1 1 .815-4.568l11.71 2.09z"
                        mask="url(#P)"
                    />
                </g>
                <g>
                    <mask id="R" fill="#fff">
                        <use xlinkHref="#Q" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M49.258 69.059l-2.285-.407"
                        mask="url(#R)"
                    />
                </g>
                <g>
                    <mask id="T" fill="#fff">
                        <use xlinkHref="#S" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M36.243 80.882l1.142.204"
                        mask="url(#T)"
                    />
                </g>
                <g>
                    <mask id="V" fill="#fff">
                        <use xlinkHref="#U" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M35.916 76.109l1.142.203"
                        mask="url(#V)"
                    />
                </g>
                <g>
                    <mask id="X" fill="#fff">
                        <use xlinkHref="#W" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M35.59 71.335l1.143.204"
                        mask="url(#X)"
                    />
                </g>
                <g>
                    <mask id="Z" fill="#fff">
                        <use xlinkHref="#Y" />
                    </mask>
                    <path
                        fill="#D6DAFF"
                        d="M61.418 90.091l25.705 4.589a1.162 1.162 0 0 0 1.346-.939l2.753-15.423a1.16 1.16 0 0 0-.938-1.346l-25.705-4.589a1.162 1.162 0 0 0-1.347.94l-2.753 15.422c-.113.63.309 1.234.939 1.346"
                        mask="url(#Z)"
                    />
                </g>
                <g>
                    <mask id="ab" fill="#fff">
                        <use xlinkHref="#aa" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M61.418 90.091l25.705 4.589a1.162 1.162 0 0 0 1.346-.939l2.753-15.423a1.16 1.16 0 0 0-.938-1.346l-25.705-4.589a1.162 1.162 0 0 0-1.347.94l-2.753 15.422c-.113.63.309 1.234.939 1.346z"
                        mask="url(#ab)"
                    />
                </g>
                <g>
                    <mask id="ad" fill="#fff">
                        <use xlinkHref="#ac" />
                    </mask>
                    <path
                        fill="#FFF"
                        d="M60.48 88.745l-2.286-.409a1.158 1.158 0 0 1-.937-1.346L59.6 73.852a1.162 1.162 0 0 1 1.346-.939l2.285.409-2.753 15.423z"
                        mask="url(#ad)"
                    />
                </g>
                <g>
                    <mask id="af" fill="#fff">
                        <use xlinkHref="#ae" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M60.48 88.745l-2.286-.409a1.158 1.158 0 0 1-.937-1.346L59.6 73.852a1.162 1.162 0 0 1 1.346-.939l2.285.409-2.753 15.423z"
                        mask="url(#af)"
                    />
                </g>
                <g>
                    <mask id="ah" fill="#fff">
                        <use xlinkHref="#ag" />
                    </mask>
                    <path
                        stroke="#4550E6"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M46.973 68.652c.335-4.345 1.404-13.601 4.967-12.965a2.612 2.612 0 0 1 2.112 3.028l-.153.858c-1.123 6.283.43 13.339 5.702 14.279l-2.34 13.139-.675-.12c-3.294-.588-5.261 1.418-11.161.365l-9.998-1.784a2.32 2.32 0 0 1 .816-4.57l-1.142-.204a2.322 2.322 0 1 1 .816-4.57l-1.143-.203a2.32 2.32 0 0 1 .816-4.57l-1.142-.205a2.322 2.322 0 1 1 .815-4.568l11.71 2.09z"
                        mask="url(#ah)"
                    />
                </g>
            </g>
        </g>
    </svg>
}

TripleThumbsUp.propTypes = {
    size: PropTypes.number
};

TripleThumbsUp.defaultProps = {
    size: 93
};

export default TripleThumbsUp;
