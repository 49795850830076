import styled from 'styled-components';

import { colors } from '../../../utils';

export default styled.div`
    color: ${colors.gray.jumbo};
    font-size: 11px;

    cursor: pointer;

    user-select: none;

    &:hover {
        color: ${colors.blue.palatinate_blue};
    }
`;
