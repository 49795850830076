import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../../utils';

const Item = styled.div`
    width: 100%;

    padding: 8px 24px;

    color: ${colors.gray.dark};

    font-size: 14px;
    font-weight: 600;

    transition: color 0.25s ease;

    user-select: none;

    cursor: ${props => (props.$enabled ? 'pointer' : 'default')};

    ${props =>
        !props.$enabled &&
        `
        pointer-events: none;
        touch-action: none;
        opacity: 0.5;
    `} &:hover, &:hover a {
        color: ${colors.blue.palatinate_blue};
    }
`;

Item.propTypes = {
    $enabled: PropTypes.bool
};

Item.defaultProps = {
    $enabled: true
};

export default Item;
