import styled from 'styled-components';
import colors from '../../../utils/colors';

const Wrapper = styled.div`
    top: 90px;
    width: 316px;
    position: absolute;
    min-height: 205px;
    box-shadow: 0 2px 24px 0 #cdd3df;
    background-color: ${colors.white.basic};
    padding: 15px;
    z-index: 10;
    border-radius: 4px;
`;

export default Wrapper;
