import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

const Title = styled.div`
    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    color: ${colors.blue.madison};

    font-size: 16px;
    font-weight: bold;

    cursor: ${props => (props.$enabled ? 'pointer' : 'default')};
    touch-action: ${props => (props.$enabled ? 'auto' : 'none')};
    pointer-events: ${props => (props.$enabled ? 'auto' : 'none')};

    user-select: none;
`;

Title.propTypes = {
    $enabled: PropTypes.bool
};

Title.defaultProps = {
    $enabled: false
};

export default Title;
