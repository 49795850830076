import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../utils';

function Cross(props) {
  return <svg width={props.size} height={props.size} viewBox="0 0 5 6">
        <g fill={props.color} fillRule="evenodd">
            <path d="M4.268.525l.707.707L.732 5.475l-.707-.707z" />
            <path d="M4.975 4.768l-.707.707L.025 1.232.732.525z" />
        </g>
    </svg>
}

Cross.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Cross.defaultProps = {
    size: 5,
    color: colors.gray.charcoal
};

export default Cross;
