import styled from 'styled-components';
import { colors } from '../../../../utils';

export const StartPhraseKeywords = styled.div`
    cursor: pointer;
    font-size: 13px;
    padding: 1px 2px;
    border-radius: 10px;
    border: 2px dashed
        ${props =>
            props.$isCallStarted ? colors.red.radical_red : colors.green.aquamarine};
    display: flex;
    margin-left: 6px;

    svg {
        display: block;
        fill: ${props =>
            props.$isCallStarted ? colors.red.radical_red : colors.green.aquamarine};
    }
`;

export const StartPhraseKeywordsPopup = styled.div`
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${colors.blue.hawkes_blue};
    background-color: ${colors.white.basic};
    max-width: 240px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    box-shadow: -2px 0 7px 0 #cdd3df;

    > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 6px;
    }

    span {
        padding: 4px 6px;
        cursor: pointer;
        border: 1px solid ${colors.gray.mischka};
        border-radius: 3px;
        margin: 0 2px 2px 0;
    }
`;
