import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Wrapper,
    Content,
    Icon,
    Title,
    Subtext,
    EnableButton,
    Timer,
    Countdown,
    AddSelection,
    AddTime,
    ResumeText,
    ErrorMessage,
    SetInfinitySnooze,
    Placeholder,
    RoundWrapper,
    LogoutButton
} from './styled';

import { SNOOZE_TIMER_CHOICES } from '../../utils/snooze';
import { formatTime } from '../../utils/date';
import errorCodes from '../../utils/errorCodes';
import { INFINITY_SNOOZE_THRESHOLD } from '../../background/constants';
import I2XLogo from '../common/icons/I2XLogo';

const IconTimer = (
    <Icon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width={48}
            height={48}
            viewBox="0 0 48 48">
            <circle cx="24" cy="24" r="24" fill="#FFC400" />
            <path
                fill="#222"
                d="m37.6 32.5-4.3 4.3L21.5 28a6 6 0 0 1-2.2-3.6c-.3-1.5 0-3 .7-4.2l7.3-10.6 5 3.8-7 9.8 12.3 9.4Z"
            />
        </svg>
    </Icon>
);

function TemporarySnooze(props) {
    const {
        localization,
        snoozeTimer,
        onAddTimeClick,
        onInfinitySnoozeClick,
        isEnabled,
        children
    } = props;
    return (
        <React.Fragment>
            <Content>
                {IconTimer}
                <Title>{localization.getText('trainer.inactive.temporary.title')}</Title>
                <Subtext>
                    {localization.getText('trainer.inactive.temporary.subtext')}
                </Subtext>
            </Content>
            <Timer>
                <Countdown>{formatTime(snoozeTimer)}</Countdown>
                <AddSelection>
                    {SNOOZE_TIMER_CHOICES.map(choice => (
                        <AddTime
                            key={choice}
                            onClick={() => onAddTimeClick(choice)}
                            $isEnabled={isEnabled}>
                            {`+${formatTime(choice, true)}`}
                        </AddTime>
                    ))}
                </AddSelection>
                <div>
                    <SetInfinitySnooze onClick={onInfinitySnoozeClick} isEnabled />
                </div>
                {children}
            </Timer>
        </React.Fragment>
    );
}

TemporarySnooze.propTypes = {
    localization: PropTypes.object.isRequired,
    snoozeTimer: PropTypes.number.isRequired,
    onAddTimeClick: PropTypes.func.isRequired,
    onInfinitySnoozeClick: PropTypes.func.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    children: PropTypes.any
};

TemporarySnooze.defaultProps = {
    children: undefined
};

function InfinitySnooze(props) {
    const { localization } = props;

    return (
        <Content>
            {IconTimer}
            <Title>{localization.getText('trainer.inactive.permanent.title')}</Title>
            <Subtext>
                {localization.getText('trainer.inactive.permanent.subtext')}
            </Subtext>
            <Placeholder>
                <div>
                    {localization.getText('trainer.inactive.permanent.placeholderTitle')}
                </div>
                <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                        __html: localization.getText(
                            'trainer.inactive.permanent.placeholderBody'
                        )
                    }}
                />
            </Placeholder>
        </Content>
    );
}

InfinitySnooze.propTypes = {
    localization: PropTypes.object.isRequired
};

class InactiveTrainer extends Component {
    static propTypes = {
        resumeTrainer: PropTypes.func.isRequired,
        localization: PropTypes.object.isRequired,
        snoozeTimer: PropTypes.number,
        addSnoozeTimer: PropTypes.func.isRequired,
        setInfinitySnooze: PropTypes.func.isRequired,
        errorCode: PropTypes.number,
        doLogout: PropTypes.func.isRequired,
        authWithPassword: PropTypes.bool
    };

    static defaultProps = {
        snoozeTimer: 0,
        errorCode: null,
        authWithPassword: false
    };

    state = {
        showLimit: false
    };

    _removeLimit = () => this.setState({ showLimit: false });

    _handleAddSnoozeTimer = time => {
        const { addSnoozeTimer, snoozeTimer } = this.props;

        if (time + snoozeTimer > 24 * 60 * 60) {
            return this.setState({ showLimit: true });
        }

        addSnoozeTimer(time);
        return this._removeLimit();
    };

    _renderError = () => {
        const { errorCode, localization } = this.props;
        const { showLimit } = this.state;

        if (errorCode && errorCode !== errorCodes.user.PATCH_USER_PROFILE_FAILED) {
            const errorMessage = localization.getText(`errors.${errorCode}`);
            return <ErrorMessage>{errorMessage}</ErrorMessage>;
        }

        if (showLimit) {
            return (
                <ErrorMessage>
                    {localization.getText('trainer.inactive.temporary.limit')}
                </ErrorMessage>
            );
        }
    };

    _setInfinitySnooze = () => {
        this.props.setInfinitySnooze();
        this.setState({ showLimit: false });
    };

    render() {
        const { snoozeTimer, localization, resumeTrainer } = this.props;
        const { showLimit } = this.state;
        const infinitySnooze = snoozeTimer >= INFINITY_SNOOZE_THRESHOLD;
        const isEnabled = !showLimit;
        return (
            <Wrapper>
                <div style={{ margin: '20px 0 10px 0' }}>
                    <I2XLogo size={70} color="#fff" />
                </div>
                {snoozeTimer <= 0 ? (
                    <ResumeText>
                        {localization.getText('trainer.inactive.resuming')}
                    </ResumeText>
                ) : (
                    <RoundWrapper>
                        {infinitySnooze ? (
                            <InfinitySnooze localization={localization} />
                        ) : (
                            <TemporarySnooze
                                localization={localization}
                                isEnabled={isEnabled}
                                onAddTimeClick={this._handleAddSnoozeTimer}
                                onInfinitySnoozeClick={this._setInfinitySnooze}
                                snoozeTimer={snoozeTimer}>
                                {this._renderError()}
                            </TemporarySnooze>
                        )}
                        <EnableButton onClick={() => resumeTrainer()}>
                            {localization.getText('trainer.inactive.button')}
                        </EnableButton>
                        {this.props.authWithPassword && (
                            <LogoutButton onClick={() => this.props.doLogout()}>
                                {localization.getText('header.menu.logout')}
                            </LogoutButton>
                        )}
                    </RoundWrapper>
                )}
            </Wrapper>
        );
    }
}

export default InactiveTrainer;
