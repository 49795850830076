import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'remote-redux-devtools';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {
    api,
    auth,
    user,
    frontendConnection,
    mainConnection,
    forwardingConnection,
    audioSystemInfoConnection,
    audioDeviceConnections,
    callManager,
    reloader,
    logger,
    errorsIntercepter,
    snoozeTimer
} from './middlewares';
import rootReducer from './reducers';
import sagas from './sagas';
import { captureException } from '../../tracker/raven';
import { types as asrTypes } from './actions/asr';
import { types as deviceTypes } from './actions/device';
import { types as mainTypes } from './actions/main';
import { types as snoozeTypes } from './actions/snooze';

const sagaMiddleware = createSagaMiddleware({
    onError: error => captureException(error)
});

export default function storeFactory(frontendStore, initialState = {}) {
    const waitStore = predicate => {
        return new Promise(resolve => {
            // eslint-disable-next-line no-use-before-define
            if (predicate(store.getState())) {
                resolve();
                return;
            }
            // eslint-disable-next-line no-use-before-define
            const unsubscribe = store.subscribe(() => {
                // eslint-disable-next-line no-use-before-define
                if (predicate(store.getState())) {
                    unsubscribe();
                    resolve();
                }
            });
        });
    };

    // const composeEnhancers = composeWithDevTools({ realtime: true });
    const middlewares = [
        auth,
        errorsIntercepter,
        api,
        frontendConnection(frontendStore),
        mainConnection,
        forwardingConnection,
        audioSystemInfoConnection,
        audioDeviceConnections,
        user,
        callManager,
        reloader(waitStore),
        snoozeTimer
    ];
    const middlewareEnhancer = applyMiddleware(
        ...middlewares,
        thunk,
        sagaMiddleware,
        logger
    );

    const ENVS = ['development'];
    const devtoolOptions = {
        name: 'background',
        actionsDenylist: [
            asrTypes.SOUND_CHUNK,
            deviceTypes.AUDIO_CHUNK_RECEIVED,
            snoozeTypes.SNOOZE_COUNTDOWN,
            mainTypes.EVENT_FORWARDED
        ]
    };
    const composeEnhancers = ENVS.includes(process.env.NODE_ENV)
        ? composeWithDevTools(devtoolOptions)(middlewareEnhancer)
        : middlewareEnhancer;

    const store = createStore(rootReducer, initialState, composeEnhancers);
    sagaMiddleware.run(sagas);
    return store;
}
