const userAgentDataBrands = navigator?.userAgentData?.brands || []


function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }

    return false;
}

export const EnableRecorderLocalStorageKey = '_enableRecorder'
export const enableRecording = () => {
    if(localStorage.getItem(EnableRecorderLocalStorageKey) !== '1') {
        localStorage.setItem(EnableRecorderLocalStorageKey, '1')
        return true;
    }
    return false;
};
export const disableRecording = () => {
    if(localStorage.getItem(EnableRecorderLocalStorageKey) === '1') {
        localStorage.removeItem(EnableRecorderLocalStorageKey)
        return true;
    }
    return false;
};

export const IS_LEGACY_LISTENER_MODE = (userAgentDataBrands.length <= 2 && userAgentDataBrands[0]?.brand === 'Chromium') || !!localStorage.getItem('debug_win_app')
export const IS_ELECTRON_APP = isElectron()
export const IS_ELECTRON_APP_WITH_RECORDING = IS_ELECTRON_APP && localStorage.getItem(EnableRecorderLocalStorageKey) === '1';
export const IS_UI_ONLY_MODE = !IS_LEGACY_LISTENER_MODE && !IS_ELECTRON_APP_WITH_RECORDING
