import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';
import sagas from './sagas';
import middlewares from './middlewares';

import { captureException } from '../tracker/raven';
import { SET_SNOOZE_TIMER_SUCCESS } from './actions/actionTypes/trainer';

/**
 * onError => catch all errors uncaught in sagas
 *
 * https://docs.sentry.io/clients/javascript/integrations/react/#redux-sagas-middleware
 * https://redux-saga.js.org/docs/api/
 */

const sagaMiddleware = createSagaMiddleware({
    onError: error => {
        console.error(error);
        captureException(error);
    }
});

const middlewareEnhancer = applyMiddleware(...middlewares, sagaMiddleware);
const ENVS = ['development'];
const devtoolsOptions = {
    actionsDenylist: [SET_SNOOZE_TIMER_SUCCESS]
};
const enhancers = ENVS.includes(process.env.NODE_ENV)
    ? composeWithDevTools(devtoolsOptions)(middlewareEnhancer)
    : middlewareEnhancer;
const store = createStore(reducers, undefined, enhancers);
const persistor = persistStore(store);

/* sagaMiddleware HAS to run after createStore */
sagaMiddleware.run(sagas);

export default () => ({ store, persistor });
